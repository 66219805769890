<template>
  <apex-chart type="area"
              :style="{height: isMobile ? '300px' : '500px', width: '100%', marginTop: '50px'}"
              :options="chartOptions"
              :series="chartData"
  >

  </apex-chart>
</template>

<script>
import Menu from "@/assets/img/report/Menu.svg";

export default {
  name: "LineFillChart",
  props: {
    chartCallData: {
      type: Array,
      required: true
    },
    chartXLabel: {
      type: Array,
      required: true
    }
  },
  data(){
    return {
      Menu: Menu
    }
  },
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    chartData() {
      return [
        {
          name: this.$t('report.booking.total_message'),
          data: this.chartCallData
        }
      ]
    },
    chartOptions() {
      return {
        chart: {
          id: 'chart-area-call',
          toolbar: {
            tools: {
              download: '<img src="' + Menu + '" width="20">',
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          }
        },
        xaxis: {
          categories: this.chartXLabel
        },
        colors: ['#FF5959'],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: false
          }
        },
        stroke: {
          width: [2, 2]
        },
        dataLabels: {
          enabled: false
        }
      };
    }
  }
}
</script>

<style scoped>

</style>