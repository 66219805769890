import VueRouter from 'vue-router'
import LoginPage from '@/components/auth/LoginPage'
import ForgotPasswordPage from '@/components/auth/ForgotPassword.vue'
import MapPage from '@/components/maps/MapPage'
import DashboardPage from '@/components/dashboard/DashboardPage'
import LoadingPage from '@/components/LoadingPage'
import NotFoundPage from '@/components/layouts/NotFoundPage.vue'
import store from '@/stores'
import StatisticalPage from "@/components/dashboard/components/StatisticalPage"
import CampaignPage from "@/components/dashboard/components/CampaignPage"
// import ProductPage from "@/components/dashboard/components/ProductPage"
import BookingProductPage from "@/components/dashboard/components/BookingProductPage"
import CampaignCreatePage from "@/components/dashboard/components/pages/campaign/CampaignCreatePage"
import CampaignUpdatePage from "@/components/dashboard/components/pages/campaign/CampaignUpdatePage"
// import BookingStorePage from "@/components/dashboard/components/BookingStorePage"
import BookingPickPage from "@/components/dashboard/components/BookingPickPage.vue"
import ReportBookingPage from "@/components/dashboard/components/ReportBookingPage.vue"
import BookingStoreCreatePage from "@/components/dashboard/components/pages/booking/BookingStoreCreatePage.vue"
import BookingStoreUpdatePage from "@/components/dashboard/components/pages/booking/BookingStoreUpdatePage.vue"
import BookingProductCreatePage from "@/components/dashboard/components/pages/booking/BookingProductCreatePage.vue"
import BookingProductUpdatePage from "@/components/dashboard/components/pages/booking/BookingProductUpdatePage.vue"
import BookingPickCreatePage from "@/components/dashboard/components/pages/booking/BookingPickCreatePage.vue"
import BookingPickUpdatePage from "@/components/dashboard/components/pages/booking/BookingPickUpdatePage.vue"
import ChatPage from "@/components/dashboard/components/ChatPage.vue"
import UserProfilePage from "@/components/dashboard/components/UserProfilePage.vue"
import CouponPage from "@/components/dashboard/components/CouponPage.vue"

const routes = [
  {
    path: '/404',
    name: 'NotFoundPage',
    components: {
      error: NotFoundPage
    },
    meta: {
      title: '404 Not Found | Vodaplay'
    },
  },
  {
    path: '/',
    redirect: {name: 'loading'}
  },
  {
    path: "/maps",
    name: "MapContent",
    components: {
      map: MapPage
    },
    meta: {title: 'Maps | Vodaplay'},
  },
  {
    path: '/loading',
    components: {
      loading: LoadingPage
    },
    name: 'loading',
    meta: {title: 'Loading | Vodaplay'},
  },
  {
    path: '/login',
    components: {
      login: LoginPage
    },
    name: 'login',
    meta: {title: 'Đăng nhập | Vodaplay'},
    beforeEnter: (to, from, next) => {
      let isAuthenticate = store.getters.isAuthenticate;
      if (!isAuthenticate) {
        return next()
      } else {
        return next({name: 'loading'})
      }
    },
  },
  {
    path: '/forgot-password',
    components: {
      login: ForgotPasswordPage
    },
    name: 'ForgotPassword',
    meta: {title: 'Quên mật khẩu | Vodaplay'},
    beforeEnter: (to, from, next) => {
      let isAuthenticate = store.getters.isAuthenticate;
      if (!isAuthenticate) {
        return next()
      } else {
        return next({name: 'loading'})
      }
    },
  },
  {
    path: '/dashboard',
    components: {
      dashboard: DashboardPage
    },
    name: 'dashboard',
    beforeEnter: (to, from, next) => {
      let isAuthenticate = store.getters.isAuthenticate;
      if (isAuthenticate) {
        return next()
      } else {
        return next({name: 'loading'})
      }
    },
    children: [
      {
        path: "/statistical",
        name: "Statistical",
        components: {
          dashboardContent: StatisticalPage
        },
        meta: {
          title: 'Statistical | Vodaplay',
          page: 'statistical',
          breadcrumb: [
            {
              to: 'Statistical',
              page: 'statistical',
            },
          ],
        },
      },
      {
        path: "/stores",
        name: "BookingStores",
        components: {
          dashboardContent: BookingStoreUpdatePage
        },
        meta: {
          title: 'Booking Store Update | Vodaplay',
          page: 'booking_store_update',
          breadcrumb: [
            // {
            //     to: 'BookingStores',
            //     page: 'booking_store',
            // },
            {
              to: 'BookingStoreUpdate',
              page: 'booking_store_update',
            },
          ],
        },
      },
      {
        path: "/products",
        name: "BookingProducts",
        components: {
          dashboardContent: BookingProductPage
        },
        meta: {
          title: 'Booking Products | Vodaplay',
          page: 'booking_product',
          breadcrumb: [
            {
              to: 'BookingProducts',
              page: 'booking_product',
            },
          ],
        }
      },
      {
        path: "/picks",
        name: "BookingPicks",
        components: {
          dashboardContent: BookingPickPage
        },
        meta: {
          title: 'Booking Picks | Vodaplay',
          page: 'booking_pick',
          breadcrumb: [
            {
              to: 'BookingPicks',
              page: 'booking_pick',
            },
          ],
        }
      },
      {
        path: "/campaigns",
        name: "Campaign",
        components: {
          dashboardContent: CampaignPage
        },
        meta: {
          title: 'Campaign | Vodaplay',
          page: 'brand_ai',
          breadcrumb: [
            {
              to: 'Campaign',
              page: 'brand_ai',
            },
          ],
        },
      },
      {
        path: "/report-booking",
        name: "ReportBooking",
        components: {
          dashboardContent: ReportBookingPage
        },
        meta: {
          title: 'Report Booking | Vodaplay',
          page: 'report_booking',
          breadcrumb: [
            {
              to: 'Report Booking',
              page: 'report_booking',
            },
          ],
        },
      },
      {
        path: "/costs",
        name: "Cost",
        components: {
          dashboardContent: CampaignPage
        },
        meta: {
          title: 'Cost | Vodaplay',
          page: 'cost',
          breadcrumb: [
            {
              to: 'Cost',
              page: 'cost',
            },
          ],
        },
      },
      {
        path: "/coupons",
        name: "Coupons",
        components: {
          dashboardContent: CouponPage
        },
        meta: {
          title: 'Coupons | Vodaplay',
          page: 'coupon',
          breadcrumb: [
            {
              to: 'Coupons',
              page: 'coupon',
            },
          ],
        }
      },
      {
        path: "/notice-board",
        name: "NoticeBoard",
        components: {
          dashboardContent: CampaignPage
        },
        meta: {
          title: 'Notice Board | Vodaplay',
          title_page: 'Statistical',
          page: 'notice_board',
          breadcrumb: [
            {
              to: 'NoticeBoard',
              page: 'notice_board',
            },
          ],
        },
      },
      {
        path: "/settings",
        name: "Setting",
        components: {
          dashboardContent: CampaignPage
        },
        meta: {
          title: 'Setting | Vodaplay',
          page: 'setting',
          breadcrumb: [
            {
              to: 'Setting',
              page: 'setting',
            },
          ],
        },
      },
      {
        path: "/campaigns/create",
        name: "CampaignCreate",
        components: {
          dashboardContent: CampaignCreatePage
        },
        meta: {
          title: 'Campaign Create | Vodaplay',
          page: 'brand_ai_create',
          breadcrumb: [
            {
              to: 'Campaign',
              page: 'brand_ai',
            },
            {
              to: 'CampaignCreate',
              page: 'brand_ai_create',
            },
          ],
        },
      },
      {
        path: "/campaigns/:id/update",
        name: "CampaignUpdate",
        components: {
          dashboardContent: CampaignUpdatePage
        },
        meta: {
          title: 'Campaign Update | Vodaplay',
          page: 'brand_ai_update',
          breadcrumb: [
            {
              to: 'Campaign',
              page: 'brand_ai',
            },
            {
              to: 'CampaignCreate',
              page: 'brand_ai_update',
            },
          ],
        },
      },
      {
        path: "/stores/create",
        name: "BookingStoreCreate",
        components: {
          dashboardContent: BookingStoreCreatePage
        },
        meta: {
          title: 'Booking Store Create | Vodaplay',
          page: 'booking_store_create',
          breadcrumb: [
            {
              to: 'BookingStores',
              page: 'booking_store',
            },
            {
              to: 'BookingStoreCreate',
              page: 'booking_store_create',
            },
          ],
        },
      },
      {
        path: "/stores/:id/update",
        name: "BookingStoreUpdate",
        components: {
          dashboardContent: BookingStoreUpdatePage
        },
        meta: {
          title: 'Booking Store Update | Vodaplay',
          page: 'booking_store_update',
          breadcrumb: [
            {
              to: 'BookingStores',
              page: 'booking_store',
            },
            {
              to: 'BookingStoreUpdate',
              page: 'booking_store_update',
            },
          ],
        },
      },
      {
        path: "/products/create",
        name: "BookingProductCreate",
        components: {
          dashboardContent: BookingProductCreatePage
        },
        meta: {
          title: 'Booking Product Create | Vodaplay',
          page: 'booking_store_product_create',
          breadcrumb: [
            {
              to: 'BookingProducts',
              page: 'booking_product',
            },
            {
              to: 'BookingProductCreate',
              page: 'booking_store_product_create',
            },
          ],
        },
      },
      {
        path: "/products/:id/update",
        name: "BookingProductUpdate",
        components: {
          dashboardContent: BookingProductUpdatePage
        },
        meta: {
          title: 'Booking Product Update | Vodaplay',
          page: 'booking_store_product_update',
          breadcrumb: [
            {
              to: 'BookingProducts',
              page: 'booking_product',
            },
            {
              to: 'BookingProductUpdate',
              page: 'booking_store_product_update',
            },
          ],
        },
      },
      {
        path: "/picks/create",
        name: "BookingPickCreate",
        components: {
          dashboardContent: BookingPickCreatePage
        },
        meta: {
          title: 'Booking Pick Create | Vodaplay',
          page: 'booking_pick_create',
          breadcrumb: [
            {
              to: 'BookingPicks',
              page: 'booking_pick',
            },
            {
              to: 'BookingPickCreate',
              page: 'booking_pick_create',
            },
          ],
        },
      },
      {
        path: "/picks/:id/update",
        name: "BookingPickUpdate",
        components: {
          dashboardContent: BookingPickUpdatePage
        },
        meta: {
          title: 'Booking Pick Update | Vodaplay',
          page: 'booking_pick_update',
          breadcrumb: [
            {
              to: 'BookingPicks',
              page: 'booking_pick',
            },
            {
              to: 'BookingPickUpdate',
              page: 'booking_pick_update',
            },
          ],
        },
      },
      {
        path: "/coupons/create",
        name: "CouponCreate",
        components: {
          dashboardContent: BookingPickCreatePage
        },
        meta: {
          title: 'Coupons Create | Vodaplay',
          page: 'coupon_create',
          breadcrumb: [
            {
              to: 'Coupons',
              page: 'coupon',
            },
            {
              to: 'CouponCreate',
              page: 'coupon_create',
            },
          ],
        },
      },
      {
        path: "/coupons/:id/update",
        name: "CouponUpdate",
        components: {
          dashboardContent: BookingPickUpdatePage
        },
        meta: {
          title: 'Coupons Update | Vodaplay',
          page: 'coupon_update',
          breadcrumb: [
            {
              to: 'Coupons',
              page: 'coupon',
            },
            {
              to: 'CouponUpdate',
              page: 'coupon_update',
            },
          ],
        },
      },
      {
        path: "/chats",
        name: "ChatPage",
        components: {
          dashboardContent: ChatPage
        },
        meta: {
          title: 'Chat | Vodaplay',
          page: 'chat_page',
          breadcrumb: [
            {
              to: 'ChatPage',
              page: 'chat_page',
            }
          ],
        },
      },
      {
        path: "/user-profile",
        name: "UserProfile",
        components: {
          dashboardContent: UserProfilePage
        },
        meta: {
          title: 'User Profile | Vodaplay',
          page: 'user_profile',
          breadcrumb: [
            {
              to: 'UserProfile',
              page: 'user_profile',
            }
          ],
        },
      },
      {
        path: "*",
        redirect: "/404",
      }
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  routes: routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'loading' && to.name !== 'login' && to.name && to.name !== 'NotFoundPage' && !from.name) {
    sessionStorage.setItem('history-page', window.location.pathname)
  }
  if (!to.name && !from.name) {
    next({name: 'NotFoundPage'})
  } else {
    next()
  }
});

router.afterEach((to) => {
  document.title = to.meta.title;
});

export default router;
