export default {
    profile: 'Tài khoản',
    edit_profile: 'Cập nhật tài khoản',
    logout: 'Đăng xuất',
    notification: {
        view_all: 'Xem tất cả',
        new_notify: 'tin nhắn mới',
        clear_all: 'Xóa hết',
    },
    payment: {
        cost: 'Chi phí quảng cáo',
        button: 'Nạp tiền'
    }
}
