<template>
  <div class="campaign-create-page w-100">
    <title-page :title="$t('booking.product.create.title')" :icon="'WomenClothes'"></title-page>
    <b-form data-vv-scope="booking_product_validate">
      <div class="container-fluid">
        <b-overlay :show="isSubmit" rounded="lg">
          <booking-product-fields :current-data="baseData"
                                  @validate-discount="validateDiscount"></booking-product-fields>
        </b-overlay>
      </div>
    </b-form>
    <div class="w-100 mt-4">
      <div class="container-fluid">
        <b-card class="card-layer">
          <b-row>
            <product-preview :base-data="baseData"></product-preview>
            <b-col cols="6" class="text-right">
              <b-button class="button-violet mr-4" v-on:click="submitCreateProduct" :disabled="isSubmit"
                        :style="{cursor: isSubmit ? 'no-drop' : 'pointer'}">
                <template v-if="isSubmit">
                  <b-spinner small></b-spinner>
                </template>
                <template v-else>
                  {{ $t('common.save') }}
                </template>
              </b-button>
              <b-button class="button-violet-outline" v-on:click="cancelCreate()">{{ $t('common.cancel') }}</b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import BookingProductFields from "@/components/dashboard/components/pages/booking/BookingProductFields"
import {mapGetters} from "vuex";
import TitlePage from "@/components/dashboard/layouts/TitlePage.vue";
import ProductPreview from "@/components/dashboard/components/pages/booking/BookingProductPreview.vue";

export default {
  components: {
    TitlePage,
    ProductPreview,
    BookingProductFields
  },
  computed: {
    ...mapGetters(["currentIpInfo"]),
    isMobile() {
      return this.$device.mobile
    },
  },
  async mounted() {
    await this.loading()
    this.$root.$emit('reset-image-upload')
    await this.$validator.reset()
  },
  data() {
    return {
      baseData: {
        image: [],
        store_id: '',
        name: '',
        status: 2,
        category_id: '',
        sub_category_id: '',
        price: '',
        sale_price: '',
        url: '',
        keyword: [],
        country_code: 'VN',
        is_booking: '',
        discount_lines: []
      },
      isSubmit: false,
      isDiscountValid: true
    }
  },
  methods: {

    async loading() {
      await this.$store.dispatch('getCurrentIpInfo')
      this.baseData.country_code = this.currentIpInfo.countryCode
    },

    validateDiscount(validates) {
      for (const key of Object.keys(validates)) {
        if (!validates[key]) {
          this.isDiscountValid = false
        }
      }
    },

    async cancelCreate() {
      await this.$router.push({name: 'BookingProducts'})
    },

    async submitCreateProduct() {
      const valid = await this.$validator.validate('booking_product_validate.*')
      if (!valid) {
        return false
      }
      if (!this.isDiscountValid) {
        return false
      }
      const vm = this
      const description = this.$t('booking.product.create.confirm_description')
      this.$bvModal.msgBoxConfirm(description, {
        title: this.$t('booking.product.create.confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okTitle: this.$t('booking.product.create.confirm_save'),
        cancelTitle: this.$t('booking.product.create.confirm_cancel'),
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0 message-button-confirm'
      })
      .then(value => {
        if (!value) {
          return false
        }
        vm.createProduct()
      })
    },

    async createProduct() {
      const vm = this
      this.isSubmit = true
      Object.keys(this.baseData).forEach((key) => {
        if (['store_id', 'status', 'category_id', 'sub_category_id', 'is_booking'].includes(key)) {
          vm.baseData[key] = vm.baseData[key]['id'] || 0
        }
      })
      const res = await this.$store.dispatch('createStoreProduct', this.baseData)
      if (res.data.status === 200) {
        this.$root.$emit('toast-event', {
          description: this.$t('booking.product.create.confirm_success_description'),
          title: this.$t('booking.product.create.confirm_success_title'),
          variant: 'success',
        })
        await this.$router.push({name: 'BookingProducts'})
      } else {
        this.$root.$emit('toast-event', {
          description: this.$t('booking.product.create.confirm_fail_description'),
          title: this.$t('booking.product.create.confirm_fail_title'),
          variant: 'danger',
        })
      }
      this.isSubmit = false
    }
  }
}
</script>

<style lang="scss">

</style>