<template>
  <div class="campaign-create-page w-100">
    <title-page :title="$t('booking.pick.update.title')" :icon="'ComputerDisk'"
                :is-has-create="false"></title-page>
    <b-form data-vv-scope="booking_product_validate">
      <b-overlay :show="isSubmit || isLoading" rounded="lg">
        <booking-pick-fields v-if="!isLoading" :current-data="baseData" :is-update="true"></booking-pick-fields>
      </b-overlay>
    </b-form>
    <div class="p-3">
      <b-card class="card-layer">
        <b-row>
          <b-col cols="12" class="text-right">
            <b-button class="button-violet" v-on:click="submitUpdateProduct" :disabled="isSubmit"
                      :style="{cursor: isSubmit ? 'no-drop' : 'pointer'}" variant="primary mr-4">
              <template v-if="isSubmit">
                <b-spinner small></b-spinner>
              </template>
              <template v-else>
                {{ $t('common.save') }}
              </template>
            </b-button>
            <b-button class="button-violet-outline" @click="cancelCreate()">{{ $t('common.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import BookingPickFields from "@/components/dashboard/components/pages/booking/BookingPickFields.vue"
import {mapGetters} from "vuex";
import TitlePage from "@/components/dashboard/layouts/TitlePage.vue";

export default {
  components: {
    TitlePage,
    BookingPickFields
  },
  computed: {
    ...mapGetters(["currentIpInfo", "bookingPick", "channelInformation", "videoInformation"]),
    isMobile() {
      return this.$device.mobile
    },
  },
  async mounted() {
    await this.$store.dispatch('setResetVideoInformation')
    await this.$store.dispatch('setResetChannelInformation')
    await this.loading()
    await this.$validator.reset()
  },
  data() {
    return {
      baseData: {
        video_id: '',
        video_title: '',
        video_url: '',
        keyword: [],
        status: '',
        created_by: '',
        created_at: '',
        product_ids: [],
        country_code: 'VN'
      },
      isLoading: true,
      isSubmit: false
    }
  },
  methods: {
    async loading() {
      const vm = this
      this.isLoading = true
      await this.$store.dispatch('getCurrentIpInfo')
      await this.$store.dispatch('findPick', this.$route.params.id)
      this.$nextTick(async () => {
        vm.baseData = vm.bookingPick.pick
        vm.baseData.count_exist_pick = vm.bookingPick.count_pick
        vm.baseData.products = vm.bookingPick.products
        vm.baseData.product_ids = vm.bookingPick.products.map((product) => {
          return product.id
        })
        vm.baseData.id = vm.$route.params.id
        vm.baseData.country_code = vm.currentIpInfo.countryCode
        if (vm.baseData.video_title === '') {
          await vm.$store.dispatch('getVideoInformation', {video_id: vm.baseData.video_id})
          vm.baseData.video_title = vm.videoInformation.title || ''
          vm.baseData.video_url = 'https://www.youtube.com/watch?v=' + vm.videoInformation.video_id
          vm.baseData.time = vm.videoInformation.time
          vm.baseData.channel_id = vm.videoInformation.channelId
          vm.baseData.channel_name = vm.videoInformation.channelTitle
        }
        if (vm.baseData.channel_image === '') {
          await this.$store.dispatch('getChannelInformation', {channel_id: vm.baseData.channel_id})
          vm.baseData.channel_image = vm.channelInformation.channel_image;
        }
        vm.isLoading = false
      })
    },

    async cancelCreate() {
      await this.$router.push({name: 'BookingPicks'})
    },

    async submitUpdateProduct() {
      console.log('Click')
      const valid = await this.$validator.validate('booking_pick_validate.*')
      console.log(valid, 'VALID')
      if (!valid) {
        return false
      }
      const vm = this
      const description = this.$t('booking.pick.update.confirm_description')
      this.$bvModal.msgBoxConfirm(description, {
        title: this.$t('booking.pick.update.confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okTitle: this.$t('booking.pick.update.confirm_save'),
        cancelTitle: this.$t('booking.pick.update.confirm_cancel'),
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0 message-button-confirm'
      })
      .then(value => {
        if (!value) {
          return false
        }
        vm.updatePick()
      })
    },

    async updatePick() {
      const vm = this
      this.isSubmit = true
      Object.keys(this.baseData).forEach((key) => {
        if (['status'].includes(key)) {
          vm.baseData[key] = vm.baseData[key]['id'] || 0
        }
      })

      const youtubePattern = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gim
      const isYoutubeUrl = youtubePattern.test(this.baseData.video_id)
      let videoId = ''
      if (isYoutubeUrl) {
        let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
        videoId = regex.exec(this.baseData.video_id)[3];
      } else {
        videoId = this.baseData.video_id
      }
      this.baseData.video_id = videoId

      const res = await this.$store.dispatch('updatePick', {
        data: this.baseData,
        video_id: this.baseData.video_id
      })
      if (res.data.status === 200) {
        this.$root.$emit('toast-event', {
          description: this.$t('booking.pick.update.confirm_success_description'),
          title: this.$t('booking.pick.update.confirm_success_title'),
          variant: 'success',
        })
        await this.$router.push({name: 'BookingPicks'})
      } else {
        this.$root.$emit('toast-event', {
          description: this.$t('booking.pick.update.confirm_fail_description'),
          title: this.$t('booking.pick.update.confirm_fail_title'),
          variant: 'danger',
        })
      }
      this.isSubmit = false
    }
  }
}
</script>

<style scoped>

</style>