<template>
  <section class="row">
    <div class="col-12">
      <div class="card card-layer padding-16">
        <h4 class="title-card-designer margin-bottom-24">{{ $t('booking.product.fields.title') }}</h4>
        <div class="card-body p-0">

          <div class="row">
            <div class="col-md-12">
              <b-overlay :show="isLoading" rounded="lg">
                <div class="table-hover table-responsive table-no-border mb-0 table-no-padding-horizontal">

                  <table class="table table-form">
                    <tr>
                      <th class="">
                        <label class="label-selector">{{ $t('booking.product.fields.image') }} <span
                            class="text-danger">*</span></label>
                      </th>
                      <td colspan="3">
                        <upload-image-layout
                            v-validate="'required'"
                            v-model="formData.image"
                            :data-vv-as="$t('booking.product.fields.image')"
                            data-vv-scope="booking_product_validate"
                            name="image"
                            :is-multiple="true"
                            :ref-name="'product-upload-images'"
                            :class="errors.first('booking_product_validate.image') ? 'is-invalid' : ''"
                        >
                        </upload-image-layout>
                        <b-form-invalid-feedback>{{
                            errors.first('booking_product_validate.image')
                          }}
                        </b-form-invalid-feedback>
                      </td>
                    </tr>
                    <tr>
                      <th class="">
                        <label class="label-selector">{{ $t('booking.product.fields.store_name') }} <span
                            class="text-danger">*</span></label>
                      </th>
                      <td>
                        <multiple-select
                            v-validate="'required'"
                            data-vv-scope="booking_product_validate"
                            :data-vv-as="$t('booking.product.fields.store_name')"
                            @close="validateMultipleSelect('store_id')"
                            name="store_id"
                            v-model="formData.store_id"
                            :options="options.stores"
                            :allow-empty="true"
                            :placeholder="$t('filter.placeholder_option')"
                            open-direction="bottom"
                            label="name"
                            :loading="searchStoreLoading"
                            @search-change="value => searchStore(value)"
                            track-by="id"
                            :class="errors.first('booking_product_validate.store_id') ? 'is-invalid' : ''"
                            :show-labels="false"
                        >
                          <span slot="noResult">{{ $t('filter.no_result') }}</span>
                        </multiple-select>
                        <b-form-invalid-feedback>{{
                            errors.first('booking_product_validate.store_id')
                          }}
                        </b-form-invalid-feedback>
                      </td>
                      <th class="padding-left-32">
                        <label class="label-selector">{{ $t('booking.product.fields.is_booking') }} <span
                            class="text-danger">*</span></label>
                      </th>
                      <td>
                        <multiple-select
                            v-validate="'required'"
                            data-vv-scope="booking_product_validate"
                            :data-vv-as="$t('booking.product.fields.is_booking')"
                            @close="validateMultipleSelect('is_booking')"
                            name="is_booking"
                            v-model="formData.is_booking"
                            :options="options.is_booking"
                            :allow-empty="false"
                            :placeholder="$t('filter.placeholder_option')"
                            open-direction="bottom"
                            label="name"
                            track-by="id"
                            :class="errors.first('booking_product_validate.is_booking') ? 'is-invalid' : ''"
                            :show-labels="false"
                        >
                          <span slot="noResult">{{ $t('filter.no_result') }}</span>
                        </multiple-select>
                        <b-form-invalid-feedback>{{
                            errors.first('booking_product_validate.is_booking')
                          }}
                        </b-form-invalid-feedback>
                      </td>
                    </tr>
                    <tr>
                      <th class="">
                        <label class="label-selector">{{ $t('booking.product.fields.name') }} <span
                            class="text-danger">*</span></label>
                      </th>
                      <td>
                        <input v-model="formData.name"
                               v-validate="'required'"
                               data-vv-scope="booking_product_validate"
                               :data-vv-as="$t('booking.product.fields.name')"
                               name="product_name"
                               type="text"
                               class="form-control"
                               :class="errors.first('booking_product_validate.product_name') ? 'is-invalid' : ''"
                               :placeholder="$t('booking.product.fields.name')">
                        <b-form-invalid-feedback>
                          {{ errors.first('booking_product_validate.product_name') }}
                        </b-form-invalid-feedback>
                      </td>
                      <th class="padding-left-32">
                        <label class="label-selector">{{ $t('booking.product.fields.status') }} <span
                            class="text-danger">*</span></label>
                      </th>
                      <td>
                        <multiple-select
                            v-validate="'required'"
                            data-vv-scope="booking_product_validate"
                            :data-vv-as="$t('booking.product.fields.status')"
                            @close="validateMultipleSelect('status')"
                            name="status"
                            v-model="formData.status"
                            :options="options.status"
                            :allow-empty="false"
                            :placeholder="$t('filter.placeholder_option')"
                            open-direction="bottom"
                            label="name"
                            track-by="id"
                            :class="errors.first('booking_product_validate.status') ? 'is-invalid' : ''"
                            :show-labels="false"
                        >
                          <span slot="noResult">{{ $t('filter.no_result') }}</span>
                        </multiple-select>
                        <b-form-invalid-feedback>{{
                            errors.first('booking_product_validate.status')
                          }}
                        </b-form-invalid-feedback>
                      </td>
                    </tr>
                    <tr>
                      <th class="">
                        <label class="label-selector">{{ $t('booking.product.fields.category') }} <span
                            class="text-danger">*</span></label>
                      </th>
                      <td>
                        <b-col cols="12">
                          <b-row>
                            <b-col cols="6">
                              <b-row>
                                <multiple-select
                                    v-validate="'required'"
                                    data-vv-scope="booking_product_validate"
                                    :data-vv-as="$t('booking.product.fields.parent_category')"
                                    @close="validateMultipleSelect('category_id')"
                                    name="category_id"
                                    v-model="formData.category_id"
                                    :options="options.category_id"
                                    :allow-empty="true"
                                    :placeholder="$t('booking.product.fields.parent_category')"
                                    open-direction="bottom"
                                    label="name"
                                    track-by="id"
                                    class="pr-2"
                                    :class="errors.first('booking_product_validate.category_id') ? 'is-invalid' : ''"
                                    :show-labels="false"
                                >
                                  <span slot="noResult">{{ $t('filter.no_result') }}</span>
                                </multiple-select>
                                <b-form-invalid-feedback>{{
                                    errors.first('booking_product_validate.category_id')
                                  }}
                                </b-form-invalid-feedback>
                              </b-row>
                            </b-col>
                            <b-col cols="6">
                              <b-row>
                                <multiple-select
                                    v-validate="'required'"
                                    data-vv-scope="booking_product_validate"
                                    :data-vv-as="$t('booking.product.fields.sub_category')"
                                    @close="validateMultipleSelect('sub_category_id')"
                                    name="sub_category_id"
                                    v-model="formData.sub_category_id"
                                    :options="options.sub_category_id"
                                    :allow-empty="true"
                                    :placeholder="$t('booking.product.fields.sub_category')"
                                    open-direction="bottom"
                                    label="name"
                                    track-by="id"
                                    :class="errors.first('booking_product_validate.sub_category_id') ? 'is-invalid' : ''"
                                    :show-labels="false"
                                >
                                  <span slot="noResult">{{ $t('filter.no_result') }}</span>
                                </multiple-select>
                                <b-form-invalid-feedback>{{
                                    errors.first('booking_product_validate.sub_category_id')
                                  }}
                                </b-form-invalid-feedback>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-col>
                      </td>
                    </tr>
                    <tr>
                      <th class="">
                        <label class="label-selector">{{ $t('booking.product.fields.price') }}</label>
                      </th>
                      <td>
                        <input-price name="product_price"
                                     v-validate="'required|is_bigger:0'"
                                     data-vv-scope="booking_product_validate"
                                     ref="product_price_ref"
                                     id="product_price_ref"
                                     :data-vv-as="$t('booking.product.fields.price')"
                                     v-model="formData.price"
                                     class="form-control"
                                     :class="errors.first('booking_product_validate.product_price') ? 'is-invalid' : ''"
                                     :placeholder="$t('booking.product.fields.price') + '...'">
                        </input-price>
                        <b-form-invalid-feedback>{{
                            errors.first('booking_product_validate.product_price')
                          }}
                        </b-form-invalid-feedback>
                      </td>
                      <th class="padding-left-32">
                        <label class="label-selector">{{ $t('booking.product.fields.sale_price') }}</label>
                      </th>
                      <td>
                        <div class="w-100 d-inline-flex align-items-center"
                             :class="errors.first('booking_product_validate.product_sale_price') ? 'is-invalid' : ''">
                          <input-price name="product_sale_price"
                                       ref="product_sale_price_ref"
                                       id="product_sale_price_ref"
                                       v-validate="'is_less_if_exist:product_price_ref|is_bigger:0'"
                                       data-vv-scope="booking_product_validate"
                                       :data-vv-as="$t('booking.product.fields.sale_price')"
                                       v-model="formData.sale_price"
                                       class="form-control mr-2"
                                       :class="errors.first('booking_product_validate.product_sale_price') ? 'is-invalid' : ''"
                                       :placeholder="$t('booking.product.fields.sale_price') + '...'">
                          </input-price>
                          <span class="discount-fields">
                            {{
                              $t('booking.product.fields.discount_rate', {rate: getRateDiscount('sale_price')})
                            }}
                          </span>
                        </div>
                        <b-form-invalid-feedback>{{
                            errors.first('booking_product_validate.product_sale_price')
                          }}
                        </b-form-invalid-feedback>
                      </td>
                    </tr>

                    <tr>
                      <th class="">
                        <label class="label-selector">{{ $t('booking.product.fields.url') }}</label>
                      </th>
                      <td colspan="3">
                        <input v-model="formData.url"
                               v-validate="{url: {require_protocol: true }}"
                               data-vv-scope="booking_product_validate"
                               :data-vv-as="$t('booking.product.fields.url')"
                               name="product_url"
                               type="text"
                               class="form-control"
                               :class="errors.first('booking_product_validate.product_url') ? 'is-invalid' : ''"
                               :placeholder="$t('booking.product.fields.url') + '...'">
                        <b-form-invalid-feedback>{{
                            errors.first('booking_product_validate.product_url')
                          }}
                        </b-form-invalid-feedback>
                      </td>
                    </tr>
                    <tr>
                      <th class="">
                        <label class="label-selector">{{ $t('booking.product.fields.keyword') }} <span
                            class="text-danger">*</span></label>
                      </th>
                      <td colspan="3">
                        <input-tags
                            v-model="formData.keyword"
                            v-validate="'required'"
                            data-vv-scope="booking_product_validate"
                            :data-vv-as="$t('booking.product.fields.keyword')"
                            :class="errors.first('booking_product_validate.keyword') ? 'is-invalid' : ''"
                            name="keyword"
                            :placeholder="$t('booking.product.fields.keyword') + '...'"
                        ></input-tags>
                        <b-form-invalid-feedback>{{
                            errors.first('booking_product_validate.keyword')
                          }}
                        </b-form-invalid-feedback>
                      </td>
                    </tr>
                  </table>
                </div>
              </b-overlay>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-12 mt-4"
         v-if="isNumeric(formData.is_booking) && formData.is_booking == 1 || isObject(formData.is_booking) && formData.is_booking.id == 1">
      <div class="card card-layer padding-16">
        <b-row>
          <b-col cols="12" class="pr-0">
            <b-row class="w-100 d-inline-flex">
              <b-col cols="6">
                <h4 class="title-card-designer">{{ $t('booking.product.fields.title_discount') }}</h4>
              </b-col>
              <b-col cols="6" class="text-right pr-0">
                <b-popover
                    id="popover-options"
                    target="popover-reactive-1"
                    triggers="click"
                    placement="top"
                    ref="popover"
                >
                  <!--                  <template #title>-->
                  <!--                    <span>{{ $t('booking.product.fields.add_discount') }}</span>-->
                  <!--                  </template>-->

                  <div class="popover-options">
                    <b-list-group flush>
                      <b-list-group-item class="cursor-pointer" v-on:click="selectedDiscount('time')">
                        {{ $t('booking.product.fields.discount_by_time') }}
                      </b-list-group-item>
                      <b-list-group-item class="cursor-pointer" v-on:click="selectedDiscount('combo')">
                        {{ $t('booking.product.fields.combo_service') }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-popover>
                <b-button class="button-violet" id="popover-reactive-1">
                  {{ $t('booking.product.fields.add_discount') }}
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div class="card-body pr-0 pl-0">
          <div class="row">
            <div class="col-md-12">
              <div class="table-discounts mb-0 mt-2">
                <div class="list-items">
                  <template v-if="formData.discount_lines && formData.discount_lines.length > 0">
                    <template v-for="(discount, index) in formData.discount_lines">
                      <div class="table-item" :key="'discount-line-' + index"
                          :class="typeof validateDiscount[index] !== 'undefined' && !validateDiscount[index] ? 'is-invalid' : ''">
                        <template v-if="discount.type === 'time'">
                          <th class="table-th">
                            <div class="d-flex align-items-center mr-4">
                              <label class="label-selector mb-0">{{ '#' + discount.id }} -
                                {{ $t('booking.product.fields.discount_by_time') }}</label>
                              <a href="javascript:;" v-on:click="deleteDiscount(index)"
                                 class="text-danger ml-2">
                                <font-awesome-icon icon="fa-solid fa-circle-xmark"/>
                              </a>
                            </div>
                          </th>
                          <div class="table-td">
                            <div class="d-flex flex-wrap">
                              <div class="col-12">
                                <div class="row">
                                  <div class="responsive-discount-line">
                                    <b-row>
                                      <div class="w-100 d-inline-flex align-items-center"
                                           :class="errors.first(`booking_product_validate.discount_price_${index}`) ? 'is-invalid' : ''">
                                        <div class="label-selector mr-4">{{ $t('booking.product.fields.sale_price') }}
                                          <span
                                              class="text-danger">*</span></div>
                                        <input-price :name="'discount_price_' + index"
                                                     v-model="discount.discount_price"
                                                     v-validate="`required|is_less_multiple_if_exist:product_sale_price_ref,product_price_ref|is_bigger:0`"
                                                     data-vv-scope="booking_product_validate"
                                                     :data-vv-as="$t('booking.product.fields.sale_price')"
                                                     class="form-control"
                                                     :class="errors.first(`booking_product_validate.discount_price_${index}`) ? 'is-invalid' : ''"
                                                     :placeholder="$t('booking.product.fields.discount_by_time') + '...'">
                                        </input-price>
                                        <span class="ml-1 discount-fields text-red">
                                          {{
                                                $t('booking.product.fields.discount_rate', {rate: getRateDiscount('discount', index)})
                                              }}
                                        </span>
                                      </div>
                                      <b-form-invalid-feedback>{{
                                          errors.first(`booking_product_validate.discount_price_${index}`)
                                        }}
                                      </b-form-invalid-feedback>
                                    </b-row>
                                  </div>
                                  <div class="responsive-discount-line padding-left-32">
                                    <div class="row">
                                      <div class="pl-0 w-100">
                                        <div class="d-inline-flex times w-100"
                                             :class="errors.first(`booking_product_validate.discount_time_start_${index}`) || errors.first(`booking_product_validate.discount_time_end_${index}`) ? 'is-invalid' : ''">
                                          <div class="label-selector mr-4 mb-0">{{
                                              $t('booking.product.fields.time_slot')
                                            }} <span
                                                class="text-danger">*</span></div>
                                          <div class="group-time-picker d-inline-flex w-100">
                                            <time-picker
                                                :min-hour="6"
                                                :max-hour="23"
                                                :ref="`discount_time_start_${index}`"
                                                :increment-minutes="30"
                                                v-model="discount.time_start"
                                                v-validate="'required'"
                                                data-vv-scope="booking_product_validate"
                                                :data-vv-as="$t('booking.product.fields.start_time')"
                                                :name="`discount_time_start_${index}`"
                                                :placeHolder="$t('booking.product.fields.start_time')"
                                                :class="errors.first(`booking_product_validate.discount_time_start_${index}`) ? 'is-invalid' : ''"
                                                @input="value => timeChoice(`discount_time_start_${index}`, 'time_start', index)"
                                            >
                                            </time-picker>
                                            ~
                                            <time-picker
                                                :min-hour="6"
                                                :max-hour="23"
                                                :increment-minutes="30"
                                                v-model="discount.time_end"
                                                v-validate="`required|is_bigger_time_if_exist:discount_time_start_${index}`"
                                                data-vv-scope="booking_product_validate"
                                                :data-vv-as="$t('booking.product.fields.end_time')"
                                                :name="`discount_time_end_${index}`"
                                                :placeHolder="$t('booking.product.fields.end_time')"
                                                :class="errors.first(`booking_product_validate.discount_time_end_${index}`) ? 'is-invalid' : ''"
                                                @input="timeChoice(`discount_time_end_${index}`, 'time_end', index)"
                                            >
                                            </time-picker>
                                          </div>
                                        </div>
                                        <b-form-invalid-feedback class="text-right">
                                          {{
                                            errors.first(`booking_product_validate.discount_time_start_${index}`) || errors.first(`booking_product_validate.discount_time_end_${index}`)
                                          }}
                                        </b-form-invalid-feedback>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 d-inline-flex align-items-center">
                                <div class="row">
                                  <div
                                      class="w-100 display-responsive-checkbox-day justify-content-start align-items-center"
                                      :class="errors.first(`booking_product_validate.discount_applicable_date_${index}`) ? 'is-invalid' : ''">
                                    <label class="label-selector mr-4 mb-0">{{
                                        $t('booking.product.fields.applicable_date')
                                      }} <span class="text-danger">*</span>
                                    </label>
                                    <b-form-group style="margin-top: 1px" class="mb-0 designer-checkbox">
                                      <b-form-checkbox-group
                                          v-validate="'required'"
                                          data-vv-scope="booking_product_validate"
                                          :data-vv-as="$t('booking.product.fields.applicable_date')"
                                          :name="`discount_applicable_date_${index}`"
                                          @input="dayChoice(`discount_applicable_date_${index}`, 'day_open', index)"
                                          v-model="discount.day_open"
                                          :options="options.day_open"
                                      ></b-form-checkbox-group>
                                    </b-form-group>
                                    <b-form-checkbox
                                        class="designer-checkbox custom-control-inline"
                                        :checked="isCheckAll[index] || false"
                                        @change="checkedToggleCheckbox(index)"
                                    >
                                      {{ $t('campaign.gender.all') }}
                                    </b-form-checkbox>
                                  </div>
                                  <b-form-invalid-feedback>{{
                                      errors.first(`booking_product_validate.discount_applicable_date_${index}`)
                                    }}
                                  </b-form-invalid-feedback>
                                  <b-form-invalid-feedback class="mt-0"
                                                           :class="typeof validateDiscount[index] !== 'undefined' && !validateDiscount[index] ? 'mt-1' : ''">
                                    {{
                                      typeof validateDiscount[index] !== 'undefined' && !validateDiscount[index] ? setupInvalid : ''
                                    }}
                                  </b-form-invalid-feedback>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="table-th">
                            <div class="d-flex align-items-center mr-4">
                              <label class="label-selector mb-0">{{ '#' + discount.id }} -
                                {{ $t('booking.product.fields.combo_name') }}</label>
                              <a href="javascript:;" v-on:click="deleteDiscount(index)"
                                 class="text-danger float-right ml-2">
                                <font-awesome-icon icon="fa-solid fa-circle-xmark"/>
                              </a>
                            </div>
                          </div>
                          <div class="table-td">
                            <div class="col-12">
                              <b-row>
                                <b-input v-model="discount.combo_name"
                                         v-validate="'required|max:250'"
                                         data-vv-scope="booking_product_validate"
                                         :data-vv-as="$t('booking.product.fields.combo_name')"
                                         :name="`combo_name_${index}`"
                                         :class="errors.first(`booking_product_validate.combo_name_${index}`) ? 'is-invalid' : ''"
                                         :placeholder="$t('booking.product.fields.combo_name') + '...'">

                                </b-input>
                                <b-form-invalid-feedback>{{
                                    errors.first(`booking_product_validate.combo_name_${index}`)
                                  }}
                                </b-form-invalid-feedback>
                              </b-row>
                            </div>
                          </div>
                        </template>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-4">
      <div class="card card-layer padding-16">
        <h4 class="title-card-designer">{{ $t('booking.product.fields.title_created') }}</h4>
        <div class="card-body p-0">
          <div class="table-hover table-responsive mt-4 table-no-border mb-0 table-no-padding-horizontal">
            <table class="table table-form">
              <tr>
                <th>
                  <label class="label-selector">{{ $t('booking.product.fields.created_by') }}</label>
                </th>
                <td>
                  <span style="max-width: 300px;" class="form-control">{{
                      formData.user ? formData.user.name : ''
                    }}</span>
                </td>
                <th class="padding-left-32">
                  <label class="label-selector">{{ $t('booking.product.fields.created_at') }}</label>
                </th>
                <td>
                  <span style="max-width: 300px;" class="form-control">{{ formData.created_at | formatDatetime }}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import {mapGetters} from "vuex";
import TimePicker from "@/components/dashboard/layouts/TimePicker.vue";
import InputTags from "@/components/dashboard/layouts/InputTags.vue";
import {PriceHelper} from "@/lib/price-helper";
import moment from "moment";

export default {
  components: {
    TimePicker,
    InputTags
  },
  props: {
    currentData: {
      required: false,
      type: Object
    }
  },
  async mounted() {
    const vm = this
    await this.filterStores()
    await this.setFormData()
    await this.$validator.reset()
    window.addEventListener('click', function (e) {
      const checkedElement = document.getElementById('popover-options')
      const checkedElementButton = document.getElementById('popover-reactive-1')
      if ((!checkedElement || checkedElement && !checkedElement.contains(e.target))
          && (checkedElementButton && !checkedElementButton.contains(e.target) && checkedElement && !checkedElement.contains(e.target))
      ) {
        vm.$root.$emit('bv::hide::popover', 'popover-options')
      }
    });
  },
  computed: {
    ...mapGetters(["searchStores", "storeCategories", "storeSubCategories"]),
    options() {
      return {
        stores: [...this.searchStores],
        status: [
          {
            id: 0,
            name: this.$i18n.t('booking.product.option_pending')
          },
          {
            id: 1,
            name: this.$i18n.t('booking.product.option_running')
          }
        ],
        is_booking: [
          {
            id: 0,
            name: this.$i18n.t('booking.product.is_booking_off')
          },
          {
            id: 1,
            name: this.$i18n.t('booking.product.is_booking_on')
          }
        ],
        day_open: [
          {text: this.$i18n.t('report.booking.monday'), value: 'monday'},
          {text: this.$i18n.t('report.booking.tuesday'), value: 'tuesday'},
          {text: this.$i18n.t('report.booking.wednesday'), value: 'wednesday'},
          {text: this.$i18n.t('report.booking.thursday'), value: 'thursday'},
          {text: this.$i18n.t('report.booking.friday'), value: 'friday'},
          {text: this.$i18n.t('report.booking.saturday'), value: 'saturday'},
          {text: this.$i18n.t('report.booking.sunday'), value: 'sunday'},
        ],
        category_id: [...this.storeCategories],
        sub_category_id: [...this.storeSubCategories],
      };
    },
    setupInvalid() {
      return this.$i18n.t('booking.product.fields.setup_invalid')
    }
  },
  watch: {
    'formData.is_booking': {
      handler(after, before) {
        if (after !== this.isBookingCache && typeof before !== 'undefined') {
          this.isBookingCache = JSON.parse(JSON.stringify(after))
          this.baseFilterCategories.is_booking = after.id
          this.loadCategories()
        }
      },
      deep: true
    },
    'formData.category_id': {
      handler(after, before) {
        if (after !== this.categoryIdCache && typeof before !== 'undefined') {
          this.categoryIdCache = JSON.parse(JSON.stringify(after))
          this.loadSubCategories()
        }
      },
      deep: true
    },
    'formData.sub_category_id': {
      handler(after, before) {
        if (after !== this.subCategoryIdCache && typeof before !== 'undefined') {
          this.subCategoryIdCache = JSON.parse(JSON.stringify(after))
          if (Array.isArray(this.formData.keyword) && this.formData.keyword) {
            this.formData.keyword = []
            const keywordOfCategory = this.formData.sub_category_id.keyword
            if (typeof keywordOfCategory === 'string' && keywordOfCategory !== '') {
              const arrayKeyword = keywordOfCategory.split(',')
              this.formData.keyword = this.formData.keyword.concat(arrayKeyword)
            }
          }
        }
      },
      deep: true
    },
  },
  data() {
    return {
      formData: {},
      cacheFormData: {},
      isBookingCache: 0,
      categoryIdCache: 0,
      subCategoryIdCache: 0,
      pageLoading: false,
      productUrl: '',
      isLoading: true,
      searchStoreLoading: true,
      typingTimer: null,
      baseFilterCategories: {
        parent_category_id: 0
      },
      validateDiscount: {},
      isCheckAll: {}
    }
  },
  methods: {
    checkedToggleCheckbox(index) {
      let vm = this
      if (!vm.isCheckAll[index]) {
        vm.options.day_open.forEach((item) => {
          if (!vm.formData.discount_lines[index].day_open) {
            vm.formData.discount_lines[index] = [];
          }
          if (!vm.formData.discount_lines[index].day_open.includes(item.value)) {
            vm.formData.discount_lines[index].day_open.push(item.value)
          }
        })
        vm.isCheckAll[index] = true
      } else {
        vm.formData.discount_lines[index].day_open = [];
        vm.isCheckAll[index] = false
      }
    },

    formatter(value) {
      return value.toUpperCase()
    },

    async dayChoice(name, value, index) {
      const discount = this.formData.discount_lines[index]
      if (discount.day_open && discount.day_open.length > 0) {
        if (discount.day_open.length >= 7) {
          this.isCheckAll[index] = true;
        } else {
          this.isCheckAll[index] = false;
        }
      } else {
        this.isCheckAll[index] = false;
      }
      await this.$validator.validate('booking_product_validate.' + name, this.formData.discount_lines[index][value])
      this.validationDiscount()
    },

    async timeChoice(name, valueField, index) {
      await this.$validator.validate('booking_product_validate.' + name, this.formData.discount_lines[index][valueField])
      this.validationDiscount()
    },

    validationDiscount() {
      let index = 0
      const isValidDiscount = {}
      if (this.formData.discount_lines.length <= 0) {
        return false
      }
      for (const line of this.formData.discount_lines) {
        if (line.day_open && line.day_open.length > 0 && line.time_start !== '' && line.time_end !== '') {
          let isValid = true
          const timeStartToCheck = moment(line.time_start, 'HH:mm')
          const timeEndToCheck = moment(line.time_end, 'HH:mm')
          for (const day of line.day_open) {
            if (!isValidDiscount[day]) {
              isValidDiscount[day] = []
            }
            if (isValidDiscount[day].length > 0) {
              const pushToDay = [];
              for (const exitConfig of isValidDiscount[day]) {
                const existStartToCheck = moment(exitConfig.start, 'HH:mm')
                const existEndToCheck = moment(exitConfig.end, 'HH:mm')
                if (timeStartToCheck.isSameOrAfter(existStartToCheck) && timeEndToCheck.isSameOrBefore(existEndToCheck)
                    ||
                    timeStartToCheck.isSameOrBefore(existStartToCheck) && timeEndToCheck.isSameOrAfter(existEndToCheck)
                ) {
                  isValid = false
                } else {
                  pushToDay.push({
                    start: line.time_start,
                    end: line.time_end,
                  })
                }
              }
              isValidDiscount[day].concat(pushToDay)
            } else {
              isValidDiscount[day].push({
                start: line.time_start,
                end: line.time_end,
              })
            }
          }
          console.log(isValid, 'TEST123')
          this.validateDiscount[index] = isValid
          this.$emit('validate-discount', this.validateDiscount)
        }
        index++
      }
    },

    deleteDiscount(index) {
      this.formData.discount_lines.splice(index, 1)
    },

    getRateDiscount(type, index = 0) {
      let discountPrice, price
      if (type === 'discount') {
        if (this.formData.discount_lines[index].discount_price === '' || typeof this.formData.price === 'undefined' || this.formData.price === 0 || this.formData.price === '') {
          return 0
        } else {
          discountPrice = PriceHelper.convertToNumber(this.formData.discount_lines[index].discount_price.toString())
          price = PriceHelper.convertToNumber(this.formData.price.toString());
        }
      } else {
        if (
            typeof this.formData.sale_price === 'undefined'
            || this.formData.sale_price === ''
            || this.formData.sale_price === 0
            || typeof this.formData.price === 'undefined'
            || this.formData.price === 0
            || this.formData.price === ''
        ) {
          return 0
        } else {
          discountPrice = PriceHelper.convertToNumber(this.formData.sale_price.toString());
          price = PriceHelper.convertToNumber(this.formData.price.toString());
        }
      }
      const percent = PriceHelper.getRoundTwo(discountPrice, price)
      return percent * -1;
    },

    selectedDiscount(type = '') {
      this.$root.$emit('bv::hide::popover', 'popover-options')
      if (!this.formData.discount_lines) {
        this.formData.discount_lines = []
      }
      this.formData.discount_lines.push({
        id: this.formData.discount_lines.length + 1,
        type: type,
        discount_price: '',
        time_start: '',
        time_end: '',
        combo_name: '',
        day_open: [],
        date_open: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false
        }
      })
    },

    async setFormData() {
      this.formData = this.currentData
      if (this.formData.category_id !== '' && this.options.category_id.length > 0) {
        this.formData.category_id = this.options.category_id.find((category) => {
          return category.id === this.formData.category_id
        })
        this.categoryIdCache = JSON.parse(JSON.stringify(this.formData.category_id))
      }
      if (this.formData.sub_category_id !== '' && this.options.sub_category_id.length > 0) {
        this.formData.sub_category_id = this.options.sub_category_id.find((category) => {
          return category.id === this.formData.sub_category_id
        })
        this.subCategoryIdCache = JSON.parse(JSON.stringify(this.formData.sub_category_id))
      }
      if (this.formData.status !== '' && this.options.status.length > 0) {
        this.formData.status = this.options.status.find((status) => {
          return status.id === this.formData.status
        })
      }
      if (this.formData.sale_price !== '' && this.formData.sale_price == 0) {
        this.formData.sale_price = ''
      }
      if (this.formData.store_id !== '' && this.options.stores.length > 0) {
        this.formData.store_id = this.options.stores.find((type) => {
          return type.id === this.formData.store_id
        })
      }
      if (this.formData.is_booking !== '' && this.options.is_booking.length > 0) {
        this.formData.is_booking = this.options.is_booking.find((type) => {
          return type.id === this.formData.is_booking
        })
      }
      if (typeof this.formData.keyword === 'string' && this.formData.keyword !== '') {
        this.formData.keyword = this.formData.keyword.split(',')
      }
      if (Array.isArray(this.formData.discount_lines) && this.formData.discount_lines.length > 0) {
        let index = 0
        for (const line of this.formData.discount_lines) {
          if (line.day_open && line.day_open.length > 0) {
            if (line.day_open.length >= 7) {
              this.isCheckAll[index] = true;
            }
          }
          index++;
        }
      }
      this.cacheFormData = JSON.parse(JSON.stringify(this.formData))
      this.isLoading = false
    },

    async searchStore(value) {
      const vm = this
      clearTimeout(vm.typingTimer);
      vm.typingTimer = setTimeout(async () => {
        await vm.filterStores(value)
      }, 500);
    },

    async filterStores(value) {
      this.searchStoreLoading = true
      await this.$store.dispatch('searchStore', value)
      this.searchStoreLoading = false
    },

    async loadCategories() {
      if (this.formData.is_booking !== '') {
        if (this.formData.is_booking.id === 1) {
          this.formData.category_id = ''
          this.formData.sub_category_id = ''
          await this.$store.dispatch('resetStoreCategories')
          await this.$store.dispatch('getStoreCategories', this.baseFilterCategories)
          if (this.formData.store_id !== '') {
            const parentCategoryId = this.formData.store_id.category.parent_category;
            this.formData.category_id = this.options.category_id.find((category) => {
              return category.id === parentCategoryId
            });
            this.categoryIdCache = JSON.parse(JSON.stringify(this.formData.category_id))
            await this.loadSubCategories()
            const categoryId = this.formData.store_id.category.id
            this.formData.sub_category_id = this.options.sub_category_id.find((category) => {
              return category.id === categoryId
            })
            this.subCategoryIdCache = JSON.parse(JSON.stringify(this.formData.sub_category_id))
            if (Array.isArray(this.formData.keyword) && this.formData.keyword) {
              this.formData.keyword = []
              const keywordOfCategory = this.formData.sub_category_id.keyword
              if (typeof keywordOfCategory === 'string' && keywordOfCategory !== '') {
                const arrayKeyword = keywordOfCategory.split(',')
                this.formData.keyword = this.formData.keyword.concat(arrayKeyword)
              }
            }
          }
        } else {
          this.formData.category_id = ''
          this.formData.sub_category_id = ''
          await this.$store.dispatch('resetStoreCategories')
          await this.$store.dispatch('getStoreCategories', this.baseFilterCategories)
        }
      }
    },

    async loadSubCategories() {
      if (this.formData.category_id !== '') {
        this.formData.sub_category_id = ''
        await this.$store.dispatch('resetStoreSubCategories')
        await this.$store.dispatch('getStoreSubCategories', {parent_category_id: this.formData.category_id.id})
      }
    },

    async validateMultipleSelect(name) {
      await this.$validator.validate('booking_product_validate.' + name, this.formData[name])
    },
  }
}
</script>

<style lang="scss">
.discount-fields {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: right;

  &.text-red {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: right;
    color: #FF5959;
  }
}

.popover-options {

  .list-group-item {

    &:hover {
      background: #007bff;
      color: #FFFFFF;
    }
  }
}

.table-validate {

  tr {

    &.is-invalid {
      border: solid 2px #dc3545;

      .invalid-feedback {
        display: block;
      }
    }
  }
}

.vue__time-picker {

  input {
    border-radius: 0.25rem;
    border: 1px solid #e8ebf1;
    font-weight: 400;
    font-size: 0.875rem;
  }

  &.is-invalid {
    input {
      border-color: #dc3545;
    }
  }
}
</style>