<template>
  <div class="clearfix d-flex">
    <div class="col-sm-6 col-md-5 justify-content-start">
      <template v-if="meta && meta.total && meta.total_page > 0">
        Hiển thị từ <b>{{ firstItem }}</b> đến <b>{{ lastItem }}</b> của <b>{{ meta.total }}</b> bản ghi
      </template>
    </div>
    <div class="col-sm-6 col-md-7 justify-content-end">
      <template v-if="meta && meta.last_page > 1">
        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item" :class="meta.current_page <= 1 ? 'disabled' : ''">
            <a class="page-link" href="javascript:;" @click.prevent="changePage(1)">&laquo;</a>
          </li>
          <li v-if="meta.current_page > (numberPage - 1) && meta.last_page > flagPaginateSpecial"
              class="page-item" :class="isCurrentPage(1) ? 'active' : ''">
            <a class="page-link" href="javascript:;" @click.prevent="changePage(1)">{{ 1 }}</a>
          </li>
          <li class="disabled"
              v-if="meta.current_page > (numberPage - 1) && meta.last_page > flagPaginateSpecial">
            <span>...</span>
          </li>
          <li class="page-item" :key="'page-'+page" v-for="page in pages" :class="isCurrentPage(page) ? 'active' : ''">
            <a class="page-link" href="javascript:;" @click.prevent="changePage(page)">{{ page }}</a>
          </li>
          <li class="disabled"
              v-if="meta.current_page < meta.last_page - numberPage && meta.last_page > flagPaginateSpecial">
            <span>...</span>
          </li>
          <li v-if="meta.current_page < meta.last_page - numberPage && meta.last_page > flagPaginateSpecial"
              class="page-item" :class="isCurrentPage(meta.last_page) ? 'active' : ''">
            <a class="page-link" href="javascript:;" @click.prevent="changePage(meta.last_page)">{{ meta.last_page }}</a>
          </li>
          <li class="page-item" :class="meta.current_page >= meta.last_page ? 'disabled' : ''">
            <a class="page-link" href="javascript:;" @click.prevent="changePage(meta.last_page)">&raquo;</a>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    meta: {
      type: Object,
      required: false
    },
    flagPaginateSpecial: {
      type: Number,
      required: true,
      default: 10
    }
  },

  data() {
    return {
      numberPage: 5
    };
  },

  computed: {

    pages() {
      let vm = this;
      const range = [];
      for (let i = vm.minPage; i <= vm.maxPage; i++) {
        range.push(i);
      }
      return range;
    },

    minPage() {
      let vm = this;
      let minPage = 0;
      if (vm.meta.last_page <= vm.numberPage && vm.meta.last_page <= vm.flagPaginateSpecial) {
        minPage = 1;
      }

      if (vm.meta.current_page > (vm.numberPage - 1) && vm.meta.last_page > vm.flagPaginateSpecial) {
        minPage = vm.meta.current_page - 2;
      }

      if (vm.meta.current_page > (vm.meta.last_page - vm.numberPage) && vm.meta.last_page > vm.flagPaginateSpecial) {
        minPage = vm.meta.last_page - vm.numberPage;
      }

      if (minPage < 1) {
        minPage = 1;
      }

      return minPage;
    },

    maxPage() {
      let vm = this;
      let maxPage = vm.minPage + vm.numberPage;

      if (vm.meta.current_page > (vm.numberPage - 1) && vm.meta.last_page > vm.flagPaginateSpecial) {
        maxPage = vm.meta.current_page + 2;
      }

      if (vm.meta.current_page > (vm.meta.last_page - (vm.numberPage + 1)) && vm.meta.last_page > vm.flagPaginateSpecial) {
        maxPage = vm.meta.last_page;
      }

      if (maxPage > vm.meta.last_page) {
        maxPage = vm.meta.last_page;
      }
      return maxPage;
    },

    firstItem() {
      let vm = this;
      let currentPage = vm.meta.current_page;
      let perPage = vm.meta.per_page;
      return 1 + ((currentPage - 1) * perPage);
    },

    lastItem() {
      let vm = this;
      let currentPage = vm.meta.current_page;
      let perPage = vm.meta.per_page;
      let total = currentPage * perPage;
      if (total > vm.meta.total_item) {
        total = vm.meta.total_item;
      }
      return total;
    }
  },

  methods: {
    isCurrentPage(page) {
      let vm = this;
      return vm.meta.current_page === page;
    },

    changePage(page) {
      let vm = this;
      if (page > vm.meta.last_page) {
        page = vm.meta.last_page;
      }
      if (page > 0 && page <= vm.meta.last_page) {
        vm.meta.current_page = page;
        vm.$emit('paginate', page);
      }
    }
  }
}
</script>

<style scoped>

</style>
