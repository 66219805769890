import { render, staticRenderFns } from "./BookingProductPage.vue?vue&type=template&id=22119284&scoped=true&"
import script from "./BookingProductPage.vue?vue&type=script&lang=js&"
export * from "./BookingProductPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22119284",
  null
  
)

export default component.exports