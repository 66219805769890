<template>
  <div class="campaign-page w-100 h-100">
    <section class="content-header w-100" :style="{height: !isMobile ? '111px' : 'auto'}">
      <div class="container-fluid">
        <div class="row mb-2">
          <b-col :cols="isMobile ? 12 : 6">
            <template v-if="isMobile">
              <h3>{{ $t('coupon.title') }}</h3>
            </template>
            <template v-else>
              <h1>{{ $t('coupon.title') }}</h1>
            </template>
          </b-col>
        </div>
        <div class="row mb-2">
          <breadcrumb-layout></breadcrumb-layout>
        </div>
      </div>
    </section>
    <div class="card-datatable p-3 w-100">
      <b-card no-body class="w-100 h-100">
        <b-card-body>
          <b-row>

          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    ...mapGetters(["coupons", "couponMeta", "bookingStores"]),
    productOptions() {
      return {
        status: [
          {
            id: 0,
            name: this.$i18n.t('coupon.option_inactive'),
            class: 'badge badge-warning'
          },
          {
            id: 1,
            name: this.$i18n.t('coupon.option_active'),
            class: 'badge badge-primary'
          }
        ],
        stores: [...this.bookingStores]
      }
    },
    productAttributes() {
      return [
        {
          name: this.$i18n.t('booking.product.attribute.id'),
          attribute: 'id',
          type: 'string',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.image'),
          attribute: 'image',
          type: 'image',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.store_id'),
          attribute: 'store_name',
          type: 'string',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.is_booking'),
          attribute: 'is_booking',
          type: 'option',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.status'),
          attribute: 'status',
          type: 'option',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.main_category_id'),
          attribute: 'category_id',
          type: 'badge',
          className: "",
          badgeOption: 'parent_category',
          badgeValue: 'name',
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.sub_category_id'),
          attribute: 'sub_category_id',
          type: 'badge',
          className: "",
          badgeOption: 'sub_category',
          badgeValue: 'name',
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.name'),
          attribute: 'name',
          type: 'string_trim',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.zalo'),
          attribute: 'zalo_contact',
          type: 'string',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.price'),
          attribute: 'price',
          type: 'price',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.discount_price'),
          attribute: 'sale_price',
          type: 'price',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.created_at'),
          attribute: 'created_at',
          type: 'string',
          className: "",
          isSort: true
        }
      ]
    }
  },
}
</script>

<style scoped>

</style>