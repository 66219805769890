<template>
  <b-card class="card-layer" :class="{'mb-3': isMobile}">
    <b-card-text class="position-relative">
      <b-row>
        <b-col class="d-inline-flex align-items-center">
          <img :src="icons[icon]" class="mr-2" alt="Vodaplay">
          <h6 class="title-box-total">{{ title }}</h6>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-inline-flex margin-top-27">
          <div class="d-flex p-0 col-md-6 justify-content-start align-items-end">
            <h2 class="box-value">
              {{ value | abbreviateNumber }}
            </h2>
          </div>
          <div class="d-flex flex-wrap p-0 col-md-6 box-footer" v-if="showPercent">
            <div class="d-flex w-100 justify-content-end align-items-center">
              <span class="title-percent-total" :class="{'percent-up' : trend === 'up', 'percent-down': trend === 'down'}">
              <template v-if="trend === 'up'">
                 <img class="mb-1" :src="icons.ArrowUp" alt="Vodaplay">
              </template>
              <template v-else>
                  <img class="mb-1" :src="icons.ArrowDown" alt="Vodaplay">
              </template>
              <span>{{ percent + '%' }}</span>
            </span>
            </div>
            <div class="d-flex w-100 justify-content-end">
              <span class="title-percent-total">{{percentTitle}}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import Message from "@/assets/img/report/Message.svg"
import Pencil from "@/assets/img/report/Pencil.svg"
import Save from "@/assets/img/report/Save.svg"
import Bookmark from "@/assets/img/report/Bookmark.svg"
import Click from "@/assets/img/report/Click.svg"
import ArrowUp from "@/assets/img/report/arrow-up.svg"
import ArrowDown from "@/assets/img/report/arrow-down.svg"

export default {
  name: "BoxTotal",
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    percentTitle: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    valueColor: {
      type: String,
      required: true
    },
    percent: {
      type: [String, Number],
      required: true
    },
    trend: {
      type: String,
      default: 'up'
    }
  },
  data(){
    return {
      icons: {
        Message: Message,
        Pencil: Pencil,
        Save: Save,
        Bookmark: Bookmark,
        Click: Click,
        ArrowUp: ArrowUp,
        ArrowDown: ArrowDown,
      }
    }
  },
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    showPercent() {
      return this.percent > 0
    }
  }
}

</script>

<style scoped lang="scss">
.box-title {

}

.box-icon {
  font-size: 40px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.box-value {

}

.box-footer {
  margin-bottom: 0;
  width: 100%;
  text-align: right;

  .percent-up {
    color: var(--level-twice-side-bar);
  }

  .percent-down {
    color: var(--light-red-FF5959);
  }
}
</style>