import {StoreServiceAPI} from "@/api/booking"
import {ProductServiceAPI} from "@/api/product"

const initialState = () => {
    return {
        bookingStores: {},
        bookingStoreMeta: {
            current_page: 1
        },
        bookingStore: {},
        bookingProduct: {},
        bookingProducts: {},
        bookingProductMeta: {
            current_page: 1
        },
        sectors: [],
        searchStores: [],
        storeCategories: [],
        storeSubCategories: [],
        filterCategories: [],
        filterSubCategories: [],
        bookingPicks: {},
        bookingPick: {},
        bookingPickMeta: {
            current_page: 1
        },
        videoInformation: {},
        channelInformation: {},
        locationByKeyword: []
    }
}
export default {
    state: initialState,
    mutations: {
        setBookingStores(state, data) {
            state.bookingStores[data.meta.current_page] = data.data
        },
        setBookingStoreMeta(state, data) {
            state.bookingStoreMeta = data
        },
        setBookingProducts(state, data) {
            state.bookingProducts[data.meta.current_page] = data.data
        },
        setBookingProductMeta(state, data) {
            state.bookingProductMeta = data
        },
        setSectors(state, data) {
            state.sectors = data
        },
        setCreateStore(state) {
            state.bookingStores = {}
            state.bookingStoreMeta = {
                current_page: 1
            }
        },
        setFindStore(state, data) {
            state.bookingStore = data
        },
        setSearchStores(state, data) {
            state.searchStores = data
        },
        setUpdateStore(state) {
            state.bookingStores = {}
            state.bookingStoreMeta = {
                current_page: 1
            }
        },
        setResetStoreCategories(state) {
            state.storeSubCategories = []
        },
        setResetStoreMainCategories(state) {
            state.storeCategories = []
            state.storeSubCategories = []
        },
        setStoreCategories(state, value) {
            state.storeCategories = value
        },
        setStoreSubCategories(state, value) {
            state.storeSubCategories = value
        },
        setFindStoreProduct(state, data) {
            state.bookingProduct = data
        },
        setCreateProduct(state) {
            state.bookingProducts = {}
            state.bookingProductMeta = {
                current_page: 1
            }
        },
        setUpdateProduct(state) {
            state.bookingProducts = {}
            state.bookingProductMeta = {
                current_page: 1
            }
        },
        setFilterCategories(state, data) {
            state.filterCategories = data
        },
        setFilterSubCategories(state, data) {
            state.filterSubCategories = data
        },
        setBookingPicks(state, data) {
            state.bookingPicks[data.meta.current_page] = data.data
        },
        setBookingPickMeta(state, data) {
            state.bookingPickMeta = data
        },
        setFindPick(state, data) {
            state.bookingPick = data
        },
        setVideoInformation(state, data) {
            state.videoInformation = data
        },
        setLocationByKeyword(state, data) {
            state.locationByKeyword = data
        },
        setResetLocationByKeyword(state) {
            state.locationByKeyword = []
        },
        setChannelInformation(state, data) {
            state.channelInformation = data
        },
        resetChannelInformation(state) {
            state.channelInformation = {}
        },
        setResetUploadImage(state) {
            state.videoInformation = {}
        },
        setCreatePick(state) {
            state.bookingPicks = {}
            state.bookingPickMeta = {
                current_page: 1
            }
        },
        setUpdatePick(state) {
            state.bookingPicks = {}
            state.bookingPickMeta = {
                current_page: 1
            }
        },
        resetBookings(state){
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        }
    },
    actions: {
        async getBookingStores({commit}, params) {
            try {
                // if (typeof getters.bookingStores[params.page] !== "undefined" && (typeof params.is_reload === 'undefined' || !params.is_reload)
                // ) {
                //     return {
                //         data: {
                //             status: 200,
                //             data: getters.bookingStores[params.page],
                //             meta: getters.bookingStoreMeta,
                //             isStorage: true
                //         }
                //     };
                // } else {
                    let result = await StoreServiceAPI.getStores(params);
                    if (result && result.status === 200) {
                        commit('setFindStore', result.data.data);
                    }
                    return result;
                // }
            } catch (e) {
                return e;
            }
        },

        async getBookingProducts({commit, getters}, params) {
            try {
                if (typeof getters.bookingProducts[params.page] !== "undefined" && (typeof params.is_reload === 'undefined' || !params.is_reload)
                ) {
                    return {
                        data: {
                            status: 200,
                            data: getters.bookingProducts[params.page],
                            meta: getters.bookingProductMeta,
                            isStorage: true
                        }
                    };
                } else {
                    let result = await ProductServiceAPI.getStoreProduct(params);
                    if (result && result.data.status === 200) {
                        commit('setBookingProducts', {data: result.data.data, meta: result.data.meta})
                        commit('setBookingProductMeta', result.data.meta)
                    }
                    return result;
                }
            } catch (e) {
                return e;
            }
        },

        async getBookingPicks({commit, getters}, params) {
            try {
                if (typeof getters.bookingPicks[params.page] !== "undefined" && (typeof params.is_reload === 'undefined' || !params.is_reload)
                ) {
                    return {
                        data: {
                            status: 200,
                            data: getters.bookingPicks[params.page],
                            meta: getters.bookingPickMeta,
                            isStorage: true
                        }
                    };
                } else {
                    let result = await StoreServiceAPI.getStorePicks(params);
                    if (result && result.data.status === 200) {
                        commit('setBookingPicks', {data: result.data.data, meta: result.data.meta})
                        commit('setBookingPickMeta', result.data.meta)
                    }
                    return result;
                }
            } catch (e) {
                return e;
            }
        },

        async findStoreProduct({commit}, id) {
            try {
                let result = await ProductServiceAPI.findStoreProduct(id);
                if (result && result.data.status === 200) {
                    commit('setFindStoreProduct', result.data.data)
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async searchStore({commit}, search) {
            try {
                let result = await StoreServiceAPI.searchStore({search: search});
                if (result && result.status === 200) {
                    commit('setSearchStores', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async findStore({commit}, id) {
            try {
                let result = await StoreServiceAPI.findStore(id);
                if (result && result.status === 200) {
                    commit('setFindStore', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async createStore({commit}, params) {
            try {
                let result = await StoreServiceAPI.createStore(params);
                if (result && result.status === 200) {
                    commit('setCreateStore', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async updateStore({commit}, params) {
            try {
                let result = await StoreServiceAPI.updateStore(params.data, params.id);
                if (result && result.status === 200) {
                    commit('setUpdateStore', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async getSectors({commit}, params) {
            try {
                let result = await StoreServiceAPI.getSectors(params);
                if (result && result.status === 200) {
                    commit('setSectors', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async getStoreCategories({commit}, params) {
            try {
                let result = await StoreServiceAPI.searchStoreCategories(params);
                if (result && result.status === 200) {
                    commit('setStoreCategories', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async getStoreSubCategories({commit}, params) {
            try {
                let result = await StoreServiceAPI.searchStoreCategories(params);
                if (result && result.status === 200) {
                    commit('setStoreSubCategories', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async createStoreProduct({commit}, params) {
            try {
                let result = await ProductServiceAPI.createStoreProduct(params);
                if (result && result.status === 200) {
                    commit('setCreateProduct', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async updateStoreProduct({commit}, params) {
            try {
                let result = await ProductServiceAPI.updateStoreProduct(params);
                if (result && result.status === 200) {
                    commit('setUpdateProduct', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async filterCategories({commit}, params) {
            try {
                let result = await StoreServiceAPI.filterCategories(params);
                if (result && result.status === 200) {
                    commit('setFilterCategories', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async filterSubCategories({commit}, params) {
            try {
                let result = await StoreServiceAPI.filterCategories(params);
                if (result && result.status === 200) {
                    commit('setFilterSubCategories', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        resetStoreCategories({commit}) {
            commit('setResetStoreMainCategories', true);
        },

        resetStoreSubCategories({commit}) {
            commit('setResetStoreCategories', true);
        },

        async getVideoInformation({commit}, params) {
            try {
                let result = await StoreServiceAPI.getVideoInformation(params);
                if (result && result.status === 200) {
                    commit('setVideoInformation', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async getLocationByKeyword({commit}, params) {
            try {
                let result = await StoreServiceAPI.getLocationByKeyword(params);
                if (result && result.status === 200) {
                    commit('setLocationByKeyword', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async getChannelInformation({commit}, params) {
            try {
                let result = await StoreServiceAPI.getChannelInformation(params);
                if (result && result.status === 200) {
                    commit('setChannelInformation', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async findPick({commit}, id) {
            try {
                let result = await StoreServiceAPI.findPick(id);
                if (result && result.status === 200) {
                    commit('setFindPick', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async createPick({commit}, params) {
            try {
                let result = await StoreServiceAPI.createPick(params);
                if (result && result.status === 200) {
                    commit('setCreatePick', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async updatePick({commit}, params) {
            try {
                let result = await StoreServiceAPI.updatePick(params.data, params.video_id);
                if (result && result.status === 200) {
                    commit('setUpdatePick', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async setResetVideoInformation({commit}) {
            commit('setResetUploadImage', '');
        },

        async setResetLocationByKeyword({commit}) {
            commit('setResetLocationByKeyword', '');
        },

        async setResetChannelInformation({commit}) {
            commit('resetChannelInformation', '');
        },

        resetBookings({commit}){
            commit('resetBookings')
        }
    },
    getters: {
        bookingStores(state) {
            return state.bookingStores
        },
        bookingStore(state) {
            return state.bookingStore
        },
        searchStores(state) {
            return state.searchStores
        },
        bookingStoreMeta(state) {
            return state.bookingStoreMeta
        },
        bookingProducts(state) {
            return state.bookingProducts;
        },
        bookingProduct(state) {
            return state.bookingProduct;
        },
        bookingProductMeta(state) {
            return state.bookingProductMeta
        },
        sectors(state) {
            return state.sectors
        },
        storeCategories(state) {
            return state.storeCategories;
        },
        storeSubCategories(state) {
            return state.storeSubCategories
        },
        filterCategories(state) {
            return state.filterCategories
        },
        filterSubCategories(state) {
            return state.filterSubCategories
        },
        bookingPicks(state) {
            return state.bookingPicks;
        },
        bookingPick(state) {
            return state.bookingPick;
        },
        bookingPickMeta(state) {
            return state.bookingPickMeta
        },
        videoInformation(state){
            return state.videoInformation
        },
        locationByKeyword(state){
            return state.locationByKeyword
        },
        channelInformation(state){
            return state.channelInformation
        },
    }
}
