export default {
    booking: {
        title: 'Report Booking',
        up_to: 'Up to',
        down: 'Down',
        box_footer: 'from Last Month',
        impression: 'Impression',
        total_message: 'Total Messages',
        total_create_order: 'Total Create Order',
        total_confirm_order: 'Total Confirm Order',
        total_call: 'Total Calls',
        total_bookmark: 'Total Bookmarks',
        total_click: 'Total Click',
        brand_awareness_report: 'Brand Awareness Report',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        report_impression: 'Report Impressions',
        report_click: 'Report Clicks',
        report_message: 'Report Messages',
        report_char_call: 'Calls',
        report_char_impression_click: 'Impressions and Clicks',
        report_less_than_7_day: 'The number of reporting days cannot be less than 7',
        report_bigger_than_30_day: 'The number of reporting days cannot be more than 30',
        label_select_time: 'Choose time',
        label_select_store: 'Choose store',
        report_filter: 'Advance Filter',
        report_percent_title_day: 'with {n} days ago',
    },
    filter: {
        today: 'Today',
        before_7_day: '7 days ago',
        before_14_day: '14 days ago',
        before_30_day: '30 days ago',
        option_day: 'Input date',
        option_from_day: 'From day',
        option_to_day: 'To day',
    },
}
