<template>
  <b-col cols="12" style="padding: 10px 16px" class="box-users w-100" :class="{'d-none': isClickedUser}">
    <div class="w-100 position-relative">
      <b-form-input v-on:input="value => searchConversation(value)" class="search-list-user" type="text"
                    :placeholder="$t('chat.search')"></b-form-input>
      <svg class="position-absolute" style="right: 16px; top: 12px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.66668 14.0002C11.1645 14.0002 14 11.1646 14 7.66683C14 4.16903 11.1645 1.3335 7.66668 1.3335C4.16887 1.3335 1.33334 4.16903 1.33334 7.66683C1.33334 11.1646 4.16887 14.0002 7.66668 14.0002Z" stroke="#A4A4A4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.6667 14.6668L13.3333 13.3335" stroke="#A4A4A4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

    </div>
    <div class="w-100">
      <b-list-group id="user-scrollable"
                    v-on:scroll="scrollLoadMore"
                    :class="allConversations.length <= 0 ? 'd-flex justify-content-center align-items-center' : ''"
                    class="chatting-scrollable user-scrollable"
                    :style="{height: heightBox  + 'px', overflowY: 'auto'}">

        <template v-if="allConversations.length > 0">
          <template v-for="(conversation, index) in allConversations">
            <b-list-group-item :key="conversation._id + '-' + index"
                               class="d-flex align-items-center cursor-pointer item-user position-relative pt-4 pb-4"
                               v-on:click="onClickUser(conversation)"
                               :class="{
                                 active: userChoose && userChoose._id === conversation.members_user[0]._id,
                                 'has-unread': conversation.number_unread > 0}
                              ">
              <template
                  v-if="conversation.members_user[0].profilePicture && conversation.members_user[0].profilePicture !== ''">
                <b-avatar
                    style="margin-right:10px;"
                    variant="info"
                    :badge="false"
                    :badge-top="false"
                    badge-variant="success"
                    badge-offset="-2px"
                    size="50px"
                    :src="conversation.members_user[0].profilePicture"
                >
                </b-avatar>
              </template>
              <template v-else>
                <b-avatar
                    style="margin-right:10px;"
                    :variant="colors[conversation.randomColor]"
                    :text="getTextShowing(conversation.members_user[0].email)"
                    :badge="false"
                    :badge-top="false"
                    badge-variant="success"
                    badge-offset="-2px"
                    size="50px"
                >
                </b-avatar>
              </template>
              <p class="mr-auto">
                <span class="user-name">{{ conversation.members_user[0].name | truncateText(20, '...') }}</span>
                <br>
                <span class="message-text-last">{{ conversation.last_messsage | getLastMessage(20, '...') }}</span>
              </p>
              <div class="position-absolute show-time-from-now">
                {{conversation.updatedAt | showFromNow(currentLanguage)}}
              </div>
              <template v-if="conversation.number_unread > 0">
                <b-badge variant="danger">{{ conversation.number_unread }}</b-badge>
              </template>
            </b-list-group-item>
          </template>
        </template>
        <template v-else>
          <p :style="{fontSize: '16px', textDecoration: 'underline', color: '#282828'}">
            <font-awesome-icon icon="fa-solid fa-user-slash"/>
            {{ $t('chat.no_result_conversation') }}
          </p>
        </template>
      </b-list-group>
    </div>
  </b-col>
</template>


<script>
import {mapGetters} from "vuex"
import moment from "moment";

export default {
  beforeDestroy(){
    this.isDestroyed = true
  },
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    currentRouteName() {
      return this.$route.name;
    },
    currentLanguage(){
      return this.$i18n.locale
    },
    ...mapGetters(["allConversations", "currentUser", "conversationMeta"])
  },
  created() {
    this.$store.dispatch('clearConversation')
    this.resizeBoxUsers()
    window.addEventListener("resize", this.resizeBoxUsers)
    this.$root.$on('back-to-users-chatting', () => {
      this.isClickedUser = false
    })
    this.getAllConversations()
    const vm = this;
    vm.$nextTick(() => {
      vm.sockets.subscribe("getMessage", (data) => {
        if(!vm.isDestroyed) {
          vm.$store.dispatch('sendUserPreview', {
            conversationId: data.conversationId,
            userEmail: data.userEmail,
            sender: data.senderId,
            currentUser: vm.currentUser,
            text: data.text,
            isPreview: true,
            now: moment()
          })
          vm.$store.dispatch('incrementCountPreview')
        }
      })
    })
  },
  data() {
    return {
      heightBox: 0,
      isClickedUser: false,
      page: 1,
      isLoading: false,
      timer: null,
      timerInput: null,
      search: '',
      oldScroll: 0,
      userChoose: null,
      colors: ['primary', 'secondary ', 'success', 'warning', 'danger', 'info', 'dark'],
      isDestroyed: false
    }
  },
  methods: {

    async scrollLoadMore(event) {
      const vm = this
      if (event.currentTarget.classList.contains('user-scrollable')) {
        const container = event.currentTarget
        const currentScroll = container.scrollTop
        const scrollHeight = container.scrollHeight - container.offsetHeight
        if (currentScroll >= (scrollHeight * 0.7) && currentScroll > this.oldScroll && this.conversationMeta.total_page > this.page) {
          this.oldScroll = currentScroll
          clearTimeout(vm.timer)
          vm.timer = setTimeout(async () => {
            vm.page++
            await vm.getAllConversations(true)
          }, 200)
        }
      }
    },

    async searchConversation(value) {
      const vm = this
      clearTimeout(vm.timerInput);
      vm.timerInput = setTimeout(async () => {
        this.search = value
        await vm.getAllConversations()
      }, 500);
    },

    async getAllConversations(isLoad = false) {
      if (this.isLoading) {
        return false
      }
      const isFirst = this.allConversations.length <= 0
      this.isLoading = true
      await this.$store.dispatch('getAllConversations', {
        page: this.page,
        userId: this.currentUser.chatting_id,
        search: this.search,
        chattingUserId: this.currentUser.chatting_id
      })
      if (!this.isMobile && isFirst && this.allConversations.length > 0) {
        this.onClickUser(this.allConversations[0])
      }
      if (!isLoad) {
        this.scrollToTop(isLoad)
      }
      this.isLoading = false
    },

    scrollToTop(isLoad = false) {
      setTimeout(() => {
        const container = document.body.querySelector("#user-scrollable")
        const scrollHeight = container ? container.scrollHeight : 0
        if (container) {
          container.scrollTop = !isLoad ? 0 : (scrollHeight * 0.7)
        }
      }, 200)
    },

    resizeBoxUsers() {
      const boxBody = document.getElementsByTagName('body')[0]
      let heightBox = 0
      if (boxBody) {
        heightBox = boxBody.offsetHeight
      }
      this.heightBox = heightBox - 59 - 50 - 111 - 51 - 48 - 60 - 2;
    },

    getTextShowing(name) {
      const nameArr = name.trim().split(/\s+/)
      const firstChar = nameArr.map(
          element => element.charAt(0)
      )
      return firstChar[0] + (firstChar[1] ? firstChar[1] : '')
    },

    onClickUser(conversation) {
      if (this.isMobile) {
        this.isClickedUser = true
      }
      this.$store.dispatch('clearMessageByUser')
      this.$store.dispatch('decrementCountPreview', conversation)
      this.$store.dispatch('messageRead', conversation)
      this.getAllConversations(true)
      const user = conversation.members_user[0]
      this.userChoose = user
      user.conversationId = conversation._id
      user.randomColor = conversation.randomColor
      this.$socket.emit("addUser", {
        userId: this.currentUser.chatting_id,
        conversationId: conversation._id,
        userEmail: this.currentUser.email
      })
      this.$root.$emit('choose-user-chatting', user)
    }
  }
}
</script>

<style scoped lang="scss">
.chatting-scrollable {
  &::-webkit-scrollbar {
    width: 6px;
    display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--level-three-side-bar);
    border-radius: 20px;
  }

  .item-user {
    border-bottom: 1px solid rgba(9, 9, 9, 0.1);
    border-right: 0;
    border-left: 0;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background: #eeeeee;
    }

    &.has-unread{

      .user-name{
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 19px !important;
        text-align: left !important;
        color: #090909 !important;
      }

      .message-text-last{
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 15px !important;
        text-align: left !important;
        color: #090909 !important;
        opacity: 1 !important;
      }

    }
  }

  .active {
    background: #F0EFFF;
    border: none;
  }
}

.box-users {
  &:hover {
    .chatting-scrollable {
      &::-webkit-scrollbar {
        display: block;
      }
    }
  }

  .show-time-from-now{
    top: 12px;
    right: 12px;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: right;
    color: #090909;
    opacity: 0.6;
  }

  .user-name{
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #090909;
  }

  .message-text-last{
    color: #090909;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    opacity: 0.6;
  }

  .search-list-user{
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 15px !important;
    text-align: left !important;
    border-radius: 8px !important;
    border-color: #F3F2F2;
    padding:12px 40px 12px 12px !important;
    height: auto;


    &::placeholder{
      color: #A4A4A4 !important;
    }
  }
}
</style>