import auth from './auth'
import header from './header'
import sidebar from './sidebar'
import filter from './filter'
import datatable from './datatable'
import campaign from './campaign'
import common from './common'
import product from './product'
import booking from './booking'
import report from './report'
import chat from './chat'
import profile from './profile'
import coupon from './coupon'

export default {
    auth: auth,
    header: header,
    sidebar: sidebar,
    filter: filter,
    datatable: datatable,
    campaign: campaign,
    common: common,
    product: product,
    booking: booking,
    report: report,
    chat: chat,
    profile: profile,
    coupon: coupon,
}
