<template>
    <footer class="footer d-flex flex-column flex-md-row align-items-center justify-content-between">
        <p class="text-muted text-center text-md-left">Copyright © 2023 <a href="https://vodaplay.co" target="_blank">Vodaplay</a>. All rights reserved</p>
    </footer>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
