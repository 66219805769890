import {BaseApi} from "@/lib/base-api";

const apiUrl = process.env.VUE_APP_TRACKING_BASE_URL + 'tracking';

export const ReportServiceAPI = {

    getTrackingCount(params) {
        return BaseApi.request('querySerialize', apiUrl + '/count', params)
    },

    getChartStatistical(params) {
        return BaseApi.request('querySerialize', apiUrl + '/statistical', params)
    },

    getHeatMap(params) {
        return BaseApi.request('querySerialize', apiUrl + '/heat-map', params)
    },
}
