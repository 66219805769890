export default {
    title: 'Đặt chỗ',
    add_new: 'Thêm mới',
    searching: 'Tìm kiếm...',
    option_pending: 'Ẩn',
    option_running: 'Hiện',
    attribute:{
        id: 'ID',
        image: 'Ảnh',
        advertiser: 'Nhà quảng cáo',
        campaign: 'Chiến dịch',
        name: 'Tên sản phẩm',
        status: 'Trạng thái',
        brand: 'Thương hiệu',
        category: 'Danh mục',
        price: 'Giá',
        sale_price: 'Giá giảm',
        is_mall_link: 'Loại đường dẫn',
        keyword: 'Từ khóa',
        created_by: 'Người tạo',
        approval_by: 'Người phê duệt',
        created_at: 'Thời gian tạo',
    }
}
