import {BaseApi} from "@/lib/base-api";

const apiUrl = process.env.VUE_APP_API_URL + 'auth/';

export const AuthServiceAPI = {

    login(params) {
        return BaseApi.request('post', apiUrl + 'login', params);
    },

    refreshToken(params) {
        return BaseApi.request('post', apiUrl + 'refresh-token', params);
    },

    logOut(params) {
        return BaseApi.request('post', apiUrl + 'logout', params);
    }
}
