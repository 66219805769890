import {BaseApi} from "@/lib/base-api";

const apiUrl = process.env.VUE_APP_API_URL + 'stores';

export const StoreServiceAPI = {

    getStores(params) {
        return BaseApi.request('querySerialize', apiUrl, params);
    },

    findStore(id) {
        return BaseApi.request('querySerialize', apiUrl + '/' + id, {});
    },

    searchStore(params) {
        return BaseApi.request('querySerialize', apiUrl + '/search', params);
    },

    searchStoreCategories(params) {
        return BaseApi.request('querySerialize', apiUrl + '/categories', params);
    },

    filterCategories(params) {
        return BaseApi.request('querySerialize', apiUrl + '/filter-categories', params);
    },

    createStore(params) {
        return BaseApi.request('post', apiUrl + '/create', params);
    },

    updateStore(params, id) {
        return BaseApi.request('post', apiUrl + '/' + id + '/update', params);
    },

    getSectors(params) {
        return BaseApi.request('querySerialize', apiUrl + '/sectors', params);
    },

    getStorePicks(params) {
        return BaseApi.request('querySerialize', apiUrl + '/picks', params);
    },

    findPick(id) {
        return BaseApi.request('querySerialize', apiUrl + '/picks/' + id, {});
    },

    createPick(params) {
        return BaseApi.request('post', apiUrl + '/picks/create', params);
    },

    updatePick(params, id) {
        return BaseApi.request('post', apiUrl + '/picks/update/' + id, params);
    },

    getVideoInformation(params) {
        return BaseApi.request('querySerialize', apiUrl + '/picks/video-information', params);
    },

    getLocationByKeyword(params) {
        return BaseApi.request('querySerialize', apiUrl + '/get-location-by-keyword', params);
    },

    getChannelInformation(params) {
        return BaseApi.request('post', process.env.VUE_APP_API_BASE_URL + '/api/v3/feed/channelDetail', params);
    }
}
