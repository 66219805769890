export default {
    title: 'Bộ lọc',
    add_filter: 'Thêm bộ lọc',
    no_result: 'Không tìm thấy thông tin',
    from: 'Từ',
    to: 'Đến',
    placeholder_option: '-- Lựa chọn thuộc tính --',
    placeholder_price: 'Nhập tiền ...',
    placeholder_input: 'Nhập ...',
}
