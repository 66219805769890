<template>
  <section class="col-sm-12 mt-5">
    <div class="row">

      <div class="card col-sm-6">
        <h4 class="mt-3 ml-3">{{ $t('campaign.product.title') }}</h4>
        <div class="card-body">

          <div class="row">

            <table class="table table-bordered table-form mt-2">
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.product.brand') }}</label>
                </th>
                <td>
                  <multiple-select
                      v-model="requestData.product_brand"
                      :options="options.brand_id"
                      :allow-empty="true"
                      :placeholder="$t('filter.placeholder_option')"
                      open-direction="bottom"
                      label="name"
                      track-by="id"
                      :show-labels="false"
                  >
                    <span slot="noResult">{{ $t('filter.no_result') }}</span>
                  </multiple-select>
                </td>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.product.category') }}</label>
                </th>
                <td>
                  <multiple-select
                      v-model="requestData.product_category"
                      :options="options.category_id"
                      :allow-empty="true"
                      :placeholder="$t('filter.placeholder_option')"
                      open-direction="bottom"
                      label="name"
                      track-by="id"
                      :show-labels="false"
                  >
                    <span slot="noResult">{{ $t('filter.no_result') }}</span>
                  </multiple-select>
                </td>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.product.keyword') }}</label>
                </th>
                <td>
                  <input-tags
                      v-model="requestData.product_keyword"
                      :placeholder="$t('campaign.field.placeholder_tags') + '...'"
                  ></input-tags>
                </td>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.product.price') }}</label>
                </th>
                <td>
                  <div class="input-group group_money w-100">
                    <div class="w-100 d-flex align-items-center"
                         :class="errors.first('campaign_validate.product_to_price') ? 'is-invalid' : ''">
                      <input-price
                          class="form-control"
                          ref="product_from_price_ref"
                          :data-vv-as="$t('campaign.product.product_from_price')"
                          v-model="requestData.product_from_price"
                          :placeholder="$t('campaign.product.from')">
                      </input-price>
                      -
                      <input-price
                          class="form-control"
                          name="product_to_price"
                          :data-vv-as="$t('campaign.product.product_to_price')"
                          v-validate="'is_bigger_if_exist:product_from_price_ref'"
                          data-vv-scope="campaign_validate"
                          v-model="requestData.product_to_price"
                          :class="errors.first('campaign_validate.product_to_price') ? 'is-invalid' : ''"
                          :placeholder="$t('campaign.product.to')">
                      </input-price>
                    </div>
                    <b-form-invalid-feedback class="d-block w-100">
                      {{ errors.first('campaign_validate.product_to_price') }}
                    </b-form-invalid-feedback>
                  </div>
                </td>
              </tr>
            </table>

          </div>

          <div class="row mt-4">
            <div class="col-12 text-center">
              <b-button :disabled="typeSearching" variant="secondary" @click="searchProductTypes()">
                <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
                {{ $t('campaign.product.search') }}
              </b-button>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12">
              <b-overlay :show="typeSearching" rounded="lg">
                <div class="table-hover table-responsive table-custom">
                  <datatable-layout
                      type="product-types"
                      :items="productTypes.products"
                      :attributes="productTypeAttributes"
                      :options="options"
                      :is-need-checkbox="true"
                      :page="1"
                      @checked="checkProductTypes"
                  >

                  </datatable-layout>
                </div>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>

      <div class="card col-sm-6">
        <h4 class="mt-3 ml-3">{{ $t('campaign.product.selected_title') }}</h4>
        <div class="card-body">

          <b-row>
            <b-col cols="12" class="text-right validation-button"
                   :class="errors.first('campaign_validate.product_checkbox') ? 'is-invalid' : ''">
              <b-button @click="OpenProductByTypeModal" class="btn btn-secondary">
                <template v-if="productByTypeSearchingButton">
                  <b-spinner small></b-spinner>
                </template>
                <template v-else>
                  {{ $t('campaign.product.button_manage') }}
                </template>
              </b-button>
              <input type="hidden"
                     v-model="requestData.product_checkbox"
                     name="product_checkbox"
                     :data-vv-as="$t('campaign.product.product_checkbox')"
                     v-validate.continues="'required_select'"
                     data-vv-scope="campaign_validate"
              >
            </b-col>
            <b-form-invalid-feedback class="text-right col-12">
              {{ errors.first('campaign_validate.product_checkbox') }}
            </b-form-invalid-feedback>
          </b-row>

          <b-modal id="modal-manager-product" size="xl" :title="$t('campaign.product_types.table_title')">

            <template #default>
              <b-container>
                <b-row class="d-flex align-items-center">
                  <b-col cols="3" class="form-group">
                    <label>{{ $t('campaign.product_types.table_link') }}</label>
                    <multiple-select
                        v-model="searchProduct.modal_link_type"
                        :options="options.type"
                        :allow-empty="true"
                        :placeholder="$t('filter.placeholder_option')"
                        open-direction="bottom"
                        label="name"
                        track-by="id"
                        :show-labels="false"
                    >
                      <span slot="noResult">{{ $t('filter.no_result') }}</span>
                    </multiple-select>
                  </b-col>
                  <b-col cols="3" class="form-group">
                    <label>{{ $t('campaign.product_types.table_category') }}</label>
                    <multiple-select
                        v-model="searchProduct.modal_product_category"
                        :options="options.category_id"
                        :allow-empty="true"
                        :placeholder="$t('filter.placeholder_option')"
                        open-direction="bottom"
                        label="name"
                        track-by="id"
                        :show-labels="false"
                    >
                      <span slot="noResult">{{ $t('filter.no_result') }}</span>
                    </multiple-select>
                  </b-col>
                  <b-col cols="3" class="form-group">
                    <label>{{ $t('campaign.product_types.table_product_name') }}</label>
                    <input type="text" class="form-control" v-model="searchProduct.modal_product_name">
                  </b-col>
                  <b-col cols="3" class="form-group">
                    <b-button variant="secondary" @click="searchingProduct()">
                      <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
                      {{ $t('campaign.product_types.table_btn_search') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col cols="6"> {{ $t('campaign.product_types.table_title_count') }}</b-col>
                  <b-col cols="6" class="text-right">
                    {{ $tc('campaign.product_types.table_count', requestData.product_checkbox.length) }}
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col cols="12">
                    <b-overlay :show="productByTypeSearching" rounded="lg">
                      <div v-if="!productByTypeSearching" class="table-hover table-responsive table-custom">
                        <datatable-layout
                            type="modal-product-types"
                            :items="productByTypes"
                            :attributes="productByTypeAttributes"
                            :options="options"
                            :is-need-checkbox="true"
                            :is-paginate="true"
                            :page="productByTypeMeta.current_page || 1"
                            :checked-items="swapKeysAndValues(requestData.product_checkbox)"
                            @checked="checkProductByTypes"
                            :is-reset-checkbox="isResetProductCheckbox"
                            @resetCheckboxDone="resetProductCheckboxDone"
                        >
                        </datatable-layout>
                      </div>
                    </b-overlay>
                    <pagination-layout :meta="productByTypeMeta" :flag-paginate-special="10" @paginate="paginate"></pagination-layout>
                  </b-col>
                </b-row>
              </b-container>
            </template>

            <template #modal-footer="{cancel}">
              <div class="w-100 text-center">
                <b-button
                    variant="primary"
                    class="mr-2"
                    @click="cancel()"
                >
                  {{ $t('campaign.product_types.table_submit') }}
                </b-button>
                <b-button
                    variant="outline-primary"
                    @click="resetProductCheckbox()"
                >
                  {{ $t('campaign.product_types.table_clear_selected') }}
                </b-button>
              </div>
            </template>
          </b-modal>

          <div class="row">

            <table class="table table-bordered table-form mt-2">
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.product_types.type_original') }}</label>
                </th>
                <td>
                    <span id="link-real">
                        {{ countRealLink }}
                    </span>
                </td>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <span>{{ $t('campaign.product_types.type_external') }}</span>
                </th>
                <td>
                    <span id="link-fake">
                      {{ countFakeLink }}
                    </span>
                </td>
              </tr>
              <tr>
                <th class="text-center" colspan="2">
                  <label> <span>{{ $t('campaign.product.pick_matching') }}</span></label>
                </th>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.product_types.type_original') }}</label>
                </th>
                <td>
                    <span id="link-real-pick">
                        0
                    </span>
                </td>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.product_types.type_external') }}</label>
                </th>
                <td>
                    <span id="link-fake-pick">
                        0
                    </span>
                </td>
              </tr>
            </table>

          </div>

        </div>
      </div>

    </div>
  </section>
</template>

<script>

import {mapGetters} from "vuex"
import _ from "lodash"
import InputTags from "@/components/dashboard/layouts/InputTags.vue";

export default {
  components:{
    InputTags
  },
  props: {
    formData: {
      required: true,
      type: Object
    },
    options: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters(["productTypes", "productByTypes", "productByTypeMeta"]),
    productTypeAttributes() {
      return [
        {
          name: this.$i18n.t('campaign.product_types.table_link'),
          attribute: 'type',
          type: 'option',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('campaign.product_types.number_product'),
          attribute: 'number_product',
          type: 'number',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('campaign.product_types.number_pick'),
          attribute: 'number_pick',
          type: 'number',
          className: "",
          isSort: true
        },
      ];
    },
    productByTypeAttributes() {
      return [
        {
          name: this.$i18n.t('campaign.product_types.table_link'),
          attribute: 'is_mall_link',
          type: 'option',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('campaign.product_types.table_brand'),
          attribute: 'brand_id',
          type: 'option',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('campaign.product_types.table_category'),
          attribute: 'category_id',
          type: 'option',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('campaign.product_types.table_product_name'),
          attribute: 'name',
          type: 'string_trim',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('campaign.product_types.table_price'),
          attribute: 'price',
          type: 'price',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('campaign.product_types.table_keyword'),
          attribute: 'keyword',
          type: 'string_trim',
          className: "",
          isSort: true
        },
      ];
    },
  },
  data() {
    return {
      productKeyword: '',
      currentProductKeyword: [],
      requestData: this.formData,
      searchProduct: {
        modal_link_type: '',
        modal_product_category: '',
        modal_product_name: '',
        page: 1
      },
      productCheckBox: {},
      typeOfProducts: {},
      countRealLink: 0,
      countFakeLink: 0,
      typeSearching: false,
      productByTypeSearching: false,
      productByTypeSearchingButton: false,
      isResetProductCheckbox: false,
      isReload: false
    }
  },
  methods: {

    async resetProductCheckbox() {
      this.isResetProductCheckbox = true
    },

    async searchProductTypes() {
      this.typeSearching = true
      await this.$store.dispatch('getProductTypes', {
        product_brand: this.requestData.product_brand,
        product_category: this.requestData.product_category,
        product_keyword: this.requestData.product_keyword,
        product_from_price: this.requestData.product_from_price,
        product_to_price: this.requestData.product_to_price,
      })
      this.typeSearching = false
    },

    async searchingProduct() {
      this.isReload = true
      await this.searchProductByTypes()
    },

    async OpenProductByTypeModal() {
      if (this.requestData.product_types.length <= 0) {
        const description = this.$t('campaign.product.description_error_product_types')
        await this.$bvModal.msgBoxOk(description, {
          title: this.$t('campaign.product.title_error_product_types'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        return false
      } else {
        this.isReload = true
        this.productByTypeSearchingButton = true
        await this.searchProductByTypes()
        this.$bvModal.show('modal-manager-product')
      }
    },

    async searchProductByTypes() {
      this.productByTypeSearching = true
      const payload = {
        ...this.searchProduct, ...{
          product_brand: this.requestData.product_brand,
          product_category: this.requestData.product_category,
          product_keyword: this.requestData.product_keyword,
          product_types: this.requestData.product_types,
          product_from_price: this.requestData.product_from_price,
          product_to_price: this.requestData.product_to_price,
          is_reload: this.isReload
        }
      }
      await this.$store.dispatch('getProductByTypes', payload)
      this.productByTypeSearchingButton = false
      this.isReload = false
      this.productByTypeSearching = false
    },

    checkProductTypes(types) {
      this.resetProductCheckboxDone()
      this.searchProduct.page = 1
      this.requestData.product_types = types
      this.$store.dispatch('resetProductByTypes', true)
    },

    resetProductCheckboxDone() {
      this.productCheckBox = {}
      this.requestData.product_checkbox = []
      this.countRealLink = 0
      this.countFakeLink = 0
      this.typeOfProducts = {}
    },

    checkProductByTypes(products) {
      const vm = this
      const productByTypeToCheck = _.keyBy(vm.productByTypes[vm.productByTypeMeta.current_page], 'id')
      const checked = {}
      products.forEach((id) => {
        const itemProduct = productByTypeToCheck[id]
        const itemChecked = vm.productCheckBox[id]
        vm.typeOfProducts[id] = itemProduct
        if (itemProduct && !itemChecked) {
          vm.productCheckBox[id] = true
          if (itemProduct.is_mall_link === 0) {
            vm.countRealLink += 1
          } else {
            vm.countFakeLink += 1
          }
          checked[id] = true
        } else if (itemProduct && itemChecked) {
          checked[id] = true
        }
      })
      Object.keys(vm.productCheckBox).forEach((id) => {
        const itemProduct = productByTypeToCheck[id]
        if (itemProduct && !checked[id]) {
          if (vm.typeOfProducts[id].is_mall_link === 0) {
            vm.countRealLink -= 1
          } else {
            vm.countFakeLink -= 1
          }
          delete vm.productCheckBox[id]
        }
      })
      this.requestData.product_checkbox = Object.keys(vm.productCheckBox)
    },

    async paginate(page) {
      this.searchProduct.page = page
      await this.searchProductByTypes()
    }
  }
}
</script>

<style scoped>

</style>