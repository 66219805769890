import {BaseApi} from "@/lib/base-api";

const apiUrl = process.env.VUE_APP_API_URL + 'user/';

export const UserServiceAPI = {

    userInfo(params) {
        return BaseApi.request('querySerialize', apiUrl + 'info', params);
    },

    updateAvatar(params) {
        return BaseApi.request('post', apiUrl + 'upload-avatar', params);
    },

    updateCover(params) {
        return BaseApi.request('post', apiUrl + 'upload-cover', params);
    },

    updateInfo(params) {
        return BaseApi.request('post', apiUrl + 'update-info', params);
    },

    updatePassword(params) {
        return BaseApi.request('post', apiUrl + 'update-password', params);
    }
}
