<template>
  <div class="card card-layer mb-4">
    <div class="card-body">
        <div class="row">
          <div class="col-12 d-flex w-100">
          <div class="filter-box w-50">
            <button class="button-violet"
                    @click="clickFilter">
              <font-awesome-icon icon="fa-solid fa-filter"/>
            </button>
          </div>
          <div class="search-box w-50 d-flex justify-content-end">
            <div class="d-flex max-width-300 flex-end position-relative">
              <input type="text" class="form-control designer-search-input"
                     :placeholder="$t('booking.product.searching')"
                     v-model="search" @keydown="searching">
              <img class="designer-search-icon" :src="searchIcon" alt="Vodaplay">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import searchIcon from "@/assets/img/common/search-normal.svg"

export default {

  data(){
    return {
      search: '',
      isShowFilter: false,
      typingTimer: null,
      searchIcon: searchIcon
    }
  },
  methods: {

    clickFilter(){
      this.isShowFilter = !this.isShowFilter
      this.$emit('isShowFilter', this.isShowFilter)
    },

    searching() {
      const vm = this
      clearTimeout(vm.typingTimer);
      vm.typingTimer = setTimeout(function () {
        vm.$emit('searching', vm.search)
      }, 500);
    },
  }
}
</script>

<style lang="scss">
.designer-search-input{
  padding-right: 40px;
}

.designer-search-icon{
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
}
</style>