import {UserServiceAPI} from "@/api/user";
import DefaultAvatar from "@/assets/img/common/Default_avatar.svg"

const initialState = () => {
    return {
        currentUser: {}
    }
}
export default {
    state: initialState,
    mutations: {
        setUserInfo(state, data) {
           state.currentUser = data.user
           if(state.currentUser.avartar === ''){
               state.currentUser.avartar = DefaultAvatar
           }
        },
        resetUsers(state) {
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        },
        updateSuccess(){

        }
    },
    actions: {
        async getUserInfo({commit}, params) {
            try {
                let result = await UserServiceAPI.userInfo(params);
                if (result && result.status === 200) {
                    commit('setUserInfo', {
                        'user': result.data.data
                    });
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async updateAvatar({commit}, params){
            try {
                let result = await UserServiceAPI.updateAvatar(params);
                if (result && result.status === 200) {
                    commit('updateSuccess');
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async updateCover({commit}, params){
            try {
                let result = await UserServiceAPI.updateCover(params);
                if (result && result.status === 200) {
                    commit('updateSuccess');
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async updateInfo({commit}, params){
            try {
                let result = await UserServiceAPI.updateInfo(params);
                if (result && result.status === 200) {
                    commit('updateSuccess');
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async updatePassword({commit}, params){
            try {
                let result = await UserServiceAPI.updatePassword(params);
                if (result && result.status === 200) {
                    commit('updateSuccess');
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        resetUsers({commit}){
            commit('resetUsers')
        }
    },
    getters: {
        currentUser(state) {
            return state.currentUser
        }
    }
}
