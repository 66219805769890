<template>
  <div class="wrapper"
       :style="{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', color:'#8C52FF', background: '#111111'}">
    <div class="preloader">
      <div class="preloader__ring">
        <div class="preloader__sector">L</div>
        <div class="preloader__sector">o</div>
        <div class="preloader__sector">a</div>
        <div class="preloader__sector">d</div>
        <div class="preloader__sector">i</div>
        <div class="preloader__sector">n</div>
        <div class="preloader__sector">g</div>
        <div class="preloader__sector">.</div>
        <div class="preloader__sector">.</div>
        <div class="preloader__sector">.</div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
      </div>
      <div class="preloader__ring">
        <div class="preloader__sector">L</div>
        <div class="preloader__sector">o</div>
        <div class="preloader__sector">a</div>
        <div class="preloader__sector">d</div>
        <div class="preloader__sector">i</div>
        <div class="preloader__sector">n</div>
        <div class="preloader__sector">g</div>
        <div class="preloader__sector">.</div>
        <div class="preloader__sector">.</div>
        <div class="preloader__sector">.</div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
        <div class="preloader__sector"></div>
      </div>
    </div>
  </div>
</template>

<script>
import JwtService from '@/lib/jwt-service'

export default {
  mounted() {
    this.getCurrentUserInfo()
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    async getCurrentUserInfo() {
      const currentToken = JwtService.getToken()
      const isRefresh = JwtService.getRefreshToken()
      if (!this.currentRouteName) {
        await this.$router.push({name: 'NotFoundPage'})
      } else if (this.currentRouteName === 'NotFoundPage') {
        console.log('Test')
      } else if (!currentToken || currentToken === 'undefined' || typeof currentToken === 'undefined') {
        await this.$router.push({name: 'login'})
      } else {
        const res = await this.$store.dispatch('getUserInfo')
        const historyPath = sessionStorage.getItem('history-page')
        if (res && res.data.status === 200) {
          this.$store.commit('setAuthenticate', true)
          if (historyPath) {
            await this.$router.push({path: historyPath})
          } else {
            await this.$router.push({name: 'ReportBooking'})
          }
        } else if (isRefresh && res.data.status === 401) {
          let refreshToken = JwtService.getRefreshToken()
          if (typeof refreshToken !== 'undefined') {
            await this.refreshToken()
          } else {
            JwtService.destroyToken()
            await this.$router.push({name: 'login'})
          }
        } else {
          JwtService.destroyToken()
          await this.$router.push({name: 'login'})
        }
      }
    },

    async refreshToken() {
      const res = await this.$store.dispatch('refreshToken')
      if (!res || res.status !== 200) {
        JwtService.destroyToken();
        await this.$router.push({name: 'login'})
      } else {
        await this.getCurrentUserInfo()
      }
    },

  }
}
</script>

<style lang="sass" scoped>
$ringRadius: 7rem
$ringSectors: 30
$animDuration: 8s

*
  border: 0
  box-sizing: border-box
  margin: 0
  padding: 0

\:root
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320))

body, .preloader
  display: flex

body
  background: #000
  color: #3df1f1
  font: 1em Dosis, sans-serif
  height: 100vh
  line-height: 1.5
  perspective: 40em

.preloader
  animation: tiltSpin $animDuration linear infinite
  flex-direction: column
  justify-content: center
  align-items: center
  margin: auto
  width: 17em
  height: 17em

  &, &__ring
    transform-style: preserve-3d

  &__ring
    animation:
      name: spin
      duration: $animDuration/2
      timing-function: inherit
      iteration-count: inherit
    font-size: 2em
    position: relative
    height: 3rem
    width: 1.5rem

    &:nth-child(even)
      animation-direction: reverse

  &__sector
    font-weight: 600
    position: absolute
    top: 0
    left: 0
    text-align: center
    text-transform: uppercase
    transform: translateZ($ringRadius)

    &, &:empty:before
      display: inline-block
      width: 100%
      height: 100%

    &:empty:before
      background: linear-gradient(transparent 45%, currentColor 45% 55%, transparent 55%)
      content: ""

    @for $s from 2 through $ringSectors
      &:nth-child(#{$s})
        $angle: (360deg / $ringSectors) * ($s - 1)
        transform: rotateY($angle) translateZ($ringRadius)

/* Animations */
@keyframes tiltSpin
  from
    transform: rotateY(0) rotateX(30deg)
  to
    transform: rotateY(1turn) rotateX(30deg)

@keyframes spin
  from
    transform: rotateY(0)
  to
    transform: rotateY(1turn)
</style>
