<template>
  <b-form-tags class="form-control tags-designer"
               v-bind:value="value"
               separator=",;"
               remove-on-delete
               @input="$emit('input', $event)"
               :tag-validator="tagValidator"
               :limit="limit">
  </b-form-tags>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      require: true
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      tags: [],
      dirty: false
    }
  },
  methods: {
    tagValidator(tag) {
      return tag === tag.toLowerCase()
    }
  }
}
</script>

<style scoped lang="scss">

</style>