import JwtService from '@/lib/jwt-service'
import {AuthServiceAPI} from "@/api/auth";

const initialState = () => {
    return {
        isAuthenticate: false
    }
}

export default {
    state: initialState,
    mutations: {
        setUserToken(state, data) {
            JwtService.saveToken(data.token)
            if (data.remember) {
                JwtService.saveRefreshToken(true);
            }
            state.isAuthenticate = true
        },
        setRefreshToken(state, data) {
            JwtService.saveToken(data.token)
            state.isAuthenticate = true
        },
        setAuthenticate(state, value) {
            state.isAuthenticate = value
        },
        setLogOut(state) {
            state.isAuthenticate = false
        },
        resetAuth(state) {
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        }
    },
    actions: {
        async login({commit}, params) {
            try {
                let result = await AuthServiceAPI.login(params);
                if (result && result.data.status === 200) {
                    commit('setUserToken', {
                        'token': result.data.data.access_token,
                        'refresh_token': result.data.data.access_token,
                        'remember': params.remember
                    });
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async refreshToken({commit}, params) {
            try {
                let result = await AuthServiceAPI.refreshToken(params);
                if (result && result.status === 200) {
                    commit('setRefreshToken', {
                        'token': result.data.token
                    });
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async logOut({commit}) {
            try {
                let result = await AuthServiceAPI.logOut();
                if (result && result.status === 200) {
                    commit('setLogOut', true);
                    JwtService.destroyToken()
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        resetAuth({commit}) {
            commit('resetAuth')
        }
    },
    getters: {
        isAuthenticate(state) {
            return state.isAuthenticate
        }
    }
}
