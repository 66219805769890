<template>
  <section class="card">
    <h4 class="mt-3 ml-3">{{ $t('campaign.info.title') }}</h4>
    <div class="card-body">

      <div class="row">

        <table class="table table-bordered table-form mt-2">
          <tr>
            <th class="bg-light-custom">
              <label>{{ $t('campaign.info.name') }} <span class="text-danger">*</span></label>
            </th>
            <td>
              <input v-model="requestData.name"
                     v-validate="'required'"
                     data-vv-scope="campaign_validate"
                     :data-vv-as="$t('campaign.info.name')"
                     name="campaign_name"
                     type="text"
                     class="form-control"
                     :placeholder="$t('campaign.info.name')">
              <b-form-invalid-feedback>{{ errors.first('campaign_validate.campaign_name') }}</b-form-invalid-feedback>
            </td>
            <th class="bg-light-custom">
              <label>{{ $t('campaign.info.status') }}</label>
            </th>
            <td>
              <span class="form-control"></span>
            </td>
          </tr>
          <tr>
            <th class="bg-light-custom">
              <label>{{ $t('campaign.info.advertiser') }} <span class="text-danger">*</span></label>
            </th>
            <td>
              <multiple-select
                  v-validate="'required'"
                  data-vv-scope="campaign_validate"
                  :data-vv-as="$t('campaign.info.advertiser')"
                  @close="validateMultipleSelect('campaign_validate.campaign_advertiser')"
                  name="campaign_advertiser"
                  v-model="requestData.advertiser"
                  :options="options.advertiser_id"
                  :allow-empty="true"
                  :placeholder="$t('filter.placeholder_option')"
                  open-direction="bottom"
                  label="name"
                  track-by="id"
                  :class="errors.first('campaign_validate.campaign_advertiser') ? 'is-invalid' : ''"
                  :show-labels="false"
              >
                <span slot="noResult">{{ $t('filter.no_result') }}</span>
              </multiple-select>
              <b-form-invalid-feedback>{{ errors.first('campaign_validate.campaign_advertiser') }}</b-form-invalid-feedback>
            </td>
            <th class="bg-light-custom">
              <label>{{ $t('campaign.info.brand') }} <span class="text-danger">*</span></label>
            </th>
            <td>
              <multiple-select
                  v-validate="'required'"
                  data-vv-scope="campaign_validate"
                  :data-vv-as="$t('campaign.info.brand')"
                  @close="validateMultipleSelect('campaign_validate.campaign_brand')"
                  name="campaign_brand"
                  v-model="requestData.brand"
                  :options="options.brand_id"
                  :allow-empty="true"
                  :placeholder="$t('filter.placeholder_option')"
                  open-direction="bottom"
                  label="name"
                  track-by="id"
                  :class="errors.first('campaign_validate.campaign_brand') ? 'is-invalid' : ''"
                  :show-labels="false"
              >
                <span slot="noResult">{{ $t('filter.no_result') }}</span>
              </multiple-select>
              <b-form-invalid-feedback>{{ errors.first('campaign_validate.campaign_brand') }}</b-form-invalid-feedback>
            </td>
          </tr>
          <tr>
            <th class="bg-light-custom">
              <label>{{ $t('campaign.info.banner') }}</label>
            </th>
            <td colspan="3">
              <upload-image-layout :ref-name="'campaign-upload-banner'"
                                   @files="file => requestData.banner = file"></upload-image-layout>
            </td>
          </tr>
          <tr>
            <th class="bg-light-custom">
              <label>{{ $t('campaign.info.budget') }} <span class="text-danger">*</span></label>
            </th>
            <td>
              <input-price v-validate="'required'"
                           data-vv-scope="campaign_validate"
                           :data-vv-as="$t('campaign.info.budget')"
                           name="campaign_budget"
                           v-model="requestData.budget"
                           class="form-control"
                           :class="errors.first('campaign_validate.campaign_budget') ? 'is-invalid' : ''"
                           :placeholder="$t('campaign.field.placeholder_budget')"></input-price>
              <b-form-invalid-feedback>{{ errors.first('campaign_validate.campaign_budget') }}</b-form-invalid-feedback>
            </td>
            <th class="bg-light-custom">
              <label>{{ $t('campaign.info.time') }} <span class="text-danger">*</span></label>
            </th>
            <td>
              <div class="input-daterange input-group d-flex align-items-center">
                <date-picker
                    v-validate="'required'"
                    data-vv-scope="campaign_validate"
                    :data-vv-as="$t('campaign.info.started_at')"
                    name="campaign_started_at"
                    class="form-control"
                    v-model="requestData.started_at"
                    :class="errors.first('campaign_validate.campaign_started_at') ? 'is-invalid' : ''"
                    valueType="format">
                </date-picker>
                <span class="input-group-addon">{{ $t('campaign.info.to') }}</span>
                <date-picker
                    v-validate="'required'"
                    data-vv-scope="campaign_validate"
                    :data-vv-as="$t('campaign.info.ended_at')"
                    name="campaign_ended_at"
                    class="form-control"
                    v-model="requestData.ended_at"
                    :class="errors.first('campaign_validate.campaign_ended_at') ? 'is-invalid' : ''"
                    valueType="format"></date-picker>
                <b-form-invalid-feedback>{{ errors.first('campaign_validate.campaign_started_at') }}</b-form-invalid-feedback>
                <b-form-invalid-feedback>{{ errors.first('campaign_validate.campaign_ended_at') }}</b-form-invalid-feedback>
              </div>
            </td>
          </tr>
          <tr>
            <th class="bg-light-custom">
              <label>{{ $t('campaign.info.keyword') }} <span class="text-danger">*</span></label>
            </th>
            <td colspan="3">
              <input-tags
                  v-model="requestData.keyword"
                  v-validate="'required'"
                  data-vv-scope="campaign_validate"
                  :data-vv-as="$t('campaign.info.keyword')"
                  :class="errors.first('campaign_validate.campaign_keyword') ? 'is-invalid' : ''"
                  name="campaign_keyword"
                  :placeholder="$t('campaign.field.placeholder_tags') + '...'"
              ></input-tags>
              <b-form-invalid-feedback>{{ errors.first('campaign_validate.campaign_keyword') }}</b-form-invalid-feedback>
            </td>
          </tr>
        </table>
      </div>

    </div>
  </section>
</template>

<script>

import InputTags from "@/components/dashboard/layouts/InputTags.vue";

export default {
  components:{
    InputTags
  },
  props: {
    formData: {
      required: true,
      type: Object
    },
    cacheData: {
      required: true,
      type: Object
    },
    options: {
      required: true,
      type: Object
    }
  },
  watch: {
    requestData: {
      handler(after) {
        const vm = this
        const keys = ['started_at', 'ended_at']
        Object.keys(after).forEach((key) => {
          if (keys.includes(key) && vm.cacheData[key] !== after[key]) {
            vm.$emit('calculator-view-guess', true)
          }
        })
      },
      deep: true
    }
  },
  data() {
    return {
      keyword: '',
      currentKeyword: [],
      requestData: this.formData,
    }
  },
  methods:{
    async validateMultipleSelect(name){
      await this.$validator.validate(name, this.requestData[name])
    }
  }
}
</script>

<style scoped>

</style>