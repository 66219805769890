<template>
  <div class="hl-upload-image">
    <div class="d-flex">
      <template v-if="isMultiple">
        <div class="d-inline-flex">
          <draggable class="draggable-item d-inline-flex"
                     v-model="previewFiles"
                     v-bind="dragOptions"
                     :move="onMove"
                     @end="onEnd"
                     @start="isDragging=true"
          >
            <transition-group type="transition" class="d-inline-flex">
              <template v-for="(preview, index) in previewFiles">
                <div class="image-upload-item" :key="'preview-multiple-image-'+index">
                  <div class="preview-image-item">
                    <b-img class="close-image" :src="CloseCircle" alt="Vodaplay"
                           v-on:click="deleteImage(index)"></b-img>
                    <b-overlay :show="isUploadFile[preview.key]"
                               rounded="sm">
                      <img :id="'display-preview-' + index" class="preview-image" :src="preview.value" alt="Vodaplay">
                    </b-overlay>
                    <div class="preview-action">
                      <ul>
                        <li class="edit">
                          <a href="javascript:;" v-on:click="changeImage(index)">{{
                              $t('common.upload_image.upload')
                            }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <template v-if="index === 0">
                    <span>{{ $t('common.upload_image.banner') }}</span>
                  </template>
                  <template v-else>
                    <span>{{ $t('common.upload_image.image', {index: index}) }}</span>
                  </template>
                </div>
              </template>
            </transition-group>
          </draggable>
          <input type="file"
                 accept=".png, .jpg, .jpeg, .gif"
                 style="display:none;"
                 :ref="refName + '-multiple-edit'"
                 :name="name"
                 :id="'upload-image-' + refName + '-multiple-edit'"
                 @change="handleUpdateImage()"
          />
          <template v-if="files.length < maxImage && previewFiles.length < maxImage">
            <div class="image-upload-item">
              <div class="box-upload-image" @click="showPreviewImage">
                <img :src="PlusCircle" alt="Vodaplay">
                <input type="file"
                       multiple
                       accept=".png, .jpg, .jpeg, .gif"
                       style="display:none;"
                       :ref="refName + '-multiple'"
                       :name="name"
                       :id="'upload-image-' + refName + '-multiple'"
                       @change="handleMultipleFileUpload()"
                />
              </div>
              <!--              <template v-if="files.length <= 0">-->
              <!--                <span class="name-upload-image">{{ $t('common.upload_image.banner') }} <span-->
              <!--                    class="text-danger">*</span></span>-->
              <!--              </template>-->
              <!--              <template v-else>-->
              <span>{{
                  $t('common.upload_image.image', {index: previewFiles.length > 0 ? previewFiles.length : 1})
                }}</span>
              <!--              </template>-->
            </div>
          </template>
          <div v-if="error !== ''" class="error-show text-danger">{{ error }}</div>
          <div v-if="isSuccess" class="error-show text-success">{{ $t('common.upload_image.success') }}</div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex flex-wrap">
          <div class="hl-preview-image">
            <template v-if="value !== '' || preview !== ''">
              <b-overlay :show="isLoading"
                         rounded="sm">
                <img class="rounded" style="max-width: 400px;" :src="preview !== '' ? preview : value" alt="Vodaplay">
              </b-overlay>
            </template>
          </div>
          <div class="w-100">
            <input type="file"
                   accept=".png, .jpg, .jpeg, .gif"
                   style="display:none;"
                   :ref="refName"
                   :name="name"
                   :id="'upload-image-' + refName"
                   @change="handleFileUpload()"
            />
            <div v-if="error !== ''" class="error-show text-danger">{{ error }}</div>
            <div v-if="isSuccess" class="error-show text-success">{{ $t('common.upload_image.success') }}</div>
            <b-button class="button-upload-image button-violet" variant="primary" :disabled="isLoading"
                      @click="showPreviewImage">
              <template v-if="isLoading">
                <b-spinner variant="light" small></b-spinner>
              </template>
              <template v-else>
                {{ $t('common.upload_image.button') }}
              </template>
            </b-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import PlusCircle from "@/assets/img/common/Plus_Circle.svg"
import CloseCircle from "@/assets/img/common/close-circle.svg"
import CryptoJS from "crypto-js";
import {mapGetters} from "vuex";
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  props: {
    value: {
      type: [String, Array],
      required: false
    },
    name: {
      type: String,
      required: false,
      default: ""
    },
    refName: {
      type: String,
      required: true
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    maxImage: {
      type: Number,
      default: 6
    }
  },
  computed: {
    ...mapGetters(['getUploadUImageS3Url']),
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    value: {
      handler() {
        if (!this.isFirst) {
          if (this.isMultiple && Array.isArray(this.value)) {
            const previews = []
            for (const image of this.value) {
              const sha1 = this.generateSHA1ByFile(image)
              previews.push({key: sha1, value: image})
              this.files.push(sha1)
              this.uploaded[sha1] = true;
              this.indexOfImages[sha1] = {
                url: image,
                file: sha1
              }
            }

            this.previewFiles = JSON.parse(JSON.stringify(previews))
          }
          this.isFirst = true
        }
      },
      deep: true
    }
  },
  mounted() {
    this.$root.$on('reset-image-upload', () => {
      this.resetUpload()
    })
  },
  data() {
    return {
      isFirst: false,
      preview: "",
      error: "",
      PlusCircle: PlusCircle,
      CloseCircle: CloseCircle,
      isSuccess: false,
      isLoading: false,
      file: null,
      files: [],
      previewFiles: [],
      uploaded: {},
      indexOfImages: {},
      errorFiles: {},
      isUploadFile: {},
      changeIndex: -1,
      isDragging: false,
      editable: true
    }
  },
  methods: {
    onMove({relatedContext, draggedContext}) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
          (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },

    onEnd() {
      this.$nextTick(() => {
        const movedPosition = []
        const movedFilePosition = []
        for (const image of this.previewFiles) {
          const imageUrl = this.indexOfImages[image.key].url
          const file = this.indexOfImages[image.key].file
          movedPosition.push(imageUrl)
          movedFilePosition.push(file)
        }
        this.files = movedFilePosition
        this.$emit('input', movedPosition)
        this.isDragging = false
      })
    },

    showPreviewImage() {
      const idElement = 'upload-image-' + this.refName + (this.isMultiple ? '-multiple' : '');
      document.getElementById(idElement).click()
    },

    changeImage(index) {
      this.changeIndex = index
      const idElement = 'upload-image-' + this.refName + (this.isMultiple ? '-multiple-edit' : '');
      document.getElementById(idElement).click()
    },

    async handleUpdateImage() {
      let vm = this;
      vm.error = "";
      let files = vm.$refs[vm.refName + '-multiple-edit'].files;
      if (files.length > 0) {
        const promise = new Promise((resolve, reject) => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          let image = new Image();
          let imageSrc = window.URL.createObjectURL(files[0]);
          image.src = imageSrc;
          vm.preview = imageSrc;
          image.onload = function () {
            let width = image.naturalWidth,
                height = image.naturalHeight;

            if (width > 1000 || height > 1000) {
              if (width > height) {
                canvas.width = 1000
                canvas.height = height * (1000 / width)
              } else {
                canvas.width = width * (1000 / height)
                canvas.height = 1000
              }
            } else {
              canvas.width = width
              canvas.height = height
            }

            ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
            const fileBase64 = canvas.toDataURL('image/png')

            const blobBin = atob(fileBase64.split(',')[1]);
            const array = [];
            for (let i = 0; i < blobBin.length; i++) {
              array.push(blobBin.charCodeAt(i));
            }
            const fileByBase64 = new Blob([new Uint8Array(array)], {type: 'image/png'});
            resolve({fileBase64: fileBase64, fileByBase64: fileByBase64})
          };
          image.onerror = reject
        })
        const result = await promise
        const sha1 = vm.generateSHA1ByFile(result.fileBase64)
        vm.isUploadFile[sha1] = true
        vm.previewFiles[vm.changeIndex] = {key: sha1, value: result.fileBase64};
        vm.previewFiles = JSON.parse(JSON.stringify(vm.previewFiles))
        vm.files[vm.changeIndex] = result.fileBase64;
        await vm.updateImage(result.fileByBase64, sha1)
      }
    },

    async updateImage(fileByBase64, sha1) {
      const vm = this
      let formData = new FormData()
      formData.append('image', fileByBase64)
      const res = await this.$store.dispatch('uploadImageS3', formData)
      if (res.data.status !== 200) {
        vm.errorFiles[sha1] = vm.$t('common.upload_error');
      } else {
        vm.isUploadFile[sha1] = false
        vm.isUploadFile = JSON.parse(JSON.stringify(vm.isUploadFile))
        const values = vm.value
        vm.indexOfImages[sha1] = {
          url: this.getUploadUImageS3Url,
          file: fileByBase64
        }
        values[vm.changeIndex] = this.getUploadUImageS3Url
        vm.$emit('input', values)
        if (typeof this.$refs[this.refName + '-multiple-edit'] !== 'undefined') {
          this.$refs[this.refName + '-multiple-edit'].value = "";
        }
        this.$emit('files', '');
        await this.$store.dispatch('setResetUploadImage')
        vm.isLoading = false
      }
    },

    async deleteImage(index) {
      const values = this.value
      values.splice(index, 1)
      this.previewFiles.splice(index, 1)
      this.files.splice(index, 1)
      this.$emit('input', values)
    },

    async handleMultipleFileUpload() {
      let vm = this;
      vm.error = "";
      vm.isSuccess = false;

      let files = vm.$refs[vm.refName + '-multiple'].files;
      if (files.length > 0) {
        for (const file of files) {
          if (vm.previewFiles.length >= vm.maxImage) {
            continue
          }
          const promise = new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            let image = new Image()
            image.src = window.URL.createObjectURL(file)
            image.onload = function () {
              let width = image.naturalWidth,
                  height = image.naturalHeight;

              if (width > 1000 || height > 1000) {
                if (width > height) {
                  canvas.width = 1000
                  canvas.height = height * (1000 / width)
                } else {
                  canvas.width = width * (1000 / height)
                  canvas.height = 1000
                }
              } else {
                canvas.width = width
                canvas.height = height
              }

              ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
              const fileBase64 = canvas.toDataURL('image/png')

              const blobBin = atob(fileBase64.split(',')[1]);
              const array = [];
              for (let i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
              }
              const fileByBase64 = new Blob([new Uint8Array(array)], {type: 'image/png'});

              resolve({fileBase64: fileBase64, fileByBase64: fileByBase64})
            }

            image.onerror = reject
          })

          const result = await promise
          const sha1 = vm.generateSHA1ByFile(result.fileBase64)
          if (!vm.uploaded[sha1]) {
            vm.isUploadFile[sha1] = true
            vm.previewFiles.push({key: sha1, value: result.fileBase64});
            vm.files.push(result.fileByBase64);
          }
        }
        await vm.uploadFiles()
      }
    },

    handleFileUpload() {
      let vm = this;
      vm.error = "";
      vm.isSuccess = false;
      let files = vm.$refs[vm.refName].files;
      if (files.length > 0) {
        let image = new Image();
        let imageSrc = window.URL.createObjectURL(files[0]);
        image.src = imageSrc;
        vm.preview = imageSrc;
        image.onload = function () {
          let width = image.naturalWidth,
              height = image.naturalHeight;

          if (width < 100 || height < 100) {
            vm.error = vm.$t('common.upload_min_size');
            vm.resetUploadOnUpload()
            return false;
          } else if (width > 2000 || height > 2000) {
            vm.error = vm.$t('common.upload_max_size');
            vm.resetUploadOnUpload()
            return false;
          }
          vm.file = files[0];
          vm.uploadFile()
        };
      }
    },

    async uploadFiles() {
      let vm = this;
      if (!vm.isLoading) {
        vm.isLoading = true;
        let index = 0
        const urlImages = vm.value
        for (const file of vm.files) {
          if (typeof file === 'string' && vm.files.length > 1) {
            index++
            continue
          }
          const sha1 = vm.previewFiles[index].key
          if (this.uploaded[sha1]) {
            index++
            continue
          }
          let formData = new FormData()
          formData.append('image', file)
          const res = await this.$store.dispatch('uploadImageS3', formData)
          if (res.data.status !== 200) {
            vm.errorFiles[sha1] = vm.$t('common.upload_error');
          } else {
            urlImages.push(this.getUploadUImageS3Url)
            vm.indexOfImages[sha1] = {
              url: this.getUploadUImageS3Url,
              file: file
            }
            vm.uploaded[sha1] = true;
            vm.isUploadFile[sha1] = false
            vm.isUploadFile = JSON.parse(JSON.stringify(vm.isUploadFile))
          }
          index++
        }
        vm.$emit('input', urlImages)
        await vm.resetMultipleUploadOnUpload()
        vm.isLoading = false
      }
    },

    async uploadFile() {
      let vm = this;
      if (!vm.isLoading) {
        vm.isLoading = true;
        let formData = new FormData()
        formData.append('file', this.file)
        const res = await this.$store.dispatch('uploadImage', formData)
        if (res.data.status === 200) {
          vm.$emit('input', res.data.data)
          vm.preview = res.data.data
          vm.isSuccess = true
        } else {
          vm.error = vm.$t('common.upload_error');
          await vm.resetUploadOnUpload()
        }
        vm.isLoading = false
      }
    },

    generateSHA1ByFile(file) {
      return CryptoJS.SHA1(file).toString()
    },

    async resetUploadOnUpload() {
      if (typeof this.$refs[this.refName] !== 'undefined') {
        this.$refs[this.refName].value = "";
      }
      this.$emit('files', '');
      await this.$store.dispatch('setResetUploadImage')
    },

    async resetMultipleUploadOnUpload() {
      if (typeof this.$refs[this.refName + '-multiple'] !== 'undefined') {
        this.$refs[this.refName + '-multiple'].value = "";
      }
      this.$emit('files', '');
      await this.$store.dispatch('setResetUploadImage')
    },

    async resetUpload() {
      this.preview = "";
      this.error = "";
      this.isSuccess = false;
      if (typeof this.$refs[this.refName] !== 'undefined') {
        this.$refs[this.refName].value = "";
      }
      this.$emit('files', '');
      await this.$store.dispatch('setResetUploadImage')
    }
  }
}
</script>

<style scoped lang="scss">


.hl-upload-image {
  &.is-invalid {
    .button-upload-image {
      border-color: #dc3545;
    }
  }
}

.m-r-10 {
  margin-right: 10px;
}

.hl-preview-image {
  width: 100%;
  margin-bottom: 10px;
  max-width: 400px;
  height: auto;

  img {
    height: auto;
    border-radius: 0;
    width: unset;
  }
}

.error-show {
  margin-bottom: 10px;
}


.image-upload-item {
  text-align: center;
  margin-right: 12px;
  position: relative;
  padding-top: 15px;
  cursor: move;

  .close-image {
    width: 16px;
    height: 16px;
    position: absolute;
    top: -6px;
    right: -6px;
    z-index: 11;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }
  }

  //&:last-child {
  //  margin-right: 0;
  //}

  .preview-image-item {
    width: 80px;
    height: 80px;
    margin-bottom: 8px;
    position: relative;

    .preview-action {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 0;
      background: rgba(50, 50, 50, 0.7);
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      transition: height ease-in-out 0.2s;
      display: flex;
      opacity: 0;

      ul {
        padding: 0;
        margin-bottom: 0;

        li {
          line-height: 24px;

          &.edit {
            a {
              color: var(--white);
              display: none;
            }
          }
        }
      }
    }

    .preview-image {
      width: 80px;
      height: 80px;
      border-radius: 4px;
    }

    &:hover {

      .preview-action {
        transition: height ease-in-out 0.2s;
        opacity: 1;
        height: 30px;

        ul {

          li {

            &.edit {
              a {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .box-upload-image {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    border: dashed 0.5px var(--level-twice-side-bar);
    margin-bottom: 8px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

  }
}
</style>
