<template>
  <div class="full-screen">
    <section class="section one">
      <div class="page-content d-flex align-items-center justify-content-center">


        <div class="row w-100 mx-0 auth-page">
          <div class="col-md-8 col-xl-6 mx-auto">
            <div class="card">
              <div class="row">
                <div class="col-md-8 pr-md-0">
                  <div class="auth-left-wrapper"
                       :style="{backgroundImage: 'url('+ require(`@/assets/assets/images/logo-mockup.jpg`) +')'}">
                  </div>
                </div>
                <div class="col-md-4 pl-md-0">
                  <form ref="login_input" data-vv-scope="login_input"
                        class="auth-form-wrapper px-4 py-5">
                    <a href="javascript:;"
                       class="noble-ui-logo logo-light d-block mb-2 text-center">
                      <img src="@/assets/assets/images/login.png" alt="">
                    </a>
                    <div v-if="error" class="alert alert-danger" role="alert">
                      {{ $t("auth.error") }}
                    </div>

                    <div class="form-group">
                      <label for="email">{{ $t("auth.email") }}</label>
                      <input type="email"
                             v-validate="'required|email'"
                             data-vv-scope="login_input"
                             name="email"
                             :data-vv-as="$t('auth.email')"
                             class="form-control" placeholder="Email"
                             required
                             autocomplete="email" autofocus v-model="email"
                             @keyup="submitWithEnter($event)"
                      >
                      <b-form-invalid-feedback>{{ errors.first('login_input.email') }}
                      </b-form-invalid-feedback>
                    </div>

                    <div class="form-group">
                      <label for="password">{{ $t("auth.password") }}</label>
                      <input type="password" class="form-control"
                             v-model="password"
                             data-vv-scope="login_input"
                             :data-vv-as="$t('auth.password')"
                             v-validate="'required'"
                             name="password"
                             autocomplete="current-password"
                             placeholder="Password" required
                             @keyup="submitWithEnter($event)"
                      >
                      <b-form-invalid-feedback>{{ errors.first('login_input.password') }}
                      </b-form-invalid-feedback>
                    </div>


                    <div class="form-check form-check-flat form-check-primary">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" name="remember"
                               v-model="remember"/>
                        {{ $t("auth.remember") }}
                        <i class="input-frame"></i>
                      </label>
                    </div>
                    <div class="mt-3 text-center">
                      <button type="button"
                              @click="login()"
                              :disabled="sending"
                              :class="{disabled: sending}"
                              class="btn btn-lg btn-primary w-100 h-100">
                        <template v-if="!sending">
                          {{ $t("auth.button_login") }}
                        </template>
                        <template v-else>
                          <b-spinner label="Spinning" variant="light"></b-spinner>
                        </template>
                      </button>
                    </div>
                    <a class="d-none mt-3 text-muted" href="javascript:;">
                      {{ $t("auth.forgot_password") }}
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  data() {
    return {
      email: null,
      password: null,
      remember: false,
      sending: false,
      error: false,
      token: ''
    }
  },
  methods: {
    submitWithEnter(event) {
      let vm = this;
      let keyCode = event.keyCode;
      vm.isError = false;
      if (keyCode === 13) {
        vm.login();
      }
    },

    async login() {
      if (this.sending) {
        return false;
      }
      this.sending = true
      const isValid = this.$validator.validate('login_input.*')
      if (!isValid) {
        return false;
      }
      await this.getTokenRecaptcha()
      const res = await this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
        token: this.token,
        remember: this.remember
      })
      if (res && res.data.status === 200) {
        await this.$router.push({name: 'loading'})
      } else {
        this.error = true
      }
      this.sending = false
    },

    async getTokenRecaptcha() {
      await this.$recaptchaLoaded();
      this.token = await this.$recaptcha('login');
    },

    async particlesLoaded() {

    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/particles/_particles.css';
</style>
