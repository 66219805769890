import {CommonServiceAPI} from "@/api/common"

const initialState = () => {
    return {
        cities: [],
        districts: [],
        wards: [],
        imageUrl: '',
        imageS3Url: '',
        currentIpInfo: {},
        filterCities: [],
        filterDistricts: [],
        filterWards: [],
    }
}

export default {
    state: initialState,
    mutations: {
        setCities(state, data) {
            state.cities = data
        },
        setDistricts(state, data) {
            state.districts = data
        },
        setWards(state, data) {
            state.wards = data
        },
        setUploadImage(state, data) {
            state.imageUrl = data
        },
        setUploadImageS3(state, data) {
            state.imageS3Url = data
        },
        setResetUploadImageS3(state, data) {
            state.imageS3Url = data
        },
        setResetUploadImage(state, data) {
            state.imageUrl = data
        },
        setCurrentIpInfo(state, data) {
            state.currentIpInfo = data
        },
        setFilterCities(state, data) {
            state.filterCities = data
        },
        setFilterDistricts(state, data) {
            state.filterDistricts = data
        },
        setFilterWards(state, data) {
            state.filterWards = data
        },
        resetCommons(state){
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        }
    },
    actions: {
        async getCities({commit}, params) {
            try {
                let result = await CommonServiceAPI.getCities(params);
                if (result && result.data.status === 200) {
                    commit('setCities', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async getDistricts({commit}, params) {
            try {
                let result = await CommonServiceAPI.getDistricts(params);
                if (result && result.data.status === 200) {
                    commit('setDistricts', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async resetDistricts({commit}) {
            commit('setDistricts', []);
        },

        async getWards({commit}, params) {
            try {
                let result = await CommonServiceAPI.getWards(params);
                if (result && result.data.status === 200) {
                    commit('setWards', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async resetWards({commit}) {
            commit('setWards', []);
        },

        async uploadImage({commit}, params) {
            try {
                let result = await CommonServiceAPI.uploadImage(params);
                if (result && result.data.status === 200) {
                    commit('setUploadImage', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async uploadImageS3({commit}, params) {
            try {
                let result = await CommonServiceAPI.uploadImageS3(params, {});
                if (result && result.data.status === 200) {
                    commit('setUploadImageS3', result.data.file_path);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async setResetUploadImage({commit}) {
            commit('setResetUploadImage', '');
        },

        async setResetUploadImageS3({commit}) {
            commit('setResetUploadImageS3', '');
        },

        async getCurrentIpInfo({commit, getters}){
            if (Object.keys(getters.currentIpInfo).length > 0){
                return {
                    data: getters.currentIpInfo
                };
            }else{
                let result = await CommonServiceAPI.getCurrentIpInfo();
                if (result && result.status === 200) {
                    commit('setCurrentIpInfo', result.data);
                }
                return result;
            }
        },

        async filterCities({commit}, params) {
            try {
                let result = await CommonServiceAPI.getFilterCities(params);
                if (result && result.data.status === 200) {
                    commit('setFilterCities', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async filterDistricts({commit}, params) {
            try {
                let result = await CommonServiceAPI.getFilterDistricts(params);
                if (result && result.data.status === 200) {
                    commit('setFilterDistricts', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async filterWards({commit}, params) {
            try {
                let result = await CommonServiceAPI.getFilterWards(params);
                if (result && result.data.status === 200) {
                    commit('setFilterWards', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        resetCommons({commit}){
            commit('resetCommons')
        }
    },
    getters: {
        cities(state) {
            return state.cities
        },
        districts(state) {
            return state.districts
        },
        wards(state) {
            return state.wards;
        },
        getUploadUImageUrl(state) {
            return state.imageUrl;
        },
        getUploadUImageS3Url(state) {
            return state.imageS3Url;
        },
        currentIpInfo(state){
            return state.currentIpInfo;
        },
        filterCities(state) {
            return state.filterCities
        },
        filterDistricts(state) {
            return state.filterDistricts
        },
        filterWards(state) {
            return state.filterWards;
        },
    }
}
