export default {
    title: 'Profile Information',
    title_store: 'Store Title',
    title_avatar: 'Profile Photo',
    title_avatar_upload: 'Avatar',
    title_cover_image_upload: 'Cover Image',
    title_info: 'Account Information',
    title_name: 'Display Name',
    title_email: 'Email',
    title_phone: 'Phone Number',
    title_gender: 'Gender',
    title_birthday: 'Date of birth',
    title_channel_id: 'Channel Info',
    title_description: 'Description',
    title_password: 'Password',
    title_current_password: 'Current Password',
    title_new_password: 'New Password',
    title_re_new_password: 'Confirm Password',
    gender_male: 'Male',
    gender_female: 'Female',
    gender_other: 'Other',
    error_wrong_current_password: 'Incorrect Password',
    error_confirm_password: 'Please check your password again',
    title_update_avatar: 'Update profile photo',
    update_avatar: 'Update profile photo successfully',
    update_avatar_fail: 'Update profile photo fail',
    title_update_info: 'Update account information',
    update_info: 'Update account information successfully',
    update_info_fail: 'Update account information fail',
    title_update_password: 'Password update',
    update_password: 'Password update successful',
    update_password_fail: 'Password update fail',
}
