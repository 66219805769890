<template>
    <div class="campaign-create-page w-100">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <b-col :cols="isMobile ? 12 : 6">
                        <template v-if="isMobile">
                            <h3>{{ $t('campaign.update.title') }}</h3>
                        </template>
                        <template v-else>
                            <h1>{{ $t('campaign.update.title') }}</h1>
                        </template>
                    </b-col>
                </div>
                <div class="row mb-2">
                    <breadcrumb-layout></breadcrumb-layout>
                </div>
            </div>
        </section>
        <div class="campaign-create-page-body d-flex p-3">

        </div>
    </div>
</template>

<script>
import BreadcrumbLayout from "@/components/dashboard/layouts/BreadcrumbLayout"

export default {
    components: {
        BreadcrumbLayout
    },
    computed: {
        isMobile() {
            return this.$device.mobile
        },
    },
    props: {
        type: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>