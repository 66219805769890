export default {
    product: {
        title: 'Sản phẩm cửa hàng',
        add_new: 'Thêm mới',
        searching: 'Tìm kiếm...',
        option_pending: 'Ẩn',
        option_running: 'Hiện',
        is_booking_off: 'Sản phẩm thường',
        is_booking_on: 'Sản phẩm đặt chỗ',
        attribute: {
            id: 'ID',
            image: 'Ảnh',
            is_booking: 'Loại sản phẩm',
            store_id: 'Cừa hàng',
            status: 'Trạng thái',
            main_category_id: 'Danh mục chính',
            sub_category_id: 'Danh mục',
            name: 'Tên sản phẩm',
            zalo: 'Liên hệ Zalo',
            price: 'Giá',
            discount_price: 'giảm',
            created_at: 'Thời gian tạo'
        },
        create: {
            title: 'Tạo sản phẩm cửa hàng',
            confirm: 'Xác nhận tạo sản phẩm cửa hàng',
            confirm_description: 'Bạn chắc chắn về quyết định này không?',
            confirm_save: 'Xác nhận',
            confirm_cancel: 'Hủy bỏ',
            confirm_success_title: 'Thành công',
            confirm_success_description: 'Tạo sản phẩm cửa hàng thành công',
            confirm_fail_title: 'Thật bại',
            confirm_fail_description: 'Tạo sản phẩm cửa hàng thất bại',
        },
        fields: {
            title: 'Thông tin sản phẩm',
            name: 'Tên sản phẩm',
            is_booking: 'Loại sản phẩm',
            image: 'Ảnh sản phẩm',
            store_name : 'Tên cửa hàng',
            status: 'Trạng thái',
            category: 'Danh mục',
            parent_category: 'Danh mục tổng',
            sub_category: 'Danh mục',
            price: 'Giá sản phẩm',
            sale_price: 'Giá sau giảm',
            url: 'Đường dẫn',
            keyword: 'Từ khóa',
            discount_rate: 'Chiết khấu: {rate}%',
            title_discount: 'Thông tin giảm giá',
            title_created: 'Thông tin người tạo',
            add_discount: 'Thêm loại giảm giá',
            combo_service: 'Gói dịch vụ',
            discount_by_time: 'Thông tin người tạo',
            discount_price: 'Nhập giá giảm',
            time_slot: 'Khung giờ',
            start_time: 'Start time',
            end_time: 'End time',
            applicable_date: 'Ngày áp dụng',
            setup_invalid: 'The time coincides with the time you set before',
            combo_name: 'Gói giảm giá',
            created_by: 'Người tạo',
            created_at: 'Ngày tạo'
        },
        update: {
            title: 'Cập nhật sản phẩm cửa hàng',
            confirm: 'Xác nhận cập nhật sản phẩm cửa hàng',
            confirm_description: 'Bạn chắc chắn về quyết định này không?',
            confirm_save: 'Xác nhận',
            confirm_cancel: 'Hủy bỏ',
            confirm_success_title: 'Thành công',
            confirm_success_description: 'Cập nhật sản phẩm cửa hàng thành công',
            confirm_fail_title: 'Thật bại',
            confirm_fail_description: 'Cập nhật sản phẩm cửa hàng thất bại',
        },
        preview: 'Xem trước',
        preview_other_product: 'Sản phẩm khác',
        preview_open_time: 'Giờ mở cửa',
        preview_close_time: 'Đóng cửa',
        preview_connect: 'Truy cập',
        preview_see_option: 'Xem các tùy chọn',
        preview_chat_with_shop: 'Nhắn tin với shop'
    },
    store: {
        title: 'Cửa hàng',
        add_new: 'Thêm mới',
        searching: 'Tìm kiếm...',
        option_pending: 'Ẩn',
        option_running: 'Hiện',
        attribute: {
            id: 'ID',
            advertiser: 'Nhà quảng cáo',
            advertiser_type: 'Loại nhà quảng cáo',
            name: 'Tên cửa hàng',
            sector: 'Lĩnh vực',
            manager: 'Người quản lý',
            manager_contact: 'Liên hệ quản lý',
            manager_email: 'Email quản lý',
            customer_advice_contact: 'Liên hệ CSKH',
            city_name: 'Tỉnh/Thành phố',
            district_name: 'Quận/Huyện',
            ward_name: 'Phường/Xã',
            created_by: 'Người tạo',
            approval_by: 'Người phê duệt',
            created_at: 'Thời gian tạo',
        },
        type:{
            advertiser: 'Nhà quảng cáo',
            agency: 'Hãng'
        },
        create: {
            title: 'Tạo cửa hàng',
            confirm: 'Xác nhận tạo cửa hàng',
            confirm_description: 'Bạn chắc chắn về quyết định này không?',
            confirm_save: 'Xác nhận',
            confirm_cancel: 'Hủy bỏ',
            confirm_success_title: 'Thành công',
            confirm_success_description: 'Tạo cửa hàng thành công',
            confirm_fail_title: 'Thật bại',
            confirm_fail_description: 'Tạo cửa hàng thất bại',
        },
        fields: {
            title: 'Thông tin cửa hàng',
            name: 'Tên cửa hàng',
            advertiser_type: 'Loại quảng cáo',
            sector: 'Phân loại',
            status: 'Trạng thái',
            manager: 'Người quản lí',
            manager_contact: 'Liên hệ quản lí',
            manager_email: 'Email quản lí',
            customer_advice_contact: 'Liên hệ CSKH',
            cities: 'Tỉnh/Thành',
            districts: 'Quận/Huyện',
            wards: 'Phường/Xã',
            address: 'Địa chỉ cửa hàng',
            maps: 'Bản đồ',
            zalo_contact: 'Liên hệ Zalo',
            time_open: 'Thời gian mở cửa',
            open_time: 'Mở cửa',
            close_time: 'Đóng cửa',
            button_add_time: 'Thêm thời gian mở cửa'
        },
        update: {
            title: 'Cập nhật cửa hàng',
            confirm: 'Xác nhận cập nhật cửa hàng',
            confirm_description: 'Bạn chắc chắn về quyết định này không?',
            confirm_save: 'Xác nhận',
            confirm_cancel: 'Hủy bỏ',
            confirm_success_title: 'Thành công',
            confirm_success_description: 'Cập nhật cửa hàng thành công',
            confirm_fail_title: 'Thật bại',
            confirm_fail_description: 'Cập nhật cửa hàng thất bại',
        },
    },
    pick: {
        title: 'Tập hợp cửa hàng',
        add_new: 'Thêm mới',
        searching: 'Tìm kiếm...',
        option_pending: 'Ẩn',
        option_running: 'Hiện',
        attribute: {
            id: 'ID',
            video_id: 'Băng hình',
            video_title: 'Tiêu đề băng hình',
            video_url: 'Đường dẫn băng hình',
            status: 'Trạng thái',
            product_name: 'Tên sản phẩm',
            price: 'Giá sản phẩm',
            price_sale: 'Giá đã giảm',
            created_by: 'Người tạo',
            created_at: 'Ngày tạo'
        },
        create: {
            title: 'Tạo tập hợp',
            confirm: 'Xác nhận tạo tập hợp',
            confirm_description: 'Bạn chắc chắn về quyết định này không?',
            confirm_save: 'Xác nhận',
            confirm_cancel: 'Hủy bỏ',
            confirm_success_title: 'Thành công',
            confirm_success_description: 'Tạo tập hợp thành công',
            confirm_fail_title: 'Thật bại',
            confirm_fail_description: 'Tạo tập hợp thất bại',
        },
        fields: {
            video: {
                title: 'Thông tin băng hình',
                input_video_id: 'Nhập băng hình',
                button_video_id: 'Lấy thông tin',
                thumbnail_title: 'Băng hình',
                video_url: 'Đường dẫn băng hình',
                number_of: 'Số tập hợp đã có',
                information_video: 'lấy thông tin băng hình',
                channel_title: 'Thông tin kênh',
            },
            pick: {
                title: 'Thông tin tập hợp',
                keyword: 'Từ khóa',
                status: 'Trạng thái',
                created_by: 'Tạo bởi',
                created_at: 'Ngày tạo',
                pick_type: 'Loại Tập hợp',
                pick_type_short: 'Băng hình ngắn',
                pick_type_video: 'Băng hình thường',
            },
            products: {
                title: 'Liên kết sản phẩm',
                information: 'Sản phẩm',
                price: 'Giá sản phẩm',
                add_product: 'Thêm sản phẩm',
                table_store: 'Cửa hàng',
                table_brand: 'Thương hiệu',
                table_category: 'Danh mục',
                table_name: 'Tên sản phẩm',
                table_price: 'Giá tiền',
                table_sale_price: 'Giá giảm',
                table_keyword: 'Từ khóa',
                table_submit: 'Xác nhận',
                number_product: 'Số sản phẩm',
                number_pick: 'Số Pick',
                table_count: 'Tổng {count} sản phẩm',
                table_title_count: 'Sản phẩm được chọn',
                table_btn_search: 'Tìm kiếm',
                table_title: 'Liên kết sản phẩm',
            }
        },
        update: {
            title: 'Cập nhật tập hợp',
            confirm: 'Xác nhận tập hợp cửa hàng',
            confirm_description: 'Bạn chắc chắn về quyết định này không?',
            confirm_save: 'Xác nhận',
            confirm_cancel: 'Hủy bỏ',
            confirm_success_title: 'Thành công',
            confirm_success_description: 'Cập nhật tập hợp thành công',
            confirm_fail_title: 'Thật bại',
            confirm_fail_description: 'Cập nhật tập hợp thất bại',
        },
    }
}
