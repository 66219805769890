import Vue from "vue"
import moment from "moment";

Vue.filter('truncateText', function (text, stop, clamp) {
  if (typeof text !== 'undefined' && text !== '' && text !== null) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
  } else {
    return "";
  }
});

Vue.filter('showFromNow', function (text, lang = 'vi') {
  if (typeof text !== 'undefined' && text !== '' && text !== null) {
    return moment(text).lang(lang).fromNow()
  } else {
    return "";
  }
});

Vue.filter('abbreviateNumber', function (number) {
  if (number === 0) return number;
  const numberPrefix = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
  ]
  const tier = numberPrefix.filter((n) => number >= n.value).pop();
  const numberFixed = (number / tier.value).toFixed(0);
  
  return `${numberFixed}${tier.symbol}`;
});

Vue.filter('getLastMessage', function (text, stop, clamp) {
  
  if (typeof text === 'undefined' || text === '' || text === null) {
    return "";
  }
  
  let convertText = ''
  if (text && typeof text === 'string') {
    convertText = text
  } else if (text.type === 'message') {
    convertText = text.value
  } else if (text.type === 'image') {
    convertText = 'Bạn nhận được ảnh từ người khác'
  } else {
    convertText = 'Bạn nhận được 1 sản phẩm từ người khác'
  }
  
  if (typeof convertText !== 'undefined' && convertText !== '' && convertText !== null) {
    return convertText.slice(0, stop) + (stop < convertText.length ? clamp || '...' : '');
  } else {
    return "";
  }
});

Vue.filter('formatDatetime', function (text, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!text || text === '') {
    return text
  }
  if (!isNaN(parseFloat(text)) && isFinite(text)) {
    return moment.unix(text).format(format)
  }
  return moment(text).format(format)
});

Vue.filter('formatPrice', function (text, symbol, freeText = '') {
  if (!isNaN(text) && text > 0 && text < 1000) {
    return text + ' ' + symbol;
  } else if (!isNaN(text) && text >= 1000) {
    return String(text).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' ' + symbol;
  } else {
    return freeText !== '' ? freeText : '0 ' + symbol
  }
});
