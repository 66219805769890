import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './auth'
import user from './user'
import language from './language'
import campaign from './campaign'
import product from './product'
import booking from './booking'
import common from './common'
import report from './report'
import chat from './chat'
import order from './order'

const store = new Vuex.Store({
    modules: {
        auth: auth,
        user: user,
        language: language,
        campaign: campaign,
        product: product,
        booking: booking,
        common: common,
        report: report,
        chat: chat,
        order: order,
    }
})

export default store
