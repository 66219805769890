<template>
  <nav class="sidebar-new" :class="{'is-mobile' : isMobile, 'show': !isOpened}">
    <sidebar-menu
        :menu="sidebar"
        :width="'240px'"
        :collapsed="isMobile && isOpened"
        :widthCollapsed="'0px'"
        :disableHover="true"
        :hideToggle="true"
        :showOneChild="true"
        @item-click="onItemClick"
    >
    </sidebar-menu>
  </nav>
</template>
<script>
import {SidebarMenu} from 'vue-sidebar-menu'
import SidebarLogo from "@/components/dashboard/layouts/components/sidebar/SidebarLogo.vue";
import ReportIcon from "@/assets/img/common/ic_chart.svg"
import DiagramIcon from "@/assets/img/common/ic_diagram.svg"
import ShopIcon from "@/assets/img/common/ic_shop.svg"
import VideoIcon from "@/assets/img/common/ic_video.svg"
import ProductIcon from "@/assets/img/common/ic_product.svg"
import ChatIcon from "@/assets/img/common/ic_chat.svg"
import AdIcon from "@/assets/img/common/ic_ad.svg"
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  components: {SidebarMenu},
  data() {
    return {
      isOpened: this.$device.mobile,
      ReportIcon: ReportIcon,
      DiagramIcon: DiagramIcon,
      ShopIcon: ShopIcon,
      VideoIcon: VideoIcon,
      ProductIcon: ProductIcon,
      ChatIcon: ChatIcon,
      AdIcon: AdIcon,
    }
  },
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    ...mapGetters(['countMessageUnRead', 'currentUser']),
    sidebar() {
      return [
        {
          component: SidebarLogo
        },
        {
          title: this.$i18n.t('sidebar.report'),
          class: 'collapse-sidebar',
          icon: {
            element: 'img',
            class: 'icon-svg',
            attributes: {
              src: this.ReportIcon,
              alt: 'Vodaplay'
            }
          },
          child: [
            {
              href: {name: 'ReportBooking'},
              title: this.$i18n.t('sidebar.report_booking'),
              icon: {
                element: 'img',
                class: 'child-icon-svg icon-svg',
                attributes: {
                  src: this.DiagramIcon,
                  alt: 'Vodaplay'
                }
              },
            }
          ]
        },
        {
          href: {name: 'BookingStores'},
          title: this.$i18n.t('sidebar.booking_store'),
          icon: {
            element: 'img',
            class: 'icon-svg',
            attributes: {
              src: this.ShopIcon,
              alt: 'Vodaplay'
            }
          },
          class: 'dont-show-child-dropdown',
          child: [
            {
              href: {name: 'BookingStoreCreate'},
              title: this.$i18n.t('sidebar.booking_store_create'),
              icon: 'fa-solid fa-plus',
              hidden: true
            },
            {
              href: {name: 'BookingStoreUpdate'},
              title: this.$i18n.t('sidebar.booking_store_update'),
              icon: 'fa-solid fa-plus',
              hidden: true
            }
          ],
        },
        {
          href: {name: 'BookingProducts'},
          title: this.$i18n.t('sidebar.booking_product'),
          icon: {
            element: 'img',
            class: 'icon-svg',
            attributes: {
              src: this.ProductIcon,
              alt: 'Vodaplay'
            }
          },
          class: 'dont-show-child-dropdown',
          child: [
            {
              href: {name: 'BookingProductCreate'},
              title: this.$i18n.t('sidebar.booking_store_create'),
              icon: 'fa-solid fa-plus',
              hidden: true
            },
            {
              href: {name: 'BookingProductUpdate'},
              title: this.$i18n.t('sidebar.booking_store_update'),
              icon: 'fa-solid fa-plus',
              hidden: true
            }
          ],
        },
        {
          href: {name: 'BookingPicks'},
          title: this.$i18n.t('sidebar.booking_pick'),
          icon: {
            element: 'img',
            class: 'icon-svg',
            attributes: {
              src: this.VideoIcon,
              alt: 'Vodaplay'
            }
          },
          class: 'dont-show-child-dropdown',
          child: [
            {
              href: {name: 'BookingPickCreate'},
              title: this.$i18n.t('sidebar.booking_pick_create'),
              icon: 'fa-solid fa-plus',
              hidden: true
            },
            {
              href: {name: 'BookingPickUpdate'},
              title: this.$i18n.t('sidebar.booking_pick_update'),
              icon: 'fa-solid fa-plus',
              hidden: true
            }
          ],
        },
        // {
        //   title: this.$i18n.t('sidebar.campaign'),
        //   class: 'collapse-sidebar',
        //   icon: {
        //     element: 'img',
        //     class: 'icon-svg',
        //     attributes: {
        //       src: this.AdIcon,
        //       alt: 'Vodaplay'
        //     }
        //   },
        //   child: [
        //     {
        //       href: {name: 'Campaign'},
        //       title: this.$i18n.t('sidebar.brand_ai'),
        //       icon: 'fa-solid fa-robot',
        //       child: [
        //         {
        //           href: {name: 'CampaignCreate'},
        //           title: this.$i18n.t('sidebar.brand_ai_create'),
        //           icon: 'fa-solid fa-plus',
        //           hidden: true
        //         },
        //         {
        //           href: {name: 'CampaignUpdate'},
        //           title: this.$i18n.t('sidebar.brand_ai_create'),
        //           icon: 'fa-solid fa-plus',
        //           hidden: true
        //         }
        //       ]
        //     },
        //   ]
        // },
        {
          title: this.$i18n.t('sidebar.chat'),
          icon: {
            element: 'img',
            class: 'icon-svg',
            attributes: {
              src: this.ChatIcon,
              alt: 'Vodaplay'
            }
          },
          href: {name: 'ChatPage'},
          badge: this.countMessageUnRead > 0 ? {
            text: this.countMessageUnRead,
            class: 'badge badge-danger',
            element: 'span'
          } : {}
        },
      ]
    }
  },
  async mounted() {
    let vm = this
    this.$root.$on('toggle-menu-bar', (open) => {
      vm.isOpened = open
    })
    await this.$store.dispatch('getMessageUnRead', {
      chattingUserId: this.currentUser.chatting_id
    })
    await this.replaceIcon()
  },
  methods: {
    async replaceIcon(){
      const svgImages = document.getElementsByClassName('icon-svg');
      for (const image of svgImages) {
        const svgUrl = image.getAttribute('src')
        const svgClas = image.getAttribute('class')
        const response = await axios.get(svgUrl)
        const contentSvg = response.data
        let svgElement = new DOMParser().parseFromString(contentSvg, 'text/html').body.childNodes[0]
        svgElement.setAttribute('class', svgClas)
        image.replaceWith(svgElement)
      }
    },

    async replaceChildIcon(){
      const svgImages = document.getElementsByClassName('child-icon-svg')
      for (const image of svgImages) {
        const svgUrl = image.getAttribute('src')
        const svgClas = image.getAttribute('class')
        const response = await axios.get(svgUrl)
        const contentSvg = response.data
        let svgElement = new DOMParser().parseFromString(contentSvg, 'text/html').body.childNodes[0]
        svgElement.setAttribute('class', svgClas)
        image.replaceWith(svgElement)
      }
    },


    async onItemClick(event, item) {
      if (item.href) {
        this.isOpened = true
        this.$root.$emit('toggle-menu-click', true)
      }else{
        setTimeout(() => {
          this.replaceChildIcon()
        }, 50)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.sidebar-new {
  &.is-mobile {
    position: fixed;
    z-index: 100;

    &.show {
      width: 100vw;
      height: 100vh;
    }
  }
}

::v-deep {

  .dont-show-child-dropdown {

    .vsm--arrow {
      display: none !important;
    }

    ~ .vsm--dropdown {
      display: none !important;
    }
  }

  .vsm--scroll-wrapper {
    margin-right: 0 !important;
  }

  .sidebar-header {
    width: auto !important;
    margin-bottom: 50px;
  }

  .v-sidebar-menu {
    background: var(--black-filter-161616);

    .vsm--dropdown {

      .vsm--list {
        background: var(--black-filter-161616);

        padding: 12px 0;

        .vsm--item {
          padding-right: 0 !important;
        }
      }

    }

    .vsm--item {
      padding: 0 20px;

      .vsm--title {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        text-align: left;
      }
    }

    &.vsm_expanded {
      .vsm--item_open {
        .vsm--link_level-1 {
          background: #685EFF !important;

            &.collapse-sidebar {
              margin-bottom: 0 !important;
            }

          .icon-svg{
            path{
              background-color: var(--white) !important;
              stroke: var(--white) !important;
            }
          }
        }
      }
    }

    .vsm--icon {
      width: 24px;
      height: 24px;
      background: transparent !important;
    }

    .vsm--toggle-btn {
      background: var(--black-filter-161616);
    }

    .vsm--link_level-1 {
      border-radius: 6px;
      color: var(--log-in-bg-A4A4A4);
      margin-bottom: 12px;
      padding: 14px 12px;

      .icon-svg{
        path{
          background-color: var(--log-in-bg-A4A4A4) !important;
          stroke: var(--log-in-bg-A4A4A4) !important;
        }
      }

      &:hover {
        color: var(--white);
        background: var(--level-three-side-bar);

        .icon-svg{
          path{
            background-color: var(--white) !important;
            stroke: var(--white) !important;
          }
        }
      }

      &.vsm--link_active {
        background: #685EFF !important;
        box-shadow: unset !important;
        color: var(--white);

        &.collapse-sidebar{
          margin-bottom: 0 !important;
        }

        .icon-svg{
          path{
            background-color: var(--white) !important;
            stroke: var(--white) !important;
          }
        }
      }

      .vsm--icon {
        width: 24px;
        height: 24px;
        background: transparent !important;

        path {
          background-color: #FFFFFF;
        }
      }

      .vsm--badge {
        padding: 3px 5px;
      }
    }

    .vsm--link_level-2 {
      border-radius: 6px;
      padding: 14px 12px;
      color: var(--log-in-bg-A4A4A4);

      .icon-svg{
        path{
          background-color: var(--log-in-bg-A4A4A4) !important;
          stroke: var(--log-in-bg-A4A4A4) !important;
        }
      }

      &:hover {
        background: var(--level-three-side-bar);
        color: var(--white);

        .icon-svg{
          path{
            background-color: var(--white) !important;
            stroke: var(--white) !important;
          }
        }
      }

      &.vsm--link_active {
        background: #685EFF;
        color: var(--white);

        .icon-svg{
          path{
            background-color: var(--white) !important;
            stroke: var(--white) !important;
          }
        }
      }

      .vsm--arrow {
        display: none;
      }
    }

    .vsm--dropdown {

      .vsm--dropdown {
        display: none;
      }
    }
  }
}
</style>
