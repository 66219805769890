<template>
  <div class="campaign-page w-100 h-100">
    <title-page :title="$t('chat.title')" :icon="'MessageChat'"></title-page>
    <div class="card-datatable p-3 w-100" :style="{height: !isMobile ? 'calc(100% - 111px)' : 'auto'}">
      <b-row>
        <b-col cols="3">
          <b-card no-body class="card-layer">
            <list-user-desktop></list-user-desktop>
          </b-card>
        </b-col>
        <b-col cols="9">
          <b-card no-body class="card-layer">
            <content-chat-desktop @open-create-order="createOrder"></content-chat-desktop>
          </b-card>
        </b-col>
      </b-row>

      <b-modal id="modal-create-order" content-class="modal-designer" ref="modal-create-order" centered>
        <template #modal-title>
          {{ $t('chat.your_order') }}
        </template>

        <div class="text-center">
          <b-alert :show="isErrorCreateOrder"
                   dismissible
                   variant="danger">
            {{ $t('chat.create_order_error') }}
          </b-alert>
          <b-row>
            <b-col cols="12">
              <table class="table table-no-border table-form mt-2 table-no-padding-horizontal">
                <tr>
                  <th class="text-left">
                    <label class="label-selector">{{ $t('booking.product.fields.name') }}</label>
                    <span class="text-danger">*</span>
                  </th>
                  <td>
                    <b-form-input v-model.trim="order.name"
                                  v-validate="'required'"
                                  data-vv-scope="chat_order_validate"
                                  :data-vv-as="$t('booking.product.fields.name')"
                                  name="product_name"
                                  type="text"
                                  class="form-control"
                                  :class="errors.first('chat_order_validate.product_name') ? 'is-invalid' : ''"
                                  :placeholder="$t('booking.product.fields.name') + '...'">
                    </b-form-input>
                    <b-form-invalid-feedback>
                      {{ errors.first('chat_order_validate.product_name') }}
                    </b-form-invalid-feedback>
                  </td>
                </tr>
                <tr>
                  <th class="text-left">
                    <label class="label-selector">{{ $t('booking.product.fields.price') }}</label>
                    <span class="text-danger">*</span>
                  </th>
                  <td>
                    <input-price name="product_price"
                                 v-validate="'required'"
                                 data-vv-scope="chat_order_validate"
                                 :data-vv-as="$t('booking.product.fields.price')"
                                 v-model.trim="order.price"
                                 class="form-control"
                                 :class="errors.first('chat_order_validate.product_price') ? 'is-invalid' : ''"
                                 :placeholder="$t('booking.product.fields.price') + '...'">
                    </input-price>
                    <b-form-invalid-feedback>
                      {{ errors.first('chat_order_validate.product_price') }}
                    </b-form-invalid-feedback>
                  </td>
                </tr>
                <tr>
                  <th class="text-left">
                    <label class="label-selector">{{ $t('booking.product.fields.applicable_date') }}</label>
                    <span class="text-danger">*</span>
                  </th>
                  <td>
                    <date-picker v-model.trim="order.applicable_date"
                                 valueType="format"
                                 format="DD/MM/YYYY"
                                 name="applicable_date"
                                 v-validate="'required'"
                                 data-vv-scope="chat_order_validate"
                                 :data-vv-as="$t('booking.product.fields.applicable_date')"
                                 class="form-control"
                                 :disabled-date="disabledBeforeToday"
                                 :placeholder="$t('booking.product.fields.applicable_date') + '...'"
                                 :class="errors.first('chat_order_validate.applicable_date') ? 'is-invalid' : ''"
                    >

                    </date-picker>
                    <b-form-invalid-feedback>
                      {{ errors.first('chat_order_validate.applicable_date') }}
                    </b-form-invalid-feedback>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <b-textarea rows="4" v-model="order.note" :placeholder="$t('chat.note') + '...'"></b-textarea>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </div>

        <template #modal-footer>
          <div class="w-100">
            <b-button
                class="float-right ml-2 button-violet"
                size="sm"
                :disabled="isSubmitModal"
                :class="isSubmitModal ? 'disabled' : ''"
                v-on:click="submitModalOrder"
            >
              <template v-if="isSubmitModal">
                <b-spinner small></b-spinner>
              </template>
              <template v-else>
                {{ $t('chat.submit_order') }}
              </template>
            </b-button>
            <b-button
                size="sm"
                class="float-right button-violet-outline"
                v-on:click="closeModalOrder"
            >
              {{ $t('chat.cancel_order') }}
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ListUserDesktop from "@/components/dashboard/components/pages/chat/ListUser.vue"
import ContentChatDesktop from "@/components/dashboard/components/pages/chat/ContentChat.vue"
import TitlePage from "@/components/dashboard/layouts/TitlePage.vue";
import {mapGetters} from "vuex";
import CryptoJS from "crypto-js";
// import Vue from "vue";
// import VueSocketIO from "vue-socket.io";
// import stores from "@/stores";

export default {
  components: {
    TitlePage,
    ListUserDesktop,
    ContentChatDesktop
  },
  created() {
    const vm = this;
    vm.$root.$on('choose-user-chatting', (user) => {
      this.user = user
    })
  },
  data() {
    return {
      user: {},
      isErrorCreateOrder: false,
      isSubmitModal: false,
      order: {
        name: '',
        price: '',
        applicable_date: '',
        note: '',
        confirm: 0
      },
    }
  },
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    ...mapGetters(["currentUser"]),
  },
  methods: {

    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today
    },

    async submitModalOrder() {
      if (this.isSubmitModal) {
        return false
      }
      const valid = await this.$validator.validate('chat_order_validate.*')
      if (!valid) {
        return false
      }
      this.isSubmitModal = true

      const response = await this.$store.dispatch('createOrder', {
        conversation_id: this.user.conversationId,
        sender_chatting_id: this.currentUser.chatting_id,
        received_chatting_id: this.user._id,
        product_name: this.order.name,
        product_price: this.order.price,
        applicable_date: this.order.applicable_date,
        note: this.order.note
      })

      if (response.data.status === 200) {
        this.order.id = response.data.data.id
        this.sendPreviewOrder()
        await this.sendOrder()
        this.$refs['modal-create-order'].hide()
        await this.resetOder()
      } else {
        this.isErrorCreateOrder = true
      }
      this.isSubmitModal = false
    },

    closeModalOrder() {
      this.$refs['modal-create-order'].hide()
    },

    sendPreviewOrder() {
      const hashing = this.generateSHA1ByString(JSON.stringify(this.order))
      this.$store.dispatch('sendMessagePreview', {
        sender: this.currentUser.chatting_id,
        hashing: hashing,
        text: {
          type: 'order',
          value: this.order
        },
        conversationId: this.user.conversationId,
        userEmail: this.user.email,
        isImage: false,
        isPreview: true
      })
      this.scrollToBottom()
    },

    async sendOrder() {
      if (this.isLoadingSendOrder) {
        return false
      }
      this.isLoadingSendOrder = true
      const orderNew = Object.assign({}, this.order)
      const message = await this.$store.dispatch('sendMessage', {
        conversationId: this.user.conversationId,
        sender: this.currentUser.chatting_id,
        receivedId: this.user._id,
        text: {
          type: 'order',
          value: orderNew
        },
        isImage: false,
        chattingUserId: this.currentUser.chatting_id
      })
      const messageId = message.data.data.result._id
      const hashing = this.generateSHA1ByString(JSON.stringify(orderNew))
      orderNew.message_id = messageId
      this.$store.dispatch('updateMessageIdToOrder', {
        messageId,
        hashing
      })
      this.$socket.emit("sendMessage", {
        senderId: this.currentUser.chatting_id,
        receiverId: this.user._id,
        text: {
          type: 'order',
          value: orderNew
        },
        conversationId: this.user.conversationId,
        userEmail: this.user.email
      })
      this.isLoadingSendOrder = false
    },

    generateSHA1ByString(string) {
      return CryptoJS.SHA1(string).toString()
    },

    async resetOder() {
      this.order = {
        name: '',
        price: '',
        applicable_date: '',
        note: ''
      }
      this.order = JSON.parse(JSON.stringify(this.order))
      this.isErrorCreateOrder = false
      await this.$validator.reset()
    },

    scrollToBottom(isLoad = false, beforeScrollHeight = 0) {
      setTimeout(() => {
        const container = document.body.querySelector("#chatting-scrollable")
        const scrollHeight = container ? container.scrollHeight : 0
        if (container) {
          container.scrollTop = isLoad ? scrollHeight - beforeScrollHeight : scrollHeight
        }
      }, 100)
    },

    async createOrder() {
      await this.resetOder()
      await this.$refs['modal-create-order'].show()
    },

  }
}
</script>

<style lang="scss" scoped>

</style>