import {BaseApi} from "@/lib/base-api";

const apiUrl = process.env.VUE_APP_API_URL + 'common';
const apiMediaUrl = process.env.VUE_APP_MEDIA_BASE_URL;

export const CommonServiceAPI = {

    getCities(params) {
        return BaseApi.request('querySerialize', apiUrl + '/cities', params)
    },

    getDistricts(params) {
        return BaseApi.request('querySerialize', apiUrl + '/districts/' + params.city_id, params)
    },

    getWards(params) {
        return BaseApi.request('querySerialize', apiUrl + '/wards/' + params.district_id, params)
    },

    uploadImage(params) {
        return BaseApi.request('postFormData', apiUrl + '/upload-image', params, '', {}, {
              withCredentials: false
          }
        )
    },

    uploadImageS3(params, headers = {}) {
        return BaseApi.request('postFormData', apiMediaUrl + 'api/media/upload_s3', params, '', headers, {
              withCredentials: false
          }
        )
    },

    getCurrentIpInfo() {
        return BaseApi.request('query', process.env.VUE_APP_IP_LINK, {})
    },

    getFilterCities(params) {
        return BaseApi.request('querySerialize', apiUrl + '/filter-cities', params)
    },

    getFilterDistricts(params) {
        return BaseApi.request('querySerialize', apiUrl + '/filter-districts', params)
    },

    getFilterWards(params) {
        return BaseApi.request('querySerialize', apiUrl + '/filter-wards', params)
    },

    isYoutubeVideoUrl(params) {
        return BaseApi.request('querySerialize', apiUrl + '/filter-wards', params)
    },
}
