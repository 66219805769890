<template>
  <div class="row">
    <div class="col-12">
      <section class="card card-layer padding-16">
        <h4 class="title-card-designer margin-bottom-24">{{ $t('booking.store.fields.title') }}</h4>
        <div class="card-body p-0">


          <b-overlay :show="isLoading" rounded="lg">
            <div class="table-hover table-responsive table-no-border mb-0 table-no-padding-horizontal">

              <table class="table table-form">
                <tr>
                  <th>
                    <label class="label-selector">{{ $t('booking.store.fields.image') }} <span
                        class="text-danger">*</span></label>
                  </th>
                  <td>
                    <upload-image-layout
                        v-validate="'required'"
                        v-model="formData.image"
                        :data-vv-as="$t('booking.store.fields.image')"
                        data-vv-scope="booking_store_validate"
                        name="image"
                        :is-multiple="false"
                        :ref-name="'store-upload-image'"
                        :class="errors.first('booking_store_validate.image') ? 'is-invalid' : ''"
                    >
                    </upload-image-layout>
                    <b-form-invalid-feedback>{{
                        errors.first('booking_store_validate.image')
                      }}
                    </b-form-invalid-feedback>
                  </td>
                </tr>
                <tr>
                  <th class="">
                    <label class="label-selector">{{ $t('booking.store.fields.name') }} <span
                        class="text-danger">*</span></label>
                  </th>
                  <td class="padding-right-32">
                    <input v-model="formData.name"
                           v-validate="'required'"
                           data-vv-scope="booking_store_validate"
                           :data-vv-as="$t('booking.store.fields.name')"
                           name="store_name"
                           type="text"
                           class="form-control"
                           :placeholder="$t('booking.store.fields.name')">
                    <b-form-invalid-feedback>{{
                        errors.first('booking_store_validate.store_name')
                      }}
                    </b-form-invalid-feedback>
                  </td>
                  <th class="padding-left-32">
                    <label class="label-selector">{{ $t('booking.store.fields.advertiser_type') }} <span
                        class="text-danger">*</span></label>
                  </th>
                  <td>
                    <multiple-select
                        v-validate="'required'"
                        data-vv-scope="booking_store_validate"
                        :data-vv-as="$t('booking.store.fields.advertiser_type')"
                        @close="validateMultipleSelect('advertiser_type')"
                        name="advertiser_type"
                        v-model="formData.advertiser_type"
                        :options="options.advertiser_type"
                        :allow-empty="true"
                        :placeholder="$t('filter.placeholder_option')"
                        open-direction="bottom"
                        label="name"
                        track-by="id"
                        :class="errors.first('booking_store_validate.advertiser_type') ? 'is-invalid' : ''"
                        :show-labels="false"
                    >
                      <span slot="noResult">{{ $t('filter.no_result') }}</span>
                    </multiple-select>
                    <b-form-invalid-feedback>{{
                        errors.first('booking_store_validate.advertiser_type')
                      }}
                    </b-form-invalid-feedback>
                  </td>
                </tr>
                <tr>
                  <th class="">
                    <label class="label-selector">{{ $t('booking.store.fields.sector') }} <span
                        class="text-danger">*</span></label>
                  </th>
                  <td class="padding-right-32">
                    <multiple-select
                        v-validate="'required'"
                        data-vv-scope="booking_store_validate"
                        :data-vv-as="$t('booking.store.fields.sector')"
                        @close="validateMultipleSelect('sector')"
                        name="sector"
                        v-model="formData.sector"
                        :options="options.sector"
                        :allow-empty="true"
                        :placeholder="$t('filter.placeholder_option')"
                        open-direction="bottom"
                        label="name"
                        track-by="id"
                        :class="errors.first('booking_store_validate.sector') ? 'is-invalid' : ''"
                        :show-labels="false"
                    >
                      <span slot="noResult">{{ $t('filter.no_result') }}</span>
                    </multiple-select>
                    <b-form-invalid-feedback>{{
                        errors.first('booking_store_validate.sector')
                      }}
                    </b-form-invalid-feedback>
                  </td>
                  <!--                  <th class="padding-left-32">-->
                  <!--                    <label class="label-selector">{{ $t('booking.store.fields.status') }} <span-->
                  <!--                        class="text-danger">*</span></label>-->
                  <!--                  </th>-->
                  <!--                  <td>-->
                  <!--                    <template v-if="isUpdate">-->
                  <!--                      <multiple-select-->
                  <!--                          v-validate="'required'"-->
                  <!--                          data-vv-scope="booking_store_validate"-->
                  <!--                          :data-vv-as="$t('booking.store.fields.status')"-->
                  <!--                          @close="validateMultipleSelect('status')"-->
                  <!--                          name="status"-->
                  <!--                          v-model="formData.status"-->
                  <!--                          :options="options.status"-->
                  <!--                          :allow-empty="false"-->
                  <!--                          :placeholder="$t('filter.placeholder_option')"-->
                  <!--                          open-direction="bottom"-->
                  <!--                          label="name"-->
                  <!--                          track-by="id"-->
                  <!--                          :class="errors.first('booking_store_validate.status') ? 'is-invalid' : ''"-->
                  <!--                          :show-labels="false"-->
                  <!--                      >-->
                  <!--                        <span slot="noResult">{{ $t('filter.no_result') }}</span>-->
                  <!--                      </multiple-select>-->
                  <!--                      <b-form-invalid-feedback>{{-->
                  <!--                          errors.first('booking_store_validate.status')-->
                  <!--                        }}-->
                  <!--                      </b-form-invalid-feedback>-->
                  <!--                    </template>-->
                  <!--                  </td>-->
                </tr>
                <!--                <tr>-->
                <!--                  <th class="">-->
                <!--                    <label class="label-selector">{{ $t('booking.store.fields.manager') }} <span-->
                <!--                        class="text-danger">*</span></label>-->
                <!--                  </th>-->
                <!--                  <td class="padding-right-32">-->
                <!--                    <input v-model="formData.manager"-->
                <!--                           v-validate="'required'"-->
                <!--                           data-vv-scope="booking_store_validate"-->
                <!--                           :data-vv-as="$t('booking.store.fields.manager')"-->
                <!--                           name="store_manager"-->
                <!--                           type="text"-->
                <!--                           class="form-control"-->
                <!--                           :placeholder="$t('booking.store.fields.manager')">-->
                <!--                    <b-form-invalid-feedback>{{-->
                <!--                        errors.first('booking_store_validate.store_manager')-->
                <!--                      }}-->
                <!--                    </b-form-invalid-feedback>-->
                <!--                  </td>-->
                <!--                  <th class="padding-left-32">-->
                <!--                    <label class="label-selector">{{ $t('booking.store.fields.manager_contact') }} <span-->
                <!--                        class="text-danger">*</span></label>-->
                <!--                  </th>-->
                <!--                  <td>-->
                <!--                    <input v-model="formData.manager_contact"-->
                <!--                           v-validate="'required|phone_number_mask'"-->
                <!--                           v-mask="'#### ### ###'"-->
                <!--                           data-vv-scope="booking_store_validate"-->
                <!--                           :data-vv-as="$t('booking.store.fields.manager_contact')"-->
                <!--                           name="store_manager_contact"-->
                <!--                           type="text"-->
                <!--                           class="form-control"-->
                <!--                           :placeholder="$t('booking.store.fields.manager_contact')">-->
                <!--                    <b-form-invalid-feedback>{{-->
                <!--                        errors.first('booking_store_validate.store_manager_contact')-->
                <!--                      }}-->
                <!--                    </b-form-invalid-feedback>-->
                <!--                  </td>-->
                <!--                </tr>-->
                <!--                <tr>-->
                <!--                  <th class="">-->
                <!--                    <label class="label-selector">{{ $t('booking.store.fields.manager_email') }} <span-->
                <!--                        class="text-danger">*</span></label>-->
                <!--                  </th>-->
                <!--                  <td class="padding-right-32">-->
                <!--                    <input v-model="formData.manager_email"-->
                <!--                           v-validate="'required|email'"-->
                <!--                           data-vv-scope="booking_store_validate"-->
                <!--                           :data-vv-as="$t('booking.store.fields.manager_email')"-->
                <!--                           name="store_manager_email"-->
                <!--                           type="text"-->
                <!--                           class="form-control"-->
                <!--                           :placeholder="$t('booking.store.fields.manager_email')">-->
                <!--                    <b-form-invalid-feedback>{{-->
                <!--                        errors.first('booking_store_validate.store_manager_email')-->
                <!--                      }}-->
                <!--                    </b-form-invalid-feedback>-->
                <!--                  </td>-->
                <!--                  <th class="padding-left-32">-->
                <!--                    <label class="label-selector">{{ $t('booking.store.fields.customer_advice_contact') }} <span-->
                <!--                        class="text-danger">*</span></label>-->
                <!--                  </th>-->
                <!--                  <td>-->
                <!--                    <input v-model="formData.customer_advice_contact"-->
                <!--                           v-validate="'required|phone_number_mask'"-->
                <!--                           v-mask="'#### ### ###'"-->
                <!--                           data-vv-scope="booking_store_validate"-->
                <!--                           :data-vv-as="$t('booking.store.fields.customer_advice_contact')"-->
                <!--                           name="store_customer_advice_contact"-->
                <!--                           type="text"-->
                <!--                           class="form-control"-->
                <!--                           :placeholder="$t('booking.store.fields.customer_advice_contact')">-->
                <!--                    <b-form-invalid-feedback>-->
                <!--                      {{ errors.first('booking_store_validate.store_customer_advice_contact') }}-->
                <!--                    </b-form-invalid-feedback>-->
                <!--                  </td>-->
                <!--                </tr>-->
                <tr>
                  <th class="">
                    <label class="label-selector">{{ $t('booking.store.fields.zalo_contact') }} <span
                        class="text-danger">*</span></label>
                  </th>
                  <td class="padding-right-32">
                    <input v-model="formData.zalo_contact"
                           v-validate="'required|phone_number_mask'"
                           v-mask="'#### ### ###'"
                           data-vv-scope="booking_store_validate"
                           :data-vv-as="$t('booking.store.fields.zalo_contact')"
                           name="zalo_contact"
                           type="text"
                           class="form-control"
                           :placeholder="$t('booking.store.fields.zalo_contact')">
                    <b-form-invalid-feedback>
                      {{ errors.first('booking_store_validate.zalo_contact') }}
                    </b-form-invalid-feedback>
                  </td>
                  <th class="padding-left-32">
                    <label class="label-selector">{{ $t('booking.store.fields.time_open') }} <span
                        class="text-danger">*</span></label>
                  </th>
                  <td>
                    <b-col cols="12">
                      <b-row>
                        <b-col cols="6">
                          <b-row>
                            <time-picker
                                :key="uniqueId"
                                :min-hour="1"
                                :max-hour="24"
                                :increment-minutes="15"
                                v-model="formData.start_time"
                                :ref="'start_time_ref'"
                                v-validate="'required'"
                                data-vv-scope="booking_store_validate"
                                :data-vv-as="$t('booking.store.fields.open_time')"
                                name="start_time"
                                :placeHolder="$t('booking.store.fields.open_time')"
                                :class="errors.first('booking_store_validate.start_time') ? 'is-invalid' : ''"
                                @input="validateMultipleSelect(`start_time`)"
                                @blur="validateMultipleSelect(`start_time`)"
                            >
                            </time-picker>
                            <b-form-invalid-feedback>
                              {{ errors.first('booking_store_validate.start_time') }}
                            </b-form-invalid-feedback>
                          </b-row>
                        </b-col>

                        <b-col cols="6">
                          <b-row>
                            <time-picker
                                :key="uniqueId"
                                :min-hour="1"
                                :max-hour="24"
                                :increment-minutes="15"
                                v-model="formData.end_time"
                                v-validate="'required|is_bigger_time_if_exist:start_time_ref'"
                                data-vv-scope="booking_store_validate"
                                :data-vv-as="$t('booking.store.fields.close_time')"
                                name="end_time"
                                :placeHolder="$t('booking.store.fields.close_time')"
                                :class="errors.first('booking_store_validate.end_time') ? 'is-invalid' : ''"
                                @input="validateMultipleSelect(`end_time`)"
                                @blur="validateMultipleSelect(`end_time`)"
                            >
                            </time-picker>
                            <b-form-invalid-feedback>
                              {{ errors.first('booking_store_validate.end_time') }}
                            </b-form-invalid-feedback>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </td>
                </tr>
                <tr>
                  <th class="">
                    <label class="label-selector">
                      {{ $t('booking.store.fields.day_open') }}
                      <span class="text-danger">*</span>
                    </label>
                  </th>
                  <td colspan="3">
                    <div class="d-inline-flex">
                      <b-form-group class="mb-0">
                        <b-form-checkbox-group
                            v-model="checkedDayOff"
                            @input="handleCheckBoxItem()"
                            :options="options.day_open"

                        ></b-form-checkbox-group>
                      </b-form-group>
                      <b-form-checkbox
                          class="designer-checkbox custom-control-inline"
                          :checked="isCheckAll"
                          @change="checkedToggleCheckbox"
                      >
                        {{ $t('campaign.gender.all') }}
                      </b-form-checkbox>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="">
                    <label class="label-selector">{{ $t('booking.store.fields.address') }} <span
                        class="text-danger">*</span></label>
                  </th>
                  <td colspan="3">
                    <b-col cols="12">
                      <b-row>
                        <b-col cols="4">
                          <b-row>
                            <multiple-select
                                v-validate="'required'"
                                data-vv-scope="booking_store_validate"
                                :data-vv-as="$t('booking.store.fields.cities')"
                                @close="validateMultipleSelect('city_id')"
                                name="city_id"
                                v-model="formData.city_id"
                                :options="options.city_id"
                                :allow-empty="false"
                                :placeholder="$t('booking.store.fields.cities')"
                                open-direction="bottom"
                                label="name"
                                track-by="id"
                                :class="errors.first('booking_store_validate.city_id') ? 'is-invalid' : ''"
                                :show-labels="false"
                            >
                              <span slot="noResult">{{ $t('filter.no_result') }}</span>
                            </multiple-select>
                            <b-form-invalid-feedback>
                              {{ errors.first('booking_store_validate.city_id') }}
                            </b-form-invalid-feedback>
                          </b-row>
                        </b-col>
                        <b-col cols="4">
                          <b-row>
                            <multiple-select
                                v-validate="'required'"
                                data-vv-scope="booking_store_validate"
                                :data-vv-as="$t('booking.store.fields.districts')"
                                @close="validateMultipleSelect('district_id')"
                                name="district_id"
                                v-model="formData.district_id"
                                :options="options.district_id"
                                :allow-empty="false"
                                :placeholder="$t('booking.store.fields.districts')"
                                open-direction="bottom"
                                label="name"
                                track-by="id"
                                class="pl-2"
                                :class="errors.first('booking_store_validate.district_id') ? 'is-invalid' : ''"
                                :show-labels="false"
                            >
                              <span slot="noResult">{{ $t('filter.no_result') }}</span>
                            </multiple-select>
                            <b-form-invalid-feedback>
                              {{ errors.first('booking_store_validate.district_id') }}
                            </b-form-invalid-feedback>
                          </b-row>
                        </b-col>
                        <b-col cols="4">
                          <b-row>
                            <multiple-select
                                v-validate="'required'"
                                data-vv-scope="booking_store_validate"
                                :data-vv-as="$t('booking.store.fields.wards')"
                                @close="validateMultipleSelect('ward_id')"
                                name="ward_id"
                                v-model="formData.ward_id"
                                :options="options.ward_id"
                                :allow-empty="false"
                                :placeholder="$t('booking.store.fields.wards')"
                                open-direction="bottom"
                                label="name"
                                track-by="id"
                                class="pl-2"
                                :class="errors.first('booking_store_validate.ward_id') ? 'is-invalid' : ''"
                                :show-labels="false"
                            >
                              <span slot="noResult">{{ $t('filter.no_result') }}</span>
                            </multiple-select>
                            <b-form-invalid-feedback>
                              {{ errors.first('booking_store_validate.ward_id') }}
                            </b-form-invalid-feedback>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </td>
                </tr>
                <tr>
                  <th class="">
                    <label class="label-selector">{{ $t('booking.store.fields.maps') }} <span
                        class="text-danger">*</span></label>
                  </th>
                  <td colspan="3">
                    <custom-map v-if="formData.latitude" :store-name="formData.name" :latitude="formData.latitude" :longitude="formData.longitude" @change-position="changePosition"></custom-map>
<!--                    <template v-if="usingMap !== 'google'">-->
<!--                      <open-street-map-->
<!--                          :style="{zIndex: 1}"-->
<!--                          :latitude="formData.latitude"-->
<!--                          :longitude="formData.longitude"-->
<!--                          :zoom="18"-->
<!--                          :is-search="true"-->
<!--                          :is-drag-marker="true"-->
<!--                          @update-location="updateLocation"-->
<!--                      >-->
<!--                      </open-street-map>-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      <google-map-->
<!--                          :style="{zIndex: 1}"-->
<!--                          :latitude="formData.latitude"-->
<!--                          :longitude="formData.longitude"-->
<!--                          :zoom="18"-->
<!--                          :is-search="true"-->
<!--                          :is-drag-marker="true"-->
<!--                          @update-location="updateLocation"-->
<!--                      ></google-map>-->
<!--                    </template>-->
                  </td>
                </tr>
              </table>
            </div>
          </b-overlay>
        </div>

      </section>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
// import OpenStreetMap from "@/components/layouts/OpenStreetMap.vue";
// import GoogleMap from "@/components/layouts/GoogleMap.vue";
import TimePicker from "@/components/dashboard/layouts/TimePicker.vue";
import {v4 as uuidv4} from "uuid";
import CustomMap from "@/components/layouts/CustomMap.vue";

export default {
  components: {CustomMap, TimePicker},
  props: {
    currentData: {
      required: false,
      type: Object
    },
    isUpdate: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    await this.$validator.reset()
    await this.setFormData()
  },
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    usingMap(){
      return process.env.VUE_APP_USING_MAP
    },
    uniqueId() {
      return uuidv4();
    },
    ...mapGetters(["cities", "districts", "wards", "sectors"]),
    labels() {
      return {
        kr: {
          labelHours: '시간',
          labelMinutes: '분',
          labelSeconds: '초',
          labelIncrement: '증가',
          labelDecrement: '감소',
          labelSelected: '선택한 시간',
          labelNoTimeSelected: '선택한 시간 없음',
          labelCloseButton: '닫다'
        },
        vi: {
          labelHours: 'Giờ',
          labelMinutes: 'Phút',
          labelSeconds: 'Giây',
          labelIncrement: 'Tăng',
          labelDecrement: 'Giảm',
          labelSelected: 'Thời gian đã chọn',
          labelNoTimeSelected: 'Chưa chọn thời gian',
          labelCloseButton: 'Đóng'
        }
      }
    },
    options() {
      return {
        city_id: [...this.cities],
        district_id: [...this.districts],
        ward_id: [...this.wards],
        sector: [...this.sectors],
        advertiser_type: [
          {
            id: 1,
            name: this.$i18n.t('booking.store.type.advertiser'),
            class: 'badge badge-warning'
          },
          {
            id: 2,
            name: this.$i18n.t('booking.store.type.agency'),
            class: 'badge badge-primary'
          }
        ],
        status: [
          {
            id: 0,
            name: this.$i18n.t('booking.product.option_pending'),
            class: 'badge badge-warning'
          },
          {
            id: 1,
            name: this.$i18n.t('booking.product.option_running'),
            class: 'badge badge-primary'
          }
        ],
        day_open: [
          {text: this.$i18n.t('report.booking.monday'), value: 'monday'},
          {text: this.$i18n.t('report.booking.tuesday'), value: 'tuesday'},
          {text: this.$i18n.t('report.booking.wednesday'), value: 'wednesday'},
          {text: this.$i18n.t('report.booking.thursday'), value: 'thursday'},
          {text: this.$i18n.t('report.booking.friday'), value: 'friday'},
          {text: this.$i18n.t('report.booking.saturday'), value: 'saturday'},
          {text: this.$i18n.t('report.booking.sunday'), value: 'sunday'},
        ]
      };
    }
  },
  watch: {
    'formData.city_id': {
      handler(after, before) {
        if (after !== this.cityIdCache && typeof before !== 'undefined') {
          this.cityIdCache = JSON.parse(JSON.stringify(after))
          this.loadDistrict()
        }
      },
      deep: true
    },
    'formData.district_id': {
      handler(after, before) {
        if (after !== this.districtIdCache && typeof before !== 'undefined') {
          this.districtIdCache = JSON.parse(JSON.stringify(after))
          this.loadWard()
        }
      },
      deep: true
    },
    checkedDayOff: {
      handler(after, before) {
        const vm = this
        if (after !== this.checkedDayOffCache && typeof before !== 'undefined') {
          this.checkedDayOffCache = JSON.parse(JSON.stringify(after))
          vm.formData.day_open = {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
          }
          after.forEach((item) => {
            vm.formData.day_open[item] = true
          })
        }
      }
    }
  },
  data() {
    return {
      formData: {},
      cacheFormData: {},
      cityIdCache: 0,
      districtIdCache: 0,
      checkedDayOff: [],
      checkedDayOffCache: [],
      pageLoading: false,
      isLoading: true,
      isCheckAll: false
    }
  },
  methods: {

    changePosition(payload = {}){
      this.formData.latitude = payload.lat;
      this.formData.longitude = payload.lng;
    },

    handleCheckBoxItem() {
      const checked = this.checkedDayOff
      if (checked && checked.length > 0) {
        if (checked.length >= 7) {
          this.isCheckAll = true;
        } else {
          this.isCheckAll = false;
        }
      } else {
        this.isCheckAll = false;
      }
    },
    checkedToggleCheckbox() {
      let vm = this
      if (!vm.isCheckAll) {
        vm.options.day_open.forEach((item) => {
          if (!vm.checkedDayOff.includes(item.value)) {
            vm.checkedDayOff.push(item.value)
          }
        })
        vm.isCheckAll = true
      } else {
        vm.checkedDayOff = [];
        vm.isCheckAll = false
      }
    },
    async setFormData() {
      this.formData = this.currentData
      if (this.formData.city_id !== '' && this.options.city_id.length > 0) {
        this.formData.city_id = this.options.city_id.find((city) => {
          return city.id === this.formData.city_id
        })
        this.cityIdCache = JSON.parse(JSON.stringify(this.formData.city_id))
      }
      if (this.formData.district_id !== '' && this.options.district_id.length > 0) {
        this.formData.district_id = this.options.district_id.find((district) => {
          return district.id === this.formData.district_id
        })
        this.districtIdCache = JSON.parse(JSON.stringify(this.formData.district_id))
      }
      // if (this.formData.status !== '' && this.options.status.length > 0) {
      //   this.formData.status = this.options.status.find((status) => {
      //     return status.id === this.formData.status
      //   })
      // }
      if (this.formData.ward_id !== '' && this.options.ward_id.length > 0) {
        this.formData.ward_id = this.options.ward_id.find((ward) => {
          return ward.id === this.formData.ward_id
        })
      }
      if (this.formData.advertiser_type !== '' && this.options.advertiser_type.length > 0) {
        this.formData.advertiser_type = this.options.advertiser_type.find((type) => {
          return type.id === this.formData.advertiser_type
        })
      }
      if (this.formData.sector !== '' && this.options.sector.length > 0) {
        this.formData.sector = this.options.sector.find((sector) => {
          return sector.id === this.formData.sector
        })
      }
      if (this.formData.latitude && typeof this.formData.latitude !== 'number') {
        this.formData.latitude = Number(this.formData.latitude)
      }
      if (this.formData.longitude && typeof this.formData.longitude !== 'number') {
        this.formData.longitude = Number(this.formData.longitude)
      }
      if (this.formData.day_open && Object.keys(this.formData.day_open).length > 0) {
        const vm = this
        Object.keys(this.formData.day_open).forEach((item) => {
          if (vm.formData.day_open[item] === '1') {
            if (!vm.checkedDayOff.includes(item.value)) {
              vm.checkedDayOff.push(item)
            }
          }
        })
        if (vm.checkedDayOff && vm.checkedDayOff.length > 0) {
          if (vm.checkedDayOff.length >= 7) {
            this.isCheckAll = true;
          } else {
            this.isCheckAll = false;
          }
        } else {
          this.isCheckAll = false;
        }
        this.checkedDayOffCache = JSON.parse(JSON.stringify(this.checkedDayOff))
      }
      this.cacheFormData = JSON.parse(JSON.stringify(this.formData))

      this.isLoading = false
    },

    updateLocation(location) {
      this.formData.longitude = Number(location.lng.toString());
      this.formData.latitude = Number(location.lat.toString());
    },

    async loadDistrict() {
      if (this.formData.city_id !== '') {
        this.formData.district_id = ''
        this.formData.ward_id = ''
        await this.$store.dispatch('resetDistricts')
        await this.$store.dispatch('resetWards')
        await this.$store.dispatch('getDistricts', {city_id: this.formData.city_id.id})
      }
    },

    async loadWard() {
      if (this.formData.district_id !== '') {
        this.formData.ward_id = ''
        await this.$store.dispatch('resetWards')
        await this.$store.dispatch('getWards', {district_id: this.formData.district_id.id})
      }
    },

    async validateMultipleSelect(name) {
      await this.$validator.validate('booking_store_validate.' + name, this.formData[name])
    },
  }
}
</script>

<style lang="scss">
.map-component {
  img {
    border-radius: unset !important;
  }
}
</style>