<template>
  <section class="campaign-fields w-100">
    <b-overlay :show="pageLoading" rounded="lg">
      <campaign-information :options="options"
                            :form-data="formData"
                            :cache-data="cacheFormData"
                            @calculator-view-guess="onEmitData">
      </campaign-information>
      <product-settings :options="options"
                        :form-data="formData"
                        @calculator-view-guess="onEmitData">
      </product-settings>
      <target-settings :form-data="formData"
                       :options="options"
                       :view-guess="viewGuess"
                       :cache-data="cacheFormData"
                       :view-per-day="viewPerDay"
                       @calculator-view-guess="onEmitData">
      </target-settings>
      <recommend-product-video :options="options"
                               :form-data="formData"
                               :cache-data="cacheFormData"
                               @calculator-view-guess="onEmitData">
      </recommend-product-video>
    </b-overlay>
  </section>
</template>

<script>

import CampaignInformation from "@/components/dashboard/components/pages/campaign/fields/CampaignInformation"
import ProductSettings from "@/components/dashboard/components/pages/campaign/fields/ProductSettings"
import TargetSettings from "@/components/dashboard/components/pages/campaign/fields/TargetSettings"
import RecommendProductVideo from "@/components/dashboard/components/pages/campaign/fields/RecommendProductVideo"
import {mapGetters} from "vuex";


export default {
  components: {
    CampaignInformation,
    ProductSettings,
    TargetSettings,
    RecommendProductVideo
  },
  props: {
    currentData: {
      required: false,
      type: Object
    }
  },
  computed: {
    ...mapGetters(["productBrands", "productCategories", "viewGuess", "viewPerDay", "youtubeCategories"]),
    options() {
      return {
        advertiser_id: [...this.advertiserOptions],
        brand_id: [...this.productBrands],
        category_id: [...this.productCategories],
        youtubeCategories: [...this.youtubeCategories],
        type: [
          {
            id: 0,
            name: this.$i18n.t('campaign.product_types.type_original'),
            class: 'badge badge-primary'
          },
          {
            id: 1,
            name: this.$i18n.t('campaign.product_types.type_shopee'),
            class: 'badge badge-waring'
          },
          {
            id: 2,
            name: this.$i18n.t('campaign.product_types.type_tiki'),
            class: 'badge badge-primary'
          },
        ],
        is_mall_link: [
          {
            id: 0,
            name: this.$i18n.t('campaign.product_types.type_original'),
            class: 'badge badge-primary'
          },
          {
            id: 1,
            name: this.$i18n.t('campaign.product_types.type_shopee'),
            class: 'badge badge-waring'
          },
          {
            id: 2,
            name: this.$i18n.t('campaign.product_types.type_tiki'),
            class: 'badge badge-primary'
          },
        ],
        gender: [
          {
            id: 1,
            name: this.$i18n.t('campaign.gender.all'),
            class: 'badge badge-secondary'
          },
          {
            id: 2,
            name: this.$i18n.t('campaign.gender.male'),
            class: 'badge badge-success'
          },
          {
            id: 3,
            name: this.$i18n.t('campaign.gender.female'),
            class: 'badge badge-primary'
          },
        ],
        countries: [
          {
            id: 1,
            name: this.$i18n.t('campaign.country.american'),
            class: 'badge badge-secondary'
          },
          {
            id: 2,
            name: this.$i18n.t('campaign.country.korean'),
            class: 'badge badge-success'
          },
          {
            id: 3,
            name: this.$i18n.t('campaign.country.vietnam'),
            class: 'badge badge-primary'
          },
        ],
        ages: [
          {
            id: 1,
            name: this.$i18n.t('campaign.ages.0_10'),
            class: 'secondary'
          },
          {
            id: 2,
            name: this.$i18n.t('campaign.ages.10_20'),
            class: 'secondary'
          },
          {
            id: 3,
            name: this.$i18n.t('campaign.ages.20_30'),
            class: 'secondary'
          },
          {
            id: 4,
            name: this.$i18n.t('campaign.ages.30_40'),
            class: 'secondary'
          },
          {
            id: 5,
            name: this.$i18n.t('campaign.ages.40_50'),
            class: 'secondary'
          },
          {
            id: 6,
            name: this.$i18n.t('campaign.ages.other'),
            class: 'secondary'
          },
        ]
      };
    }
  },
  created(){
    this.$store.dispatch('resetCampaign')
    this.$store.dispatch('resetCampaignProduct')
    this.setFormData()
  },
  data() {
    return {
      formData: {},
      cacheFormData: {},
      advertiserOptions: [{id: 1, name: 'Test'}],
      categories: [],
      pageLoading: false
    }
  },
  methods: {
    setFormData() {
      this.formData = this.currentData
      this.cacheFormData = JSON.parse(JSON.stringify(this.currentData))
    },
    setCacheForm(){
      this.cacheFormData = JSON.parse(JSON.stringify(this.formData))
    },
    async getVideoGuess() {
      if(this.formData.started_at === ''
          || this.formData.ended_at === ''
          || ![1,2,3].includes(this.formData.gender)
          || this.formData.countries.length <= 0
          || ![1,2,3,4,5,6].includes(this.formData.ages)
          || this.formData.ages === 6 && this.formData.age_from === ''
          || this.formData.ages === 6 && this.formData.age_to === ''
          || this.formData.youtube_categories.length <= 0
      ){
        return false
      }
      this.pageLoading = true
      await this.$store.dispatch('getViewGuess', {
        started_at: this.formData.started_at,
        ended_at: this.formData.ended_at,
        gender: this.formData.gender,
        countries: this.formData.countries,
        ages: this.formData.ages,
        age_from: this.formData.age_from,
        age_to: this.formData.age_to,
        subscriber: this.formData.subscriber,
        video_view: this.formData.video_view,
        youtube_categories: this.formData.youtube_categories
      })
      this.pageLoading = false
    },
    onEmitData() {
      this.getVideoGuess()
      this.setCacheForm()
    }
  }
}
</script>

<style scoped>

</style>