<template>
  <div class="report-booking-page w-100">
    <title-page :title="$t('report.booking.title')" :icon="'Fire'"></title-page>
    <b-container fluid class="mb-4">
      <b-card no-body class="card-layer">
        <b-card-header header-tag="header" class="p-3 card-layer" role="tab">
          <b-button v-b-toggle.filter-report class="float-right button-violet" variant="info">
            {{ $t('report.booking.report_filter') }}
          </b-button>
        </b-card-header>
        <b-collapse id="filter-report" class="border-top-05" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-row>
              <!--              <b-col cols="12" md="6">-->
              <!--                <label class="label-selector">{{ $t('report.booking.label_select_store') }}</label>-->
              <!--                <multiple-select-->
              <!--                    :multiple="true"-->
              <!--                    v-model="store_id"-->
              <!--                    :options="options.stores"-->
              <!--                    :allow-empty="true"-->
              <!--                    :placeholder="$t('filter.placeholder_option')"-->
              <!--                    open-direction="bottom"-->
              <!--                    label="name"-->
              <!--                    track-by="id"-->
              <!--                    @search-change="value => searchStore(value)"-->
              <!--                    :show-labels="false"-->
              <!--                >-->
              <!--                  <span slot="noResult">{{ $t('filter.no_result') }}</span>-->
              <!--                </multiple-select>-->
              <!--              </b-col>-->
              <b-col cols="12" md="6">
                <label class="label-selector">{{ $t('report.booking.label_select_time') }}</label>
                <div class="w-100">
                  <multiple-select
                      :value="dateType"
                      :options="options.filter"
                      :allow-empty="false"
                      :placeholder="$t('filter.placeholder_option')"
                      open-direction="bottom"
                      label="name"
                      track-by="id"
                      :show-labels="false"
                      @input="object => filterProcess(object)"
                  >
                    <span slot="noResult">{{ $t('filter.no_result') }}</span>
                  </multiple-select>
                  <template v-if="dateType.id === 4">
                    <div class="input-daterange input-group d-flex align-items-center mt-2">
                      <date-picker
                          :range="true"
                          class="form-control"
                          v-model="filter.range"
                          :disabled-date="disableDate"
                          :placeholder="$t('report.filter.option_day')"
                          @change="value => filterDatetime(value)"
                          :class="rangeError !== '' ? 'is-invalid' : ''"
                          valueType="format">
                      </date-picker>
                      <b-form-invalid-feedback>
                        {{ rangeError }}
                      </b-form-invalid-feedback>
                    </div>
                  </template>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-container>
    <div class="report-content mb-4">
      <div class="container-fluid">
        <b-row>
          <b-col cols="12" md="4" class="mb-4">
            <b-overlay :show="isLoadingTotalMessage" rounded="lg">
              <box-total
                  icon="Message"
                  :title="$t('report.booking.total_message')"
                  :value-color="'#FF33FF'"
                  :value="totalMessage.value"
                  :percent="totalMessage.percent"
                  :trend="totalMessage.trend"
                  :percent-title="percentTitle"
              >
              </box-total>
            </b-overlay>
          </b-col>
          <b-col cols="12" md="4" class="mb-4">
            <b-overlay :show="isLoadingTotalCreateOrder" rounded="lg">
              <box-total
                  icon="Pencil"
                  :title="$t('report.booking.total_create_order')"
                  :value-color="'#262626'"
                  :value="totalCreateOrder.value"
                  :percent="totalCreateOrder.percent"
                  :trend="totalCreateOrder.trend"
                  :percent-title="percentTitle"
              >
              </box-total>
            </b-overlay>
          </b-col>
          <b-col cols="12" md="4" class="mb-4">
            <b-overlay :show="isLoadingTotalConfirmOrder" rounded="lg">
              <box-total
                  icon="Save"
                  :title="$t('report.booking.total_confirm_order')"
                  :value-color="'#262626'"
                  :value="totalConfirmOrder.value"
                  :percent="totalConfirmOrder.percent"
                  :trend="totalConfirmOrder.trend"
                  :percent-title="percentTitle"
              >
              </box-total>
            </b-overlay>
          </b-col>
          <b-col cols="12" md="4">
            <b-overlay :show="isLoadingTotalBookmark" rounded="lg">
              <box-total
                  icon="Bookmark"
                  :title="$t('report.booking.total_bookmark')"
                  :value-color="'#262626'"
                  :value="totalBookmark.value"
                  :percent="totalBookmark.percent"
                  :trend="totalBookmark.trend"
                  :percent-title="percentTitle"
              >
              </box-total>
            </b-overlay>
          </b-col>
          <b-col cols="12" md="4">
            <b-overlay :show="isLoadingTotalClick" rounded="lg">
              <box-total
                  icon="Click"
                  :title="$t('report.booking.total_click')"
                  :value-color="'#262626'"
                  :value="totalClick.value"
                  :percent="totalClick.percent"
                  :trend="totalClick.trend"
                  :percent-title="percentTitle"
              >
              </box-total>
            </b-overlay>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-container fluid class="mb-4">
      <b-card class="card-layer" :title="$t('report.booking.brand_awareness_report')">
        <b-row>
          <b-col cols="12" md="6">
            <b-overlay :show="isLoadingChartImpression" rounded="lg">
              <axes-line-chart
                  :chart-impression-data="chartImpressionAndClick.impression"
                  :chart-click-data="chartImpressionAndClick.click"
                  :chart-x-label="chartImpressionAndClick.label"
              >

              </axes-line-chart>
            </b-overlay>
          </b-col>
          <b-col cols="12" md="6">
            <b-overlay :show="isLoadingChartMessage" rounded="lg">
              <area-fill-chart
                  :chart-call-data="chartMessage.chat_with_shop"
                  :chart-x-label="chartMessage.chat_with_shop"
              ></area-fill-chart>
            </b-overlay>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="4">
          <b-card class="card-layer" :title="$t('report.booking.report_impression')">
            <b-overlay :show="isLoadingImpression" rounded="lg">
              <heat-map-chart :chart-data="impressionHeatMap"></heat-map-chart>
            </b-overlay>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card class="card-layer" :title="$t('report.booking.report_click')">
            <b-overlay :show="isLoadingClick" rounded="lg">
              <heat-map-chart :chart-data="clickHeatMap"></heat-map-chart>
            </b-overlay>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card class="card-layer" :title="$t('report.booking.report_message')">
            <b-overlay :show="isLoadingMessage" rounded="lg">
              <heat-map-chart :chart-data="messageHeatMap"></heat-map-chart>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from 'moment'
import {mapGetters} from "vuex";
import BoxTotal from "@/components/dashboard/components/pages/report-booking/BoxTotal.vue";
import AxesLineChart from "@/components/dashboard/components/pages/report-booking/AxesLineChart.vue";
import AreaFillChart from "@/components/dashboard/components/pages/report-booking/AreaFillChart.vue";
import HeatMapChart from "@/components/dashboard/components/pages/report-booking/HeatMapChart.vue";
import TitlePage from "@/components/dashboard/layouts/TitlePage.vue";

export default {
  name: "ReportBookingPage",
  components: {TitlePage, BoxTotal, AreaFillChart, HeatMapChart, AxesLineChart},
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    ...mapGetters(['totalClick', 'totalMessage', 'totalCreateOrder', 'totalConfirmOrder', 'totalBookmark', 'chartImpressionAndClick', 'chartMessage', 'impressionHeatMap', 'clickHeatMap', 'messageHeatMap', 'searchStores']),
    options() {
      return {
        filter: [
          {
            id: 1,
            name: this.$t('report.filter.before_7_day')
          },
          {
            id: 2,
            name: this.$t('report.filter.before_14_day')
          },
          {
            id: 3,
            name: this.$t('report.filter.before_30_day')
          },
          {
            id: 4,
            name: this.$t('report.filter.option_day')
          }
        ],
        stores: [...this.searchStores]
      }
    },
    percentTitle() {
      return this.$tc('report.booking.report_percent_title_day', this.dayFilter)
    }
  },
  watch: {
    store_id: {
      handler(after) {
        this.storeIds = after.map((item) => {
          return item.id
        })
        this.filter.is_reload = true
        this.getTotalClick()
        this.getTotalMessage()
        this.getTotalCreateOrder()
        this.getTotalConfirmOrder()
        this.getTotalBookmark()
        this.getChartImpressionAndClick()
        this.getChartMessage()
        this.getImpression()
        this.getClick()
        this.getCall()
      },
      deep: true
    }
  },
  async mounted() {
    await this.filterStores()
    this.getTotalClick()
    this.getTotalMessage()
    this.getTotalCreateOrder()
    this.getTotalConfirmOrder()
    this.getTotalBookmark()
    this.getChartImpressionAndClick()
    this.getChartMessage()
    this.getImpression()
    this.getClick()
    this.getCall()
  },
  data() {
    return {
      isLoadingTotalClick: true,
      isLoadingTotalMessage: true,
      isLoadingTotalCreateOrder: true,
      isLoadingTotalConfirmOrder: true,
      isLoadingTotalBookmark: true,
      isLoadingChartImpression: true,
      isLoadingChartMessage: true,
      isLoadingImpression: true,
      isLoadingClick: true,
      isLoadingMessage: true,
      dateType: {
        id: 1,
        name: this.$t('report.filter.before_7_day')
      },
      store_id: '',
      storeIds: [-1],
      dayFilter: 7,
      filter: {
        appId: 1,
        range: '',
        from: moment().subtract(6, "days").format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        is_reload: false
      },
      rangeError: ''
    }
  },
  methods: {
    disableDate(date) {
      return date < new Date(2023, 4, 15);
    },

    async searchStore(value) {
      const vm = this
      clearTimeout(vm.typingTimer);
      vm.typingTimer = setTimeout(async () => {
        await vm.filterStores(value)
      }, 500);
    },

    async filterStores(value) {
      this.searchStoreLoading = true
      await this.$store.dispatch('searchStore', value)
      this.storeIds = Array.from(new Set(this.searchStores.map(obj => obj['id'])))
      if (this.storeIds.length <= 0) {
        this.storeIds = [-1]
      }
      this.searchStoreLoading = false
    },

    filterProcess(object) {
      this.dateType = object
      let dateRange = 0
      if (object.id === 1) {
        dateRange = 6
      } else if (object.id === 2) {
        dateRange = 13
      } else if (object.id === 3) {
        dateRange = 29
      } else {
        this.filter.from = ''
        this.filter.to = ''
        return true;
      }
      this.dayFilter = dateRange + 1
      this.filter.from = moment().subtract(dateRange, "days").format('YYYY-MM-DD')
      this.filter.to = moment().format('YYYY-MM-DD')
      this.filter.is_reload = true
      this.getTotalClick()
      this.getTotalMessage()
      this.getTotalCreateOrder()
      this.getTotalConfirmOrder()
      this.getTotalBookmark()
      this.getChartImpressionAndClick()
      this.getChartMessage()
    },

    filterDatetime(value) {
      const from = value[0];
      const to = value[1];
      const diff = moment(to).diff(moment(from), 'days');
      // if (diff < 7) {
      //   this.rangeError = this.$t('report.booking.report_less_than_7_day')
      //   return false
      // }
      if (diff > 30) {
        this.rangeError = this.$t('report.booking.report_bigger_than_30_day')
        return false
      }
      this.dayFilter = diff + 1
      this.rangeError = ''
      this.filter.from = value[0]
      this.filter.to = value[1]
      this.filter.is_reload = true
      this.getTotalClick()
      this.getTotalMessage()
      this.getTotalCreateOrder()
      this.getTotalConfirmOrder()
      this.getTotalBookmark()
      this.getChartImpressionAndClick()
      this.getChartMessage()
    },

    async getTotalClick() {
      this.isLoadingTotalClick = true
      await this.$store.dispatch('getTotalClick', {...this.filter, storeId: this.storeIds, type: 'click'})
      this.isLoadingTotalClick = false
      this.filter.is_reload = false
    },

    async getTotalMessage() {
      this.isLoadingTotalMessage = true
      await this.$store.dispatch('getTotalMessage', {...this.filter, storeId: this.storeIds, type: 'chat_with_shop'})
      this.isLoadingTotalMessage = false
      this.filter.is_reload = false
    },

    async getTotalCreateOrder() {
      this.isLoadingTotalCreateOrder = true
      await this.$store.dispatch('getTotalCreateOrder', {...this.filter, storeId: this.storeIds, type: 'send_order'})
      this.isLoadingTotalCreateOrder = false
      this.filter.is_reload = false
    },

    async getTotalConfirmOrder() {
      this.isLoadingTotalConfirmOrder = true
      await this.$store.dispatch('getTotalConfirmOrder', {...this.filter, storeId: this.storeIds, type: 'confirm_order'})
      this.isLoadingTotalConfirmOrder = false
      this.filter.is_reload = false
    },

    async getTotalBookmark() {
      this.isLoadingTotalBookmark = true
      await this.$store.dispatch('getTotalBookmark', {...this.filter, storeId: this.storeIds, type: 'bookmark'})
      this.isLoadingTotalBookmark = false
      this.filter.is_reload = false
    },

    async getChartImpressionAndClick() {
      this.isLoadingChartImpression = true
      await this.$store.dispatch('getChartImpressionAndClick', {
        ...this.filter,
        storeId: this.storeIds,
        types: 'impression,click'
      })
      this.isLoadingChartImpression = false
      this.filter.is_reload = false
    },

    async getChartMessage() {
      this.isLoadingChartMessage = true
      await this.$store.dispatch('getChartMessage', {...this.filter, storeId: this.storeIds, types: 'chat_with_shop'})
      this.isLoadingChartMessage = false
      this.filter.is_reload = false
    },

    async getImpression() {
      this.isLoadingImpression = true
      await this.$store.dispatch('getImpression', {
        appId: 1,
        storeId: this.storeIds,
        is_reload: this.filter.is_reload,
        type: 'impression'
      })
      this.isLoadingImpression = false
      this.filter.is_reload = false
    },

    async getClick() {
      this.isLoadingClick = true
      await this.$store.dispatch('getClick', {
        appId: 1,
        storeId: this.storeIds,
        is_reload: this.filter.is_reload,
        type: 'click'
      })
      this.isLoadingClick = false
      this.filter.is_reload = false
    },

    async getCall() {
      this.isLoadingMessage = true
      await this.$store.dispatch('getMessage', {
        appId: 1,
        storeId: this.storeIds,
        is_reload: this.filter.is_reload,
        type: 'chat_with_shop'
      })
      this.isLoadingMessage = false
      this.filter.is_reload = false
    },
  }
}
</script>

<style lang="scss">

.multiselect__tag {
  background: #0b95ef !important;
}

.multiselect__tag-icon:hover {
  background: #0b95ef !important;
}
</style>