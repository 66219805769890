<template>
    <div class="campaign-create-page w-100">
        <section class="content-header">
            <div class="container-fluid" style="padding: 1rem 1rem 0 1rem">
                <div class="row mb-2">
                    <b-col :cols="isMobile ? 12 : 6">
                        <template v-if="isMobile">
                            <h3>{{ $t('campaign.create.title') }}</h3>
                        </template>
                        <template v-else>
                            <h1>{{ $t('campaign.create.title') }}</h1>
                        </template>
                    </b-col>
                </div>
                <div class="row mb-2">
                    <breadcrumb-layout></breadcrumb-layout>
                </div>
            </div>
        </section>
        <b-form data-vv-scope="campaign_validate" style="padding: 1rem 1rem 0 1rem">
            <b-overlay :show="isSubmit" rounded="lg">
                <campaign-fields :current-data="baseData"></campaign-fields>
            </b-overlay>
        </b-form>
        <div class="p-3">
            <b-card>
                <b-row>
                    <b-col cols="12">
                        <b-button @click="submitCreateCampaign" :disabled="isSubmit"
                                  :style="{cursor: isSubmit ? 'no-drop' : 'pointer'}" variant="primary mr-4">
                            <template v-if="isSubmit">
                                <b-spinner small></b-spinner>
                            </template>
                            <template v-else>
                                {{ $t('campaign.save') }}
                            </template>
                        </b-button>
                        <b-button variant="outline-primary">{{ $t('campaign.cancel') }}</b-button>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </div>
</template>

<script>
import BreadcrumbLayout from "@/components/dashboard/layouts/BreadcrumbLayout"
import CampaignFields from "@/components/dashboard/components/pages/campaign/CampaignFields"

export default {
    components: {
        BreadcrumbLayout,
        CampaignFields
    },
    computed: {
        isMobile() {
            return this.$device.mobile
        },
    },
    mounted() {
        this.$validator.reset()
        this.loading()
    },
    data() {
        return {
            baseData: {
                name: '',
                status: 0,
                advertiser: '',
                brand: '',
                banner: '',
                budget: '',
                keyword: [],
                started_at: this.getToDay(),
                ended_at: this.getToDay(),
                product_brand: '',
                product_category: '',
                product_keyword: [],
                product_from_price: '',
                product_to_price: '',
                product_types: [],
                product_checkbox: [],
                gender: 1,
                countries: [],
                ages: '',
                age_from: '',
                age_to: '',
                interests: [],
                view_guess: [],
                view_per_day: [],
                subscriber: [10000, 30000],
                video_view: [10000, 30000],
                youtube_categories: []
            },
            isSubmit: false
        }
    },
    methods: {
        getToDay() {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();
            return yyyy + '-' + mm + '-' + dd;
        },

        async loading() {
            await this.$store.dispatch('getProductBrands')
            await this.$store.dispatch('getProductCategories')
            await this.$store.dispatch('getYoutubeCategories')
        },

        async submitCreateCampaign() {
            const valid = await this.$validator.validate('campaign_validate.*')
            if (!valid) {
                return false
            }
            const vm = this
            const description = this.$t('campaign.create.confirm_description')
            this.$bvModal.msgBoxConfirm(description, {
                title: this.$t('campaign.create.confirm'),
                size: 'sm',
                buttonSize: 'sm',
                okTitle: this.$t('campaign.create.confirm_save'),
                cancelTitle: this.$t('campaign.create.confirm_cancel'),
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0 message-button-confirm'
            })
                .then(value => {
                    if (!value) {
                        return false
                    }
                    vm.createCampaign()
                })
        },

        async createCampaign() {
            this.isSubmit = true
            let formData = new FormData()
            Object.keys(this.baseData).forEach((key) => {
                if (['advertiser', 'brand', 'product_brand', 'product_category'].includes(key)) {
                    formData.append(key, this.baseData[key]['id'] || 0)
                } else {
                    formData.append(key, this.baseData[key])
                }
            })
            const res = await this.$store.dispatch('createCampaign', formData)
            if (res.data.status === 200) {
                this.$root.$emit('toast-event', {
                    description: this.$t('campaign.create.confirm_success_description'),
                    title: this.$t('campaign.create.confirm_success_title'),
                    variant: 'success',
                })
                await this.$router.push({name: 'Campaign'})
            } else {
                this.$root.$emit('toast-event', {
                    description: this.$t('campaign.create.confirm_fail_description'),
                    title: this.$t('campaign.create.confirm_fail_title'),
                    variant: 'danger',
                })
            }
            this.isSubmit = false
        }
    }
}
</script>

<style scoped>

</style>