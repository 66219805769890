<template>
  <div class="campaign-create-page w-100">
    <title-page :title="$t('booking.product.update.title')" :icon="'WomenClothes'"></title-page>
    <b-form data-vv-scope="booking_product_validate">
      <div class="container-fluid">
        <b-overlay :show="isSubmit || isLoading" rounded="lg">
          <template v-if="!isLoading">
            <booking-product-fields :current-data="baseData"
                                    @validate-discount="validateDiscount"></booking-product-fields>
          </template>
        </b-overlay>
      </div>
    </b-form>
    <div class="mt-2 p-3">
      <b-card class="card-layer">
        <b-row>
          <template v-if="!isLoading">
            <product-preview :base-data="baseData"></product-preview>
          </template>
          <b-col cols="6" class="text-right">
            <b-button class="button-violet mr-4" v-on:click="submitUpdateProduct" :disabled="isSubmit"
                      :style="{cursor: isSubmit ? 'no-drop' : 'pointer'}">
              <template v-if="isSubmit">
                <b-spinner small></b-spinner>
              </template>
              <template v-else>
                {{ $t('common.save') }}
              </template>
            </b-button>
            <b-button class="button-violet-outline" v-on:click="cancelUpdate()">{{ $t('common.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TitlePage from "@/components/dashboard/layouts/TitlePage.vue";
import ProductPreview from "@/components/dashboard/components/pages/booking/BookingProductPreview.vue";
import BookingProductFields from "@/components/dashboard/components/pages/booking/BookingProductFields.vue";

export default {
  components: {
    TitlePage,
    ProductPreview,
    BookingProductFields
  },
  computed: {
    ...mapGetters(["bookingProduct"]),
    isMobile() {
      return this.$device.mobile
    },
  },
  async mounted() {
    await this.loading()
    this.$root.$emit('reset-image-upload')
    await this.$validator.reset()
  },
  data() {
    return {
      baseData: {},
      isSubmit: false,
      isLoading: true,
      isDiscountValid: true
    }
  },
  methods: {
    async loading() {
      const vm = this
      await this.$store.dispatch('findStoreProduct', this.$route.params.id)
      this.$nextTick(async () => {
        vm.baseData = vm.bookingProduct
        vm.baseData.id = vm.$route.params.id
        await this.$store.dispatch('getStoreCategories', {
          parent_category_id: 0,
          is_booking: vm.bookingProduct.is_booking
        })
        await vm.$store.dispatch('getStoreSubCategories', {parent_category_id: vm.bookingProduct.category_id})
        vm.isLoading = false
      })
    },

    validateDiscount(validates) {
      for (const key of Object.keys(validates)) {
        if (!validates[key]) {
          this.isDiscountValid = false
        }
      }
    },

    async cancelUpdate() {
      await this.$router.push({name: 'BookingProducts'})
    },

    async submitUpdateProduct() {
      const valid = await this.$validator.validate('booking_product_validate.*')
      if (!valid) {
        return false
      }
      if (!this.isDiscountValid) {
        return false
      }
      const vm = this
      const description = this.$t('booking.product.update.confirm_description')
      this.$bvModal.msgBoxConfirm(description, {
        title: this.$t('booking.product.update.confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okTitle: this.$t('booking.product.update.confirm_save'),
        cancelTitle: this.$t('booking.product.update.confirm_cancel'),
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0 message-button-confirm'
      })
      .then(value => {
        if (!value) {
          return false
        }
        vm.updateProduct()
      })
    },

    async updateProduct() {
      const vm = this
      this.isSubmit = true
      Object.keys(this.baseData).forEach((key) => {
        if (['store_id', 'status', 'category_id', 'sub_category_id', 'is_booking'].includes(key)) {
          vm.baseData[key] = vm.baseData[key]['id'] || 0
        }
      })
      if (this.baseData.sale_price === '') {
        this.baseData.sale_price = 0
      }
      const res = await this.$store.dispatch('updateStoreProduct', this.baseData)
      if (res.data.status === 200) {
        this.$root.$emit('toast-event', {
          description: this.$t('booking.product.update.confirm_success_description'),
          title: this.$t('booking.product.update.confirm_success_title'),
          variant: 'success',
        })
        await this.$router.push({name: 'BookingProducts'})
      } else {
        this.$root.$emit('toast-event', {
          description: this.$t('booking.product.update.confirm_fail_description'),
          title: this.$t('booking.product.update.confirm_fail_title'),
          variant: 'danger',
        })
      }
      this.isSubmit = false
    }
  }
}
</script>

<style scoped>

</style>