import {BaseApi} from "@/lib/base-api";

const apiUrl = process.env.VUE_APP_API_URL + 'products';

export const ProductServiceAPI = {

    getProducts(params) {
        return BaseApi.request('querySerialize', apiUrl, params);
    },

    getProductByTypes(params) {
        return BaseApi.request('querySerialize', apiUrl + '/by-types', params);
    },

    getProductTypes(params) {
        return BaseApi.request('querySerialize', apiUrl + '/types', params);
    },

    getBrands(params) {
        return BaseApi.request('querySerialize', apiUrl + '/brands', params);
    },

    getCategories(params) {
        return BaseApi.request('querySerialize', apiUrl + '/categories', params);
    },

    getStoreProduct(params) {
        return BaseApi.request('querySerialize', apiUrl + '/stores', params);
    },

    findStoreProduct(id) {
        return BaseApi.request('querySerialize', apiUrl + '/stores/' + id, {});
    },

    createStoreProduct(params) {
        return BaseApi.request('post', apiUrl + '/create-store-product', params);
    },

    updateStoreProduct(params) {
        return BaseApi.request('post', apiUrl + '/update-store-product/' + params.id, params);
    },
    
    updateProductImage(params) {
        return BaseApi.request('post', apiUrl + '/update-product-image', params);
    },
    
    deleteProductImage(params) {
        return BaseApi.request('post', apiUrl + '/delete-product-image', params);
    },
}
