var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"google-map-section map-component"},[(_vm.isSearch)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('div',{staticClass:"search-map map-component-search"},[_c('div',{staticClass:"d-inline-flex w-100 align-items-center"},[_c('b-input',{staticClass:"form-control mr-2",attrs:{"placeholder":_vm.$t('common.google_search_title')},on:{"keydown":function($event){return _vm.keyDownSearch($event)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('b-button',{staticClass:"button-violet",on:{"click":_vm.searchLocation}},[(_vm.isLoading)?[_c('b-spinner',{attrs:{"small":"","variant":"light"}})]:[_vm._v(" "+_vm._s(_vm.$t('common.google_button_search'))+" ")]],2)],1),_c('div',{staticClass:"search-map-result",class:{open: _vm.locationByKeyword.length > 0}},_vm._l((_vm.locationByKeyword),function(item,index){return _c('div',{key:'search-map-item-' + index,staticClass:"search-map-result-item",on:{"click":function($event){return _vm.changeCenter(item)}}},[_c('h6',[_vm._v(_vm._s(item.address))])])}),0)])])],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('gmap-map',{style:({width: '100%', height: _vm.height}),attrs:{"center":_vm.currentCenter,"zoom":15,"map-type-id":"terrain","options":{
                 zoomControl: true,
                 mapTypeControl: false,
                 scaleControl: false,
                 streetViewControl: false,
                 rotateControl: false,
                 fullscreenControl: true,
                 disableDefaultUi: false
              }}},[_c('gmap-marker',{key:'google-market-1',attrs:{"position":_vm.currentCenter,"draggable":true},on:{"drag":_vm.updateCoordinates}},[_c('gmap-info-window',{attrs:{"opened":this.isTooltip}},[_c('b',{staticStyle:{"white-space":"nowrap","font-weight":"700","color":"#323232","line-height":"17px","font-size":"14px"}},[_vm._v(_vm._s(_vm.tooltipText))])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }