<template>
  <b-col class="box-message-chat p-0 w-100" :class="{'d-none': isMobile && !isShowBoxChat}">
    <div class="w-100">

      <b-col cols="12" class="chat-header d-flex justify-content-start align-items-center">
        <template v-if="isMobile">
          <font-awesome-icon class="mr-3" v-on:click="backToUsers" icon="fa-solid fa-chevron-left"/>
        </template>
        <div class="d-flex align-items-center w-100 position-relative"
             :style="{opacity: Object.keys(user).length <= 0 ? '0' : '1'}">
          <template v-if="user.profilePicture && user.profilePicture !== ''">
            <b-avatar
                class="mr-3"
                variant="info"
                :badge="false"
                :badge-top="false"
                badge-variant="success"
                badge-offset="-2px"
                :src="user.profilePicture"
            >
            </b-avatar>
          </template>
          <template v-else>
            <b-avatar
                :variant="colors[user.randomColor]"
                :text="getTextShowing(user.name)"
                :badge="false"
                :badge-top="false"
                badge-variant="success"
                badge-offset="-2px"
                class="mr-3"
            >
            </b-avatar>
          </template>
          <span class="mr-auto header-user-name">{{ user.name | truncateText(isMobile ? 15 : 100, '...') }}</span>

          <template v-if="!isMobile">
            <multiple-select
                style="max-width: 400px"
                name="product_id"
                @close="handleSelectedProduct"
                v-model="selectedProduct"
                :options="options.products"
                :allow-empty="true"
                :placeholder="$t('chat.filter_products')"
                open-direction="bottom"
                label="name"
                :loading="searchProductLoading"
                @search-change="value => filterProducts(value)"
                track-by="id"
                :show-labels="false"
            >
              <span slot="noResult">{{ $t('chat.filter_no_result') }}</span>
              <span slot="noOptions">{{ $t('chat.filter_no_options') }}</span>
            </multiple-select>
          </template>
          <template v-else>
            <b-dropdown class="dropdown-search-product" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <font-awesome-icon icon="fa-solid fa-bars"/>
              </template>
              <b-dropdown-text style="width: 240px;">
                <multiple-select
                    style="max-width: 400px"
                    name="product_id"
                    @close="handleSelectedProduct"
                    v-model="selectedProduct"
                    :options="options.products"
                    :allow-empty="true"
                    :placeholder="$t('chat.filter_products')"
                    open-direction="bottom"
                    label="name"
                    :loading="searchProductLoading"
                    @search-change="value => filterProducts(value)"
                    track-by="id"
                    :show-labels="false"
                >
                  <span slot="noResult">{{ $t('chat.filter_no_result') }}</span>
                  <span slot="noOptions">{{ $t('chat.filter_no_options') }}</span>
                </multiple-select>
              </b-dropdown-text>
            </b-dropdown>
          </template>

          <div id="show-product-discounts" class="preview-choose-discounts message-item-product" style="width: 400px;">
            <b-col cols="12">
              <b-row>
                <b-card no-body class="overflow-hidden w-100 card-layer">
                  <div class="d-inline-flex border-bottom-1">
                    <div class="product-image">
                      <b-card-img
                          style="max-width: 110px; max-height: 110px; min-width: 110px; min-height: 110px;"
                          v-on:load="imageLoaded"
                          :src="Array.isArray(sendingProduct.image) ? sendingProduct.image[0] : sendingProduct.image"
                          alt="Image"
                          class="rounded-0"></b-card-img>
                    </div>
                    <div class="info-message-product">
                      <div class="w-100">
                        <b-card-text class="message-product-title">{{ sendingProduct.name }}</b-card-text>
                        <div v-if="sendingProduct.discount_lines && sendingProduct.discount_lines.length > 0"
                             class="product-preview-discount-name">
                          {{ sendingProduct.discount_lines[0].name }}
                        </div>
                        <div class="product-preview-price">
                          <template
                              v-if="sendingProduct.discount_lines && sendingProduct.discount_lines.length > 0 && sendingProduct.discount_lines[0].type !== 'combo'">
                            <div class="discount-display-price">
                              {{ sendingProduct.discount_lines[0].price | formatPrice('đ') }}
                            </div>
                            <div class="real-price">
                              {{ sendingProduct.price_real | formatPrice('đ') }}
                            </div>
                            <div class="discount-rate">
                              -{{
                                sendingProduct.discount_lines[0].rate
                              }}
                            </div>
                          </template>
                          <template v-else>
                            <div class="discount-display-price">
                              {{ sendingProduct.price_real | formatPrice('đ') }}
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-discounts border-bottom-1"
                       v-if="selectedProduct.discount_lines && selectedProduct.discount_lines.length > 0">
                    <b-form-group class="discounts">
                      <b-form-radio class="discount-preview" :key="'discount-selected--1'"
                                    @change="handlePreviewDiscounts"
                                    v-model="discountSelected" name="discount" value="-1">
                        <span class="w-100 max-1-line">{{ $t('chat.none_select') }}</span>
                      </b-form-radio>
                      <template v-for="(discount, index) of selectedProduct.discount_lines">
                        <b-form-radio class="discount-preview" :class="discount.type !== 'combo' ? 'show-price' : ''"
                                      :key="'discount-selected-' + index"
                                      @change="handlePreviewDiscounts"
                                      v-model="discountSelected" name="discount" :value="discount.id">
                          <span class="w-100 max-1-line">{{ discount.name }}</span>
                          <br>
                          <span class="display-price mr-2 mt-1 mb-1"
                                v-if="discount.type !== 'combo'">{{ discount.price_view }}</span><span
                            v-if="discount.type !== 'combo'"
                            class="hidden-price mt-1">{{ selectedProduct.price_format }}</span>
                        </b-form-radio>
                      </template>
                    </b-form-group>
                  </div>
                  <div class="d-inline-flex">
                    <b-button class="button-violet" v-on:click="submitSendProduct">{{ $t('common.save') }}</b-button>
                    <b-button class="button-violet-outline ml-2" v-on:click="cancelSendProduct">{{
                        $t('common.cancel')
                      }}
                    </b-button>
                  </div>
                </b-card>
              </b-row>
            </b-col>
          </div>
        </div>
      </b-col>
      <b-col cols="12" class="p-0">
        <div class="w-100 h-100 message draggable drag-off" :style="{background: 'rgba(233,233,233,0.6)'}"
             @dragover="dragover" @drop="drop">
          <div class="draggable-backdrop" v-if="!isMobile">
            <div class="draggable-spare">
              <div class="draggable-content">
                <span>{{ $t('chat.text_drag_file') }}</span>
                <font-awesome-icon class="icon-send" icon="fa-regular fa-image"/>
              </div>
            </div>
          </div>
          <div id="chatting-scrollable" class="chatting-scrollable chat-message-scrollable"
               v-on:scroll="scrollLoadMore"
               :style="{position: 'relative', height: heightBox  + 'px', width: '100%', overflowY: 'auto', padding: '10px'}">
            <template v-for="(message, index) in messageByUser">
              <div class="chat-message" :class="message.sender === currentUser.chatting_id ? 'current' : 'guest'"
                   :key="message._id + '-' + index">
                <div class="message-item" :class="'message-item-'+ message.text.type">
                  <template v-if="message.sender !== currentUser.chatting_id">
                    <template v-if="user.profilePicture && user.profilePicture !== ''">
                      <b-avatar
                          class="mr-2"
                          variant="info"
                          badge-offset="-2px"
                          :src="user.profilePicture"
                      >
                      </b-avatar>
                    </template>
                    <template v-else>
                      <b-avatar
                          :variant="colors[user.randomColor]"
                          :text="getTextShowing(user.email)"
                          badge-offset="-2px"
                          class="mr-2"
                      >
                      </b-avatar>
                    </template>
                  </template>
                  <div class="message-text" :style="{padding: message.isImage ? '2px' : '10px'}">
                    <template v-if="message.isImage">
                      <div class="text-center flexing-responsive-images"
                           :class="message.baseImages.length === 1 ? 'is-main' : ''">
                        <template v-for="(images, index) in message.text.value">
                          <div :key="'image-message-column-' + index" class="column">
                            <template v-for="(image, i) in images">
                              <b-overlay :key="'image-message-preview-' + i"
                                         :show="isLoadingUpload[message.text.hash[index][i] || 0] && message.isPreview"
                                         rounded="sm">
                                <b-img v-on:load="imageLoaded" rounded class="cursor-pointer" :src="image" fluid
                                       alt="Vodaplay"
                                       v-on:click="showWhenClickImage(image)"></b-img>
                              </b-overlay>
                            </template>
                          </div>
                        </template>
                      </div>
                    </template>
                    <template v-else-if="message.text.type === 'product'">
                      <b-card no-body class="overflow-hidden" style="max-width: 600px;">
                        <div class="d-inline-flex">
                          <div class="product-image">
                            <router-link :to="{name: 'BookingProductUpdate', params: {id: message.text.value.id}}">
                              <b-card-img
                                  style="max-width: 110px; max-height: 110px; min-width: 110px; min-height: 110px;"
                                  v-on:load="imageLoaded"
                                  :src="Array.isArray(message.text.value.image) ? message.text.value.image[0] : message.text.value.image"
                                  alt="Image"
                                  class="rounded-0"></b-card-img>
                            </router-link>
                          </div>
                          <div class="info-message-product">
                            <router-link :to="{name: 'BookingProductUpdate', params: {id: message.text.value.id}}">
                              <div class="w-100">
                                <b-card-text class="message-product-title">{{ message.text.value.name }}</b-card-text>
                                <div v-if="message.text.value.discounts && message.text.value.discounts.length > 0"
                                     class="product-preview-discount-name">
                                  {{ message.text.value.discounts[0].name }}
                                </div>
                                <div class="product-preview-price">
                                  <template
                                      v-if="message.text.value.discounts && message.text.value.discounts.length > 0 && message.text.value.discounts[0].type !== 'combo'">
                                    <div class="discount-display-price">
                                      {{ message.text.value.discounts[0].price | formatPrice('đ') }}
                                    </div>
                                    <div class="real-price">
                                      {{ message.text.value.price_real | formatPrice('đ') }}
                                    </div>
                                    <div class="discount-rate">
                                      -{{
                                        message.text.value.discounts[0].rate
                                      }}
                                    </div>
                                  </template>
                                  <template v-else>
                                    <div class="discount-display-price">
                                      {{ message.text.value.price_real | formatPrice('đ') }}
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </router-link>
                          </div>
                        </div>
                      </b-card>
                    </template>
                    <template v-else-if="message.text.type === 'order'">
                      <template v-if="message.sender === currentUser.chatting_id">
                        <h5>{{ $t('chat.your_order') }}</h5>
                      </template>
                      <template v-else>
                        <h5>{{ $t('chat.client_order') }}</h5>
                      </template>
                      <div class="table-hover table-responsive table-custom mb-0">
                        <table class="table mt-2">
                          <tr>
                            <td class="pl-0 text-left">
                              <label class="mb-0">{{ $t('booking.product.fields.name') }}</label>
                            </td>
                            <td class="text-break break-space" style="line-height: 20px">
                              {{ message.text.value.name }}
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-0 text-left">
                              <label class="mb-0">{{ $t('booking.product.fields.price') }}</label>
                            </td>
                            <td>
                              {{ message.text.value.price | formatPrice('đ') }}
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-0 text-left">
                              <label class="mb-0">{{ $t('booking.product.fields.applicable_date') }}</label>
                            </td>
                            <td>
                              {{ message.text.value.applicable_date }}
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-0 text-left">
                              <label class="mb-0">{{ $t('chat.note') }}</label>
                            </td>
                            <td>
                              <p class="mb-0 text-break break-space">
                                {{ message.text.value.note }}
                              </p>
                            </td>
                          </tr>
                          <template v-if="message.sender === currentUser.chatting_id">
                            <tr>
                              <td class="pl-0 text-left">
                                <label class="mb-0">{{ $t('booking.product.fields.status') }}</label>
                              </td>
                              <td>
                                <template v-for="(status, sIndex) in options.status">
                                  <template v-if="status.id == message.text.value.confirm">
                                  <span :key="index + '-message-status-' + sIndex">
                                  {{ status.name }}
                                </span>
                                  </template>
                                </template>
                              </td>
                            </tr>
                          </template>
                          <template
                              v-if="message.sender !== currentUser.chatting_id && message.text.value.confirm != 1">
                            <tr>
                              <td class="pl-0 text-left" colspan="2">
                                <b-button variant="primary"
                                          :class="isMobile ? 'float-left' : ''"
                                          v-on:click="showConfirmOrder(message._id || message.text.messageId, message.text.value)">
                                  {{ $t('chat.confirm_order') }}
                                </b-button>
                              </td>
                            </tr>
                          </template>
                        </table>
                      </div>
                    </template>
                    <template v-else>
                      <span style="white-space: pre-line" v-html="linkify(message.text.value)"></span>
                    </template>
                    <div class="arrow-message"></div>
                  </div>
                </div>
              </div>
            </template>

            <div id="chat-typing" class="typing d-none">
              <img src="@/assets/img/typing-message.gif" alt="Vodaplay">
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="position-relative chatting-scrollable chat-footer d-inline-flex align-items-center" cols="12"
             :style="{opacity: Object.keys(user).length <= 0 ? '0' : '1', pointerEvents: Object.keys(user).length <= 0 ? 'none' : 'auto'}">

        <div class="footer-chat-content w-100">
          <Picker id="emoji-picker"
                  :style="{maxWidth: isMobile ? '288px' : ''}"
                  v-click-outside="clickOutsideEmoji"
                  :class="showEmoji ? '' : 'd-none'"
                  :data="emojiIndex"
                  set="twitter"
                  @select="insertEmoji"
                  :showPreview="false"
                  :i18n="emojiI18n"
          />

          <svg class="icon-upload-image" v-on:click="showPreviewImage" width="18" height="18" viewBox="0 0 18 18"
               fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5"
                  d="M16.5812 8.48471L9.21477 15.8511C8.31233 16.7536 7.08835 17.2606 5.8121 17.2606C4.53585 17.2606 3.31188 16.7536 2.40943 15.8511C1.50699 14.9487 1 13.7247 1 12.4485C1 11.1722 1.50699 9.94825 2.40943 9.04581L9.77587 1.67937C10.3775 1.07774 11.1935 0.739746 12.0443 0.739746C12.8952 0.739746 13.7111 1.07774 14.3128 1.67937C14.9144 2.281 15.2524 3.09698 15.2524 3.94781C15.2524 4.79865 14.9144 5.61463 14.3128 6.21626L6.93831 13.5827C6.63749 13.8835 6.2295 14.0525 5.80409 14.0525C5.37867 14.0525 4.97068 13.8835 4.66986 13.5827C4.36905 13.2819 4.20005 12.8739 4.20005 12.4485C4.20005 12.0231 4.36905 11.6151 4.66986 11.3143L11.4752 4.51693"
                  stroke="#090909" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <svg class="line-break-chat" width="2" height="20" viewBox="0 0 2 20" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <line opacity="0.5" x1="1.08118" y1="0.5" x2="1.08118" y2="19.5" stroke="#090909" stroke-linecap="round"/>
          </svg>

          <svg ref="open-emoji-picker"
               class="sticker-open-emoji cursor-pointer"
               v-on:click="showEmojiHandle"
               width="20"
               height="20"
               viewBox="0 0 20 20"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
              <path
                  d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                  stroke="#090909" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                  d="M6.40015 11.7998C6.40015 11.7998 7.75015 13.5998 10.0001 13.5998C12.2501 13.5998 13.6001 11.7998 13.6001 11.7998"
                  stroke="#090909" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.30005 7.2998H7.30905" stroke="#090909" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.7 7.2998H12.709" stroke="#090909" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>


          <b-textarea :placeholder="'Type a Message...'" class="input-chat-message textarea-center no-scroll-css"
                      ref="input-chat-message"
                      v-model="inputText"
                      v-on:paste="onPaste"
                      v-on:keydown="actionSendMessage"
          ></b-textarea>


          <b-popover
              id="popover-chat-options"
              target="popover-reactive-2"
              triggers="click"
              placement="top"
              ref="popover"
          >
            <div class="popover-chat-options">
              <b-list-group flush>
                <b-list-group-item class="cursor-pointer item-popover" v-on:click="showPreviewImage">
                  <svg class="mr-1" width="20" height="20" viewBox="0 0 20 20" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.15001 15.8419L2.13334 15.8586C1.90834 15.3669 1.76668 14.8086 1.70834 14.1919C1.76668 14.8002 1.92501 15.3502 2.15001 15.8419Z"
                        fill="#090909"/>
                    <path
                        d="M7.5 8.64977C8.59536 8.64977 9.48333 7.7618 9.48333 6.66644C9.48333 5.57107 8.59536 4.68311 7.5 4.68311C6.40463 4.68311 5.51666 5.57107 5.51666 6.66644C5.51666 7.7618 6.40463 8.64977 7.5 8.64977Z"
                        fill="#090909"/>
                    <path
                        d="M13.4917 1.6665H6.50832C3.47499 1.6665 1.66666 3.47484 1.66666 6.50817V13.4915C1.66666 14.3998 1.82499 15.1915 2.13332 15.8582C2.84999 17.4415 4.38332 18.3332 6.50832 18.3332H13.4917C16.525 18.3332 18.3333 16.5248 18.3333 13.4915V11.5832V6.50817C18.3333 3.47484 16.525 1.6665 13.4917 1.6665ZM16.975 10.4165C16.325 9.85817 15.275 9.85817 14.625 10.4165L11.1583 13.3915C10.5083 13.9498 9.45832 13.9498 8.80832 13.3915L8.52499 13.1582C7.93332 12.6415 6.99166 12.5915 6.32499 13.0415L3.20832 15.1332C3.02499 14.6665 2.91666 14.1248 2.91666 13.4915V6.50817C2.91666 4.15817 4.15832 2.9165 6.50832 2.9165H13.4917C15.8417 2.9165 17.0833 4.15817 17.0833 6.50817V10.5082L16.975 10.4165Z"
                        fill="#090909"/>
                  </svg>

                  {{ $t('chat.upload_image') }}
                </b-list-group-item>
                <b-list-group-item class="cursor-pointer item-popover" v-on:click="createOrder">
                  <svg class="mr-1" width="20" height="20" viewBox="0 0 20 20" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.29166 2.9165V1.6665C7.29166 1.32484 7.00832 1.0415 6.66666 1.0415C6.32499 1.0415 6.04166 1.32484 6.04166 1.6665V2.9665C6.24999 2.9415 6.44166 2.9165 6.66666 2.9165H7.29166Z"
                        fill="#090909"/>
                    <path
                        d="M13.9583 2.9665V1.6665C13.9583 1.32484 13.675 1.0415 13.3333 1.0415C12.9917 1.0415 12.7083 1.32484 12.7083 1.6665V2.9165H13.3333C13.5583 2.9165 13.75 2.9415 13.9583 2.9665Z"
                        fill="#090909"/>
                    <path
                        d="M13.9583 2.9665V4.1665C13.9583 4.50817 13.675 4.7915 13.3333 4.7915C12.9917 4.7915 12.7083 4.50817 12.7083 4.1665V2.9165H7.29167V4.1665C7.29167 4.50817 7.00833 4.7915 6.66667 4.7915C6.325 4.7915 6.04167 4.50817 6.04167 4.1665V2.9665C3.58333 3.1915 2.5 4.77484 2.5 7.08317V14.1665C2.5 16.6665 3.75 18.3332 6.66667 18.3332H13.3333C16.25 18.3332 17.5 16.6665 17.5 14.1665V7.08317C17.5 4.77484 16.4167 3.1915 13.9583 2.9665ZM10 13.9582H6.66667C6.325 13.9582 6.04167 13.6748 6.04167 13.3332C6.04167 12.9915 6.325 12.7082 6.66667 12.7082H10C10.3417 12.7082 10.625 12.9915 10.625 13.3332C10.625 13.6748 10.3417 13.9582 10 13.9582ZM13.3333 9.7915H6.66667C6.325 9.7915 6.04167 9.50817 6.04167 9.1665C6.04167 8.82484 6.325 8.5415 6.66667 8.5415H13.3333C13.675 8.5415 13.9583 8.82484 13.9583 9.1665C13.9583 9.50817 13.675 9.7915 13.3333 9.7915Z"
                        fill="#090909"/>
                  </svg>

                  {{ $t('chat.create_order') }}
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-popover>

          <svg id="popover-reactive-2" class="cursor-pointer more-action-chat" width="4" height="18" viewBox="0 0 4 18"
               fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM2 14C0.9 14 0 14.9 0 16C0 17.1 0.9 18 2 18C3.1 18 4 17.1 4 16C4 14.9 3.1 14 2 14ZM2 7C0.9 7 0 7.9 0 9C0 10.1 0.9 11 2 11C3.1 11 4 10.1 4 9C4 7.9 3.1 7 2 7Z"
                fill="#A4A4A4"/>
          </svg>


          <b-button class="button-send-message"
                    variant="primary"
                    v-on:click="clickSendImage">
            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.9999 4.64956L11.278 20.373L9.89997 11.6354L3.02194 6.07319L21.9999 4.64956Z" fill="#685EFF"
                    stroke="#685EFF" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.9986 4.64905L9.93488 11.7583" stroke="#685EFF" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>

          </b-button>
        </div>
        <input type="file" multiple id="chat-attach-image" ref="attach-image" class="d-none"
               accept=".png, .jpg, .jpeg, .gif"
               @change="handleFileUpload()">
        <b-modal id="modal-attach" ref="modal-attach" content-class="modal-designer" centered @hidden="resetUpload">
          <template #modal-title>
            {{ $t('chat.title_modal_image') }}
          </template>
          <div class="text-center flexing-responsive-images" :class="preview.length === 1 ? 'is-main' : ''">
            <template v-for="(images, index) in twoHalfColumn">
              <div :key="'image-modal-column-' + index" class="column">
                <template v-for="(image, i) in images">
                  <img :key="'image-modal-preview-' + i" style="border-radius: 6px" :src="image" alt="Vodaplay">
                </template>
              </div>
            </template>
          </div>
          <h6 class="text-danger" :class="Object.keys(error) > 0 ? 'd-block' : 'd-none'">
            <template v-for="(item, index) in Object.keys(error)">
              <span :key="'modal-preview-error-message-' + index">{{ error[item] }}<br></span>
            </template>
          </h6>
          <template #modal-footer>
            <div class="d-flex w-100 justify-content-center align-items-center">
              <b-form-input v-model="captionImage" :placeholder="$t('chat.caption_placeholder')"></b-form-input>
              <b-button class="button-violet ml-2" :disabled="Object.keys(error) > 0 || isUpload"
                        v-on:click="uploadFile">
                <template v-if="isUpload">
                  <b-spinner small variant="light" label="Spinning"></b-spinner>
                </template>
                <template v-else>
                  {{ $t('chat.send_image') }}
                </template>
              </b-button>
            </div>
          </template>
        </b-modal>
      </b-col>
    </div>

    <b-modal hide-footer hide-header id="modal-click-show-image" content-class="modal-designer"
             ref="modal-click-show-image" centered>
      <div class="text-center">
        <b-img style="width: 100%" rounded :src="clickShowImage" alt="Vodaplay"></b-img>
      </div>
    </b-modal>

    <b-modal id="modal-confirm-order" ref="modal-confirm-order" size="sm" :title="$t('chat.confirm_order')"
             @ok="submitConfirmOrder">
      <template v-if="isConfirmOrder">
        <b-spinner small variant="light" label="Spinning"></b-spinner>
      </template>
      <template v-else>
        {{ $t('chat.confirm_order_text') }}
      </template>
    </b-modal>
  </b-col>
</template>

<script>
import {mapGetters} from "vuex"
import data from 'emoji-mart-vue-fast/data/all.json'
import "emoji-mart-vue-fast/css/emoji-mart.css"
import {Picker, EmojiIndex} from "emoji-mart-vue-fast"

const emojiIndex = new EmojiIndex(data)

const CryptoJS = require("crypto-js")

export default {
  beforeDestroy() {
    this.isDestroyed = true
  },
  components: {
    Picker
  },
  mounted() {
    const vm = this
    this.$nextTick(() => {
      vm.$refs['input-chat-message'].$el.focus()
    })
  },
  updated() {
    const vm = this
    this.$nextTick(() => {
      vm.$refs['input-chat-message'].$el.focus()
    })
  },
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    twoHalfColumn() {
      const list = JSON.parse(JSON.stringify(this.preview))
      const middleIndex = Math.ceil(list.length / 2)
      const firstHalf = list.splice(0, middleIndex)
      const secondHalf = list.splice(-middleIndex)
      return [firstHalf, secondHalf]
    },
    emojiI18n() {
      return {
        search: this.$i18n.t('chat.emoji.search'),
        notfound: this.$i18n.t('chat.emoji.notfound'),
        categories: {
          search: this.$i18n.t('chat.emoji.categories.search'),
          recent: this.$i18n.t('chat.emoji.categories.recent'),
          people: this.$i18n.t('chat.emoji.categories.people'),
          nature: this.$i18n.t('chat.emoji.categories.nature'),
          foods: this.$i18n.t('chat.emoji.categories.foods'),
          activity: this.$i18n.t('chat.emoji.categories.activity'),
          places: this.$i18n.t('chat.emoji.categories.places'),
          objects: this.$i18n.t('chat.emoji.categories.objects'),
          symbols: this.$i18n.t('chat.emoji.categories.symbols'),
          flags: this.$i18n.t('chat.emoji.categories.flags'),
          custom: this.$i18n.t('chat.emoji.categories.custom'),
        }
      }
    },
    options() {
      return {
        products: [...this.searchProducts],
        status: [
          {
            id: 0,
            name: this.$i18n.t('chat.unconfimred')
          },
          {
            id: 1,
            name: this.$i18n.t('chat.confirmed')
          }
        ],
      }
    },
    ...mapGetters(["messageByUser", "messageByUserMeta", "getUploadUImageS3Url", "currentUser", "searchProducts"]),
  },
  created() {
    this.$store.dispatch('clearMessageByUser')
    this.resizeBoxUsers()
    window.addEventListener("resize", this.resizeBoxUsers)
    const vm = this
    vm.$nextTick(() => {
      vm.$root.$on('choose-user-chatting', (user) => {
        if (!vm.isDestroyed) {
          this.user = user
          this.page = 1
          this.getAllMessages()
          this.isShowBoxChat = true
        }
      })
      vm.sockets.subscribe("getMessage", (data) => {
        if (!vm.isDestroyed) {
          if (data.senderId === vm.user._id) {
            const isImage = data.text.type === 'image'
            vm.$store.dispatch('sendMessagePreview', {
              conversationId: vm.user.conversationId,
              sender: data.sender,
              text: data.text,
              isImage: isImage,
              isContent: true
            })
          }
          vm.scrollToBottom()
        }
      })
      vm.sockets.subscribe("getConfirmOrderStatus", (data) => {
        if (!vm.isDestroyed) {
          if (data.senderId === vm.user._id) {
            vm.$store.dispatch('updateMessagePreview', {
              messageId: data.message_id,
              confirm: 1
            })
          }
        }
      })
    })
    vm.callFilterProducts()
    window.addEventListener('click', function (e) {
      const checkedElement = document.getElementById('popover-chat-options')
      const checkedElementButton = document.getElementById('popover-reactive-2')
      if ((!checkedElement || checkedElement && !checkedElement.contains(e.target))
          && (checkedElementButton && !checkedElementButton.contains(e.target) && checkedElement && !checkedElement.contains(e.target))
      ) {
        vm.$root.$emit('bv::hide::popover', 'popover-chat-options')
      }
    });
  },
  data() {
    return {
      user: {},
      captionImage: '',
      inputText: '',
      clickShowImage: '',
      showEmoji: false,
      heightBox: 0,
      slide: 0,
      sliding: null,
      preview: [],
      error: {},
      isLoading: false,
      isLoadingUpload: {},
      isLoadingSend: false,
      isLoadingSendOrder: false,
      isLoadingSendImage: false,
      isLoadingSendProduct: false,
      file: [],
      isShowBoxChat: false,
      isUpload: false,
      timer: null,
      page: 1,
      colors: ['primary', 'secondary ', 'success', 'warning', 'danger', 'info', 'dark'],
      emojiIndex: emojiIndex,
      emojisOutput: "",
      selectedProduct: '',
      discountSelected: '',
      sendingProduct: {},
      selectedMessage: '',
      searchProductLoading: false,
      isLoadmore: false,
      confirmOrder: {},
      isConfirmOrder: false,
      isErrorConfirmOrder: false,
      isDestroyed: false
    }
  },
  directives: {
    'click-outside': {
      bind: function (el, binding) {

        const bubble = binding.modifiers.bubble

        const handler = (e) => {
          const isHidden = el.classList.contains('d-none')
          if (bubble || (!isHidden && !el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler
        document.addEventListener('click', handler)
      },

      unbind: function (el) {
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null

      }
    }
  },
  methods: {

    cancelSendProduct() {
      this.sendingProduct.discount_lines = []
      document.getElementById('show-product-discounts').classList.remove('active')
    },

    submitSendProduct() {
      const vm = this;
      setTimeout(() => {
        vm.sendPreviewProduct()
        vm.sendMessageProduct()
      }, 200);
      document.getElementById('show-product-discounts').classList.remove('active')
    },

    handlePreviewDiscounts() {
      const vm = this;
      if (this.discountSelected === '-1') {
        this.sendingProduct.discount_lines = []
        return false;
      }
      const discountSelected = this.selectedProduct.discount_lines.find((discount) => {
        return discount.id === vm.discountSelected
      })
      this.sendingProduct.discount_lines = [discountSelected]
    },

    resetConfirmOder() {
      this.confirmOrder = {}
      this.selectedMessage = ''
      this.isErrorConfirmOrder = false
    },

    showConfirmOrder(messageId, order) {
      this.selectedMessage = messageId
      this.confirmOrder = order
      this.$refs['modal-confirm-order'].show()
    },

    async submitConfirmOrder() {
      if (this.isConfirmOrder) {
        return false
      }
      this.isConfirmOrder = true

      const response = await this.$store.dispatch('confirmOrder', {
        id: this.confirmOrder.id,
        confirm: 1
      })

      if (response.data.status === 200) {
        this.updatePreviewOrder()
        this.updateOrderMessage()
        this.$refs['modal-confirm-order'].hide()
        this.resetConfirmOder()
      } else {
        this.isErrorConfirmOrder = true
      }
      this.isConfirmOrder = false
    },

    createOrder() {
      this.$root.$emit('bv::hide::popover', 'popover-chat-options')
      this.$emit('open-create-order', true);
    },

    imageLoaded() {
      if (!this.isLoadmore) {
        this.scrollToBottom()
      }
    },

    handleSelectedProduct() {
      const vm = this
      if (this.selectedProduct === '') {
        return false
      }
      vm.sendingProduct = {
        id: parseInt(this.selectedProduct.id),
        image: Array.isArray(this.selectedProduct.image) ? this.selectedProduct.image[0] : this.selectedProduct.image,
        name: this.selectedProduct.name,
        store_name: this.selectedProduct.store_name,
        store_id: this.selectedProduct.store_id,
        ward_name: this.selectedProduct.ward_name,
        district_name: this.selectedProduct.district_name,
        price: this.selectedProduct.price_format,
        price_sale: this.selectedProduct.sale_price,
        price_real: this.selectedProduct.price,
        discount_lines: []
      }
      document.getElementById('show-product-discounts').classList.add('active')
    },

    filterProducts(value) {
      const vm = this
      clearTimeout(vm.typingTimer);
      vm.typingTimer = setTimeout(async () => {
        await vm.callFilterProducts(value)
      }, 500);
    },

    async callFilterProducts(value = '') {
      this.searchStoreLoading = true
      await this.$store.dispatch('searchProducts', {
        page: 1,
        search: value
      })
      this.searchStoreLoading = false
    },

    showEmojiHandle() {
      const vm = this
      setTimeout(() => {
        vm.showEmoji = true
      }, 50)
    },

    clickOutsideEmoji() {
      this.showEmoji = false
    },

    showWhenClickImage(image) {
      this.clickShowImage = image
      this.$refs['modal-click-show-image'].show()
    },

    linkify(inputText) {
      const youtubePattern = /https?:\/\/(?:[a-zA_Z]{2,3}.)?(?:youtube\.com\/watch\?)((?:[\w\d\-_=]+&amp;(?:amp;)?)*v(?:&lt;[A-Z]+&gt;)?=([0-9a-zA-Z\-_]+))/gim
      let text = ''
      const isYoutubeUrl = youtubePattern.test(inputText)
      if (isYoutubeUrl) {
        const newInput = JSON.parse(JSON.stringify(inputText))
        const urlYoutube = newInput.match(youtubePattern)[0]
        const minWidth = this.isMobile ? '100%' : '350px';
        const minHeight = this.isMobile ? 'auto' : '250px';
        text = newInput.replace(youtubePattern, '<iframe style="width:100%; min-width: ' + minWidth + '; max-width: 500px; min-height: ' + minHeight + '; max-height: 400px; border-radius: 6px; border: solid 1px #DDDDDD;" src="https://www.youtube.com/embed/' + this.YouTubeGetID(urlYoutube) + '" allowfullscreen></iframe>');
      } else {
        const pattern1 = /(\b(https?|http):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gim;
        text = inputText.replace(pattern1, '<a style="color: #FFFFFF; text-decoration: underline" class="message-link" href="$1" target="_blank">$1</a>');

        const pattern2 = /(^|[^\\/])(www\.[\S]+(\b|$))/gim;
        text = text.replace(pattern2, '$1<a style="color: #FFFFFF; text-decoration: underline" class="message-link" href="http://$2" target="_blank">$2</a>');
      }

      return text;
    },

    YouTubeGetID(url) {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\\-]/i)[0] : url[0];
    },

    actionSendMessage(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        if (event.shiftKey) {
          this.inputText = this.inputText + '\n';
        } else {
          if (this.inputText.trim() !== '') {
            this.sendPreviewMessage()
            this.sendMessage()
            this.inputText = ''
          }
        }
      }
    },

    clickSendImage() {
      if (this.inputText.trim() !== '') {
        this.sendPreviewMessage()
        this.sendMessage()
        this.inputText = ''
      }
    },

    updatePreviewOrder() {
      this.$socket.emit("orderConfirm", {
        senderId: this.currentUser.chatting_id,
        receiverId: this.user._id,
        message_id: this.selectedMessage,
        confirm: 1
      })
      this.$store.dispatch('updateMessagePreview', {
        messageId: this.selectedMessage,
        confirm: 1
      })
    },

    async updateOrderMessage() {
      await this.$store.dispatch('updateMessage', {
        message_id: this.selectedMessage
      })
    },

    sendPreviewMessage(isCaption = false, caption = '') {
      this.$socket.emit("sendMessage", {
        senderId: this.currentUser.chatting_id,
        receiverId: this.user._id,
        text: {
          type: 'message',
          value: isCaption ? caption : this.inputText
        },
        conversationId: this.user.conversationId,
        userEmail: this.user.email
      })
      this.$store.dispatch('sendMessagePreview', {
        conversationId: this.user.conversationId,
        sender: this.currentUser.chatting_id,
        text: {
          type: 'message',
          value: isCaption ? caption : this.inputText
        },
        isImage: false,
        isPreview: true
      })
      this.scrollToBottom()
    },

    sendPreviewImage(images = {}) {
      this.$store.dispatch('sendMessagePreview', {
        conversationId: this.user.conversationId,
        sender: this.currentUser.chatting_id,
        text: {
          type: 'image',
          value: images
        },
        isImage: true,
        isPreview: true
      })
      this.scrollToBottom()
    },

    sendPreviewProduct() {
      this.$socket.emit("sendMessage", {
        senderId: this.currentUser.chatting_id,
        receiverId: this.user._id,
        text: {
          type: 'product',
          value: {
            id: parseInt(this.sendingProduct.id),
            image: Array.isArray(this.sendingProduct.image) ? this.sendingProduct.image[0] : this.sendingProduct.image,
            name: this.sendingProduct.name,
            store_name: this.sendingProduct.store_name,
            store_id: this.sendingProduct.store_id,
            ward_name: this.sendingProduct.ward_name,
            district_name: this.sendingProduct.district_name,
            price: this.sendingProduct.price,
            price_sale: this.sendingProduct.price_sale,
            price_real: this.sendingProduct.price_real,
            discounts: this.sendingProduct.discount_lines || []
          }
        },
        conversationId: this.user.conversationId,
        userEmail: this.user.email
      })

      this.$store.dispatch('sendMessagePreview', {
        conversationId: this.user.conversationId,
        sender: this.currentUser.chatting_id,
        text: {
          type: 'product',
          value: {
            id: parseInt(this.sendingProduct.id),
            image: Array.isArray(this.sendingProduct.image) ? this.sendingProduct.image[0] : this.sendingProduct.image,
            name: this.sendingProduct.name,
            store_name: this.sendingProduct.store_name,
            store_id: this.sendingProduct.store_id,
            ward_name: this.sendingProduct.ward_name,
            district_name: this.sendingProduct.district_name,
            price: this.sendingProduct.price,
            price_sale: this.sendingProduct.price_sale,
            price_real: this.sendingProduct.price_real,
            discounts: this.sendingProduct.discount_lines || []
          }
        },
        isPreview: true
      })
      this.scrollToBottom()
    },

    randomColor() {
      return Math.floor(Math.random() * this.colors.length)
    },

    getTextShowing(name) {
      if (!name || name === '') {
        return name
      }
      const nameArr = name.trim().split(/\s+/)
      const firstChar = nameArr.map(
          element => element.charAt(0)
      )
      return firstChar[0] + (firstChar[1] ? firstChar[1] : '')
    },

    async scrollLoadMore(event) {
      const vm = this
      if (event.currentTarget.classList.contains('chat-message-scrollable')) {
        const container = event.currentTarget
        const currentScroll = container.scrollTop
        if (currentScroll <= 300 && this.messageByUserMeta.total_page > this.page) {
          clearTimeout(vm.timer)
          vm.timer = setTimeout(async () => {
            vm.page++
            await vm.getAllMessages(true)
          }, 200)
        }
      }
    },

    async getAllMessages(isLoad = false) {
      this.isLoading = true
      this.isLoadmore = isLoad
      const container = document.body.querySelector("#chatting-scrollable")
      const scrollHeight = container ? container.scrollHeight : 0
      await this.$store.dispatch('getAllMessageByUser', {
        page: this.page,
        conversationId: this.user.conversationId,
        chattingUserId: this.currentUser.chatting_id
      })
      this.isLoading = false
      this.scrollToBottom(isLoad, scrollHeight)
    },

    async sendMessage(isCaption = false, caption = '') {
      if (this.isLoadingSend) {
        return false
      }
      this.isLoadingSend = true
      await this.$store.dispatch('sendMessage', {
        conversationId: this.user.conversationId,
        sender: this.currentUser.chatting_id,
        receivedId: this.user._id,
        text: {
          type: 'message',
          value: isCaption ? caption : this.inputText
        },
        isImage: false,
        chattingUserId: this.currentUser.chatting_id
      })
      this.isLoadingSend = false
    },

    async sendMessageImage(images = []) {
      if (this.isLoadingSendImage) {
        return false
      }
      this.isLoadingSendImage = true
      await this.$store.dispatch('sendMessage', {
        conversationId: this.user.conversationId,
        sender: this.currentUser.chatting_id,
        receivedId: this.user._id,
        text: {
          type: 'image',
          value: images
        },
        isImage: true,
        chattingUserId: this.currentUser.chatting_id
      })
      this.isLoadingSendImage = false
    },

    async sendMessageProduct() {
      if (this.isLoadingSendProduct) {
        return false
      }
      this.isLoadingSendProduct = true
      await this.$store.dispatch('sendMessage', {
        conversationId: this.user.conversationId,
        sender: this.currentUser.chatting_id,
        receivedId: this.user._id,
        text: {
          type: 'product',
          value: {
            id: parseInt(this.sendingProduct.id),
            image: this.sendingProduct.image,
            name: this.sendingProduct.name,
            store_name: this.sendingProduct.store_name,
            store_id: this.sendingProduct.store_id,
            ward_name: this.sendingProduct.ward_name,
            district_name: this.sendingProduct.district_name,
            price: this.sendingProduct.price,
            price_sale: this.sendingProduct.price_sale,
            price_real: this.sendingProduct.price_real,
            discounts: this.sendingProduct.discount_lines || []
          }
        },
        chattingUserId: this.currentUser.chatting_id
      })
      this.selectedProduct = ''
      this.sendingProduct = {}
      this.isLoadingSendProduct = false
    },

    resizeBoxUsers() {
      const boxBody = document.getElementsByTagName('body')[0]
      let heightBox = 0
      if (boxBody) {
        heightBox = boxBody.offsetHeight
      }
      this.heightBox = heightBox - 76 - 50 - 111 - 51 - 41 - 160;
    },

    scrollToBottom(isLoad = false, beforeScrollHeight = 0) {
      setTimeout(() => {
        const container = document.body.querySelector("#chatting-scrollable")
        const scrollHeight = container ? container.scrollHeight : 0
        if (container) {
          container.scrollTop = isLoad ? scrollHeight - beforeScrollHeight : scrollHeight
        }
      }, 100)
    },

    backToUsers() {
      if (this.isMobile) {
        this.isShowBoxChat = false
      }
      this.$root.$emit('back-to-users-chatting')
    },

    showPreviewImage() {
      document.getElementById('chat-attach-image').click()
    },

    dragover(event) {
      if (this.isMobile) {
        return false
      }
      event.preventDefault();

      if (!event.currentTarget.classList.contains('drag-on')) {
        event.currentTarget.classList.remove('drag-off');
        event.currentTarget.classList.add('drag-on');
      }
    },

    drop(event) {
      if (this.isMobile) {
        return false
      }
      event.preventDefault();
      event.currentTarget.classList.add('drag-off');
      event.currentTarget.classList.remove('drag-on');
      this.$refs['attach-image'].files = event.dataTransfer.files;
      this.handleFileUpload()
    },

    onPaste(event) {
      if (event.clipboardData === false) {
        return false
      }
      const items = event.clipboardData.items;
      if (items === undefined) {
        return false
      }
      let isShow = false
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") === -1) continue;
        const blob = items[i].getAsFile();
        this.handleUploadFileOnPaste(blob)
        isShow = true
      }
      if (isShow) {
        this.$refs['modal-attach'].show()
      }
    },

    handleUploadFileOnPaste(blob) {
      let vm = this;
      vm.error = {};
      let image = new Image();
      let imageSrc = window.URL.createObjectURL(blob);
      image.src = imageSrc;
      const hashFile = CryptoJS.SHA1(blob.name).toString()
      vm.preview.push(imageSrc);
      image.onload = function () {
        let width = image.naturalWidth,
            height = image.naturalHeight;
        if (width < 100 || height < 100) {
          vm.error[hashFile] = vm.$t('common.upload_min_size');
          vm.resetUploadOnFail()
          return false;
        } else if (width > 2000 || height > 2000) {
          vm.error[hashFile] = vm.$t('common.upload_max_size');
          vm.resetUploadOnFail()
          return false;
        }
        vm.file.push(blob);
      };
    },

    generateSHA1ByString(string) {
      return CryptoJS.SHA1(string).toString()
    },

    generateSHA1ByFile(file) {
      return CryptoJS.SHA1(file).toString()
    },

    async uploadFile() {
      let vm = this;
      vm.isUpload = true;
      await this.$refs['modal-attach'].hide()

      const imageWithHash = []
      for (const preview of this.preview) {
        const sha1 = this.generateSHA1ByFile(preview)
        vm.isLoadingUpload[sha1] = true;
        imageWithHash.push({
          image: preview,
          hash: sha1
        })
      }

      this.sendPreviewImage(imageWithHash)

      let index = 0
      const urlImages = []
      const reUpdatePreview = {}

      for (const file of this.file) {
        const sha1 = this.generateSHA1ByFile(this.preview[index])
        if (vm.isLoadingUpload[sha1]) {
          let formData = new FormData()
          formData.append('image', file)
          const res = await this.$store.dispatch('uploadImageS3', formData)
          if (res.data.status !== 200) {
            vm.error = vm.$t('common.upload_error');
            await vm.resetUploadOnFail()
          }
          urlImages.push(this.getUploadUImageS3Url)
          reUpdatePreview[sha1] = this.getUploadUImageS3Url
          vm.isLoadingUpload[sha1] = false
          vm.isLoadingUpload = JSON.parse(JSON.stringify(vm.isLoadingUpload))
        }
        index++
      }
      this.$socket.emit("sendMessage", {
        senderId: this.currentUser.chatting_id,
        receiverId: this.user._id,
        text: {
          type: 'image',
          value: urlImages
        },
        conversationId: this.user.conversationId,
        userEmail: this.user.email
      })
      const caption = this.captionImage
      vm.isUpload = false;

      await this.resetUpload()
      await this.sendMessageImage(urlImages)
      if (caption !== '') {
        this.sendPreviewMessage(true, caption)
        this.sendMessage(true, caption)
      }
    },

    handleFileUpload() {
      let vm = this;
      vm.error = {};
      let files = vm.$refs['attach-image'].files;
      if (files.length > 0) {
        for (const file of files) {
          let image = new Image();
          let imageSrc = window.URL.createObjectURL(file);
          image.src = imageSrc;
          const hashFile = CryptoJS.SHA1(file.name).toString()
          vm.preview.push(imageSrc);
          image.onload = () => {
            let width = image.naturalWidth,
                height = image.naturalHeight;
            if (width < 100 || height < 100) {
              vm.error[hashFile] = vm.$t('common.upload_min_size');
              vm.resetUploadOnFail()
              return false;
            } else if (width > 2000 || height > 2000) {
              vm.error[hashFile] = vm.$t('common.upload_max_size');
              vm.resetUploadOnFail()
              return false;
            }
            vm.file.push(file);
          }
        }
        vm.$refs['modal-attach'].show()
      }
    },

    async resetUploadOnFail() {
      if (typeof this.$refs['attach-image'] !== 'undefined') {
        this.$refs['attach-image'].value = "";
      }
      await this.$store.dispatch('setResetUploadImageS3')
    },

    async resetUpload() {
      if (this.isUpload) {
        return false;
      }
      this.preview = []
      this.error = {}
      this.captionImage = ""
      if (typeof this.$refs['attach-image'] !== 'undefined') {
        this.$refs['attach-image'].value = ""
      }
      await this.$store.dispatch('setResetUploadImageS3')
    },

    insertEmoji(emoji) {
      this.inputText += emoji.native
    }
  }
}
</script>

<style scoped lang="scss">
.box-message-chat {
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: #E9E9E9;
  border-radius: 10px;
}


.typing {
  position: absolute;
  bottom: 6px;
  left: 8px;

  width: 60px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
  }
}

.chat-header {
  height: 76px;
  padding: 16px;
  background: #FFFFFF;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  .header-user-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: left;
    color: #090909;
  }
}

.chat-footer {
  height: 92px;
  padding: 16px;
  background: rgba(233, 233, 233, 0.6);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  .footer-chat-content {
    background: #FFFFFF;
    position: relative;
    border-radius: 10px;
    height: 60px;

    .icon-upload-image {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 2;
      cursor: pointer;
    }

    .line-break-chat {
      position: absolute;
      left: 45px;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 2;
    }

    .sticker-open-emoji {
      position: absolute;
      right: 90px;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 20px;
      z-index: 2;
    }

    .input-chat-message {
      border: none;
      position: absolute;
      padding: 0 126px 0 66px;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      height: 16px;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;

      &::placeholder {
        color: #A4A4A4;
      }
    }

    .more-action-chat {
      position: absolute;
      right: 60px;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 18px
    }

    .button-send-message {
      position: absolute;
      width: auto !important;
      min-width: auto !important;
      padding: 0 !important;
      right: 16px;
      top: 50%;
      transform: translate(0, -50%);
      height: auto;
      background: transparent !important;
      border: none !important;
      outline: none !important;
      box-shadow: unset !important;

      &:focus {
        background: transparent;
        outline: none;
        border: none;
        box-shadow: unset;
      }

      &:active {
        background: transparent;
        outline: none;
        border: none;
        box-shadow: unset;
      }
    }
  }
}

.no-scroll-css {
  &::-webkit-scrollbar {
    display: none;
  }
}

.chatting-scrollable {
  &::-webkit-scrollbar {
    width: 6px;
    display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--level-three-side-bar);
    border-radius: 20px;
  }
}

.chatting-scrollable {
  &:hover {

    &::-webkit-scrollbar {
      display: block;
    }
  }
}

#emoji-picker {
  position: absolute;
  right: 10px;
  top: -405px;
  background: #FFFFFF;
  z-index: 20;

  .arrow {
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
    position: absolute;
    bottom: -9px;
    left: 5px;
    display: block;

    &:before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-color: transparent;
      border-top-color: rgba(0, 0, 0, .25);
      position: absolute;
      display: block;
      content: " ";
      border-style: solid;
    }

    &:after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-color: transparent;
      border-top-color: #fff;
      position: absolute;
      display: block;
      content: " ";
      border-style: solid;
    }
  }
}

.chat-message .message-item {
  padding: 10px;
  display: flex;
  align-items: center;
  max-width: 600px;
}

.chat-message.current {
  display: flex;
  justify-content: flex-end;
}

.chat-message .message-item .user-avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.chat-message .message-item .message-text {
  padding: 10px;
  border-radius: 8px;
  word-break: break-all;
}

.chat-message.current .message-item {
  flex-direction: row-reverse;
}

.chat-message.current .message-item .message-text {
  background: #F0EFFF;
  color: #090909;
  margin-right: 10px;
}

@media only screen and (max-width: 500px) {
  .chat-message .message-item .message-text {
    max-width: 200px;
  }
}

@media only screen and (max-width: 400px) {
  .chat-message .message-item .message-text {
    max-width: 160px;
  }
}

@media only screen and (max-width: 360px) {
  .chat-message .message-item .message-text {
    max-width: 140px;
  }
}

.chat-message.current .message-item .message-text .message-link {
  color: #090909 !important;
  text-decoration: underline;
}

.chat-message.guest .message-item .message-text {
  background: #FFFFFF;
  color: #282828;
  margin-left: 10px;
}

.chat-message.guest .message-item .message-text .message-link {
  color: #282828 !important;
  text-decoration: underline;
}


.draggable {
  position: relative;

  .draggable-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 254, 254, 0.7);
    z-index: 1000;
    display: none;
  }

  &.drag-on {
    .draggable-backdrop {
      display: flex;
      justify-content: center;
      align-items: center;

      .draggable-spare {
        width: 80%;
        height: 80%;
        border-radius: 12px;
        border: dashed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .draggable-content {
          width: 100%;
          text-align: center;
        }

        span {
          font-size: 2rem;
          width: 100%;
          display: block;
          margin-bottom: 20px;
        }

        .icon-send {
          font-size: 4rem;
        }
      }
    }
  }
}

.chat-message {

  &.guest {
    .message-text {
      position: relative;

      .arrow-message {
        width: 0.5rem;
        height: 1rem;
        position: absolute;
        top: 50%;
        left: -8px;
        transform: translate(0, -50%);
        display: block;

        &:before {
          left: 0;
          border-width: 0.5rem 0.5rem 0.5rem 0;
          border-color: transparent;
          border-right-color: #FFFFFF;
          position: absolute;
          display: block;
          content: " ";
          border-style: solid;
        }

        &:after {
          left: 1px;
          border-width: 0.5rem 0.5rem 0.5rem 0;
          border-color: transparent;
          border-right-color: #FFFFFF;
          position: absolute;
          display: block;
          content: " ";
          border-style: solid;
        }
      }
    }
  }

  &.current {
    .message-text {
      position: relative;

      .arrow-message {
        width: 0.5rem;
        height: 1rem;
        position: absolute;
        top: 50%;
        right: -8px;
        transform: translate(0, -50%);
        display: block;

        &:before {
          right: 0;
          border-width: 0.5rem 0 0.5rem 0.5rem;
          border-color: transparent;
          border-left-color: #F0EFFF;
          position: absolute;
          display: block;
          content: " ";
          border-style: solid;
        }

        &:after {
          right: 1px;
          border-width: 0.5rem 0 0.5rem 0.5rem;
          border-color: transparent;
          border-left-color: #F0EFFF;
          position: absolute;
          display: block;
          content: " ";
          border-style: solid;
        }
      }
    }

    .message-item-product {

      .card {
        background: #F0EFFF !important;
      }

    }
  }
}

.message-item-product {
  padding: 10px;

  .card {
    box-shadow: none !important;
    border: none !important;
  }

  &.discount {

    .card {
      box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2) !important;
      -webkit-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2) !important;
      -moz-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2) !important;
      -ms-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2) !important;
    }
  }

  .info-message-product {
    padding: 0 12px;

    .message-product-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #090909;
      margin-bottom: 12px;
    }

    .product-preview-discount-name {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #090909;
      margin-bottom: 12px;
    }

    .product-preview-price {
      display: inline-flex;
      align-items: center;


      .discount-display-price {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        text-align: left;
        color: #FE2D55;
        margin-right: 8px;
      }

      .real-price {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        color: #A4A4A4;
        text-decoration: line-through;
        margin-right: 8px;
      }

      .discount-rate {
        background: #FE2D551A;
        color: #FE2D55;
        padding: 2px;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
      }
    }
  }
}

.flexing-responsive-images {

  display: flex;
  flex-wrap: wrap;

  .column {
    flex: 50%;
    max-width: 50%;
    padding: 0 2px;

    img {
      width: 100%;
      margin-top: 4px;
      vertical-align: middle;
    }
  }

  &.is-main {

    .column {
      flex: 100%;
      max-width: 100%;
      padding: 0;

      img {
        margin-top: 0;
        vertical-align: middle;
      }
    }
  }
}

.popover-chat-options {

  .item-popover {

    &:hover {
      background: #0b95ef;
      color: #FFFFFF;
    }
  }
}
</style>