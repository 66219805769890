<template>
  <apex-chart type="line"
              :style="{height: isMobile ? '300px' : '500px', width: '100%', marginTop: '50px'}"
              :options="chartOptions"
              :series="chartData"
  >

  </apex-chart>
</template>

<script>
import Menu from "@/assets/img/report/Menu.svg";

export default {
  name: "AxesLineChart",
  props: {
    chartImpressionData: {
      type: Array,
      required: true
    },
    chartClickData: {
      type: Array,
      required: true
    },
    chartXLabel: {
      type: Array,
      required: true
    }
  },
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    chartData() {
      return [
        {
          name: this.$t('report.booking.impression'),
          data: this.chartImpressionData
        },
        {
          name: this.$t('report.booking.total_click'),
          data: this.chartClickData
        }
      ]
    },
    chartOptions() {
      return {
        chart: {
          id: 'chart-axes-impression',
          toolbar: {
            tools: {
              download: '<img src="' + Menu + '" width="20">',
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          }
        },
        xaxis: {
          categories: this.chartXLabel
        },
        colors: ['#685EFF', '#FF5959'],
        yaxis: [
          {
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: "#685EFF"
            },
            labels: {
              style: {
                colors: "#685EFF"
              }
            },
            title: {
              text: this.$t('report.booking.impression'),
              style: {
                color: "#685EFF"
              }
            }
          },
          {
            opposite: true,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: "#FF5959"
            },
            labels: {
              style: {
                colors: "#FF5959"
              }
            },
            title: {
              text: this.$t('report.booking.total_click'),
              style: {
                color: "#FF5959"
              }
            }
          }
        ],
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: "20%"
          },
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          width: [2, 2]
        },
      };
    }
  },
  data() {
    return {
      Menu: Menu
    }
  }
}
</script>

<style scoped>

</style>