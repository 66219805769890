import {OrderServiceAPI} from "@/api/order";

const initialState = () => {
    return {
        createOrder: {},
        confirm: false
    }
}

export default {
    state: initialState,
    mutations: {
        setCreateOrder(state, data){
            state.createOrder = data
        },
        setConfirmOrder(state, data){
            state.confirm = data
        }
    },
    actions: {
        async createOrder({commit}, params) {
            try {
                let result = await OrderServiceAPI.createOrder(params);
                if (result && result.data.status === 200) {
                    commit('setCreateOrder', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async confirmOrder({commit}, params) {
            try {
                let result = await OrderServiceAPI.confirmOrder(params);
                if (result && result.data.status === 200) {
                    commit('setConfirmOrder', result.data.data);
                }
                return result;
            } catch (e) {
                return e;
            }
        }
    },
    getters: {
        createdOrder(state) {
            return state.createOrder
        },
        confirmOrder(state) {
            return state.confirm
        }
    }
}
