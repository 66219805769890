import {ReportServiceAPI} from "@/api/report";

const initialState = () => {
  return {
    totalClick: {
      value: 0,
      percent: '0',
      trend: 'down',
      isLoaded: false
    },
    totalMessage: {
      value: 0,
      percent: '0',
      trend: 'down',
      isLoaded: false
    },
    totalCreateOrder: {
      value: 0,
      percent: '0',
      trend: 'down',
      isLoaded: false
    },
    totalConfirmOrder: {
      value: 0,
      percent: '0',
      trend: 'down',
      isLoaded: false
    },
    totalBookmark: {
      value: 0,
      percent: '0',
      trend: 'down',
      isLoaded: false
    },
    chartImpressionAndClick: {
      impression: [],
      click: [],
      label: [],
      isLoaded: false
    },
    chartMessage: {
      chat_with_shop: [],
      label: [],
      isLoaded: false
    },
    impressionHeatMap: [
      {
        name: 'sunday',
        data: []
      },
      {
        name: 'monday',
        data: []
      },
      {
        name: 'tuesday',
        data: []
      },
      {
        name: 'wednesday',
        data: []
      },
      {
        name: 'thursday',
        data: []
      },
      {
        name: 'friday',
        data: []
      },
      {
        name: 'saturday',
        data: []
      }
    ],
    loadedImpressionHeatMap: false,
    clickHeatMap: [
      {
        name: 'sunday',
        data: []
      },
      {
        name: 'monday',
        data: []
      },
      {
        name: 'tuesday',
        data: []
      },
      {
        name: 'wednesday',
        data: []
      },
      {
        name: 'thursday',
        data: []
      },
      {
        name: 'friday',
        data: []
      },
      {
        name: 'saturday',
        data: []
      }
    ],
    loadedClickHeatMap: false,
    messageHeatMap: [
      {
        name: 'sunday',
        data: []
      },
      {
        name: 'monday',
        data: []
      },
      {
        name: 'tuesday',
        data: []
      },
      {
        name: 'wednesday',
        data: []
      },
      {
        name: 'thursday',
        data: []
      },
      {
        name: 'friday',
        data: []
      },
      {
        name: 'saturday',
        data: []
      }
    ],
    loadedMessageHeatMap: false,
  }
}

export default {
  state: initialState,
  mutations: {
    setTotalClick(state, data) {
      state.totalClick = data;
      state.totalClick.isLoaded = true;
    },
    setTotalMessage(state, data) {
      state.totalMessage = data;
      state.totalMessage.isLoaded = true;
    },
    setTotalCreateOrder(state, data) {
      state.totalCreateOrder = data;
      state.totalCreateOrder.isLoaded = true;
    },
    setTotalConfirmOrder(state, data) {
      state.totalConfirmOrder = data;
      state.totalConfirmOrder.isLoaded = true;
    },
    setTotalBookmark(state, data) {
      state.totalBookmark = data;
      state.totalBookmark.isLoaded = true;
    },
    setChartImpressionAndClick(state, data) {
      state.chartImpressionAndClick = data;
    },
    setChartMessage(state, data) {
      state.chartMessage = data;
    },
    setImpressionHeatMap(state, data) {
      state.impressionHeatMap = data;
      state.loadedImpressionHeatMap = true;
    },
    setClickHeatMap(state, data) {
      state.clickHeatMap = data;
      state.loadedClickHeatMap = true;
    },
    setMessageHeatMap(state, data) {
      state.messageHeatMap = data;
      state.loadedMessageHeatMap = true;
    },
    resetReports(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  actions: {
    async getTotalClick({commit, getters}, params) {
      try {
        if (getters.totalClick.isLoaded && !params.is_reload) {
          return {
            data: {
              status: 200,
              data: getters.totalClick
            }
          };
        } else {
          let result = await ReportServiceAPI.getTrackingCount(params);
          if (result && result.data.success) {
            commit('setTotalClick', result.data.data)
          }
          return result;
        }
      } catch (e) {
        return e;
      }
    },

    async getTotalMessage({commit, getters}, params) {
      try {
        if (getters.totalMessage.isLoaded && !params.is_reload) {
          return {
            data: {
              status: 200,
              data: getters.totalMessage
            }
          };
        } else {
          let result = await ReportServiceAPI.getTrackingCount(params);
          if (result && result.data.success) {
            commit('setTotalMessage', result.data.data)
          }
          return result;
        }
      } catch (e) {
        return e;
      }
    },
    
    async getTotalCreateOrder({commit, getters}, params) {
      try {
        if (getters.totalCreateOrder.isLoaded && !params.is_reload) {
          return {
            data: {
              status: 200,
              data: getters.totalCreateOrder
            }
          };
        } else {
          let result = await ReportServiceAPI.getTrackingCount(params);
          if (result && result.data.success) {
            commit('setTotalCreateOrder', result.data.data)
          }
          return result;
        }
      } catch (e) {
        return e;
      }
    },
    
    async getTotalConfirmOrder({commit, getters}, params) {
      try {
        if (getters.totalConfirmOrder.isLoaded && !params.is_reload) {
          return {
            data: {
              status: 200,
              data: getters.totalConfirmOrder
            }
          };
        } else {
          let result = await ReportServiceAPI.getTrackingCount(params);
          if (result && result.data.success) {
            commit('setTotalConfirmOrder', result.data.data)
          }
          return result;
        }
      } catch (e) {
        return e;
      }
    },

    async getTotalBookmark({commit, getters}, params) {
      try {
        if (getters.totalBookmark.isLoaded && !params.is_reload) {
          return {
            data: {
              status: 200,
              data: getters.totalBookmark
            }
          };
        } else {
          let result = await ReportServiceAPI.getTrackingCount(params);
          if (result && result.data.success) {
            commit('setTotalBookmark', result.data.data)
          }
          return result;
        }
      } catch (e) {
        return e;
      }
    },

    async getChartImpressionAndClick({commit, getters}, params) {
      try {
        if (getters.chartImpressionAndClick.isLoaded && !params.is_reload) {
          return {
            data: {
              status: 200,
              data: getters.chartImpressionAndClick
            }
          };
        } else {
          let result = await ReportServiceAPI.getChartStatistical(params);
          if (result && result.data.success) {
            commit('setChartImpressionAndClick', result.data.data)
          }
          return result;
        }
      } catch (e) {
        return e;
      }
    },

    async getChartMessage({commit, getters}, params) {
      try {
        if (getters.chartMessage.isLoaded && !params.is_reload) {
          return {
            data: {
              status: 200,
              data: getters.chartMessage
            }
          };
        } else {
          let result = await ReportServiceAPI.getChartStatistical(params);
          if (result && result.data.success) {
            commit('setChartMessage', result.data.data)
          }
          return result;
        }
      } catch (e) {
        return e;
      }
    },

    async getImpression({commit, getters}, params) {
      try {
        if (getters.loadedImpressionHeatMap && !params.is_reload) {
          return {
            data: {
              status: 200,
              data: getters.impressionHeatMap
            }
          };
        } else {
          let result = await ReportServiceAPI.getHeatMap(params);
          if (result && result.data.success) {
            commit('setImpressionHeatMap', result.data.data)
          }
          return result;
        }
      } catch (e) {
        return e;
      }
    },

    async getClick({commit, getters}, params) {
      try {
        if (getters.loadedClickHeatMap && !params.is_reload) {
          return {
            data: {
              status: 200,
              data: getters.clickHeatMap
            }
          };
        } else {
          let result = await ReportServiceAPI.getHeatMap(params);
          if (result && result.data.success) {
            commit('setClickHeatMap', result.data.data)
          }
          return result;
        }
      } catch (e) {
        return e;
      }
    },

    async getMessage({commit, getters}, params) {
      try {
        if (getters.loadedMessageHeatMap && !params.is_reload) {
          return {
            data: {
              status: 200,
              data: getters.messageHeatMap
            }
          };
        } else {
          let result = await ReportServiceAPI.getHeatMap(params);
          if (result && result.data.success) {
            commit('setMessageHeatMap', result.data.data)
          }
          return result;
        }
      } catch (e) {
        return e;
      }
    },

    resetReports({commit}){
      commit('resetReports')
    }
  },
  getters: {
    totalClick: (state) => state.totalClick,
    totalMessage: (state) => state.totalMessage,
    totalCreateOrder: (state) => state.totalCreateOrder,
    totalConfirmOrder: (state) => state.totalConfirmOrder,
    totalBookmark: (state) => state.totalBookmark,
    chartImpressionAndClick: (state) => state.chartImpressionAndClick,
    chartMessage: (state) => state.chartMessage,
    impressionHeatMap: (state) => state.impressionHeatMap,
    loadedImpressionHeatMap: (state) => state.loadedImpressionHeatMap,
    clickHeatMap: (state) => state.clickHeatMap,
    loadedClickHeatMap: (state) => state.loadedClickHeatMap,
    messageHeatMap: (state) => state.messageHeatMap,
    loadedMessageHeatMap: (state) => state.loadedMessageHeatMap,
  }
}
