import {ChatServiceAPI} from "@/api/chat";
import {StringHelper} from "@/lib/string-helper"
import {ArrayHelper} from "@/lib/array-helper"

const initialState = () => {
    return {
        conversations: [],
        conversationMeta: {},
        chats: [],
        chatMeta: {},
        countMessageUnRead: 0
    }
}

export default {
    state: initialState,
    mutations: {
        setAllConversations(state, data) {
            const keyByData = state.conversations.reduce((acc, item) => ({
                ...acc,
                [item._id]: true
            }), {})

            let result = []

            for (const item of data) {
                if (item.members_user.length <= 0) {
                    continue
                }
                if (!keyByData[item._id]) {
                    item.randomColor = Math.floor(Math.random() * 7)
                    result.push(item)
                }
            }

            result = result.map((item, index) => {
                item.order = index + 1
                return item
            })

            state.conversations = state.conversations.concat(result)
        },
        setConversationMeta(state, data) {
            state.conversationMeta = data
        },
        setMessageByUser(state, data) {
            const keyByData = state.chats.reduce((acc, item) => ({
                ...acc,
                [item._id]: true
            }), {})

            const twoHalfColumn = (images) => {
                const list = JSON.parse(JSON.stringify(images))
                const middleIndex = Math.ceil(list.length / 2)
                const firstHalf = list.splice(0, middleIndex)
                const secondHalf = list.splice(-middleIndex)
                return [firstHalf, secondHalf]
            }

            const result = []
            for (const item of data) {
                if (!keyByData[item._id]) {
                    if (item.text && item.text.type === 'image') {
                        item.baseImages = item.text.value
                        item.text.value = twoHalfColumn(item.text.value)
                        item.text.hash = twoHalfColumn(item.text.value)
                        item.isImage = true
                    }
                    result.push(item)
                }
            }

            state.chats = state.chats.concat(result)
        },
        setMessagePreviewByUser(state, data) {
            const text = JSON.parse(JSON.stringify(data.text))

            const item = {
                _id: StringHelper.generateUUID(),
                sender: data.sender,
                hashing: data.hashing || '',
                text: text,
                conversationId: data.conversationId,
                isImage: data.isImage,
                isPreview: data.isPreview
            }

            if (item.isImage) {
                const twoHalfColumn = (images) => {
                    const list = JSON.parse(JSON.stringify(images))
                    const middleIndex = Math.ceil(list.length / 2)
                    const firstHalf = list.splice(0, middleIndex)
                    const secondHalf = list.splice(-middleIndex)
                    return [firstHalf, secondHalf]
                }

                const hash = []
                const images = []

                for (const image of text.value) {
                    hash.push(image.hash)
                    images.push(image.image)
                }

                item.baseImages = item.text.value
                item.text.value = twoHalfColumn(images)
                item.text.hash = twoHalfColumn(hash)

            }
            state.conversations.forEach((conversation) => {
                if (conversation._id === data.conversationId) {
                    let message
                    if (item.text.type === 'image') {
                        message = 'Bạn vừa gửi một ảnh'
                    } else if (item.text.type === 'product') {
                        message = 'Bạn vừa gửi một sản phẩm'
                    } else {
                        message = item.text.value
                    }
                    conversation.last_messsage = message
                    conversation.order = 1
                } else {
                    conversation.order++
                }
            })
            const sorted = ArrayHelper.sortByKey(state.conversations, 'order')
            state.conversations = JSON.parse(JSON.stringify(sorted))

            state.chats.unshift(item)
        },
        sendUserPreview(state, message) {
            let conversationWithChat = {}
            let index = 0
            let updateIndex = 0
            if (state.conversations.length > 0) {
                for (const conversation of state.conversations) {
                    if (conversation.members.includes(message.sender)) {
                        conversationWithChat = {
                            _id: conversation._id,
                            last_messsage: message.text,
                            members: conversation.members,
                            members_user: conversation.members_user,
                            randomColor: Math.floor(Math.random() * 7),
                            number_unread: conversation.number_unread + 1,
                            order: 1,
                            isPreview: message.isPreview,
                            updatedAt: message.now
                        }
                        updateIndex = index
                    }
                    index++
                }
                state.conversations.map((item) => {
                    item.order++
                    return item
                })
                if (Object.keys(conversationWithChat) <= 0) {
                    conversationWithChat = {
                        _id: message.conversationId,
                        last_messsage: message.text,
                        members: [message.sender, message.currentUser.chatting_id],
                        members_user: [{_id: message.sender, email: message.userEmail, profilePicture: ""}],
                        randomColor: Math.floor(Math.random() * 7),
                        number_unread: 1,
                        order: 1,
                        isPreview: message.isPreview,
                        updatedAt: message.now
                    }
                    state.conversations.push(conversationWithChat)
                } else {
                    state.conversations[updateIndex] = conversationWithChat
                }
            } else {
                conversationWithChat = {
                    _id: message.conversationId,
                    last_messsage: message.text,
                    members: [message.sender, message.currentUser.chatting_id],
                    members_user: [{_id: message.sender, email: message.userEmail, profilePicture: ""}],
                    randomColor: Math.floor(Math.random() * 7),
                    number_unread: 1,
                    order: 1,
                    isPreview: message.isPreview,
                    updatedAt: message.now
                }
                state.conversations.push(conversationWithChat)
            }

            const sorted = ArrayHelper.sortByKey(state.conversations, 'order')
            state.conversations = JSON.parse(JSON.stringify(sorted))
        },
        sendMessage() {

        },
        messageRead(state, conversation) {
            state.conversations.map((item) => {
                if (conversation._id === item._id) {
                    item.number_unread = 0
                }
                return item
            })
        },
        setMessageByUserMeta(state, data) {
            state.chatMeta = data
        },
        clearMessageByUser(state) {
            state.chats = []
            state.chatMeta = {}
        },
        clearConversation(state) {
            state.conversations = []
            state.conversationMeta = {}
        },
        resetChats(state) {
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        },
        updateMessageOrder(state, data) {
            state.chats.map((item) => {
                if (item._id === data.messageId) {
                    item.text.value.confirm = data.confirm
                    return item
                } else {
                    return item
                }
            })
            state.chats = JSON.parse(JSON.stringify(state.chats))
        },
        setUpdateMessageIdToOrder(state, data){
            const hashing = data.hashing;
            state.chats.map((item) => {
                if (item.hashing === hashing && item.text.type === 'order') {
                    item._id = data.messageId
                    return item
                } else {
                    return item
                }
            })
        },
        setMessageUnRead(state, data){
            state.countMessageUnRead = data.data.total_message;
        },
        incrementCountPreview(state){
            state.countMessageUnRead++
        },
        decrementCountPreview(state, conversation){
            const neededConversation = state.conversations.find((item) => {
                return conversation._id === item._id
            })
            const countMessageUnRead = state.countMessageUnRead - neededConversation.number_unread
            state.countMessageUnRead = countMessageUnRead > 0 ? countMessageUnRead : 0;
        }
    },
    actions: {

        async getAllConversations({commit}, params) {
            let result = await ChatServiceAPI.getAllConversations({
                page: params.page,
                search: params.search
            }, params.userId, {
                'Chatting-User-Id': params.chattingUserId
            });
            if (result && result.data.status === 200) {
                commit('setAllConversations', result.data.data.result)
                commit('setConversationMeta', {
                    next_page: result.data.data.next_page,
                    page: result.data.data.page,
                    total_page: result.data.data.total_page,
                    total_user: result.data.data.total_user,
                })
            }
            return result;
        },

        async getAllMessageByUser({commit}, params) {
            let result = await ChatServiceAPI.getAllMessageByUser({page: params.page}, params.conversationId, {
                'Chatting-User-Id': params.chattingUserId
            });
            if (result && result.data.status === 200) {
                commit('setMessageByUser', result.data.data.result)
                commit('setMessageByUserMeta', {
                    next_page: result.data.data.next_page,
                    page: result.data.data.page,
                    total_page: result.data.data.total_page,
                    total_message: result.data.data.total_message,
                })
            }
            return result;
        },

        async sendMessage({commit}, params) {
            let result = await ChatServiceAPI.postMessage(params, {
                'Chatting-User-Id': params.chattingUserId
            });
            if (result && result.data.status === 200) {
                commit('sendMessage', result.data.data.result)
            }
            return result;
        },

        sendMessagePreview({commit}, params) {
            commit('setMessagePreviewByUser', params)
        },

        updateMessagePreview({commit}, params) {
            commit('updateMessageOrder', params)
        },

        updateMessageIdToOrder({commit}, params) {
            commit('setUpdateMessageIdToOrder', params)
        },

        async updateMessage({commit}, params) {
            commit('sendMessage')
            await ChatServiceAPI.updateMessage(params)
        },

        clearMessageByUser({commit}) {
            commit('clearMessageByUser')
        },

        messageRead({commit}, params) {
            commit('messageRead', params)
        },

        clearConversation({commit}) {
            commit('clearConversation')
        },

        resetChats({commit}) {
            commit('resetChats')
        },

        sendUserPreview({commit}, params) {
            commit('sendUserPreview', params)
        },
        
        async getMessageUnRead({commit}, params) {
            let result = await ChatServiceAPI.getMessageUnRead({}, {
                'Chatting-User-Id': params.chattingUserId
            });
            if (result && result.data.status === 200) {
                commit('setMessageUnRead', result.data)
            }
            return result;
        },
        
        incrementCountPreview({commit}) {
            commit('incrementCountPreview')
        },
        decrementCountPreview({commit}, params) {
            commit('decrementCountPreview', params)
        },
    },
    getters: {
        allConversations(state) {
            return ArrayHelper.sortByKey(state.conversations, 'order')
        },
        conversationMeta(state) {
            return state.conversationMeta
        },
        messageByUser(state) {
            let newMessage = state.chats
            newMessage = JSON.parse(JSON.stringify(newMessage))
            return newMessage.reverse()
        },
        messageByUserMeta(state) {
            return state.chatMeta
        },
        countMessageUnRead(state){
            return state.countMessageUnRead
        }
    }
}
