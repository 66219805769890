export default {
    title: 'Tự động',
    add_new: 'Thêm mới',
    searching: 'Tìm kiếm',
    campaign_manage: 'Chiến dịch',
    option_pending: 'Chưa bắt đầu',
    option_running: 'Đang chạy',
    option_stop: 'Tạm dừng',
    option_delete: 'Đã xóa',
    attribute: {
        id: 'ID',
        status: 'Trạng thái',
        advertiser: 'Người quảng cáo',
        brand: 'Thương hiệu',
        name: 'Tên chiến dịch',
        budget: 'Ngân sách',
        count_product: 'Số sản phẩm',
        created_by: 'Người đăng',
        started_at: 'Thời gian bắt đầu',
        ended_at: 'Thời gian kết thúc',
        cpc: 'CPC',
        showing: 'Số hiển thị',
        cost: 'Chi phí',
        created_at: 'Ngày tạo',
    },
    create:{
        title: 'Tạo Chiến dịch',
        confirm: 'Xác nhận tạo chiến dịch',
        confirm_description: 'Are you sure about this decision?',
        confirm_save: 'Xác nhận',
        confirm_cancel: 'Hủy bỏ',
        confirm_success_title: 'Thành Công',
        confirm_success_description: 'Tạo Chiến dịch thành công',
        confirm_fail_title: 'Thật bại',
        confirm_fail_description: 'Tạo Chiến dịch thất bại',
    },
    update:{
        title: 'Cập nhật Chiến dịch',
        confirm: 'Xác nhận cập nhật chiến dịch',
        confirm_description: 'Are you sure about this decision?',
        confirm_save: 'Xác nhận',
        confirm_cancel: 'Hủy bỏ',
        confirm_success_title: 'Thành Công',
        confirm_success_description: 'Tạo Chiến dịch thành công',
        confirm_fail_title: 'Thật bại',
        confirm_fail_description: 'Tạo Chiến dịch thất bại',
    },
    field:{
        placeholder_budget: 'Nhập ngân sách...',
        placeholder_tags: 'Nhập...',
        placeholder_input: 'Nhập...',
    },
    gender:{
        all: 'Tất cả',
        male: 'Nam giới',
        female: 'Nữ giới'
    },
    country:{
        american: 'Hoa Kì',
        korean: 'Hàn Quốc',
        vietnam: 'Việt Nam'
    },
    ages: {
        '0_10': 'Dưới 10 tuổi',
        '10_20': '10 đến 20 tuổi',
        '20_30': '20 đến 30 tuổi',
        '30_40': '30 đến 40 tuổi',
        '40_50': '40 đến 50 tuổi',
        other: 'Tuổi khác',
    },
    product_types:{
        table_title: 'Quản lí Product được chọn',
        table_category: 'Loại sản phẩm',
        table_product_name: 'Tên sản phẩm',
        table_btn_search: 'Tìm kiếm',
        table_title_count: 'Sản phẩm được chọn',
        table_count: 'Tổng {count} sản phẩm',
        table_submit: 'Xác nhận',
        table_clear_selected: 'Bỏ các sản phẩm đã chọn',
        table_brand: 'Thương hiệu',
        table_price: 'Giá sản phẩm',
        table_keyword: 'Từ khóa',
        table_link: 'Loại liên kết',
        number_product: 'Số sản phẩm',
        number_pick: 'Số Pick',
        type_original: 'Liên kết chính hãng',
        type_external: 'Liên kết bên ngoài',
        type_shopee: 'Liên kết shopee',
        type_tiki: 'Liên kết tiki',
    },
    info: {
        title: 'Campagin Information',
        name: 'Campaign Title',
        status: 'Campaign Status',
        advertiser: 'Advertiser',
        brand: 'Brand',
        banner: 'Banner',
        budget: 'Budget',
        time: 'Campaign Time',
        keyword: 'Keyword',
        to: 'To',
        started_at: 'Started At',
        ended_at: 'Ended At',
    },
    product: {
        title: 'Product Settings',
        brand: 'Brand',
        category: 'Category',
        keyword: 'Keyword',
        price: 'Price',
        from: 'From',
        to: 'To',
        search: 'Search',
        selected_title: 'Selected product information',
        product_from_price: 'From Price',
        product_to_price: 'To Price',
        product_checkbox: 'select product!',
        button_manage: 'Product Management',
        pick_matching: 'Pick matches the selected product',
        title_error_product_types: 'Warning',
        description_error_product_types: 'Please select product type',
    },
    target: {
        title: 'Target settings',
        gender: 'Gender',
        country: 'Country',
        age: 'Age',
        age_from: 'Độ tuổi khởi đầu',
        age_to: 'Độ tuổi kết thúc',
        interests: 'Interests',
        total_view_guess: 'Total views',
        check_target_setted: 'Check the installed target',
        expected_views: 'Expected views',
        average_views: 'Average number of views per day'
    },
    recommend: {
        title: 'Settings recommend product videos',
        subscriber: 'Channel\'s number of followers',
        view: 'Video views',
        category: 'Youtube Category'
    },
    save: 'Save',
    cancel: 'Cancel'
}
