import {CampaignServiceAPI} from "@/api/campaign";

const initialState = () => {
    return {
        campaigns: {},
        campaignMeta: {},
        viewGuess: [1000, 30000],
        viewPerDay: [10, 300],
        youtubeCategories: []
    }
}

export default {
    state: initialState,
    mutations: {
        setCampaigns(state, data) {
            state.campaigns[data.meta.current_page] = data.data
        },
        setCampaignMeta(state, data) {
            state.campaignMeta = data
        },
        setViewGuess(state, data){
            state.viewGuess = data
        },
        setViewPerDay(state, data){
            state.viewPerDay = data
        },
        setYoutubeCategories(state, data){
            state.youtubeCategories = data
        },
        setResetCampaign(state){
            state.viewGuess = [1000, 30000];
            state.viewPerDay = [10, 300];
        },
        setCreateCampaign(state){
            state.campaigns = {}
            state.campaignMeta = {}
        },
        resetCampaign(state){
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        }
    },
    actions: {
        async getCampaigns({commit, getters}, params) {
            try {
                if (typeof getters.campaigns[params.page] !== "undefined" && (typeof params.is_reload === 'undefined' || !params.is_reload)
                ) {
                    return {
                        data: {
                            status: 200,
                            data: getters.campaigns[params.page],
                            meta: getters.campaignMeta,
                            isStorage: true
                        }
                    };
                } else {
                    let result = await CampaignServiceAPI.getCampaigns(params);
                    if (result && result.data.status === 200) {
                        commit('setCampaigns', {data: result.data.data, meta: result.data.meta})
                        commit('setCampaignMeta', result.data.meta)
                    }
                    return result;
                }
            } catch (e) {
                return e;
            }
        },

        async getViewGuess({commit}, params){
            try {
                let result = await CampaignServiceAPI.getViewGuess(params);
                if (result && result.data.status === 200) {
                    commit('setViewGuess', result.data.data.view_guess)
                    commit('setViewPerDay', result.data.data.view_per_day)
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        async getYoutubeCategories({commit}, params){
            try {
                let result = await CampaignServiceAPI.getYoutubeCategories(params);
                if (result && result.data.status === 200) {
                    commit('setYoutubeCategories', result.data.data)
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        resetCampaign({commit}){
            commit('setResetCampaign', true)
        },

        async createCampaign({commit}, params){
            try {
                let result = await CampaignServiceAPI.createCampaign(params);
                if (result && result.data.status === 200) {
                    commit('setCreateCampaign', result.data.data)
                }
                return result;
            } catch (e) {
                return e;
            }
        },

        resetCampaigns({commit}){
            commit('resetCampaign')
        }
    },
    getters: {
        campaigns(state) {
            return state.campaigns
        },
        campaignMeta(state) {
            return state.campaignMeta
        },
        viewGuess(state){
            return state.viewGuess
        },
        viewPerDay(state){
            return state.viewPerDay
        },
        youtubeCategories(state){
            return state.youtubeCategories
        }
    }
}
