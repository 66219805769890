export default {
    statistical: 'Dashboard',
    campaign: 'Campaigns',
    product: 'Products',
    booking: 'Bookings',
    booking_store: 'Stores',
    booking_store_create: 'Create Store',
    booking_store_update: 'Update Store',
    booking_product: 'Products',
    booking_store_product_create: 'Create Product',
    booking_store_product_update: 'Update Product',
    booking_pick: 'Picks',
    booking_pick_create: 'Create Pick',
    booking_pick_update: 'Update Pick',
    brand_ai: 'Brand AI',
    brand_ai_create: 'Create Brand AI',
    brand_ai_update: 'Update Brand AI',
    report: 'Reports',
    report_booking: 'Report Booking',
    cost: 'Costs',
    notice: 'Notice Board',
    chat: 'Chat',
    chat_page: 'Chat',
    user_profile: 'Edit Profile',
    coupon: 'Coupon',
    coupon_create: 'Create Coupon',
    coupon_update: 'Update Coupon',
    setting: 'Settings',
}
