export default {
    profile: 'Profile',
    edit_profile: 'Edit Profile',
    logout: 'Logout',
    notification: {
        view_all: 'View All',
        new_notify: 'new notifications',
        clear_all: 'Clear all',
    },
    payment: {
        cost: 'Advertising Costs',
        button: 'Recharge'
    }
}
