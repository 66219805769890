import {BaseApi} from "@/lib/base-api";

const apiUrl = process.env.VUE_APP_API_URL + 'campaigns';

export const CampaignServiceAPI = {

    getCampaigns(params) {
        return BaseApi.request('querySerialize', apiUrl, params);
    },

    getViewGuess(params) {
        return BaseApi.request('querySerialize', apiUrl + '/view-guess', params);
    },

    getYoutubeCategories(params) {
        return BaseApi.request('querySerialize', apiUrl + '/get-youtube-categories', params);
    },

    createCampaign(params) {
        return BaseApi.request('post', apiUrl + '/create', params);
    },

    updateCampaign(params) {
        return BaseApi.request('post', apiUrl + '/update', params);
    }
}
