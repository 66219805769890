<template>
  <div class="full-screen h-100">

    <b-col cols="12" class="h-100">
      <b-row class="h-100">
        <b-col cols="9" class="h-100">
          <b-row class="h-100 d-flex justify-content-center align-items-center">
            <b-img :src="Illustration" alt="Vodaplay"></b-img>
          </b-row>
        </b-col>
        <b-col cols="3" class="h-100 bg-white">
          <b-row class="h-100 d-flex justify-content-center align-items-center">
            <b-col cols="12">
              <b-row>
                <div class="w-100 d-flex justify-content-center align-items-center flex-wrap">
                  <b-img :src="LogoCircle" alt="Vodaplay"></b-img>
                  <h3 class="header-title-page-no-auth w-100" style="margin-top:32px;">{{ $t("auth.button_login") }}</h3>
                </div>
                <div class="w-100" style="margin-top:110px; padding: 0 50px;">
                  <form ref="login_input" data-vv-scope="login_input"
                        class="auth-form-wrapper">

                    <div v-if="error" class="alert alert-danger" role="alert">
                      {{ $t("auth.error") }}
                    </div>

                    <div class="form-group" style="margin-bottom:20px;">
                      <label class="label-selector" for="email">{{ $t("auth.email") }}</label>
                      <input type="email"
                             v-validate="'required|email'"
                             data-vv-scope="login_input"
                             name="email"
                             :data-vv-as="$t('auth.email')"
                             class="form-control" placeholder="Email"
                             required
                             autocomplete="email" autofocus v-model="email"
                             @keyup="submitWithEnter($event)"
                      >
                      <b-form-invalid-feedback>{{ errors.first('login_input.email') }}
                      </b-form-invalid-feedback>
                    </div>

                    <div class="form-group" style="margin-bottom:20px;">
                      <label class="label-selector" for="password">{{ $t("auth.password") }}</label>
                      <input type="password" class="form-control"
                             v-model="password"
                             data-vv-scope="login_input"
                             :data-vv-as="$t('auth.password')"
                             v-validate="'required'"
                             name="password"
                             autocomplete="current-password"
                             placeholder="Password" required
                             @keyup="submitWithEnter($event)"
                      >
                      <b-form-invalid-feedback>{{ errors.first('login_input.password') }}
                      </b-form-invalid-feedback>
                    </div>


                    <div class="form-check d-flex justify-content-between">
                      <b-form-checkbox
                          v-model="remember"
                      >
                        {{ $t("auth.remember") }}
                      </b-form-checkbox>

                      <!--                      <router-link :to="{name: 'ForgotPassword'}" class="router-link-primary">-->
                      <!--                        {{ $t('auth.forgot_password') }}-->
                      <!--                      </router-link>-->
                    </div>
                    <div class="w-100 text-center" style="margin-top:62px">
                      <button type="button"
                              style="padding: 16px 0 !important;"
                              @click="login()"
                              :disabled="sending"
                              :class="{disabled: sending}"
                              class="button-violet w-100">
                        <template v-if="!sending">
                          {{ $t("auth.button_login") }}
                        </template>
                        <template v-else>
                          <b-spinner label="Spinning" variant="light"></b-spinner>
                        </template>
                      </button>
                    </div>
                    <a class="d-none mt-3 text-muted" href="javascript:;">
                      {{ $t("auth.forgot_password") }}
                    </a>
                  </form>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import LogoCircle from "@/assets/img/logo-circle.svg"
import Illustration from "@/assets/img/Illustration.svg"

export default {
  data() {
    return {
      email: null,
      password: null,
      remember: false,
      sending: false,
      error: false,
      token: '',
      LogoCircle: LogoCircle,
      Illustration: Illustration,
    }
  },
  methods: {
    submitWithEnter(event) {
      let vm = this;
      let keyCode = event.keyCode;
      vm.isError = false;
      if (keyCode === 13) {
        vm.login();
      }
    },

    async login() {
      if (this.sending) {
        return false;
      }
      this.sending = true
      const isValid = this.$validator.validate('login_input.*')
      if (!isValid) {
        return false;
      }
      await this.getTokenRecaptcha()
      const res = await this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
        token: this.token,
        remember: this.remember
      })
      if (res && res.data.status === 200) {
        await this.$router.push({name: 'loading'})
      } else {
        this.error = true
      }
      this.sending = false
    },

    async getTokenRecaptcha() {
      await this.$recaptchaLoaded();
      this.token = await this.$recaptcha('login');
    },

    async particlesLoaded() {

    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/particles/_particles.css';
</style>
