<template>
  <li class="nav-item nav-profile">
    <b-dropdown id="dropdownProfile" right variant="link" size="lg" no-caret>
      <template #button-content>
        <b-avatar variant="light" :src="currentUser.avartar"></b-avatar>
      </template>
      <b-dropdown-header>
        <div class="d-flex flex-wrap justify-content-center">
          <div class="figure" style="margin-bottom: 20px">
            <b-avatar variant="light" :src="currentUser.avartar" size="6rem"></b-avatar>
          </div>
          <div class="info text-center">
            <p class="name" style="margin-bottom: 4px">{{ currentUser.name }}</p>
            <p class="email mb-1">{{ currentUser.email }}</p>
          </div>
        </div>
      </b-dropdown-header>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item class="dropdown-item-designer">
        <router-link :to="{name: 'UserProfile'}" class="nav-link">
          <img :src="UserIcon" alt="Vodaplay">
          <span>{{ $t('header.profile') }}</span>
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item class="dropdown-item-designer" @click="logOut">
        <img :src="LogoutIcon" alt="Vodaplay">
        <span>{{ $t('header.logout') }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </li>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import UserIcon from "@/assets/img/common/ic_user.svg"
import LogoutIcon from "@/assets/img/common/ic_logout.svg"

export default {
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      UserIcon: UserIcon,
      LogoutIcon: LogoutIcon
    }
  },
  methods: {
    ...mapActions(['resetAuth', 'resetBookings', 'resetCampaigns', 'resetChats', 'resetCommons', 'resetProducts', 'resetReports', 'resetUsers']),

    async logOut() {
      this.$root.$emit('logout-loading', true)
      await this.$store.dispatch('logOut')
      this.resetStores()
      await this.$router.push({name: 'login'})
      this.$root.$emit('logout-loading', false)
    },

    resetStores() {
      this.resetAuth()
      this.resetBookings()
      this.resetCampaigns()
      this.resetCommons()
      this.resetProducts()
      this.resetReports()
      this.resetUsers()
    }
  }
}
</script>

<style lang="scss">
.dropdown-item-designer {
  text-align: center;

  &.text-left{

    a{
      justify-content: left;
    }
  }

  a {
    padding-right: 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-height: 16px;
      max-width: 16px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: left;
      color: #090909;
      margin-left: 12px;
    }
  }
}

#dropdownProfile {


  button {
    padding: 0;
    border: none;
    outline: none;
    box-shadow: unset;
    height: auto;
    min-width: unset;
  }

  .dropdown-menu {
    border-radius: 8px;
    padding: 12px;
    background: #FFFFFF;
    box-shadow: 0 4px 14px 0 #0000001A;
    border: none;
    top: 8px !important;

    .dropdown-item{
      padding-right: 30px;
    }
  }

  .name {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    text-align: center;
    color: #090909;
  }

  .email {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    color: #323232 !important;
  }
}
</style>