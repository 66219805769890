<template>
  <li class="nav-item nav-language">
    <b-dropdown id="dropdownLanguage" right variant="link" size="lg" no-caret>
      <template #button-content>
        <img :src="currentLanguage.icon" alt="Vodaplay" :title="currentLanguage.name">
        <span class="current-language">{{ currentLanguage.name }}</span>
        <img :src="ArrowDownIcon" alt="Vodaplay">
      </template>
      <b-dropdown-item class="dropdown-item-designer text-left"  v-for="language in languages" :key="'language-' + language.key" @click="setLocale(language.key)">
          <img :src="language.icon" alt="Vodaplay" :title="language.name">
          <span class="ml-1">{{ language.name }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </li>
</template>

<script>
import axios from "axios";
// import SouthKoreaFlag from "@/assets/img/common/south-korea.png"
import UnitedKingdomFlag from "@/assets/img/common/united-kingdom.png"
import VietnamFlag from "@/assets/img/common/vietnam.png"
import ArrowDownIcon from "@/assets/img/common/arrow-down.svg"

export default {
  computed: {
    currentLanguage() {
      const currentLocale = this.$i18n.locale || 'vi'
      return this.languages.find((language) => language.key === currentLocale)
    }
  },
  data() {
    return {
      languages: [
        {
          key: 'en',
          name: 'English',
          icon: UnitedKingdomFlag
        },
        // {
        //   key: 'kr',
        //   name: '한국인',
        //   icon: SouthKoreaFlag
        // },
        {
          key: 'vi',
          name: 'Tiếng Việt',
          icon: VietnamFlag
        }
      ],
      ArrowDownIcon: ArrowDownIcon
    }
  },
  methods: {
    setLocale(language) {
      this.$i18n.locale = language
      axios.defaults.headers.common['Accept-Language'] = language
      document.querySelector('html').setAttribute('lang', language)
      this.$validator.localize(language)
      this.$i18n.setLocaleMessage(language, require(`@/i18n/${language}`).default)
      this.$store.commit('setAppLanguage', language)
    }
  }
}
</script>

<style  lang="scss">
#dropdownLanguage{

  button{
    background: #FFFFFF;
    border-radius: 10px;
    border: none;
    outline: none;
    box-shadow: unset;
    height: 42px;
    padding: 0 10px;
    min-width: unset;
    text-decoration: none !important;

    &:hover{
      text-decoration: none !important;
    }

    .current-language{
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: left;
      margin: 0 10px;
      color: #090909;
    }

    img{
      width: 20px;
      height: 20px;
      border-radius: 100%;
    }
  }

  .dropdown-menu{
    border-radius: 8px;
    padding: 12px;
    background: #FFFFFF;
    box-shadow: 0 4px 14px 0 #0000001A;
    border: none;
    top: 8px !important;
  }
}
</style>
