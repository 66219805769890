<template>
  <ol class="breadcrumb p-0">
    <li class="breadcrumb-item breadcrumb-style active">Home</li>
    <li class="breadcrumb-item breadcrumb-style" v-for="(breadcrumb) in breadcrumbList"
        :class="{'active': currentPage === breadcrumb.page}" :key="breadcrumb.page">
      <template v-if="currentPage !== breadcrumb.page">
        <router-link tag="a" :to="{ name: breadcrumb.to}">
          {{ $t('sidebar.' + breadcrumb.page) }}
        </router-link>
      </template>
      <template v-else>
        <span>{{ $t('sidebar.' + breadcrumb.page) }}</span>
      </template>
    </li>
  </ol>

</template>

<script>
export default {
  computed: {
    breadcrumbList() {
      let vm = this;
      let breads = vm.$route.meta.breadcrumb;
      let breadCrumbs = [];
      if (breads && breads.length > 0) {
        breads.forEach(function (item) {
          breadCrumbs.push(item);
        });
      }
      return breadCrumbs;
    },
    currentPage() {
      let vm = this;
      return vm.$route.meta.page;
    }
  }

}
</script>

<style scoped>

</style>
