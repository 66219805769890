<template>
  <div class="google-map-section map-component">
    <b-col cols="12" v-if="isSearch">
      <b-row>
        <div class="search-map map-component-search">
          <div class="d-inline-flex w-100 align-items-center">
            <b-input class="form-control mr-2" :placeholder="$t('common.google_search_title')"
                     v-model="keyword" v-on:keydown="keyDownSearch($event)"></b-input>
            <b-button class="button-violet" @click="searchLocation">
              <template v-if="isLoading">
                <b-spinner small variant="light"></b-spinner>
              </template>
              <template v-else>
                {{ $t('common.google_button_search') }}
              </template>
            </b-button>
          </div>
          <div class="search-map-result" :class="{open: locationByKeyword.length > 0}">
            <div :key="'search-map-item-' + index" v-for="(item, index) in locationByKeyword"
                 v-on:click="changeCenter(item)"
                 class="search-map-result-item">
              <h6>{{ item.address }}</h6>
            </div>
          </div>
        </div>
      </b-row>
    </b-col>
    <b-col cols="12">
      <b-row>
        <gmap-map
            :center="currentCenter"
            :zoom="15"
            map-type-id="terrain"
            :style="{width: '100%', height: height}"
            :options="{
                   zoomControl: true,
                   mapTypeControl: false,
                   scaleControl: false,
                   streetViewControl: false,
                   rotateControl: false,
                   fullscreenControl: true,
                   disableDefaultUi: false
                }"
        >
          <gmap-marker
              :key="'google-market-1'"
              :position="currentCenter"
              :draggable="true"
              @drag="updateCoordinates"
          >
            <gmap-info-window :opened="this.isTooltip">
              <b style="white-space: nowrap; font-weight: 700; color: #323232; line-height: 17px; font-size: 14px;">{{
                  tooltipText
                }}</b>
            </gmap-info-window>
          </gmap-marker>
        </gmap-map>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "GoogleMap",
  props: {
    longitude: {
      type: [Number],
      default: 105.83991
    },
    latitude: {
      type: [Number],
      default: 21.02800
    },
    zoom: {
      type: Number,
      default: 15
    },
    height: {
      type: String,
      default: '600px'
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    isDragMarker: {
      type: Boolean,
      default: false
    },
    isTooltip: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['locationByKeyword', 'currentIpInfo'])
  },
  data() {
    return {
      currentCenter: {},
      keyword: '',
      isLoading: false
    }
  },
  mounted() {
    const vm = this
    this.$nextTick(() => {
      vm.currentCenter = {
        lat: Number(vm.latitude),
        lng: Number(vm.longitude)
      };
    })
    // if (this.$device.mobile && this.$device.ios) {
    //     let mapComponent = document.querySelector('.map-component')
    //     mapComponent.style.pointerEvents = "none";
    // }
  },
  methods: {

    keyDownSearch(event) {
      if (event.keyCode === 13) {
        this.searchLocation()
      }
    },

    async searchLocation() {
      this.isLoading = true
      await this.$store.dispatch('setResetLocationByKeyword')
      await this.$store.dispatch('getLocationByKeyword', {keyword: this.keyword})
      this.isLoading = false
    },

    updateCoordinates(location) {
      this.currentCenter = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      };
      this.$emit('update-location', {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      })
    },

    async changeCenter(item) {
      this.currentCenter = {
        lat: Number(item.latitude),
        lng: Number(item.longitude)
      };
      this.$emit('update-location', {
        lat: item.latitude,
        lng: item.longitude
      })
      await this.$store.dispatch('setResetLocationByKeyword')
    },
  }
}
</script>

<style scoped>

</style>