export default {
    upload_image: {
        button: 'Tải ảnh',
        cancel: 'Hủy bỏ',
        success: 'Tải ảnh thành công',
        banner: 'Ảnh bìa',
        image: 'Hình ảnh {index}',
        upload: 'Chỉnh sửa',
        delete_image: 'Xóa'
    },
    google_search_title: 'Tìm kiếm địa chỉ',
    google_button_search: 'Tìm kiếm',
    save: 'Xác nhận',
    cancel: 'Hủy bỏ',
    upload_min_size: 'Kích thước tối thiểu của ảnh phải là 100x100',
    upload_max_size: 'Kích thước tối đa của ảnh là 2000x2000',
    upload_error: 'Tải lên ảnh không thành công',
}
