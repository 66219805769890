<template>
  <div class="map-component">
    <b-col v-if="isSearch">
      <b-row>
        <div class="search-map map-component-search">
          <div class="d-inline-flex w-100 align-items-center">
            <b-input class="form-control mr-2" :placeholder="$t('common.google_search_title')"
                     v-model="keyword"></b-input>
            <b-button class="button-violet" @click="searchLocation">
              <template v-if="isLoading">
                <b-spinner small variant="light"></b-spinner>
              </template>
              <template v-else>
                {{ $t('common.google_button_search') }}
              </template>
            </b-button>
          </div>
          <div class="search-map-result" :class="{open: locationByKeyword.length > 0}">
            <div :key="'search-map-item-' + index" v-for="(item, index) in locationByKeyword"
                 v-on:click="changeCenter(item)"
                 class="search-map-result-item">
              <h6>{{ item.address | truncateText(44, '...') }}</h6>
            </div>
          </div>
        </div>
      </b-row>
    </b-col>
    <b-col cols="12">
      <b-row>
        <l-map ref="open-street-map" :style="'height: ' + height +'; width: 100%'" :zoom="zoom"
               :center="center">
          <l-marker ref="open-street-map-marker" :lat-lng="center" :draggable="isDragMarker"
                    @update:latLng="updateLatLong">
            <l-popup v-if="isTooltip" :options="{ permanent: true, interactive: true }">
              <b style="white-space: nowrap">{{ tooltipText }}</b>
            </l-popup>
          </l-marker>
          <!--                    <template v-if="currentIpInfo.countryCode === 'VN'">-->
          <!--                        <l-tile-layer :url="layerVN.url"-->
          <!--                                      :attribution="layerVN.attribution"-->
          <!--                        />-->
          <!--                    </template>-->
          <!--                    <template v-else>-->
          <l-tile-layer :url="layerOther.url"
                        :attribution="layerOther.attribution"
          />
          <!--                    </template>-->
        </l-map>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import {latLng, Icon} from "leaflet"
import {LMap, LTileLayer, LMarker, LPopup} from 'vue2-leaflet'
import {mapGetters} from "vuex";

export default {
  name: "OpenStreetMap",
  props: {
    longitude: {
      type: [String, Number],
      default: '105.83991'
    },
    latitude: {
      type: [String, Number],
      default: '21.02800'
    },
    zoom: {
      type: Number,
      default: 15
    },
    height: {
      type: String,
      default: '600px'
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    isDragMarker: {
      type: Boolean,
      default: false
    },
    isTooltip: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    }
  },
  components: {
    LMap,
    LMarker,
    LTileLayer,
    LPopup
  },
  computed: {
    ...mapGetters(['locationByKeyword', 'currentIpInfo'])
  },
  data() {
    return {
      center: latLng(this.latitude, this.longitude),
      layerOther: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '<a href="https://vodaplay.vn"><img src="/logo-dark-64.jpg" style="width: 10px; height: 10px" alt="">&nbsp;Vodaplay</a> <span aria-hidden="true">|</span> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      },
      layerVN: {
        url: 'https://images.vietbando.com/ImageLoader/GetImage.ashx?Ver=2016&LayerIds=VBD&Level={z}&X={x}&Y={y}',
        attribution: '<a href="https://vodaplay.vn"><img src="/logo-dark-64.jpg" style="width: 10px; height: 10px" alt="">&nbsp;Vodaplay</a> <span aria-hidden="true">|</span> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      },
      keyword: '',
      isLoading: false
    }
  },
  async created() {
    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
      iconRetinaUrl: require('@/assets/img/marker-x2.png'),
      iconUrl: require('@/assets/img/marker.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
  },
  mounted() {
    const vm = this
    if (this.isTooltip) {
      this.$nextTick(() => {
        setTimeout(() => {
          vm.$refs['open-street-map-marker'].mapObject.openPopup()
        }, 300)
      })
    }
    this.$nextTick(() => {
      setTimeout(() => {
        vm.center = latLng(vm.latitude, vm.longitude)
      }, 300)
    })
    // if (this.$device.mobile && this.$device.ios) {
    //     let mapComponent = document.querySelector('.map-component')
    //     mapComponent.style.pointerEvents = "none";
    // }
  },
  methods: {
    async searchLocation() {
      this.isLoading = true
      await this.$store.dispatch('setResetLocationByKeyword')
      await this.$store.dispatch('getLocationByKeyword', {keyword: this.keyword})
      this.isLoading = false
    },

    async updateLatLong(location) {
      this.center = latLng(location.lat, location.lng)
      this.$emit('update-location', {
        lat: location.lat,
        lng: location.lng
      })
    },

    async changeCenter(item) {
      this.center = latLng(item.latitude, item.longitude)
      this.$emit('update-location', {
        lat: item.latitude,
        lng: item.longitude
      })
      await this.$store.dispatch('setResetLocationByKeyword')
    }
  }
}
</script>

<style scoped lang="scss">
@import 'leaflet/dist/leaflet.css';
</style>