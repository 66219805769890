const ID_TOKEN_KEY = process.env.VUE_APP_ID_TOKEN_KEY;
const ID_REFRESH_KEY = process.env.VUE_APP_ID_REFRESH_KEY;

export const getToken = () => {
    if (window.localStorage.getItem(ID_TOKEN_KEY) != null || window.sessionStorage.getItem(ID_TOKEN_KEY) != null) {
        let token = window.localStorage.getItem(ID_TOKEN_KEY) != null ? window.localStorage.getItem(ID_TOKEN_KEY) : window.sessionStorage.getItem(ID_TOKEN_KEY);
        if (Object.keys(token).length > 0) {
            return token;
        }
    }
};

export const getRefreshToken = () => {
    if (window.localStorage.getItem(ID_REFRESH_KEY) != null ) {
        let token = window.localStorage.getItem(ID_REFRESH_KEY);
        if (Object.keys(token).length > 0) {
            return token;
        }
    }
}

export const saveTempToken = token => {
    window.sessionStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveRefreshToken = token => {
    window.localStorage.setItem(ID_REFRESH_KEY, token);
}

export const saveToken = token => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
    window.sessionStorage.removeItem(ID_TOKEN_KEY);
    window.localStorage.removeItem(ID_TOKEN_KEY);
    window.localStorage.removeItem(ID_REFRESH_KEY);
};

export default {getToken, saveToken, destroyToken, saveTempToken, saveRefreshToken, getRefreshToken};
