<template>
    <div class="col-12">
      <div class="row">
        <div class="table-responsive">
          <div class="data-table" v-on:contextmenu="rightClickHandle($event)">
            <table class="table table-new-design">
              <thead>
              <tr>
                <th v-if="isNeedCheckbox">
                  <b-form-checkbox
                     :checked="isCheckAll[page] || false"
                     @change="checkedToggleCheckbox"
                     >
                  </b-form-checkbox>
<!--                  <input type="checkbox" :checked="isCheckAll[page] || false" @click="checkedToggleCheckbox()">-->
                </th>
                <template v-for="attribute in attributes">
                  <th :class="attribute.className + ' ' + attribute.isSort ? 'sorting' : ''"
                      :key="attribute.attribute"
                      @click="sorting( attribute)"
                      v-if="!attribute.isHidden"
                  >
                    {{ attribute.name }}
                  </th>
                </template>
                <th v-if="isUpdate"></th>
              </tr>
              </thead>
              <tbody>
              <template v-if="dataItems && dataItems.length">
                <tr v-for="item in dataItems" :key="item.id"
                    v-on:contextmenu="openMenu($event, item)">
                  <td v-if="isNeedCheckbox">
                    <b-form-checkbox
                        :style="{margin: '0 auto', minHeight: '1rem'}"
                        v-model="checked"
                        :value="item.id"
                    >
                    </b-form-checkbox>
<!--                    <input type="checkbox" :style="{margin: '0 auto'}" class="form-check" v-model="checked"-->
<!--                           @click="checkedCheckbox(item)"-->
<!--                           :value="item.id">-->
                  </td>
                  <template v-for="attribute in attributes">
                    <td :key="attribute.attribute" :class="attribute.className" v-if="!attribute.isHidden">
                      <template
                          v-if="attribute.type === 'string' || attribute.type === 'number' || attribute.type === 'date' || attribute.type === 'number_range'">
                            <span style="word-break: break-word">
                              {{ item[attribute.attribute] }}
                            </span>
                      </template>
                      <template v-if="attribute.type === 'string_trim'">
                            <span style="word-break: break-word">
                              {{ item[attribute.attribute] | truncateText(30, '...') }}
                            </span>
                      </template>
                      <template v-if="attribute.type === 'video'">
                        <iframe width="150" height="150"
                                :src="'https://www.youtube-nocookie.com/embed/' + item[attribute.attribute].trim()"></iframe>
                      </template>
                      <template v-if="attribute.type === 'link'">
                        <a :href="item[attribute.href]"
                           :target="attribute.target ? attribute.target : '_self'"
                           :class="attribute.className">
                                  <span style="word-break: break-all">
                                    {{ item[attribute.attribute] }}
                                  </span>
                        </a>
                      </template>
                      <template v-if="attribute.type === 'price'">
                            <span style="word-break: break-all">
                              {{ formatPrice(item[attribute.attribute]) }}
                            </span>
                      </template>
                      <template v-if="attribute.type === 'image'">
                        <img class="table-show-image" :src="item[attribute.attribute]" alt="">
                      </template>
                      <template v-if="attribute.type === 'images'">
                        <b-avatar-group size="32px" rounded="lg" class="show-table-multiple-images">
                          <template v-for="(image, index) in item[attribute.attribute]">
                            <b-avatar :key="'images-' + page + '-' + index" :src="image"></b-avatar>
                          </template>
                        </b-avatar-group>
                      </template>
                      <template v-if="attribute.type === 'badge'">
                        <template
                            v-if="item[attribute.badgeOption] && item[attribute.badgeOption][attribute.badgeValue]">
                                    <span class="badge badge-info" :class="attribute.className">
                                        {{ item[attribute.badgeOption][attribute.badgeValue] || '' }}
                                    </span>
                        </template>
                      </template>
                      <template v-if="attribute.type === 'badges'">
                            <span class="badge badge-info"
                                  v-for="content in item[attribute.attribute]" :key="content.attribute"
                                  :class="attribute.className ? attribute.className  :''">
                                {{ content.name }}
                            </span>
                      </template>
                      <template v-if="attribute.type === 'text'">
                            <span style="word-break: break-all">
                              {{ item[attribute.attribute] | truncateText(250, '...') }}
                            </span>
                      </template>
                      <template v-if="attribute.type === 'editor'">
                        <div v-html="item[attribute.attribute]"></div>
                      </template>
                      <template v-if="attribute.type === 'option' && options[attribute.attribute]">
                            <span v-for="option in options[attribute.attribute]"
                                  :key="option.id"
                                  v-show="option.id === item[attribute.attribute]"
                                  :class="option.class">
                                    {{ option.name }}
                            </span>
                      </template>
                      <template v-if="attribute.type === 'status'">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input"
                                 :checked="item[attribute.attribute] === 1"
                                 :value="item[attribute.attribute]"
                                 :id="attribute.attribute"
                                 @click="changeStatus(attribute, item[attribute.attribute])"/>
                          <label class="custom-control-label"
                                 :for="attribute.attribute"></label>
                        </div>
                      </template>
                    </td>
                  </template>
                  <template v-if="isUpdate">
                    <td>
                      <a href="javascript:;" class="text-edit-violet" @click="clickUpdate(item)">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.84006 2.40006L3.36673 8.1934C3.16006 8.4134 2.96006 8.84673 2.92006 9.14673L2.6734 11.3067C2.58673 12.0867 3.14673 12.6201 3.92006 12.4867L6.06673 12.1201C6.36673 12.0667 6.78673 11.8467 6.9934 11.6201L12.4667 5.82673C13.4134 4.82673 13.8401 3.68673 12.3667 2.2934C10.9001 0.913397 9.78673 1.40006 8.84006 2.40006Z" stroke="#685EFF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M7.92676 3.3667C8.21342 5.2067 9.70676 6.61337 11.5601 6.80003" stroke="#685EFF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M2 14.6667H14" stroke="#685EFF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{ $t('datatable.edit') }}</a>
                    </td>
                  </template>
                </tr>
                <template v-if="isUpdate && isOpenActionRightClick">
                  <div ref="open-action-right-click" class="position-absolute" v-if="viewMenu"
                       :style="{top: top, left: left}" v-click-outside="closeMenu">
                    <b-list-group-item button @click="clickUpdate(itemChoose)">{{
                        $t('datatable.edit')
                      }}
                    </b-list-group-item>
                  </div>
                </template>
              </template>
              <template v-else>
                <tr class="odd">
                  <td :colspan="attributes.length + 1" class="dataTables_empty" valign="top">
                    {{ $t('datatable.no_result') }}
                  </td>
                </tr>
              </template>
              </tbody>
            </table>


          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
    props: {
        attributes: {
            type: Array,
            required: true
        },
        items: {
            type: [Array, Object],
            required: false
        },
        options: {
            type: Object,
            required: false
        },
        page: {
            type: Number,
            required: false,
            default: 1
        },
        isNeedCheckbox: {
            type: Boolean,
            require: false,
            default: false
        },
        isPaginate: {
            type: Boolean,
            require: false,
            default: false
        },
        checkedItems: {
            type: Object,
            required: false
        },
        isUpdate: {
            type: Boolean,
            require: false,
            default: false
        },
        isResetCheckbox: {
            type: Boolean,
            require: false,
            default: false
        },
        isOpenActionRightClick: {
            type: Boolean,
            require: false,
            default: false
        }
    },
    async mounted() {
        if (typeof this.checkPerPage[this.page] === 'undefined') {
            this.checkPerPage[this.page] = []
        }
        if (typeof this.isCheckAll[this.page] === 'undefined') {
            this.isCheckAll[this.page] = false
        }
        if (this.isNeedCheckbox && this.checkedItems && Object.keys(this.checkedItems).length > 0) {
            await this.loadingData()
        }
    },
    directives:{
        'click-outside': {
            bind: function(el, binding) {
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e)
                    }
                }
                el.__vueClickOutside__ = handler
                document.addEventListener('click', handler)
            },

            unbind: function(el) {
                document.removeEventListener('click', el.__vueClickOutside__)
                el.__vueClickOutside__ = null

            }
        }
    },
    computed: {
        dataItems() {
            return this.isPaginate ? this.items[this.page] : this.items
        }
    },
    watch: {
        checked: {
            handler(after) {
                this.$emit('checked', after)
            },
            deep: true
        },
        isResetCheckbox: {
            handler(after) {
                if (after) {
                    const vm = this
                    vm.checked = []
                    Object.keys(vm.checkPerPage).forEach((page) => {
                        vm.checkPerPage[page] = []
                        vm.isCheckAll[page] = false
                    })
                    vm.sortDirection = {}
                    vm.$emit('resetCheckboxDone', true)
                }
            },
            deep: true
        },
        page: {
            handler(after) {
                if (typeof this.checkPerPage[after] === 'undefined') {
                    this.checkPerPage[after] = []
                }
                if (typeof this.isCheckAll[after] === 'undefined') {
                    this.isCheckAll[after] = false
                }
            },
            deep: true
        }
    },
    data() {
        return {
            loadItems: {},
            sortDirection: {},
            isCheckAll: {},
            checked: [],
            checkPerPage: {},
            viewMenu: false,
            top: '0px',
            left: '0px',
            itemChoose: null
        }
    },

    methods: {

        setMenu(top, left) {
            this.top = top + 'px';
            this.left = left + 'px';
        },

        closeMenu() {
            this.viewMenu = false;
        },

        openMenu(e, item) {
            if (this.isOpenActionRightClick) {
                this.viewMenu = true;
                this.itemChoose = item
                this.$nextTick(() => {
                    this.setMenu(e.layerY, e.layerX)
                });
                e.preventDefault();
            }
        },

        rightClickHandle(event) {
            if (this.isOpenActionRightClick) {
                event.preventDefault()
            }
        },

        async loadingData() {
            let vm = this
            vm.dataItems.forEach((item) => {
                if (vm.checkedItems[item.id]) {
                    vm.checkPerPage[vm.page].push(item.id)
                    vm.checked.push(item.id)
                }
            })
            vm.checkedAllWhenFull()
        },

        checkedCheckbox(item) {
            if (!this.checkPerPage[this.page].includes(item.id)) {
                this.checkPerPage[this.page].push(item.id)
            } else {
                this.checkPerPage[this.page].splice(this.checkPerPage[this.page].indexOf(item.id), 1)
            }
            this.checkedAllWhenFull()
        },

        checkedToggleCheckbox() {
            let vm = this
            if (!vm.isCheckAll[vm.page]) {
                vm.dataItems.forEach((item) => {
                    vm.checked.push(item.id)
                    vm.checkPerPage[vm.page].push(item.id)
                })
                vm.isCheckAll[vm.page] = true
            } else {
                vm.checkPerPage[vm.page].forEach((item) => {
                    vm.checked.splice(vm.checked.indexOf(item), 1)
                })
                vm.checkPerPage[vm.page] = []
                vm.isCheckAll[vm.page] = false
            }
        },

        checkedAllWhenFull() {
            if (this.checkPerPage[this.page].length === this.dataItems.length) {
                this.isCheckAll[this.page] = true
            } else {
                this.isCheckAll[this.page] = false
            }
        },

        changeStatus(currentValue) {
            let vm = this;
            vm.$emit('change_status', currentValue === 2 ? 1 : 2)
        },

        sorting(attribute) {
            if (!attribute.isSort) {
                return false
            }
            const attr = attribute.attribute
            if (typeof (this.sortDirection[attr]) == 'undefined') {
                this.sortDirection[attr] = 1;
            }
            this.sortDirection[attr]++;
            let sort = 'desc';
            if (this.sortDirection[attr]) {
                sort = 'asc'
            } else {
                sort = 'desc'
            }
            if (this.sortDirection[attr] === 3) {
                sort = ''
                this.sortDirection[attr] = 0;
            }
            this.$emit('sorting', {
                'attribute': attr,
                'sort': sort
            })
        },

        clickUpdate(item) {
            let vm = this;
            vm.$emit('edit', item);
        }
    },

    events: {
        closeEvent: function () {
            console.log('close event called')
            this.closeMenu()
        }
    }
}
</script>

<style scoped lang="scss">

</style>
