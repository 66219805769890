export default {
    booking: {
        title: 'Báo cáo',
        up_to: 'Tăng',
        down: 'Giảm',
        box_footer: 'so với Tháng trước',
        impression: 'Lượng tiếp cận',
        total_message: 'Tin nhắn',
        total_create_order: 'Lượt tạo đơn',
        total_confirm_order: 'Lượt KH xác nhận',
        total_bookmark: 'Số lượt lưu',
        total_click: 'Số lượt click',
        brand_awareness_report: 'Báo cáo nhận biết thương hiệu',
        monday: 'Thứ hai',
        tuesday: 'Thứ ba',
        wednesday: 'Thứ tư',
        thursday: 'Thứ năm',
        friday: 'Thứ sáu',
        saturday: 'Thứ bảy',
        sunday: 'Chủ nhật',
        report_impression: 'Báo cáo tiếp cận',
        report_click: 'Báo cáo lượt xem',
        report_message: 'Báo cáo tin nhắn',
        report_char_call: 'Cuộc gọi',
        report_char_impression_click: 'Tiếp cận và Lượt xem',
        report_less_than_7_day: 'Số ngày báo cáo không thể nhỏ hơn 7',
        report_bigger_than_30_day: 'Số ngày báo cáo không thể lớn hơn 30',
        label_select_time: 'Lựa chọn thời gian',
        label_select_store: 'Lựa chọn cửa hàng',
        report_filter: 'Bộ lọc nâng cao',
        report_percent_title_day: 'so với {n} ngày trước',
    },
    filter: {
        today: 'Ngày hôm nay',
        before_7_day: '7 ngày trước',
        before_14_day: '14 ngày trước',
        before_30_day: '30 ngày trước',
        option_day: 'Nhập thời gian',
        option_from_day: 'Từ ngày',
        option_to_day: 'Đến ngày',
    }
}
