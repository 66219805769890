<template>
  <section class="content-header">
    <div class="container-fluid">
      <div class="row" style="margin-bottom: 12px">
        <b-col class="d-inline-flex align-items-center" :cols="isMobile ? 12 : 6">
          <template v-if="isMobile">
            <h3 class="title-page">{{ title }}</h3>
          </template>
          <template v-else>
            <h1 class="title-page">{{ title }}</h1>
          </template>
          <template v-if="icon && icon !== ''">
            <img class="title-icon" :src="getImageSrc" alt="Vodaplay">
          </template>
        </b-col>
        <b-col v-if="isHasCreate" :cols="isMobile ? 12 : 6" :class="{'mt-4': isMobile}">
          <router-link tag="a" :to="{ name: name}" class="button-violet float-right">
            {{ $t('booking.store.add_new') }}
          </router-link>
        </b-col>
      </div>
      <div class="row" style="margin-bottom: 20px">
        <b-col>
          <breadcrumb-layout></breadcrumb-layout>
        </b-col>
      </div>
    </div>
  </section>
</template>

<script>
import Fire from "@/assets/img/report/Fire.png"
import WomenClothes from "@/assets/img/product/Womans_Clothes.svg"
import WomanLightSkinTone from "@/assets/img/profile/WomanLightSkinTone.svg"
import MessageChat from "@/assets/img/chat/message-chat.svg"
import ShoppingBags from "@/assets/img/store/ShoppingBags.svg"
import ComputerDisk from "@/assets/img/pick/ComputerDisk.svg"

export default {
  props: {
    title: {
      type: String,
      default: '',
      require: true
    },
    isHasCreate: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      images: {
        WomenClothes: WomenClothes,
        Fire: Fire,
        MessageChat: MessageChat,
        WomanLightSkinTone: WomanLightSkinTone,
        ShoppingBags: ShoppingBags,
        ComputerDisk: ComputerDisk,
      }
    }
  },
  computed:{
    isMobile() {
      return this.$device.mobile
    },
    getImageSrc(){
      return this.images[this.icon]
    }
  }
}
</script>

<style scoped lang="scss">
.title-icon{
  width: 40px;
  height: 40px;
  margin-left: 8px;
}
</style>