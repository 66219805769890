<template>
  <section class="row">
    <div class="col-12">
      <section class="campaign-fields pr-3 pl-3 pb-3">
        <section class="card card-layer padding-16">
          <h4 class="title-card-designer margin-bottom-24">{{ $t('booking.pick.fields.video.title') }}</h4>
          <div class="card-body p-0">

            <div class="row">
              <div class="col-md-12">
                <div class="table-hover table-responsive table-no-border mb-0 table-no-padding-horizontal">

                  <table class="table table-form">
                    <tr>
                      <th>
                        <label class="label-selector">{{ $t('booking.pick.fields.video.input_video_id') }}
                          <span class="text-danger">*</span>
                        </label>
                      </th>
                      <td colspan="3">
                        <template v-if="isUpdate">
                          <b-col cols="12">
                            <b-row>

                          <span class="form-control">
                              {{ formData.video_id }}
                          </span>
                            </b-row>
                          </b-col>
                        </template>
                        <template v-else>
                          <b-col cols="12">
                            <b-row>
                              <div
                                  class="d-inline-flex w-100 align-items-center"
                                  :class="{
                                'is-invalid' : errors.first('booking_pick_validate.video_id') || errors.first('booking_pick_validate.video_title'),
                                'validation-button': errors.first('booking_pick_validate.video_title')
                           }"
                              >
                                <b-input
                                    v-validate="'required|is_youtube_video'"
                                    data-vv-scope="booking_pick_validate"
                                    :data-vv-as="$t('booking.pick.fields.video.input_video_id')"
                                    name="video_id"
                                    type="text"
                                    class="form-control mr-2"
                                    style="min-width: 100px"
                                    :class="{'is-invalid': errors.has('booking_pick_validate.video_id')}"
                                    :placeholder="$t('booking.pick.fields.video.input_video_id')"
                                    v-model="formData.video_id">
                                </b-input>
                                <b-button class="button-violet" :disabled="searchVideoLoading"
                                          v-on:click="getVideoInformation">
                                  <template v-if="searchVideoLoading">
                                    <b-spinner small></b-spinner>
                                  </template>
                                  <template v-else>
                                    <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
                                  </template>
                                </b-button>
                                <input type="hidden"
                                       v-model="formData.video_title"
                                       v-validate.continues="'required_select'"
                                       data-vv-scope="booking_pick_validate"
                                       :data-vv-as="$t('booking.pick.fields.video.information_video')"
                                       name="video_title"
                                />
                              </div>
                              <b-form-invalid-feedback
                                  style="margin-top: 6px;">
                                {{
                                  errors.first('booking_pick_validate.video_id') ? errors.first('booking_pick_validate.video_id') : (errors.first('booking_pick_validate.video_title') ? errors.first('booking_pick_validate.video_title') : '')
                                }}
                              </b-form-invalid-feedback>
                            </b-row>
                          </b-col>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label class="label-selector">{{ $t('booking.pick.fields.video.thumbnail_title') }}
                        </label>
                      </th>
                      <td colspan="3">
                        <template
                            v-if="videoInformation && Object.keys(videoInformation).length > 0 || formData.video_title !== ''">
                          <div class="form-control" style="height: auto">
                            <img
                                :src="'https://i.ytimg.com/vi/' + (videoInformation.video_id || formData.video_id) +'/hqdefault.jpg'"
                                alt="" class="rounded-0 w-auto mr-2">
                            <a target="_blank"
                               :href="formData.video_url">{{
                                videoInformation.title || formData.video_title
                              }}
                            </a>
                          </div>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label class="label-selector">{{ $t('booking.pick.fields.video.channel_title') }}
                        </label>
                      </th>
                      <td colspan="3">
                        <template
                            v-if="videoInformation && Object.keys(videoInformation).length > 0 || formData.channel_name !== ''">
                          <div class="form-control" style="height: auto">
                            <template
                                v-if="Object.keys(channelInformation).length > 0 || formData.channel_image !== ''">
                              <img :src="(channelInformation.channel_image || formData.channel_image)"
                                   alt="" class="w-auto mr-2">
                              <input type="hidden" v-model="formData.channel_image">
                            </template>
                            <a target="_blank"
                               :href="'https://www.youtube.com/channel/' + (videoInformation.channelId || formData.channel_id)">
                              {{ videoInformation.channelTitle || formData.channel_name }}
                            </a>
                          </div>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label class="label-selector">{{ $t('booking.pick.fields.video.video_url') }}
                        </label>
                      </th>
                      <td class="padding-right-32">
                        <template
                            v-if="Object.keys(videoInformation).length > 0 || formData.video_title !== ''">
                          <div class="form-control" style="height: auto">
                            <a target="_blank"
                               :href="formData.video_url">{{
                                formData.video_url
                              }}</a>
                          </div>
                        </template>
                      </td>
                      <th class="padding-left-32">
                        <label class="label-selector">{{ $t('booking.pick.fields.video.number_of') }}</label>
                      </th>
                      <td>
                        <template
                            v-if="Object.keys(videoInformation).length > 0 || formData.video_title !== ''">
                          <div class="form-control" style="height: auto">
                            <span>{{ videoInformation.count || formData.count_exist_pick || 0 }}</span>
                          </div>
                        </template>
                        <template v-else>
                          <div class="form-control" style="height: auto">
                            <span>0</span>
                          </div>
                        </template>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </section>
        <section class="card card-layer mt-4">
          <b-col cols="12">
            <b-row>
              <b-col cols="6">
                <b-row>
                  <h4 class="mt-3 ml-3">{{ $t('booking.pick.fields.products.title') }}</h4>
                </b-row>
              </b-col>
              <b-col cols="6">
                <b-row class="float-right">
                  <b-button class="mt-3 mr-3 button-violet" v-b-modal.modal-manager-choose-product @click="resetModal">
                    {{ $t('booking.pick.fields.products.add_product') }}
                  </b-button>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <div class="card-body">

            <div class="col-md-12">
              <div class="row mt-3">

                <div class="table-hover table-responsive table-no-border mb-0 table-no-padding-horizontal">

                  <table class="table table-form">
                    <template v-for="(product, index) in addedProducts">
                      <tr :key="'matching-product-'+ index">
                        <th>
                          <label class="label-selector">{{
                              $t('booking.pick.fields.products.information') + ' #' + (parseInt(index) + 1)
                            }}</label>
                        </th>
                        <td class="padding-right-32">
                          <div class="form-control" style="height: auto">
                            <img class="table-show-image" :src="product.image[0]" alt="">
                            {{ product.name | truncateText(40, '...') }}
                          </div>
                        </td>
                        <th class="padding-left-32">
                          <label class="label-selector">{{ $t('booking.pick.fields.products.price') }}</label>
                        </th>
                        <td style="padding-right: 16px !important;">
                          <div class="form-control" style="height: auto">
                            {{ formatPrice(product.price) }}
                            <template v-if="product.sale_price > 0">`
                              <font-awesome-icon icon="fa-solid fa-arrow-right"/>
                              {{ formatPrice(product.sale_price) }}
                            </template>
                          </div>
                        </td>
                        <td>
                          <div class="form-control" style="height: auto">
                            <a href="javascript:;" v-on:click="closeProduct(product.id, index)"
                               class="text-danger">
                              <font-awesome-icon icon="fa-solid fa-circle-xmark"/>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
            </div>

            <b-modal id="modal-manager-choose-product" size="xl"
                     content-class="modal-designer"
                     :title="$t('booking.pick.fields.products.table_title')">

              <template #default>
                <b-container>
                  <b-row>
                    <b-col cols="12">
                      <b-row>
                        <!--                      <b-col cols="12" sm="12" md="3" class="pr-4">-->
                        <!--                        <b-row class="form-group">-->
                        <!--                          <label>{{ $t('booking.pick.fields.products.table_store') }}</label>-->
                        <!--                          <multiple-select-->
                        <!--                              v-model="store_id"-->
                        <!--                              :options="options.store_id"-->
                        <!--                              :allow-empty="true"-->
                        <!--                              :placeholder="$t('filter.placeholder_option')"-->
                        <!--                              open-direction="bottom"-->
                        <!--                              label="name"-->
                        <!--                              track-by="id"-->
                        <!--                              :show-labels="false"-->
                        <!--                              @search-change="value => getStores(value)"-->
                        <!--                          >-->
                        <!--                            <span slot="noResult">{{ $t('filter.no_result') }}</span>-->
                        <!--                          </multiple-select>-->
                        <!--                        </b-row>-->
                        <!--                      </b-col>-->
                        <b-col cols="12" sm="12" md="3" class="pr-4">
                          <b-row class="form-group">
                            <label>{{ $t('booking.pick.fields.products.table_category') }}</label>
                            <multiple-select
                                v-model="sub_category_id"
                                :options="options.category_id"
                                :allow-empty="true"
                                :placeholder="$t('filter.placeholder_option')"
                                open-direction="bottom"
                                label="name"
                                track-by="id"
                                :show-labels="false"
                                @search-change="value => searchCategories(value)"
                            >
                              <span slot="noResult">{{ $t('filter.no_result') }}</span>
                            </multiple-select>
                          </b-row>
                        </b-col>
                        <b-col cols="12" sm="12" md="3" class="pr-4 d-flex align-items-center">
                          <b-row class="form-group">
                            <label>{{ $t('booking.pick.fields.products.table_name') }}</label>
                            <input type="text" class="form-control" v-model="searchProduct.search">
                          </b-row>
                        </b-col>
                        <b-col cols="12" sm="12" md="3" class="d-flex align-items-center">
                          <b-row class="form-group">
                            <b-button class="button-violet" @click="searchingProduct()">
                              <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
                              {{ $t('booking.pick.fields.products.table_btn_search') }}
                            </b-button>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="12" class="mt-4">
                      <b-row>
                        <b-col cols="6">
                          <b-row>
                            {{ $t('booking.pick.fields.products.table_title_count') }}
                          </b-row>
                        </b-col>
                        <b-col cols="6">
                          <b-row class="float-right">
                            {{
                              $tc('booking.pick.fields.products.table_count', formData.product_ids.length || 0)
                            }}
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="12" class="mt-4">
                      <b-row>

                        <b-overlay class="w-100" :show="productLoading" rounded="lg">
                          <div v-if="!productLoading" class="table-hover table-responsive table-custom">
                            <datatable-layout
                                type="modal-matching-products"
                                :items="products"
                                :attributes="productAttributes"
                                :options="options"
                                :is-need-checkbox="true"
                                :is-paginate="true"
                                :page="productMeta.current_page || 1"
                                :checked-items="swapKeysAndValues(formData.product_ids)"
                                @checked="checkProducts"
                            >
                            </datatable-layout>
                          </div>
                        </b-overlay>
                        <div class="w-100">
                          <pagination-layout :meta="productMeta" :flag-paginate-special="10"
                                             @paginate="paginate"></pagination-layout>
                        </div>

                      </b-row>
                    </b-col>
                  </b-row>
                </b-container>
              </template>

              <template #modal-footer>
                <div class="w-100 text-center">
                  <b-button
                      class="mr-2 button-violet"
                      @click="submitModal()"
                  >
                    {{ $t('booking.pick.fields.products.table_submit') }}
                  </b-button>
                </div>
              </template>
            </b-modal>
          </div>
        </section>
        <section class="card card-layer mt-4">
          <h4 class="mt-3 ml-3">{{ $t('booking.pick.fields.pick.title') }}</h4>
          <div class="card-body">

            <div class="row">
              <div class="col-md-12">
                <div class="table-hover table-responsive table-no-border mb-0 table-no-padding-horizontal">

                  <table class="table table-form">
                    <tr>
                      <th>
                        <label class="label-selector">{{ $t('booking.pick.fields.pick.keyword') }}
                          <span class="text-danger">*</span>
                        </label>
                      </th>
                      <td class="padding-right-32">
                        <input-tags
                            v-model="formData.keyword"
                            v-validate="'required'"
                            :limit="1000"
                            data-vv-scope="booking_pick_validate"
                            :data-vv-as="$t('booking.pick.fields.pick.keyword')"
                            :class="errors.first('booking_pick_validate.keyword') ? 'is-invalid' : ''"
                            name="keyword"
                            :placeholder="$t('booking.pick.fields.pick.keyword') + '...'"
                        ></input-tags>

                        <b-form-invalid-feedback>
                          {{ errors.first('booking_pick_validate.keyword') }}
                        </b-form-invalid-feedback>
                      </td>
                      <th class="padding-left-32">
                        <label class="label-selector">{{ $t('booking.pick.fields.pick.status') }}
                        </label>
                      </th>
                      <td>
                        <multiple-select
                            v-validate="'required'"
                            data-vv-scope="booking_pick_validate"
                            :data-vv-as="$t('booking.pick.fields.pick.status')"
                            @close="validateMultipleSelect('status')"
                            name="status"
                            v-model="formData.status"
                            :options="options.status"
                            :allow-empty="false"
                            :placeholder="$t('filter.placeholder_option')"
                            open-direction="bottom"
                            label="name"
                            track-by="id"
                            :class="errors.first('booking_pick_validate.status') ? 'is-invalid' : ''"
                            :show-labels="false"
                        >
                          <span slot="noResult">{{ $t('filter.no_result') }}</span>
                        </multiple-select>
                        <b-form-invalid-feedback>{{
                            errors.first('booking_pick_validate.status')
                          }}
                        </b-form-invalid-feedback>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label class="label-selector">{{ $t('booking.pick.fields.pick.pick_type') }}
                          <span class="text-danger">*</span>
                        </label>
                      </th>
                      <td>
                        <b-form-group :style="{marginBottom: 0}">
                          <b-form-radio-group
                              v-model="formData.type_link"
                              :options="options.pick_type"
                              name="radio-options"
                          ></b-form-radio-group>
                        </b-form-group>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label class="label-selector">{{ $t('booking.pick.fields.pick.created_by') }}
                        </label>
                      </th>
                      <td class="padding-right-32">
                        {{ formData.created_name }}
                      </td>
                      <th class="padding-left-32">
                        <label class="label-selector">{{ $t('booking.pick.fields.pick.created_at') }}
                        </label>
                      </th>
                      <td>
                        {{ formData.created_at }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </section>
      </section>
    </div>
  </section>
</template>

<script>

import {mapGetters} from "vuex";
import _ from "lodash";
import InputTags from "@/components/dashboard/layouts/InputTags.vue";

export default {
  components: {
    InputTags
  },
  props: {
    currentData: {
      required: false,
      type: Object
    },
    isUpdate: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  async created() {
    await this.setFormData()
  },
  computed: {
    ...mapGetters(["searchStores", "filterSubCategories", "videoInformation", "products", "productMeta", "channelInformation"]),
    productAttributes() {
      return [
        // {
        //   name: this.$i18n.t('booking.pick.fields.products.table_store'),
        //   attribute: 'store_id',
        //   type: 'badge',
        //   badgeOption: 'store',
        //   badgeValue: 'name',
        //   className: "",
        //   isSort: true
        // },
        // {
        //   name: this.$i18n.t('booking.pick.fields.products.table_brand'),
        //   attribute: 'brand_id',
        //   type: 'badge',
        //   badgeOption: 'brand',
        //   badgeValue: 'name',
        //   className: "",
        //   isSort: true
        // },
        {
          name: this.$i18n.t('booking.product.attribute.image'),
          attribute: 'image',
          type: 'images',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.pick.fields.products.table_category'),
          attribute: 'sub_category_id',
          type: 'badge',
          badgeOption: 'sub_category',
          badgeValue: 'name',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.pick.fields.products.table_name'),
          attribute: 'name',
          type: 'string_trim',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.pick.fields.products.table_price'),
          attribute: 'price',
          type: 'price',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.pick.fields.products.table_sale_price'),
          attribute: 'sale_price',
          type: 'price',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.pick.fields.products.table_keyword'),
          attribute: 'keyword',
          type: 'string_trim',
          className: "",
          isSort: true
        },
      ];
    },
    options() {
      return {
        status: [
          {
            id: 0,
            name: this.$i18n.t('booking.pick.option_pending'),
            class: 'badge badge-warning'
          },
          {
            id: 1,
            name: this.$i18n.t('booking.pick.option_running'),
            class: 'badge badge-primary'
          }
        ],
        category_id: [...this.filterSubCategories],
        store_id: [...this.searchStores],
        pick_type: [
          {text: this.$i18n.t('booking.pick.fields.pick.pick_type_short'), value: '1'},
          {text: this.$i18n.t('booking.pick.fields.pick.pick_type_video'), value: '0'},
        ]
      };
    }
  },
  data() {
    return {
      searchVideoLoading: false,
      formData: {},
      cacheFormData: {},
      searchLoading: false,
      addedProducts: [],
      productCheckBox: {},
      productLoading: false,
      searchProduct: {
        page: 1,
        search: '',
        is_reload: false
      },
      store_id: '',
      sub_category_id: '',
      baseFilterSubCategories: {is_sub_category: 1},
      searchStore: ''
    }
  },
  methods: {

    async setFormData() {
      const vm = this
      await this.filterStores()
      await this.filterCategories()
      this.formData = this.currentData
      if (this.formData.status !== '' && this.options.status.length > 0) {
        this.formData.status = this.options.status.find((status) => {
          return status.id === vm.formData.status
        })
      }
      if (this.formData.products && this.formData.products.length > 0) {
        this.addedProducts = this.formData.products
        this.formData.products.forEach((product) => {
          vm.productCheckBox[product.id] = true
        })
      }
      if (typeof this.formData.keyword === 'string' && this.formData.keyword !== '') {
        this.formData.keyword = this.formData.keyword.split(',')
      }
      this.cacheFormData = JSON.parse(JSON.stringify(this.formData))
      await this.$validator.reset()
    },

    async validateMultipleSelect(name) {
      await this.$validator.validate('booking_pick_validate.' + name, this.formData[name])
    },

    async getVideoInformation() {
      const vm = this
      const valid = await this.$validator.validate('booking_pick_validate.video_id', this.formData.video_id)
      if (!valid) {
        return false
      }

      const youtubePattern = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gim
      const isYoutubeUrl = youtubePattern.test(this.formData.video_id)
      let videoId = ''
      if (isYoutubeUrl) {
        let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
        videoId = regex.exec(this.formData.video_id)[3];
      } else {
        videoId = this.formData.video_id
      }

      this.searchVideoLoading = true
      await this.$store.dispatch('getVideoInformation', {video_id: videoId})

      const thumbnails = this.videoInformation.thumbnail || null
      let videoImage = ''
      if (thumbnails) {
        const listThumbs = thumbnails.thumbnails
        if (Array.isArray(listThumbs) && listThumbs.length > 0) {
          const thumbnail = listThumbs.pop()
          videoImage = thumbnail.url
        } else {
          videoImage = `https://i.ytimg.com/vi/${videoId}/0.jpg`
        }
      } else {
        videoImage = `https://i.ytimg.com/vi/${videoId}/0.jpg`
      }
      this.formData.video_title = this.videoInformation.title || ''
      this.formData.video_image = videoImage
      this.formData.view_count = this.videoInformation.view_count || 0
      this.formData.video_url = isYoutubeUrl ? this.formData.video_id : 'https://www.youtube.com/watch?v=' + this.videoInformation.video_id
      this.formData.time = this.videoInformation.time
      this.formData.channel_id = this.videoInformation.channelId
      this.formData.channel_name = this.videoInformation.channelTitle
      await this.$store.dispatch('getChannelInformation', {channel_id: this.videoInformation.channelId})
      this.formData.channel_image = this.channelInformation.channel_image;
      this.$nextTick(async () => {
        await vm.$validator.validate('booking_pick_validate.video_title', this.formData.video_title)
      })
      this.searchVideoLoading = false
    },

    async submitModal() {
      this.$bvModal.hide('modal-manager-choose-product')
      await this.resetModal()
    },

    async resetModal() {
      await this.$store.dispatch('resetProduct')
      this.searchProduct = {
        page: 1,
        search: '',
        store_id: '',
        sub_category_id: '',
        is_reload: false
      }
      this.store_id = ''
      this.sub_category_id = ''
      await this.getProducts()
    },

    closeProduct(id, index) {
      this.addedProducts.splice(index, 1);
      delete this.productCheckBox[id]
      this.formData.product_ids.splice(this.formData.product_ids.indexOf(id), 1)
    },

    async getProducts() {
      this.productLoading = true
      await this.$store.dispatch('getProducts', this.searchProduct)
      this.productLoading = false
    },

    async getStores(value) {
      this.searchStore = value
      await this.filterStores()
    },

    async searchCategories(value) {
      this.baseFilterSubCategories.sub_category_id = value
      await this.filterCategories()
    },

    async filterStores() {
      this.searchStoreLoading = true
      await this.$store.dispatch('searchStore', this.searchStore)
      this.searchStoreLoading = false
    },

    async filterCategories() {
      this.searchStoreLoading = true
      await this.$store.dispatch('filterSubCategories', this.baseFilterSubCategories)
      this.searchStoreLoading = false
    },

    checkProducts(products) {
      const vm = this
      const productToCheck = _.keyBy(vm.products[vm.productMeta.current_page], 'id')
      const checked = {}
      products.forEach((id) => {
        const itemProduct = productToCheck[id]
        const itemChecked = vm.productCheckBox[id]
        if (itemProduct && !itemChecked) {
          vm.addedProducts.unshift(itemProduct)
          const productKeywords = itemProduct.keyword.split(',')
          for (const keyword of productKeywords) {
            if (!vm.formData.keyword.includes(keyword)) {
              vm.formData.keyword.push(keyword)
            }
          }
          vm.productCheckBox[id] = true
          checked[id] = true
        } else if (itemProduct && itemChecked) {
          checked[id] = true
        }
      })
      Object.keys(vm.productCheckBox).forEach((id) => {
        const itemProduct = productToCheck[id]
        if (itemProduct && !checked[id]) {
          const productKeywords = itemProduct.keyword.split(',')
          for (const keyword of productKeywords) {
            if (vm.formData.keyword.includes(keyword)) {
              const indexOfKeyword = vm.formData.keyword.indexOf(keyword)
              vm.formData.keyword.splice(indexOfKeyword, 1)
            }
          }
          delete vm.productCheckBox[id]
          vm.addedProducts.splice(vm.addedProducts.indexOf(id), 1);
        }
      })
      this.formData.product_ids = Object.keys(vm.productCheckBox)
      this.formData.product_ids = JSON.parse(JSON.stringify(this.formData.product_ids))
    },

    async paginate(page) {
      this.searchProduct.page = page
      await this.getProducts()
    },

    async searchingProduct() {
      this.searchProduct['store_id'] = this.store_id['id'] || 0
      this.searchProduct['sub_category_id'] = this.sub_category_id['id'] || 0
      this.searchProduct.is_reload = true
      await this.getProducts()
    },
  }
}
</script>

<style lang="scss">
.custom-control-label {
  margin-bottom: 0 !important;
}

.custom-control-inline {
  display: inline-flex;
  align-items: center;

  .custom-control-label {
    &::before {
      top: 50% !important;
      transform: translate(0, -50%);
    }

    &::after {
      top: 50% !important;
      transform: translate(0, -50%);
    }
  }
}
</style>