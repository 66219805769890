export default {
  methods: {
    regexPhone() {
      return /^0[0-9]{3}[\s]{1}[0-9]{3}[\s]{1}[0-9]{3,5}$/;
    },
    
    replacePhone(data) {
      return data.replace(/(\d{4})(\d{3})(\d{3,5})/, '$1 $2 $3')
    },
    
    regexEmail() {
      return /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    },
    
    validURL(str) {
      let pattern = new RegExp('^((https|http)?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(str);
    },
    
    isExistString(filter, string) {
      return string.indexOf(filter) > -1;
    },
    
    formatPrice(price, symbol = 'đ') {
      if (!isNaN(price) && price > 0 && price < 1000) {
        return price + ' ' + symbol;
      } else if (!isNaN(price) && price >= 1000) {
        return String(price).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' ' + symbol;
      }
      return '0 ' + symbol;
    },
    
    getTagInput(tags) {
      return tags.map((tag) => tag.text)
    },
    
    getMessageSocket(text, stop = 30, clamp = '...') {
      let textMessage = text
      
      if (typeof textMessage === 'undefined' || textMessage === '' || textMessage === null) {
        return "";
      }
      
      let convertText = ''
      if (textMessage && typeof textMessage === 'string') {
        convertText = textMessage
      } else if (textMessage.type === 'message') {
        convertText = textMessage.value
      } else if (textMessage.type === 'image') {
        convertText = 'Bạn nhận được ảnh từ người khác'
      } else {
        convertText = 'Bạn nhận được 1 sản phẩm từ người khác'
      }
      
      if (typeof convertText !== 'undefined' && convertText !== '' && convertText !== null) {
        return convertText.slice(0, stop) + (stop < convertText.length ? clamp || '...' : '');
      } else {
        return "";
      }
    },
    
    swapKeysAndValues(obj) {
      const swapped = Object.entries(obj).map(
        ([key, value]) => [value, key]
      )
      return Object.fromEntries(swapped)
    },
    
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    
    isObject(o) {
      return typeof o === 'object'
    },
    
    async beforeRouteLeave(to, from, next) {
      await this.nextTick();
      next()
    },
    
    async isValid(scope) {
      let vm = this;
      let isValid = await vm.$refs[scope].validate();
      return isValid;
    }
  }
}
