<template>
    <nav class="navbar" style="z-index: 1001;">
        <a href="javascript:;" @click="openMenuBar()" class="sidebar-toggler">
            <menu-icon></menu-icon>
        </a>
        <div class="navbar-content">
            <ul class="navbar-nav">
<!--                <header-payment v-if="!isMobile"></header-payment>-->
<!--                <dropdown-notifications></dropdown-notifications>-->
                <dropdown-language></dropdown-language>
                <dropdown-profile></dropdown-profile>
            </ul>
        </div>
    </nav>

</template>

<script>

import DropdownLanguage from "./components/header/DropdownLanguage.vue";
// import DropdownNotifications from "./components/header/HeaderNotifications.vue";
// import HeaderPayment from "./components/header/HeaderPayment.vue";
import DropdownProfile from "./components/header/DropdownProfile.vue";
import {MenuIcon} from "vue-feather-icons"

export default {
    components: {
        DropdownLanguage,
        // DropdownNotifications,
        // HeaderPayment,
        DropdownProfile,
        MenuIcon
    },
    computed:{
        isMobile(){
            return this.$device.mobile
        },
    },
    data(){
        return {
            collapsed: this.$device.mobile
        }
    },
    mounted() {
        this.$root.$on('toggle-menu-click', () => {
            this.collapsed = true
        })
    },
    methods: {
        openMenuBar() {
            if (this.collapsed) {
                this.collapsed = false;
            } else {
                this.collapsed = true
            }
            this.$root.$emit('toggle-menu-bar', this.collapsed)
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar{
  background: #F7F7FC !important;
  border: none;
  box-shadow: unset;
}
@import "@/assets/assets/plugins/flag-icon-css/css/flag-icon.min.css";
</style>
