<template>
  <div class="campaign-page w-100">
    <title-page :title="$t('booking.pick.title')" :icon="'ComputerDisk'"
                :is-has-create="true"
                :name="'BookingPickCreate'"></title-page>

    <div class="card-datatable d-flex pr-3 pl-3" :class="{'flex-wrap': isMobile}">
      <filter-box-layout :options="pickOptions" :attributes="pickAttributes" :is-show="isShowFilter"
                         @filtered="filtered"
                         @search-filter="searchOptionFilter"
                         @hide="hideFilter"></filter-box-layout>
      <div class="content content-datatable" :class="{'collapse-right': !isMobile && isShowFilter}">
        <div class="clearfix"></div>

        <filter-layout @searching="searching" @isShowFilter="click => isShowFilter = click"></filter-layout>

        <div class="w-100">
          <b-overlay :show="isLoading" rounded="lg">
            <datatable-layout
                type="product-booking"
                :key="`product-booking-table-${keyRerender}`"
                :items="bookingPicks"
                :attributes="pickAttributes"
                :options="pickOptions"
                :is-paginate="true"
                :is-update="true"
                :page="bookingPickMeta.current_page || 1"
                :is-open-action-right-click="true"
                @edit="redirectEditPage"
            >

            </datatable-layout>
          </b-overlay>

          <pagination-layout class="mt-3" :meta="bookingPickMeta" :flag-paginate-special="10"
                             @paginate="paginate"></pagination-layout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import TitlePage from "@/components/dashboard/layouts/TitlePage.vue";
import FilterLayout from "@/components/dashboard/layouts/FilterLayout.vue";

export default {
  components: {FilterLayout, TitlePage},
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    ...mapGetters(["bookingPicks", "bookingPickMeta"]),
    pickOptions() {
      return {
        status: [
          {
            id: 0,
            name: this.$i18n.t('booking.pick.option_pending'),
            class: 'badge badge-danger'
          },
          {
            id: 1,
            name: this.$i18n.t('booking.pick.option_running'),
            class: 'badge badge-primary'
          }
        ],
        type_link: [
          {
            id: 0,
            name: this.$i18n.t('booking.pick.fields.pick.pick_type_video'),
            class: 'badge badge-warning'
          },
          {
            id: 1,
            name: this.$i18n.t('booking.pick.fields.pick.pick_type_short'),
            class: 'badge badge-primary'
          }
        ],
      }
    },
    pickAttributes() {
      return [
        {
          name: this.$i18n.t('booking.pick.attribute.id'),
          attribute: 'id',
          type: 'string',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.pick.attribute.video_id'),
          attribute: 'video_id',
          type: 'video',
          className: "",
          isHiddenFilter: true,
          isSort: true
        },
        {
          name: this.$i18n.t('booking.pick.attribute.video_title'),
          attribute: 'video_title',
          type: 'string_trim',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.pick.attribute.video_url'),
          attribute: 'video_url',
          href: 'video_url',
          type: 'link',
          target: '_blank',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.pick.attribute.status'),
          attribute: 'status',
          type: 'option',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.pick.fields.pick.pick_type'),
          attribute: 'type_link',
          type: 'option',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.pick.attribute.price'),
          attribute: 'price',
          type: 'price',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.pick.attribute.price_sale'),
          attribute: 'sale_price',
          type: 'price',
          className: "",
          isSort: true
        }
      ]
    }
  },
  async created() {
    await this.getListBookingPicks()
  },
  data() {
    return {
      isLoading: true,
      isShowFilter: false,
      filter: {},
      payload: {
        page: 1,
        search: ""
      },
      keyRerender: 0,
      typingTimer: null
    }
  },
  methods: {

    searching(search) {
      const vm = this
      vm.payload.search = search
      vm.payload.is_reload = true
      vm.getListBookingPicks(true)
    },

    redirectEditPage(item) {
      this.$router.push({name: 'BookingPickUpdate', params: {id: item.video_id}})
    },

    reRender() {
      this.keyRerender++
    },

    async getListBookingPicks(render = true) {
      this.isLoading = true
      const payload = {
        ...this.payload,
        ...this.filter
      }
      const res = await this.$store.dispatch('getBookingPicks', payload)
      this.isLoading = false
      if (res.data && !res.data.isStorage || render) {
        this.reRender()
      }
    },

    async paginate(page) {
      this.payload.page = page
      await this.getListBookingPicks()
    },

    checkedProduct(campaigns) {
      this.productCheckbox = campaigns
    },

    async searchOptionFilter() {

    },

    hideFilter() {
      let vm = this;
      vm.isShowFilter = false;
    },

    filtered(filters) {
      let vm = this;
      vm.filter = {}
      filters.forEach(function (filter) {
        let value = filter.value;
        let attribute = filter.attribute;
        if (value !== null) {
          if (typeof value === 'object') {
            if (Array.isArray(value) && value.length > 0) {
              vm.filter[attribute] = value;
            } else {
              if (value.from !== null) {
                vm.filter['from_' + attribute] = value.from;
              }
              if (filter.value.to !== null) {
                vm.filter['to_' + attribute] = value.to;
              }
            }
          } else if (typeof value !== 'object') {
            vm.filter[attribute] = value;
          }
        }
      })
      vm.payload.is_reload = true
      vm.getListBookingPicks(true)
    }
  }
}
</script>

<style scoped>

</style>