export default {
    title: 'Brand AI',
    add_new: 'Add New',
    searching: 'Searching...',
    campaign_manage: 'Campaign',
    option_pending: 'Pending',
    option_running: 'Running',
    option_stop: 'Stopped',
    option_delete: 'Deleted',
    attribute: {
        id: 'ID',
        status: 'Status',
        advertiser: 'Advertiser',
        brand: 'Brand',
        name: 'Name',
        budget: 'Budget',
        count_product: 'Count Product',
        created_by: 'Created By',
        started_at: 'Started At',
        ended_at: 'Ended At',
        cpc: 'CPC',
        showing: 'Showing',
        cost: 'Cost',
        created_at: 'Created At',
    },
    create: {
        title: 'Create Brand AI',
        confirm: 'Confirm Create Brand AI',
        confirm_description: 'Are you sure about this decision?',
        confirm_save: 'Submit',
        confirm_cancel: 'Cancel',
        confirm_success_title: 'Success',
        confirm_success_description: 'Create Campaign Success!',
        confirm_fail_title: 'Fail',
        confirm_fail_description: 'Create Campaign Fail!',
    },
    update: {
        title: 'Update Brand AI',
        confirm: 'Confirm Update Brand AI',
        confirm_description: 'Are you sure about this decision?',
        confirm_save: 'Submit',
        confirm_cancel: 'Cancel',
        confirm_success_title: 'Success',
        confirm_success_description: 'Create Campaign Success!',
        confirm_fail_title: 'Fail',
        confirm_fail_description: 'Update Campaign Fail!',
    },
    field: {
        placeholder_budget: 'Input Budget...',
        placeholder_tags: 'Input...',
        placeholder_input: 'Input...',
    },
    gender:{
        all: 'All',
        male: 'Male',
        female: 'Female'
    },
    country:{
        american: 'American',
        korean: 'Korean',
        vietnam: 'Viet Nam'
    },
    ages: {
        '0_10': 'Under 10 old',
        '10_20': '10 to 20 old',
        '20_30': '20 to 30 old',
        '30_40': '30 to 40 old',
        '40_50': '40 to 50 old',
        other: 'Other age',
    },
    product_types: {
        table_title: 'Products Selected Manage',
        table_category: 'Product Category',
        table_product_name: 'Product Name',
        table_btn_search: 'Search',
        table_title_count: 'Products Selected',
        table_count: 'Total {count} item',
        table_submit: 'Submit',
        table_clear_selected: 'Remove selected products',
        table_brand: 'Brand',
        table_price: 'Price',
        table_keyword: 'Keyword',
        table_link: 'Link Type',
        number_product: 'Number Product',
        number_pick: 'Number Pick',
        type_original: 'Original',
        type_external: 'External',
        type_shopee: 'Shopee',
        type_tiki: 'Tiki',
    },
    info: {
        title: 'Campagin Information',
        name: 'Campaign Title',
        status: 'Campaign Status',
        advertiser: 'Advertiser',
        brand: 'Brand',
        banner: 'Banner',
        budget: 'Budget',
        time: 'Campaign Time',
        keyword: 'Keyword',
        to: 'To',
        started_at: 'Started At',
        ended_at: 'Ended At',
    },
    product: {
        title: 'Product Settings',
        brand: 'Brand',
        category: 'Category',
        keyword: 'Keyword',
        price: 'Price',
        from: 'From',
        to: 'To',
        search: 'Search',
        selected_title: 'Selected product information',
        product_from_price: 'From Price',
        product_to_price: 'To Price',
        product_checkbox: 'select product!',
        button_manage: 'Product Management',
        pick_matching: 'Pick matches the selected product',
        title_error_product_types: 'Warning',
        description_error_product_types: 'Please select product link type',
    },
    target: {
        title: 'Target settings',
        gender: 'Gender',
        country: 'Country',
        age: 'Age',
        age_from: 'Age From',
        age_to: 'Age To',
        interests: 'Interests',
        total_view_guess: 'Total views',
        check_target_setted: 'Check the installed target',
        expected_views: 'Expected views',
        average_views: 'Average number of views per day'
    },
    recommend: {
        title: 'Settings recommend product videos',
        subscriber: 'Channel\'s number of followers',
        view: 'Video views',
        category: 'Youtube Category'
    },
    save: 'Save',
    cancel: 'Cancel'
}
