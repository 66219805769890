<template>
  <div class="campaign-create-page w-100">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <b-col :cols="isMobile ? 12 : 6">
            <template v-if="isMobile">
              <h3>{{ $t('booking.store.create.title') }}</h3>
            </template>
            <template v-else>
              <h1>{{ $t('booking.store.create.title') }}</h1>
            </template>
          </b-col>
        </div>
        <div class="row mb-2">
          <breadcrumb-layout></breadcrumb-layout>
        </div>
      </div>
    </section>
    <b-form data-vv-scope="booking_store_validate" style="padding: 1rem 1rem 0 1rem">
      <b-overlay :show="isSubmit" rounded="lg">
        <booking-store-fields :current-data="baseData"></booking-store-fields>
      </b-overlay>
    </b-form>
    <div class="p-3">
      <b-card>
        <b-row>
          <b-col cols="12">
            <b-button @click="submitCreateStore" :disabled="isSubmit"
                      :style="{cursor: isSubmit ? 'no-drop' : 'pointer'}" variant="primary mr-4">
              <template v-if="isSubmit">
                <b-spinner small></b-spinner>
              </template>
              <template v-else>
                {{ $t('common.save') }}
              </template>
            </b-button>
            <b-button variant="outline-primary" v-on:click="cancelCreate">{{ $t('common.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import BreadcrumbLayout from "@/components/dashboard/layouts/BreadcrumbLayout"
import BookingStoreFields from "@/components/dashboard/components/pages/booking/BookingStoreFields"
import {mapGetters} from "vuex";

export default {
  components: {
    BreadcrumbLayout,
    BookingStoreFields
  },
  computed: {
    ...mapGetters(["bookingStores"]),
    isMobile() {
      return this.$device.mobile
    },
  },
  async mounted() {
    await this.getStores()
    if(this.bookingStores[1] && this.bookingStores[1].length >0){
      await this.$router.push({name: 'BookingStores'})
    }
    await this.loading()
    await this.$validator.reset()
  },
  data() {
    return {
      baseData: {
        name: '',
        sector: '',
        manager: '',
        manager_contact: '',
        manager_email: '',
        customer_advice_contact: '',
        city_id: '',
        district_id: '',
        ward_id: '',
        latitude: 21.028511,
        longitude: 105.804817,
        zalo_contact: '',
        start_time: '',
        end_time: '',
        is_booking: '',
        day_open: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false
        },
      },
      isSubmit: false
    }
  },
  methods: {
    async getStores() {
      await this.$store.dispatch('getBookingStores', {})
    },

    async cancelCreate() {
      await this.$router.push({name: 'BookingStores'})
    },

    async loading() {
      await this.$store.dispatch('getCities')
      await this.$store.dispatch('getSectors')
      await this.$store.dispatch('setResetLocationByKeyword')
    },

    async submitCreateStore() {
      const valid = await this.$validator.validate('booking_store_validate.*')
      if (!valid) {
        return false
      }
      const vm = this
      const description = this.$t('booking.store.create.confirm_description')
      this.$bvModal.msgBoxConfirm(description, {
        title: this.$t('booking.store.create.confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okTitle: this.$t('booking.store.create.confirm_save'),
        cancelTitle: this.$t('booking.store.create.confirm_cancel'),
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0 message-button-confirm'
      })
      .then(value => {
        if (!value) {
          return false
        }
        vm.createStore()
      })
    },

    async createStore() {
      this.isSubmit = true
      let payload = {}
      Object.keys(this.baseData).forEach((key) => {
        if (['sector', 'city_id', 'district_id', 'ward_id', 'advertiser_type'].includes(key)) {
          payload[key] = this.baseData[key]['id'] || 0
        } else {
          payload[key] = this.baseData[key]
        }
      })
      const res = await this.$store.dispatch('createStore', payload)
      if (res.data.status === 200) {
        this.$root.$emit('toast-event', {
          description: this.$t('booking.store.create.confirm_success_description'),
          title: this.$t('booking.store.create.confirm_success_title'),
          variant: 'success',
        })
        await this.$router.push({name: 'BookingStores'})
      } else {
        this.$root.$emit('toast-event', {
          description: this.$t('booking.store.confirm_fail_description'),
          title: this.$t('booking.store.create.confirm_fail_title'),
          variant: 'danger',
        })
      }
      this.isSubmit = false
    }
  }
}
</script>

<style scoped>

</style>