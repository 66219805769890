export default {
    state: () => ({
        appLanguage: localStorage.getItem("language") || 'vi'
    }),
    mutations: {
        setAppLanguage(state, language) {
            state.appLanguage = language;
            localStorage.setItem("language", language);
        }
    },
    actions: {

    },
    getters: {
        getAppLanguage: (state) => state.appLanguage
    }
}
