<template>
  <div class="campaign-create-page w-100">
    <title-page :title="$t('booking.store.update.title')" :icon="'ShoppingBags'"></title-page>
    <div class="pt-0 pr-3 pb-3 pl-3">
      <b-form data-vv-scope="booking_store_validate">
        <b-overlay :show="isSubmit || isLoading" rounded="lg">
          <booking-store-fields v-if="!isLoading" :current-data="baseData" :is-update="true"></booking-store-fields>
        </b-overlay>
      </b-form>
    </div>
    <div class="p-3">
      <b-card class="card-layer">
        <b-col cols="12">
          <b-row class="d-flex justify-content-end">
            <b-button class="button-violet mr-4" @click="submitUpdateStore" :disabled="isSubmit"
                      :style="{cursor: isSubmit ? 'no-drop' : 'pointer'}">
              <template v-if="isSubmit">
                <b-spinner small></b-spinner>
              </template>
              <template v-else>
                {{ $t('common.save') }}
              </template>
            </b-button>
            <b-button class="button-violet-outline" v-on:click="cancelUpdate">{{ $t('common.cancel') }}</b-button>
          </b-row>
        </b-col>
      </b-card>
    </div>
  </div>
</template>

<script>
import BookingStoreFields from "@/components/dashboard/components/pages/booking/BookingStoreFields"
import {mapGetters} from "vuex";
import TitlePage from "@/components/dashboard/layouts/TitlePage.vue";

export default {
  components: {
    TitlePage,
    BookingStoreFields
  },
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    ...mapGetters(["bookingStore", "currentIpInfo"]),
  },
  async mounted() {
    await this.loading()
    await this.$validator.reset()
  },
  data() {
    return {
      baseData: {},
      cacheBaseData: {},
      isSubmit: false,
      isLoading: true
    }
  },
  methods: {
    async cancelUpdate() {
      this.isLoading = true
      this.baseData = this.cacheBaseData
      this.baseData = JSON.parse(JSON.stringify(this.baseData))
      await this.$validator.reset()
      this.isLoading = false
    },

    async loading() {
      const vm = this
      await this.$store.dispatch('getCurrentIpInfo')
      await this.$store.dispatch('getSectors')
      await this.$store.dispatch('setResetLocationByKeyword')
      await this.$store.dispatch('getBookingStores')
      this.$nextTick(async () => {

        const enviroment = process.env.NODE_ENV || 'development'

        const isHasStore = vm.bookingStore && Object.keys(vm.bookingStore).length > 0

        vm.baseData = isHasStore ? vm.bookingStore : {
          name: '',
          sector: '',
          manager: '',
          manager_contact: '',
          manager_email: '',
          customer_advice_contact: '',
          city_id: '',
          district_id: '',
          ward_id: '',
          latitude: enviroment === 'production' ? vm.currentIpInfo.latitude : vm.currentIpInfo.lat,
          longitude: enviroment === 'production' ? vm.currentIpInfo.longitude : vm.currentIpInfo.lon,
          zalo_contact: '',
          start_time: '',
          end_time: '',
          is_booking: '',
          day_open: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
          },
          status: {
            id: 1
          }
        };
        if (isHasStore) {
          if (this.baseData.day_open !== '') {
            vm.baseData.day_open = JSON.parse(vm.baseData.day_open)
          } else {
            vm.baseData.day_open = {
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
              sunday: false
            }
          }
        }
        vm.cacheBaseData = JSON.parse(JSON.stringify(this.baseData))
        await this.$store.dispatch('getCities')
        if (isHasStore) {
          await vm.$store.dispatch('getDistricts', {city_id: vm.bookingStore.city_id})
          await vm.$store.dispatch('getWards', {district_id: vm.bookingStore.district_id})
        }
        vm.isLoading = false
      })
    },

    async submitUpdateStore() {
      const valid = await this.$validator.validate('booking_store_validate.*')
      if (!valid) {
        return false
      }
      const vm = this
      const description = this.$t('booking.store.update.confirm_description')
      this.$bvModal.msgBoxConfirm(description, {
        title: this.$t('booking.store.update.confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okTitle: this.$t('booking.store.update.confirm_save'),
        cancelTitle: this.$t('booking.store.update.confirm_cancel'),
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0 message-button-confirm'
      })
      .then(value => {
        if (!value) {
          return false
        }
        vm.updateStore()
      })
    },

    async updateStore() {
      this.isSubmit = true
      let payload = {}
      Object.keys(this.baseData).forEach((key) => {
        if (['sector', 'city_id', 'district_id', 'ward_id', 'status', 'advertiser_type'].includes(key)) {
          payload[key] = this.baseData[key]['id'] || 0
        } else {
          payload[key] = this.baseData[key]
        }
      })
      const storeId = this.bookingStore ? this.bookingStore.id : 0;
      let res = await this.$store.dispatch('updateStore', {data: payload, id: storeId})
      if (res.data.status === 200) {
        this.$root.$emit('toast-event', {
          description: this.$t('booking.store.update.confirm_success_description'),
          title: this.$t('booking.store.update.confirm_success_title'),
          variant: 'success',
        })
        await this.$store.dispatch('getBookingStores')
        // await this.$router.push({name: 'BookingStores'})
      } else {
        this.$root.$emit('toast-event', {
          description: this.$t('booking.store.update.confirm_fail_description'),
          title: this.$t('booking.store.update.confirm_fail_title'),
          variant: 'danger',
        })
      }
      this.isSubmit = false
    }
  }
}
</script>

<style scoped>

</style>