<template>
    <div class="sidebar-header">
        <a href="javascript:;" class="sidebar-brand">
            <img class="w-100" src="@/assets/img/common/Logo.png" alt="Vodaplay">
        </a>
    </div>
</template>

<script>
export default {
    name: "SidebarLogo"
}
</script>

<style scoped lang="scss">
.sidebar-header {
  background: var(--black-filter-161616);
  height: 60px;
  border-bottom: 1px solid var(--black-filter-161616);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  border-right: 1px solid var(--black-filter-161616);
  z-index: 999;
  width: 240px;
  transition: width 0.1s ease;
}

.is-mobile{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1;

    .sidebar-header {
        margin-bottom: 5px !important;
    }
}
</style>