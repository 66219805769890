import {BaseApi} from "@/lib/base-api";

const apiUrl = process.env.VUE_APP_API_URL + 'orders';

export const OrderServiceAPI = {

    createOrder(params) {
        return BaseApi.request('post', apiUrl + '/create-order', params);
    },

    confirmOrder(params) {
        return BaseApi.request('post', apiUrl + '/confirm-order', params);
    }
}
