import {BaseApi} from "@/lib/base-api";

const apiUrl = process.env.VUE_APP_CHAT_BASE_URL + 'api/';

export const ChatServiceAPI = {

    getAllConversations(params, userId, headers = {}) {
        return BaseApi.request('querySerialize', apiUrl + 'conversations/' + userId, params, '', headers);
    },

    getAllMessageByUser(params, conversationId, headers = {}) {
        return BaseApi.request('querySerialize', apiUrl + 'messages/' + conversationId, params, '', headers);
    },

    postMessage(params, headers = {}) {
        return BaseApi.request('post', apiUrl + 'messages', params, '', headers);
    },

    updateMessage(params, headers = {}) {
        return BaseApi.request('post', apiUrl + 'messages/update_order_status', params, '', headers);
    },
    
    getMessageUnRead(params, headers = {}){
        return BaseApi.request('post', apiUrl + 'messages/get_unread_message', params, '', headers);
    }
}
