<template>
  <div class="page-wrapper">
    <header-bar></header-bar>
    <div class="page-content">
      <b-overlay :show="isLoading" :class="isChatPage ? 'h-100' : ''">
        <router-view name="dashboardContent" :key="$route.fullPath"></router-view>
      </b-overlay>
    </div>
    <b-toast static no-auto-hide>
    </b-toast>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import HeaderBar from "@/components/dashboard/layouts/HeaderLayout";
import FooterBar from "@/components/dashboard/layouts/FooterLayout";
import {mapGetters} from "vuex";
import Vue from "vue";
import VueSocketIO from "vue-socket.io";
import stores from "@/stores";

export default {
  components: {
    HeaderBar,
    FooterBar
  },
  computed: {
    isChatPage() {
      return this.$route.name === 'ChatPage'
    },
    isMapPage() {
      return this.$route.name === 'MapContent'
    },
    noticeMessageTitle() {
      return this.$t('chat.chat_toast_title')
    },
    noticeMessageImage() {
      return this.$t('chat.chat_toast_images')
    },
    currentRouteName() {
      return this.$route.name;
    },
    ...mapGetters(['currentUser'])
  },
  created() {
    Vue.use(new VueSocketIO({
      debug: true,
      connection: process.env.VUE_APP_LINK_SOCKET_CHATTING,
      vuex: {
        stores
      },
      options: {
        allowEIO3: true
      }
    }))
    if (this.isChatPage) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
    const vm = this
    this.$root.$on('logout-loading', (load) => {
      vm.isLoading = load
    })
    // vm.$socket.emit("addUser", {
    //   userId: this.currentUser.chatting_id,
    //   conversationId: '',
    //   userEmail: this.currentUser.email
    // })
    // vm.$socket.subscribe("getMessage", (data) => {
    //   if (!vm.isChatPage && !vm.isMapPage) {
    //     const isImage = data.text.type === 'image'
    //     const description = isImage ? vm.noticeMessageImage : vm.getMessageSocket(data.text)
    //
    //     vm.$bvToast.toast(description, {
    //       title: vm.noticeMessageTitle,
    //       href: '/chats',
    //       variant: 'info',
    //       solid: true,
    //       autoHideDelay: 5000,
    //       appendToast: true
    //     })
    //
    //     vm.$store.dispatch('incrementCountPreview')
    //   }
    // })
    this.$root.$on('toast-event', (toast) => {
      vm.$bvToast.toast(toast.description, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    })
  },
  data() {
    return {
      isLoading: false
    }
  },
  sockets: {
    connect: function () {
      console.log('socket connected')
    },
  },
  watch: {
    '$route.name': {
      handler(after) {
        if (after === 'ChatPage') {
          document.getElementsByTagName('body')[0].style.overflow = 'hidden'
        } else {
          document.getElementsByTagName('body')[0].style.overflow = 'auto'
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import "vue-multiselect/dist/vue-multiselect.min.css";
@import "vue2-datepicker/index.css";

#b-toaster-top-right {
  .b-toast-info {
    .toast-body {
      color: #0c5460 !important;
    }
  }
}
</style>
