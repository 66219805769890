import { render, staticRenderFns } from "./SidebarLogo.vue?vue&type=template&id=7c31fd42&scoped=true&"
import script from "./SidebarLogo.vue?vue&type=script&lang=js&"
export * from "./SidebarLogo.vue?vue&type=script&lang=js&"
import style0 from "./SidebarLogo.vue?vue&type=style&index=0&id=7c31fd42&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c31fd42",
  null
  
)

export default component.exports