export default {
    title: 'Chat',
    search: 'Search...',
    send_image: 'Send',
    title_modal_image: 'Send File',
    text_drag_file: 'Drag files here to send them',
    caption_placeholder: 'Add a caption...',
    no_result_conversation: 'No contact person',
    chat_toast_images: 'Sent you a picture',
    chat_toast_title: 'You have a new message',
    filter_products: 'Product selection',
    filter_no_result: 'No products found',
    filter_no_options: 'Enter to search for products',
    no_price_text: 'Free',
    emoji:{
        search: 'Search',
        notfound: 'No Emoji Found',
        categories: {
            search: 'Search Results',
            recent: 'Frequently Used',
            people: 'Smileys & People',
            nature: 'Animals & Nature',
            foods: 'Food & Drink',
            activity: 'Activity',
            places: 'Travel & Places',
            objects: 'Objects',
            symbols: 'Symbols',
            flags: 'Flags',
            custom: 'Custom',
        }
    },
    none_select: 'No Choice',
    upload_image: 'Upload Image',
    create_order: 'Create Order',
    client_order: 'Order',
    your_order: 'Your Order',
    confirm_order: 'Confirm',
    confirm_order_text: 'Want to confirm an existing order?',
    note: 'Note',
    submit_order: 'Create Order',
    cancel_order: 'Cancel',
    create_order_error: 'Unable to create order. Please try again!',
    confirmed: 'Confirmed',
    unconfimred: 'Unconfimred',
}