export default {
    title: 'Filter',
    add_filter: 'Add Filter',
    no_result: 'No information found',
    from: 'From',
    to: 'To',
    placeholder_option: '-- Select Option --',
    placeholder_price: 'Input Price ...',
    placeholder_input: 'Input ...',
}
