export default {
    title: 'Trò chuyện',
    search: 'Tìm kiếm...',
    send_image: 'Gửi',
    title_modal_image: 'Gửi tệp',
    text_drag_file: 'Kéo tệp vào đây để gửi chúng',
    caption_placeholder: 'Thêm một chú thích...',
    no_result_conversation: 'Không có người liên hệ',
    chat_toast_images: 'Đã gửi cho bạn một hình ảnh',
    chat_toast_title: 'Bạn có tin nhắn mới',
    filter_products: 'Lựa chọn sản phẩm',
    filter_no_result: 'Không tìm thấy sản phẩm',
    filter_no_options: 'Nhập để tìm kiếm sản phẩm',
    no_price_text: 'Miễn phí',
    emoji:{
        search: 'Tìm kiếm',
        notfound: 'Không tìm thấy',
        categories: {
            search: 'Kết quả tìm kiếm',
            recent: 'Thường xuyên sử dụng',
            people: 'Mặt cười & Con người',
            nature: 'Động vật & Thiên nhiên',
            foods: 'Đồ ăn thức uống',
            activity: 'Activity',
            places: 'Hoạt động',
            objects: 'Các đối tượng',
            symbols: 'Ký hiệu',
            flags: 'Cờ',
            custom: 'Phong tục',
        }
    },
    none_select: 'Không chọn',
    upload_image: 'Tải ảnh lên',
    create_order: 'Tạo đơn hàng',
    your_order: 'Đơn hàng của bạn',
    client_order: 'Đơn hàng',
    confirm_order: 'Xác nhận',
    confirm_order_text: 'Bạn muốn xác nhận đơn hàng hiện tại?',
    note: 'Ghi chú',
    submit_order: 'Tạo đơn hàng',
    cancel_order: 'Hủy bỏ',
    create_order_error: 'Không thể tạo đơn hàng. Vui lòng thử lại!',
    confirmed: 'Đã xác nhận',
    unconfimred: 'Chưa xác nhận',
}