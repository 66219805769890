<template>
    <apex-chart type="heatmap"
                :style="{width: '100%'}"
                :options="chartOptions"
                :series="chartDataLocalize"
    >

    </apex-chart>
</template>

<script>
import Menu from "@/assets/img/report/Menu.svg";

export default {
  name: "HeatMapChart",
  props: {
    chartData: {
      type: Array,
      required: true
    },
  },

  computed: {
    isMobile() {
      return this.$device.mobile
    },
    chartDataLocalize() {
      const result = []
      for (const item of this.chartData) {
        result.push({
          name: this.$t(`report.booking.${item.name}`),
          data: item.data
        })
      }
      return result
    },
    chartOptions() {
      return {
        chart: {
          id: 'chart-area-call',
          toolbar: {
            tools: {
              download: '<img src="' + Menu + '" width="20">',
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          }
        },
        colors: ["#685EFF"],
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.chartXLabel
        },
        yaxis: {
          reversed: true,
        }
      };
    }
  },
  data() {
    return {
      chartXLabel: ['0', '2', '4', '6', '8', '10', '12', '14', '16', '18', '20', '22', '0'],
      Menu: Menu
    }
  },
}
</script>

<style scoped>

</style>