<template>
  <div class="product-page w-100">
    <title-page :title="$t('booking.product.title')" :icon="'WomenClothes'" :is-has-create="true"
                :name="'BookingProductCreate'"></title-page>
    <div class="card-datatable d-flex pr-3 pl-3" :class="{'flex-wrap': isMobile}">
      <filter-box-layout :options="productOptions" :attributes="productAttributes" :is-show="isShowFilter"
                         @filtered="filtered"
                         @search-filter="searchOptionFilter"
                         @hide="hideFilter">
      </filter-box-layout>
      <div class="content content-datatable" :class="{'collapse-right': !isMobile && isShowFilter}">
        <div class="clearfix"></div>

        <filter-layout @searching="searching" @isShowFilter="click => isShowFilter = click"></filter-layout>

        <div class="w-100">

          <b-overlay :show="isLoading" rounded="lg">
            <datatable-layout
                type="product-booking"
                :key="`product-booking-table-${keyRerender}`"
                :items="bookingProducts"
                :attributes="productAttributes"
                :options="productOptions"
                :is-paginate="true"
                :is-update="true"
                :page="bookingProductMeta.current_page || 1"
                @edit="redirectEditPage"
            >

            </datatable-layout>
          </b-overlay>
          <pagination-layout class="mt-3" :meta="bookingProductMeta" :flag-paginate-special="10"
                             @paginate="paginate"></pagination-layout>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import TitlePage from "@/components/dashboard/layouts/TitlePage.vue";
import FilterLayout from "@/components/dashboard/layouts/FilterLayout.vue";

export default {
  components: {
    TitlePage,
    FilterLayout
  },
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    ...mapGetters(["bookingProducts", "bookingProductMeta", "filterCategories", "filterSubCategories"]),
    productOptions() {
      return {
        status: [
          {
            id: 0,
            name: this.$i18n.t('booking.product.option_pending'),
            class: 'badge badge-warning'
          },
          {
            id: 1,
            name: this.$i18n.t('booking.product.option_running'),
            class: 'badge badge-primary'
          }
        ],
        is_booking: [
          {
            id: 0,
            name: this.$i18n.t('booking.product.is_booking_off'),
            class: 'badge badge-secondary'
          },
          {
            id: 1,
            name: this.$i18n.t('booking.product.is_booking_on'),
            class: 'badge badge-primary'
          }
        ],
        category_id: [...this.filterCategories],
        sub_category_id: [...this.filterSubCategories]
      }
    },
    productAttributes() {
      return [
        {
          name: this.$i18n.t('booking.product.attribute.id'),
          attribute: 'id',
          type: 'string',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.image'),
          attribute: 'image',
          type: 'images',
          className: "",
          isSort: true,
          isHiddenFilter: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.store_id'),
          attribute: 'store_name',
          type: 'string',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.status'),
          attribute: 'status',
          type: 'option',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.name'),
          attribute: 'name',
          type: 'string_trim',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.price'),
          attribute: 'price',
          type: 'price',
          className: "",
          isSort: true
        },
        {
          name: this.$i18n.t('booking.product.attribute.discount_price'),
          attribute: 'sale_price',
          type: 'price',
          className: "",
          isSort: true
        }
      ]
    }
  },
  async created() {
    await this.getListBookingProducts()
  },
  data() {
    return {
      isLoading: true,
      isShowFilter: false,
      filter: {},
      payload: {
        page: 1,
        search: ""
      },
      productCheckbox: [],
      keyRerender: 0,
      typingTimer: null
    }
  },
  methods: {

    searching(search){
      console.log(search)
      this.payload.search = search
      this.payload.is_reload = true
      this.getListBookingProducts(true)
    },

    redirectEditPage(item) {
      this.$router.push({name: 'BookingProductUpdate', params: {id: item.id}})
    },

    reRender() {
      this.keyRerender++
    },

    async getListBookingProducts(render = true) {
      this.isLoading = true
      const payload = {
        ...this.payload,
        ...this.filter
      }
      const res = await this.$store.dispatch('getBookingProducts', payload)
      this.isLoading = false
      this.payload.is_reload = false
      if (res.data && !res.data.isStorage || render) {
        this.reRender()
      }
    },

    async paginate(page) {
      this.payload.page = page
      await this.getListBookingProducts()
    },

    checkedProduct(campaigns) {
      this.productCheckbox = campaigns
    },

    async searchOptionFilter(filter) {
      if (filter.category_id) {
        this.baseFilterCategories.category_id = filter.category_id
        await this.$store.dispatch('filterCategories', this.baseFilterCategories)
      }
      if (filter.sub_category_id) {
        this.baseFilterCategories.sub_category_id = filter.sub_category_id
        await this.$store.dispatch('filterSubCategories', this.baseFilterSubCategories)
      }
    },

    hideFilter() {
      let vm = this;
      vm.isShowFilter = false;
    },

    filtered(filters) {
      let vm = this;
      vm.filter = {}
      filters.forEach(function (filter) {
        let value = filter.value;
        let attribute = filter.attribute;
        if (value !== null) {
          if (typeof value === 'object') {
            if (Array.isArray(value) && value.length > 0) {
              vm.filter[attribute] = value;
            } else {
              if (value.from !== null) {
                vm.filter['from_' + attribute] = value.from;
              }
              if (filter.value.to !== null) {
                vm.filter['to_' + attribute] = value.to;
              }
            }
          } else if (typeof value !== 'object') {
            vm.filter[attribute] = value;
          }
        }
      })
      vm.payload.is_reload = true
      vm.getListBookingProducts(true)
    }
  }
}
</script>

<style scoped>

</style>