import {Validator} from 'vee-validate'
import {PriceHelper} from "@/lib/price-helper";
import moment from "moment";

Validator.extend('required_if_has_age', {
  validate: (value, [otherValue]) => {
    return otherValue == 6 && value !== '' && value !== null && value >= 0
  },
  getMessage: (fieldName) => {
    return `The ${fieldName} is required field`
  },
}, {
  computesRequired: true
});

Validator.extend('is_bigger', {
  validate: (value, [otherValue]) => {
    return value > otherValue
  }
}, {
  hasTarget: true
});

Validator.extend('phone_number_mask', {
  validate: (value) => {
    value = value.split(' ').join('');
    let regex = /^([0-9]{8,10})$/
    // let regex = /^(0[35789]|01[2689]|1[2689]00)+([0-9]{8}|[0-9]{4,6})$/
    return regex.test(value)
  }
});

Validator.extend('required_select', {
  validate: (value) => {
    return (typeof value === 'string' && value !== '') || (typeof value === 'object' && Object.keys(value).length !== 0) || (Array.isArray(value) && value.length !== 0)
  }
}, {
  computesRequired: true
});

Validator.extend('is_youtube_video', {
  validate: (value) => {
    const youtubePattern = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gim
    const isYoutubeUrl = youtubePattern.test(value)
    if (isValidUrl(value)) {
      if (isYoutubeUrl) {
        return true;
      } else {
        return false
      }
    } else {
      return (typeof value === 'string' && value !== '')
    }
  }
}, {
  computesRequired: true
});

const isValidUrl = urlString => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
}

Validator.extend('required_time', {
  validate: (value) => {
    return typeof value === 'string' && value !== ''
  }
}, {
  computesRequired: true
});

Validator.extend('is_bigger_if_exist', {
  validate: (value, [otherValue]) => {
    if (value !== '' && otherValue !== '') {
      const convertValue = PriceHelper.convertToNumber(value.toString())
      const convertOtherValue = PriceHelper.convertToNumber(otherValue.toString())
      return value > 0 && otherValue > 0 && convertValue > convertOtherValue
    } else {
      return true
    }
  }
}, {
  hasTarget: true
});

Validator.extend('is_less_if_exist', {
  validate: (value, [otherValue]) => {
    if (value !== '' && otherValue !== '') {
      const convertValue = PriceHelper.convertToNumber(value.toString())
      const convertOtherValue = PriceHelper.convertToNumber(otherValue.toString())
      return convertValue > 0 && convertOtherValue > 0 && convertValue < convertOtherValue
    } else {
      return true
    }
  }
}, {
  hasTarget: true
});

Validator.extend('is_less_multiple_if_exist', {
  validate: (value, {otherRef, baseRef}) => {
    const otherElement = document.getElementById(otherRef)
    const baseElement = document.getElementById(baseRef)
    const otherValue = otherElement.value
    const baseValue = baseElement.value
    
    if (value === '') {
      return new Promise(resolve => {
        resolve({
          valid: false,
          data: {message: 'is_empty'}
        });
      })
    }
    const convertOtherValue = PriceHelper.convertToNumber(otherValue.toString())
    const convertValue = PriceHelper.convertToNumber(value.toString())
    
    if (otherValue !== '' && convertOtherValue > 0) {
      return new Promise(resolve => {
        resolve({
          valid: convertValue < convertOtherValue,
          data: {message: 'invalid', refField: otherElement.dataset['vvAs']}
        });
      })
    }
    
    return new Promise(resolve => {
      resolve({
        valid: baseValue !== '' && convertValue < PriceHelper.convertToNumber(baseValue.toString()),
        data: {message: 'invalid', refField: baseElement.dataset['vvAs']}
      });
    })
  }
}, {
  paramNames: ['otherRef', 'baseRef']
});

Validator.extend('is_bigger_time_if_exist', {
  validate: (value, [otherValue]) => {
    const validTime = moment(value.toString(), 'HH:mm').isValid()
    const validCheck = moment(otherValue.toString(), 'HH:mm').isValid()
    if (!validTime || !validCheck) {
      return false;
    }
    const checkTime = moment(value.toString(), 'HH:mm')
    const checkWith = moment(otherValue.toString(), 'HH:mm')
    return checkTime.isAfter(checkWith)
  }
}, {
  hasTarget: true
});


export default {
  en: {
    messages: {
      required: (fieldName) => `The ${fieldName} value is required field`,
      required_time: (fieldName) => `The ${fieldName} value is required field`,
      required_if_has_age: (fieldName) => `The ${fieldName} value is required field`,
      required_select: (fieldName) => `Please ${fieldName}`,
      phone_number_mask: (fieldName) => `The ${fieldName} value is not valid`,
      url: (fieldName) => `The ${fieldName} value is not valid`,
      email: (fieldName) => `The ${fieldName} value is not valid`,
      is_youtube_video: (fieldName) => `The ${fieldName} value is not valid`,
      is_bigger: (field, params) => {
        return `Field ${field} must be bigger ${params[0]}`
      },
      max: (field, params) => {
        return `Field ${field} up to ${params[0]} characters`
      },
      is_bigger_if_exist: (field, params) => {
        return `Field ${field} must be bigger field ${params[0]}`
      },
      is_less_multiple_if_exist: (field, params, data) => {
        if (data.message === 'is_empty') {
          return `The ${field} value is required field`
        }
        return `Field ${field} must be less field ${data.refField}`
      },
      is_less_if_exist: (field, params) => {
        return `Field ${field} must be less field ${params[0]}`
      },
      is_bigger_time_if_exist: (field, params) => {
        return `Field ${field} must be bigger field ${params[0]}`
      }
    }
  },
  kr: {
    messages: {
      required: (fieldName) => `The ${fieldName} is required field`,
      required_time: (fieldName) => `The ${fieldName} is required field`,
      required_if_has_age: (fieldName) => `The ${fieldName} is required field`,
      required_select: (fieldName) => `Please ${fieldName}`,
      phone_number_mask: (fieldName) => `The ${fieldName} is not valid`,
      url: (fieldName) => `The ${fieldName} is not valid`,
      email: (fieldName) => `The ${fieldName} is not valid`,
      is_youtube_video: (fieldName) => `The ${fieldName} is not valid`,
      is_bigger: (field, params) => {
        return `Trường ${field} phải lớn hơn ${params[0]}`
      },
      max: (field, params) => {
        return `Field ${field} up to ${params[0]} characters`
      },
      is_bigger_if_exist: (field, params) => {
        return `Field ${field} must be bigger field ${params[0]}`
      },
      is_less_multiple_if_exist: (field, params, data) => {
        if (data.message === 'is_empty') {
          return `The ${field} value is required field`
        }
        return `Field ${field} must be less field ${data.refField}`
      },
      is_less_if_exist: (field, params) => {
        return `Field ${field} must be less field ${params[0]}`
      },
      is_bigger_time_if_exist: (field, params) => {
        return `Field ${field} must be bigger field ${params[0]}`
      }
    }
  },
  vi: {
    messages: {
      required: (fieldName) => `${fieldName} là trường bắt buộc`,
      required_time: (fieldName) => `${fieldName} là trường bắt buộc`,
      required_if_has_age: (fieldName) => `${fieldName} là trường bắt buộc`,
      required_select: (fieldName) => `Xin mời ${fieldName}`,
      phone_number_mask: (fieldName) => `${fieldName} không đúng định dạng`,
      url: (fieldName) => `${fieldName} không đúng định dạng`,
      email: (fieldName) => `${fieldName} không đúng định dạng`,
      is_youtube_video: (fieldName) => `${fieldName} không đúng định dạng`,
      is_bigger: (field, params) => {
        return `Trường ${field} phải lớn hơn ${params[0]}`
      },
      max: (field, params) => {
        return `Trường ${field} tối đa ${params[0]} kí tự`
      },
      is_bigger_if_exist: (field, params) => {
        return `Trường ${field} phải lớn hơn trường ${params[0]}`
      },
      is_less_multiple_if_exist: (field, params, data) => {
        if (data.message === 'is_empty') {
          return `${field} là trường bắt buộc`
        }
        return `Trường ${field} phải nhỏ hơn trường ${data.refField}`
      },
      is_less_if_exist: (field, params) => {
        return `Trường ${field} phải nhỏ hơn trường ${params[0]}`
      },
      is_bigger_time_if_exist: (field, params) => {
        return `Trường ${field} phải lớn hơn trường ${params[0]}`
      }
    }
  }
}