<template>
  <section class="col-sm-12 mt-5">
    <div class="row">

      <div class="card col-sm-6">
        <h4 class="mt-3 ml-3">{{ $t('campaign.target.title') }}</h4>
        <div class="card-body">

          <div class="row">

            <table class="table table-bordered table-form mt-2">
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.target.gender') }} <span class="text-danger">*</span></label>
                </th>
                <td>
                  <div class="form-check mr-3">
                    <b-form-radio-group
                        class="form-group-checked"
                        :class="errors.first('campaign_validate.campaign_gender') ? 'is-invalid' : ''"
                        v-model="requestData.gender"
                    >
                      <b-form-radio class="d-inline-flex align-items-center"
                                    v-model="requestData.gender"
                                    v-for="option in options.gender"
                                    name="campaign_gender"
                                    :data-vv-as="$t('campaign.target.gender')"
                                    v-validate="'required'"
                                    data-vv-scope="campaign_validate"
                                    :checked="requestData.gender === option.id"
                                    :key="'gender-'+option.id" :value="option.id">
                        {{ option.name }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback>{{errors.first('campaign_validate.campaign_gender')}}</b-form-invalid-feedback>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.target.country') }} <span class="text-danger">*</span></label>
                </th>
                <td>
                  <div class="form-check mr-3">
                    <b-form-checkbox-group
                        v-model="requestData.countries"
                        class="form-group-checked"
                        :class="errors.first('campaign_validate.campaign_country') ? 'is-invalid' : ''"
                    >
                      <b-form-checkbox
                          v-for="option in options.countries"
                          v-model="requestData.countries"
                          name="campaign_country"
                          :data-vv-as="$t('campaign.target.country')"
                          v-validate="'required'"
                          data-vv-scope="campaign_validate"
                          :checked="requestData.countries.includes(option.id)"
                          :key="'country-'+option.id"
                          :value="option.id">
                        {{ option.name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                    <b-form-invalid-feedback>{{errors.first('campaign_validate.campaign_country')}}</b-form-invalid-feedback>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.target.age') }} <span class="text-danger">*</span></label>
                </th>
                <td>
                  <div class="form-check mr-3">
                    <b-form-radio-group v-model="requestData.ages"
                                        class="d-block break-space form-group-selected"
                                        buttons
                                        button-variant="secondary"
                                        :class="errors.first('campaign_validate.campaign_age') ? 'is-invalid' : ''"
                    >
                      <b-form-radio
                          v-model="requestData.ages"
                          name="campaign_age"
                          :data-vv-as="$t('campaign.target.age')"
                          v-validate="'required'"
                          data-vv-scope="campaign_validate"
                          :checked="requestData.ages === option.id"
                          class="mr-2 mb-2" v-for="option in options.ages" :key="'age-'+option.id"
                          :value="option.id">
                        {{ option.name }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback>{{errors.first('campaign_validate.campaign_age')}}</b-form-invalid-feedback>
                  </div>
                  <div v-if="requestData.ages === 6" class="d-flex align-items-center w-100 mt-2 flex-wrap">
                    <div class="d-inline-flex w-100" :class="errors.first('campaign_validate.campaign_age_to') || errors.first('campaign_validate.campaign_age_from') ? 'is-invalid' : ''">
                      <b-form-input min="0"
                                    max="150"
                                    ref="campaign_age_from_ref"
                                    name="campaign_age_from"
                                    :data-vv-as="$t('campaign.target.age_from')"
                                    v-validate.continues="'required_if_has_age:'+requestData.ages"
                                    data-vv-scope="campaign_validate"
                                    v-model="requestData.age_from"
                                    :class="errors.first('campaign_validate.campaign_age_from') ? 'is-invalid' : ''"
                                    :placeholder="$t('campaign.target.age_from')">
                      </b-form-input>
                      <b-form-input min="0"
                                    max="150"
                                    name="campaign_age_to"
                                    :data-vv-as="$t('campaign.target.age_to')"
                                    v-validate.continues="'required_if_has_age:'+requestData.ages+'|is_bigger:campaign_age_from_ref'"
                                    data-vv-scope="campaign_validate"
                                    :class="errors.first('campaign_validate.campaign_age_to') ? 'is-invalid' : ''"
                                    v-model="requestData.age_to"
                                    :placeholder="$t('campaign.target.age_to')">
                      </b-form-input>
                    </div>
                    <b-form-invalid-feedback class="d-block w-100">{{errors.first('campaign_validate.campaign_age_from')}}</b-form-invalid-feedback>
                    <b-form-invalid-feedback class="d-block w-100">{{errors.first('campaign_validate.campaign_age_to')}}</b-form-invalid-feedback>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.target.interests') }} <span class="text-danger">*</span></label>
                </th>
                <td>
                  <input-tags
                      v-model="requestData.interests"
                      v-validate="'required'"
                      data-vv-scope="campaign_validate"
                      :data-vv-as="$t('campaign.target.interests')"
                      :class="errors.first('campaign_validate.campaign_interests') ? 'is-invalid' : ''"
                      name="interests"
                      :placeholder="$t('campaign.field.placeholder_tags') + '...'"
                  ></input-tags>
                  <b-form-invalid-feedback>{{errors.first('campaign_validate.campaign_interests')}}</b-form-invalid-feedback>
                </td>
              </tr>
            </table>

          </div>

        </div>
      </div>

      <div class="card col-sm-6">
        <h4 class="mt-3 ml-3">{{ $t('campaign.target.total_view_guess') }}</h4>
        <div class="card-body">

          <div class="row">

            <table class="table table-bordered table-form mt-2">
              <tr>
                <td colspan="2">
                  <div id="slider-outer-div-guess" class="slider-outer-div">
                    <div id="slider-div-guess" class="view-rate">
                      <div id="slider-range-guess" class="w-100 mt-4">
                        <vue-slider :value="viewShow"
                                    :min="0" :max="maxGuess"
                                    :tooltip-formatter="value => formatPrice(value, '')"
                                    :disabled="true"
                                    :interval="0.01"
                                    :process-style="{background:'#FF0000'}"
                        ></vue-slider>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-center" colspan="2">
                  {{ $t('campaign.target.check_target_setted') }}
                </th>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.target.expected_views') }}</label>
                </th>
                <td>
                  <span id="expected_views">
                    {{ formatPrice(viewGuess[0], '') }} ~ {{ formatPrice(viewGuess[1], '') }}
                  </span>
                </td>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <label>{{ $t('campaign.target.average_views') }}</label>
                </th>
                <td>
                  <span id="view_per_day">
                    {{ formatPrice(viewPerDay[0], '') }} ~ {{ formatPrice(viewPerDay[1], '') }}
                  </span>
                </td>
              </tr>
            </table>

          </div>

        </div>
      </div>

    </div>
  </section>
</template>

<script>

import InputTags from "@/components/dashboard/layouts/InputTags.vue";

export default {
  components:{
    InputTags
  },
  props: {
    formData: {
      required: true,
      type: Object
    },
    cacheData: {
      required: true,
      type: Object
    },
    options: {
      required: true,
      type: Object
    },
    viewGuess: {
      required: true,
      type: Array
    },
    viewPerDay: {
      required: true,
      type: Array
    }
  },
  computed: {
    maxGuess() {
      return this.viewShow[1] > 0 ? this.viewShow[1] * 1.5 : 1
    },
    viewShow() {
      return this.viewGuess
    }
  },
  watch: {
    requestData: {
      handler(after) {
        const vm = this
        const keys = ['ages', 'age_from', 'age_to', 'gender']
        Object.keys(after).forEach((key) => {
          if (keys.includes(key) && vm.cacheData[key] !== after[key]) {
            vm.$emit('calculator-view-guess', true)
          }
          if (key === 'countries' && vm.cacheData[key].toString() !== after[key].toString()) {
            vm.$emit('calculator-view-guess', true)
          }
        })
      },
      deep: true
    }
  },
  data() {
    return {
      interests: '',
      currentInterests: [],
      requestData: this.formData
    }
  },
  methods: {
    validateRequireInputAge(){
      return this.requestData.ages === 6
    },
    validateInputAge() {

    }
  }
}
</script>

<style lang="scss">
.custom-control-label {
  padding-top: 1px !important;
}

.custom-control-label::before {
  top: 0 !important;
}

.custom-control-label::after {
  top: 0 !important;
}
</style>