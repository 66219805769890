<template>
  <section class="col-sm-12 mt-5">
    <div class="row">

      <div class="card col-sm-6">
        <h4 class="mt-3 ml-3">{{$t('campaign.recommend.title')}} </h4>
        <div class="card-body">

          <div class="row">

            <table class="table table-bordered table-form mt-2">
              <tr>
                <th class="bg-light-custom">
                  <label>{{$t('campaign.recommend.subscriber')}} <span class="text-danger">*</span></label>
                </th>
                <td class="view-rate">
                  <div class="slider-outer-div">
                    <div class="view-min-rate">{{ formatPrice(requestData.subscriber[0], '') }}</div>
                    <div class="view-max-rate">{{ formatPrice(requestData.subscriber[1], '') }}</div>
                    <div class="w-100 mt-4">
                      <vue-slider ref="vue-slider-subscriber" :value="requestData.subscriber"
                                  :min="0" :max="100000000"
                                  @drag-end="dragStopSub"
                                  :tooltip-formatter="value => formatPrice(value, '')"
                      ></vue-slider>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <label>{{$t('campaign.recommend.view')}} <span class="text-danger">*</span></label>
                </th>
                <td class="view-rate">
                  <div class="slider-outer-div">
                    <div class="view-min-rate">{{ formatPrice(requestData.video_view[0], '') }}</div>
                    <div class="view-max-rate">{{ formatPrice(requestData.video_view[1], '') }}</div>
                    <div id="slider-range-view" class="w-100 mt-4">
                      <vue-slider ref="vue-slider-video_view" :value="requestData.video_view"
                                  :min="0" :max="100000000"
                                  @drag-end="dragStopView"
                                  :tooltip-formatter="value => formatPrice(value, '')"
                      ></vue-slider>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="bg-light-custom">
                  <label>{{$t('campaign.recommend.category')}} <span class="text-danger">*</span></label>
                </th>
                <td>
                  <div class="form-check mr-3 d-block break-space">
                    <b-form-checkbox-group
                        v-model="requestData.youtube_categories"
                        buttons
                        button-variant="secondary"
                        class="d-block break-space form-group-selected"
                        :class="errors.first('campaign_validate.campaign_youtube_categories') ? 'is-invalid' : ''"
                    >
                      <b-form-checkbox class="mr-2 mb-2"
                                       name="campaign_youtube_categories"
                                       v-model="requestData.youtube_categories"
                                       :data-vv-as="$t('campaign.recommend.category')"
                                       v-validate="'required'"
                                       data-vv-scope="campaign_validate"
                                       :checked="requestData.youtube_categories === option.id"
                                       v-for="option in options.youtubeCategories"
                                       :key="'y-category-'+option.id"
                                       :value="option.id">
                        {{ option.name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                    <b-form-invalid-feedback>{{errors.first('campaign_validate.campaign_youtube_categories')}}</b-form-invalid-feedback>
                  </div>
                </td>
              </tr>
            </table>

          </div>

        </div>
      </div>

    </div>
  </section>
</template>

<script>

export default {
  props: {
    formData: {
      required: true,
      type: Object
    },
    cacheData: {
      required: true,
      type: Object
    },
    options: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      interests: '',
      currentInterests: [],
      requestData: this.formData
    }
  },
  watch: {
    requestData: {
      handler(after) {
        const vm = this
        Object.keys(after).forEach((key) => {
          if(key === 'youtube_categories' && vm.cacheData[key].toString() !== after[key].toString()){
            vm.$emit('calculator-view-guess', true)
          }
        })
      },
      deep: true
    }
  },
  methods:{
    dragStopView(){
      this.requestData.video_view = this.$refs['vue-slider-video_view'].getValue()
      this.$emit('calculator-view-guess', true)
    },
    dragStopSub(){
      this.requestData.subscriber = this.$refs['vue-slider-subscriber'].getValue()
      this.$emit('calculator-view-guess', true)
    }
  }
}
</script>

<style scoped lang="scss">
.view-rate {
  position: relative;
  width: 100%;
}

.view-min-rate {
  position: absolute;
  top: 5px;
  left: 05px;
}

.view-max-rate {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>