export default {
    statistical: 'Bảng thống kê',
    campaign: 'Chiến dịch',
    product: 'Sản phẩm',
    booking: 'Đặt chỗ',
    booking_store: 'Cửa hàng',
    booking_store_create: 'Tạo cửa hàng',
    booking_store_update: 'Cập nhật cửa hàng',
    booking_product: 'Sản phẩm cửa hàng',
    booking_store_product_create: 'Create Store Product',
    booking_store_product_update: 'Update Store Product',
    booking_pick: 'Tập hợp cửa hàng',
    booking_pick_create: 'Tạo tập hợp cửa hàng',
    booking_pick_update: 'Cập nhật tập hợp cửa hàng',
    brand_ai: 'Tự động',
    brand_ai_create: 'Tạo chiến dịch',
    brand_ai_update: 'Cập nhật chiến dịch',
    report: 'Báo cáo',
    report_booking: 'Báo cáo',
    cost: 'Quản lí chi phí',
    notice: 'Quản lí thông báo',
    chat: 'Trò chuyện',
    chat_page: 'Trò chuyện',
    user_profile: 'Chỉnh sửa hồ sơ',
    coupon: 'Phiếu giảm giá',
    coupon_create: 'Tạo phiếu giảm giá',
    coupon_update: 'Cập nhật phiếu giảm giá',
    setting: 'Cài đặt'
}
