<template>
  <b-col cols="6">
    <template v-if="isCompletedProduct">
      <b-modal size="lg" centered id="bv-modal-preview-product" hide-header hide-footer>
        <div class="d-flex justify-content-center" style="padding: calc(48px - 1rem)">
          <div class="close-button cursor-pointer" @click="$bvModal.hide('bv-modal-preview-product')">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M20.0002 36.6666C29.1668 36.6666 36.6668 29.1666 36.6668 19.9999C36.6668 10.8333 29.1668 3.33325 20.0002 3.33325C10.8335 3.33325 3.3335 10.8333 3.3335 19.9999C3.3335 29.1666 10.8335 36.6666 20.0002 36.6666Z"
                  fill="#090909" stroke="white" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.2832 24.7165L24.7165 15.2832" stroke="white" stroke-width="1.875" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M24.7165 24.7165L15.2832 15.2832" stroke="white" stroke-width="1.875" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>

          </div>
          <div class="preview-product">
            <div class="preview-image">
              <b-carousel
                  id="carousel-1"
                  :interval="4000"
                  controls
              >
                <template v-for="(image, index) in baseData.image">
                  <b-carousel-slide :key="'preview-image-modal-' + index">
                    <template #img>
                      <img
                          class="d-block image-preview"
                          width="375"
                          height="375"
                          :src="image"
                          alt="Vodaplay"
                      >
                      <div class='other-product carousel-extra-content'>
                        {{ $t('booking.product.preview_other_product') }}
                      </div>
                    </template>
                    <template #default>
                      <div class="discounts">
                        <template v-if="percentDiscount > 0">
                          <div class="discount-percent">
                            <svg width="29" height="26" viewBox="0 0 29 26" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 26L0 11.9694H7.80769V0H21.1923V11.9694H29L14.5 26Z" fill="white"/>
                            </svg>
                            <span>{{ percentDiscount }}</span>
                            <span>%</span>
                          </div>
                        </template>
                        <template v-if="hasComboDiscount">
                          <div class="discount-combo">
                            <svg viewBox="0 0 77 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="77" height="50" fill="#26AB9A"/>
                              <path
                                  d="M12.3438 11.2524H15.5123L18.2001 17.8465H18.3242L21.012 11.2524H24.1806V21.9027H21.6892V15.3607H21.6013L19.0582 21.8351H17.4662L14.923 15.3243H14.8352V21.9027H12.3438V11.2524Z"
                                  fill="white"/>
                              <path
                                  d="M32.1808 11.2524H34.7342V18.1221C34.7342 18.916 34.5464 19.6076 34.1708 20.197C33.7952 20.7829 33.2714 21.2371 32.5995 21.5595C31.9275 21.8785 31.147 22.0379 30.2579 22.0379C29.3586 22.0379 28.5729 21.8785 27.9009 21.5595C27.229 21.2371 26.7069 20.7829 26.3347 20.197C25.9626 19.6076 25.7765 18.916 25.7765 18.1221V11.2524H28.3351V17.8985C28.3351 18.266 28.4144 18.5936 28.5729 18.8813C28.7348 19.1691 28.9605 19.3944 29.25 19.5574C29.5395 19.7203 29.8754 19.8018 30.2579 19.8018C30.6404 19.8018 30.9747 19.7203 31.2607 19.5574C31.5502 19.3944 31.7759 19.1691 31.9378 18.8813C32.0998 18.5936 32.1808 18.266 32.1808 17.8985V11.2524Z"
                                  fill="white"/>
                              <path
                                  d="M38.6277 21.9027H35.8779L39.4496 11.2524H42.8559L46.4276 21.9027H43.6778L41.1915 13.9358H41.1088L38.6277 21.9027ZM38.2607 17.7113H44.0086V19.6666H38.2607V17.7113Z"
                                  fill="white"/>
                              <path d="M12 30.3304V28.2399H20.9474V30.3304H17.7375V38.8901H15.2151V30.3304H12Z"
                                    fill="white"/>
                              <path
                                  d="M23.1649 38.8901H20.415L23.9867 28.2399H27.3931L30.9648 38.8901H28.2149L25.7287 30.9232H25.646L23.1649 38.8901ZM22.7979 34.6987H28.5457V36.654H22.7979V34.6987ZM26.7211 25.4109H28.3855C28.382 26.0627 28.1374 26.5862 27.6515 26.9814C27.1691 27.3766 26.5143 27.5742 25.6873 27.5742C24.8603 27.5742 24.2038 27.3766 23.7179 26.9814C23.2355 26.5862 22.996 26.0627 22.9995 25.4109H24.6484C24.6449 25.6397 24.7224 25.8477 24.881 26.0349C25.0395 26.2221 25.3083 26.3157 25.6873 26.3157C26.056 26.3157 26.3196 26.2239 26.4782 26.0401C26.6367 25.8564 26.7176 25.6466 26.7211 25.4109ZM25.6925 41.9999C25.3341 41.9999 25.0274 41.8803 24.7724 41.6411C24.5209 41.4019 24.3951 41.1159 24.3951 40.7831C24.3951 40.4502 24.5226 40.1642 24.7776 39.925C25.0326 39.6858 25.3375 39.5662 25.6925 39.5662C26.0509 39.5662 26.3558 39.6858 26.6074 39.925C26.8624 40.1642 26.9899 40.4502 26.9899 40.7831C26.9899 41.1159 26.8624 41.4019 26.6074 41.6411C26.3558 41.8803 26.0509 41.9999 25.6925 41.9999Z"
                                  fill="white"/>
                              <path
                                  d="M41.114 28.2399V38.8901H38.943L34.7303 32.7434H34.6631V38.8901H32.1045V28.2399H34.3065L38.4726 34.3763H38.5605V28.2399H41.114Z"
                                  fill="white"/>
                              <path
                                  d="M49.6698 31.7189C49.6112 31.5005 49.5251 31.3081 49.4114 31.1417C49.2976 30.9718 49.1581 30.8279 48.9927 30.71C48.8273 30.5922 48.6377 30.5037 48.4241 30.4448C48.2104 30.3824 47.9761 30.3512 47.7211 30.3512C47.2077 30.3512 46.7632 30.476 46.3875 30.7256C46.0154 30.9752 45.7276 31.3393 45.5243 31.8177C45.321 32.2927 45.2194 32.8699 45.2194 33.5494C45.2194 34.2324 45.3176 34.8148 45.514 35.2967C45.7104 35.7786 45.9947 36.1461 46.3669 36.3992C46.739 36.6523 47.1904 36.7788 47.7211 36.7788C48.1898 36.7788 48.5861 36.7025 48.91 36.55C49.2373 36.394 49.4855 36.1738 49.6543 35.8896C49.8232 35.6053 49.9076 35.2707 49.9076 34.8859L50.3831 34.9431H47.809V33.0658H52.3732V34.4751C52.3732 35.4285 52.1716 36.2449 51.7684 36.9244C51.3687 37.6039 50.8173 38.1257 50.1143 38.4897C49.4148 38.8537 48.6102 39.0358 47.7005 39.0358C46.6908 39.0358 45.8035 38.8156 45.0385 38.3753C44.2735 37.935 43.6773 37.3075 43.25 36.4928C42.8227 35.6746 42.6091 34.7039 42.6091 33.5806C42.6091 32.707 42.7383 31.9321 42.9967 31.2561C43.2552 30.5766 43.6153 30.001 44.077 29.5296C44.5422 29.0581 45.0798 28.701 45.6897 28.4583C46.3031 28.2156 46.963 28.0943 47.6694 28.0943C48.2828 28.0943 48.8531 28.1827 49.3803 28.3595C49.911 28.5363 50.3797 28.7859 50.7863 29.1083C51.1964 29.4307 51.5289 29.8138 51.7839 30.2576C52.0389 30.7014 52.1974 31.1885 52.2594 31.7189H49.6698Z"
                                  fill="white"/>
                              <path
                                  d="M66.0018 11V38.8901H59.315V17.2916H59.1526L52.9531 21.1047V15.2761L59.7888 11H66.0018Z"
                                  fill="#FCFC19"/>
                            </svg>
                          </div>
                        </template>
                        <template v-if="hasHourDiscount">
                          <div class="discount-per-hours">
                            <svg width="52" height="50" viewBox="0 0 52 50" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <rect width="52" height="50" fill="#ED4D2D"/>
                              <rect x="3" y="4" width="46" height="42" rx="2" fill="#DD230D"/>
                              <path
                                  d="M26.005 37.0008C31.8961 37.0008 36.6717 32.2252 36.6717 26.3341C36.6717 20.4431 31.8961 15.6675 26.005 15.6675C20.114 15.6675 15.3384 20.4431 15.3384 26.3341C15.3384 32.2252 20.114 37.0008 26.005 37.0008Z"
                                  stroke="#FCFC19" stroke-width="2.57143" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                              <path
                                  d="M26.0023 21V26.3333L28.6689 29M16.6689 13L12.6689 17M39.3356 17L35.3356 13M18.5089 33.9333L15.3356 37M33.5223 33.8933L36.6689 37"
                                  stroke="#FCFC19" stroke-width="2.57143" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            </svg>
                          </div>
                        </template>
                      </div>
                    </template>
                  </b-carousel-slide>
                </template>
              </b-carousel>
              <div class="product-info">
                <div class="general-info">
                  <div class="product-preview-title">
                    {{ baseData.name }}
                  </div>
                  <div class="product-preview-price">
                    <template v-if="topDiscount">
                      <div class="discount-display-price">
                        {{ topDiscount.discount_price | formatPrice('đ') }}
                      </div>
                      <div class="real-price">
                        {{ baseData.price | formatPrice('đ') }}
                      </div>
                      <div class="discount-rate">
                        -{{ percentDiscount }}%
                      </div>
                    </template>
                    <template v-else>
                      <div class="discount-display-price">
                        {{ baseData.price | formatPrice('đ') }}
                      </div>
                    </template>
                  </div>
                </div>
                <div class="store-info">
                  <div class="product-preview-store">
                    <div class="row">
                      <div class="col-6">
                        <div class="store-title">
                          {{ baseData.store_id.name }}
                        </div>
                        <div class="store-address">
                          {{ storeAddress }}
                        </div>
                        <div class="store-time d-flex flex-wrap">
                          <div class="w-100">
                            {{ $t('booking.product.preview_open_time') }}: {{ storeOpenTime }}
                          </div>
                          <div class="w-100">
                            {{ $t('booking.product.preview_close_time') }}: {{ closeDays }}
                          </div>
                        </div>
                      </div>
                      <div class="col-6 d-flex flex-wrap">
                        <div class="w-100">
                          <div class="connect">
                            {{ $t('booking.product.preview_connect') }}
                          </div>
                          <div class="bookmark">
                            <svg viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M12 16L6.5 12.7391L1 16V1.65217C1 1.47921 1.07243 1.31332 1.20136 1.19102C1.3303 1.06871 1.50516 1 1.6875 1H11.3125C11.4948 1 11.6697 1.06871 11.7986 1.19102C11.9276 1.31332 12 1.47921 12 1.65217V16Z"
                                  stroke="#090909" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="contact-with-store">
                  <div class="product-preview-button">
                    <b-button class="button-outline">{{ $t('booking.product.preview_see_option') }}</b-button>
                    <b-button class="button-dark">{{ $t('booking.product.preview_chat_with_shop') }}</b-button>
                  </div>
                  <div class="line-bottom">
                    <div class="content-line">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </template>
    <b-button class="button-violet cursor-pointer"
              :class="!isCompletedProduct ? 'disabled' : ''"
              v-on:click="isCompletedProduct ? $bvModal.show('bv-modal-preview-product') : false">
      {{ $t('booking.product.preview') }}
    </b-button>
  </b-col>
</template>

<script>
export default {
  props: {
    baseData: {
      required: true
    }
  },
  computed: {
    storeOpenTime() {
      const store = this.baseData.store_id
      if (Object.keys(store).length > 0) {
        return store.start_time_minutes + ' - ' + store.end_time_minutes
      }
      return ''
    },
    closeDays() {
      const store = this.baseData.store_id
      if (Object.keys(store).length > 0) {
        return store.status_day_close
      }
      return ''
    },
    storeAddress() {
      const store = this.baseData.store_id
      if (Object.keys(store).length > 0) {
        let ward = store.ward_name;
        let district = store.district_name;
        let city = store.city_name;
        ward = ward.replaceAll(/Phường|Xã/ig, '')
        district = district.replaceAll(/Quận|Huyện/ig, '')
        city = city.replaceAll(/Tỉnh|Thành phố/ig, '')
        return ward.trim() + ', ' + district + ', ' + city
      }
      return ''
    },
    isCompletedProduct() {
      const product = this.baseData
      return product.name !== '' && product.price > 0 && product.image.length > 0 && Object.keys(product.store_id).length > 0
    },
    topDiscount() {
      const discountLines = this.baseData.discount_lines
      if (discountLines && discountLines.length > 0) {
        const discounts = this.baseData.discount_lines.filter((item) => {
          return item.type === 'time'
        })
        if (discounts.length > 0) {
          const sortedDiscount = discounts.sort((a, b) => {
            if (a.discount_price === b.discount_price) {
              return 0
            }
            return (a.discount_price < b.discount_price) ? -1 : 1;
          })
          return sortedDiscount[0]
        }
      }
      return this.baseData.sale_price > 0 ? {
        id: this.baseData.discount_lines && this.baseData.discount_lines.length >= 0 ? this.baseData.discount_lines.length + 1 : 1,
        type: 'normal',
        discount_price: this.baseData.sale_price,
        time_start: '',
        time_end: '',
        combo_name: '',
        day_open: [],
        date_open: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false
        }
      } : null
    },
    percentDiscount() {
      const topDiscount = this.topDiscount
      if (topDiscount && this.baseData.price > 0) {
        return Math.floor(((this.baseData.price - topDiscount.discount_price) / this.baseData.price) * 100)
      }
      return 0
    },
    hasHourDiscount() {
      const discountLines = this.baseData.discount_lines
      let timeDiscount = false;
      if (discountLines) {
        discountLines.find((item) => {
          return item.type === 'time'
        })
      }
      return !!timeDiscount
    },
    hasComboDiscount() {
      const discountLines = this.baseData.discount_lines
      let comboDiscount = false
      if (discountLines) {
        comboDiscount = discountLines.find((item) => {
          return item.type === 'combo'
        })
      }
      return !!comboDiscount
    },
  }
}
</script>

<style lang="scss">
#bv-modal-preview-product {

  .close-button {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    position: absolute;
    top: -18px;
    right: -18px;
  }
}

.preview-product {
  width: 375px;

  .carousel-inner {

    .carousel-item {
      position: relative;

      .carousel-caption {
        left: 0;
        bottom: 0;
      }

      .image-preview {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      .other-product {
        position: absolute;
        top: 16px;
        left: 16px;
        background: #1A1D1F99;
        border-radius: 100px;
        padding: 6px 14px;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #FFFFFF;
      }

      .discounts {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        width: 242px;
        height: 50px;


        .discount-percent {
          color: var(--white);
          background: #FE2D55;
          font-size: 25px;
          font-weight: 800;
          line-height: 30px;
          text-align: left;
          padding: 10px;
          height: 50px;
          width: 113px;
          display: inline-flex;
          align-items: center;

          svg {
            margin-right: 5px;
          }
        }

        .discount-combo {
          width: 77px;
          height: 50px;
        }

        .discount-per-hours {
          width: 52px;
          height: 50px;
        }
      }
    }
  }

  .product-info {

    .general-info {
      border-color: #A4A4A499;
      border-style: solid;
      border-width: 0 1px 1px 1px;
      padding: 8px 16px;

      .product-preview-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        text-align: left;
        color: #090909;
        margin-bottom: 12px;

        white-space: normal;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .product-preview-price {
        display: inline-flex;
        align-items: center;


        .discount-display-price {
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          text-align: left;
          color: #FE2D55;
          margin-right: 8px;
        }

        .real-price {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          text-align: left;
          color: #A4A4A4;
          text-decoration: line-through;
          margin-right: 8px;
        }

        .discount-rate {
          background: #FE2D551A;
          color: #FE2D55;
          padding: 2px;
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          text-align: left;
        }
      }
    }
  }

  .store-info {
    border-color: #A4A4A499;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    padding: 8px 16px;
    position: relative;

    .store-title {
      font-size: 15px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      color: #090909;
      margin-bottom: 6px;
    }

    .store-address {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      color: #323232;
    }

    .store-time {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      color: #323232;
    }

    .connect {
      position: absolute;
      top: 6px;
      right: 16px;
      background: #F3F2F2;
      padding: 4px 6px;
      border: solid 1px #E8E8E8;
      border-radius: 2px;
      gap: 10px;

      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      text-align: left;
    }

    .bookmark {
      position: absolute;
      bottom: 2px;
      right: 16px;

      svg {
        width: 11px;
        height: 15px;
      }
    }
  }

  .contact-with-store {
    border-color: #A4A4A499;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    padding: 19px 16px 24px 16px;
    position: relative;


    .product-preview-button {
      display: inline-flex;

      .button-outline {
        border: solid 1px #090909;
        background: #FFFFFF;
        padding: 16px 22px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        margin-right: 9px;
        height: auto !important;
        color: #090909;
      }

      .button-dark {
        background: #090909;
        border: solid 1px #090909;
        padding: 16px 22px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        height: auto !important;
        color: #FFFFFF;
      }
    }

    .line-bottom {
      position: absolute;
      bottom: 6px;
      width: 100%;
      height: 5px;
      left: 0;
      display: flex;
      justify-content: center;

      .content-line {
        background: #090909;
        height: 5px;
        width: 30%;
        border-radius: 5px;
      }
    }
  }
}
</style>