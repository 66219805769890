<template>
  <div class="full-screen">
    <b-row>
      <b-col cols="12">
        <template v-if="usingMap !== 'google'">
          <open-street-map
              v-if="isLoaded"
              :height="'100vh'"
              :latitude="currentCenter.lat"
              :longitude="currentCenter.lng"
              :zoom="18"
              :is-tooltip="true"
              :tooltip-text="currentCenter.text"
          >
          </open-street-map>
        </template>
        <template v-else>
          <google-map
              v-if="isLoaded"
              :height="'100vh'"
              :latitude="currentCenter.lat"
              :longitude="currentCenter.lng"
              :zoom="18"
              :is-tooltip="true"
              :tooltip-text="currentCenter.text"
          >

          </google-map>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMap from "@/components/layouts/GoogleMap.vue";
import OpenStreetMap from "@/components/layouts/OpenStreetMap.vue";

export default {
  components: {OpenStreetMap, GoogleMap},
  async created() {
    await this.$store.dispatch('getCurrentIpInfo')
    document.querySelector('body').classList.add('page-google-map');
  },
  async mounted() {
    await this.setFormData()
  },
  computed: {
    usingMap(){
      return process.env.VUE_APP_USING_MAP
    }
  },
  data() {
    return {
      currentCenter: {},
      isLoaded: false
    }
  },
  methods: {
    async setFormData() {
      const latitude = this.$route.query.latitude
      const longitude = this.$route.query.longitude
      const tooltipText = this.$route.query.text
      this.currentCenter.lat = parseFloat(latitude)
      this.currentCenter.lng = parseFloat(longitude)
      this.currentCenter.text = tooltipText
      this.isLoaded = true
    }
  }
}
</script>

<style lang="scss">
.page-google-map {

  .grecaptcha-badge {
    display: none !important;
  }
}
</style>