<template>
  <div class="card card-layer card-filter" :class="{active: isShow, 'is-mobile': isMobile}">
    <div class="card-header bg-white"
         style="padding: 20px 16px; border-top-left-radius: 10px; border-top-right-radius: 10px">
      <h5>{{ $t('filter.title') }}</h5>
      <button v-if="isMobile" class="close-filter" @click="$emit('hide', true)">
        <font-awesome-icon icon="fa-solid fa-xmark"></font-awesome-icon>
      </button>
    </div>
    <div class="card-body">
      <div class="filter">
        <template v-if="filterOptions && filterOptions.length > 0">
          <template v-for="(option, index) in filterOptions">
            <template v-if="!option.isHiddenFilter">
              <div :key="option.id" class="filter-item" :class="index === filterOptions.length - 1 ? 'last-item' : ''">
                <h6 class="pb-2">{{ $t('filter.title') }} {{ index + 1 }}</h6>
                <button class="filter-close" @click="closeFilter(index)">
                  <font-awesome-icon icon="fa-solid fa-xmark"></font-awesome-icon>
                </button>
                <multiselect
                    style="margin-bottom: 12px"
                    :value="option"
                    :options="filterAttributes"
                    :allow-empty="false"
                    :placeholder="$t('filter.placeholder_option')"
                    label="name"
                    track-by="attribute"
                    :show-labels="false"
                    @input="object => getAttributeFilter(object, index)"
                >
                </multiselect>

                <template v-if="option.type === 'string' || option.type === 'string_trim'">
                  <input type="text" class="form-control" :value="option.value"
                         :placeholder="$t('filter.placeholder_input')"
                         @input="value => inputValue(value, index)">
                </template>
                <template v-if="option.type === 'number'">
                  <input type="text" v-mask="'###########################################'"
                         class="form-control" :value="option.value"
                         :placeholder="$t('filter.placeholder_input')"
                         @input="value => inputValue(value, index)">
                </template>

                <template v-if="option.type === 'number_range' || option.type === 'quantity'">
                  <label class="label-filter" :for="'filter-number-range-from-'+index">{{ $t('filter.from') }}</label>
                  <input type="text" v-mask="'###########################################'"
                         class="form-control" :value="option.value.from"
                         :placeholder="$t('filter.placeholder_input')"
                         @input="value => inputNumberRange(value, index, 'from')">
                  <label class="label-filter" :for="'filter-number-range-'+index">{{ $t('filter.to') }}</label>
                  <input type="text" v-mask="'###########################################'"
                         class="form-control" :value="option.value.to"
                         :placeholder="$t('filter.placeholder_input')"
                         @input="value => inputNumberRange(value, index, 'to')">
                </template>

                <template v-if="option.type === 'price'">
                  <label class="label-filter" :for="'filter-price-from-'+index">{{ $t('filter.from') }}</label>
                  <input-price :id="'filter-price-from-'+index" class="form-control"
                               :value="option.value.from"
                               :placeholder="$t('filter.placeholder_price')"
                               @input="value => inputValuePrice(value, index, 'from')"
                  ></input-price>
                  <label class="mt-2 label-filter" :for="'filter-price-'+index">{{ $t('filter.to') }}</label>
                  <input-price :id="'filter-price-to-'+index" class="form-control"
                               :value="option.value.to"
                               :placeholder="$t('filter.placeholder_price')"
                               @input="value => inputValuePrice(value, index, 'to')"
                  ></input-price>
                </template>
                <template v-if="option.type === 'option' || option.type === 'status'">
                  <multiselect
                      :value="getValueOption(options[option.attribute], option.value)"
                      :options="options[option.attribute]"
                      :allow-empty="false"
                      :placeholder="$t('filter.placeholder_option')"
                      label="name"
                      track-by="id"
                      @search-change="value => asyncFind(option, value)"
                      :show-labels="false"
                      @input="object => getValueOptionEmit(object, index)"
                  >
                  </multiselect>
                </template>
                <template v-if="option.type === 'date'">
                  <label class="label-filter">{{ $t('filter.from') }}</label>
                  <date-picker v-model="option.value.from" valueType="format"></date-picker>
                  <label class="label-filter">{{ $t('filter.to') }}</label>
                  <date-picker v-model="option.value.to" valueType="format"></date-picker>
                </template>
                <template v-if="option.type === 'badge' || option.type === 'badges'">
                  <multiselect
                      :multiple="true"
                      :taggable="true"
                      :value="getValueBadgeOption(generateOptions(options[option.attribute], option.attribute, index), option.value)"
                      :options="generateOptions(options[option.attribute], option.attribute, index)"
                      :allow-empty="true"
                      :placeholder="$t('filter.placeholder_option')"
                      open-direction="bottom"
                      label="name"
                      track-by="id"
                      :show-labels="false"
                      @input="object => getValueOptionBadgeEmit(object, index)"
                      @search-change="value => asyncFind(option, value)"
                      :max-height="600"
                      :show-no-results="false"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :hide-selected="true"
                      :internal-search="!option.isFetch"
                  >
                    <span slot="noResult">{{ $t('filter.no_result') }}</span>
                  </multiselect>

                </template>
              </div>
            </template>
          </template>
        </template>
        <div class="add-filter">
          <button class="button-violet-outline w-100" @click="addFilter()">
            <font-awesome-icon icon="fa-solid fa-plus"></font-awesome-icon>
            {{ $t('filter.add_filter') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import InputPrice from "./InputPrice"

export default {
  name: "FilterBox",
  props: {
    isShow: {
      type: Boolean,
      required: false,
      default: false
    },
    attributes: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: false
    }
  },
  components: {
    Multiselect,
    InputPrice,
    DatePicker
  },
  data() {
    return {
      filterOptions: [],
      typing: null,
      doneTypingInterval: 500,
      filterTyping: null,
      selectedBadgeOptions: {}
    }
  },
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    filterAttributes() {
      return this.attributes.filter((attribute) => {
        return !attribute.isHiddenFilter
      })
    }
  },
  watch: {
    filterOptions: {
      handler(after) {
        let vm = this;
        vm.$emit('filtered', after);
      },
      deep: true
    },
  },
  methods: {
    addFilter() {
      let vm = this;
      let newFilter = vm.attributes.find(function (attribute) {
        return attribute.attribute === 'id'
      })
      newFilter['value'] = null;
      vm.filterOptions.push(newFilter);
    },
    asyncFind(option, search = '') {
      let vm = this;
      if (option.isFetch) {
        clearTimeout(vm.typingTimer);
        vm.typingTimer = setTimeout(function () {
          const filter = {};
          filter[option.attribute] = search
          vm.$emit('search-filter', filter)
        }, vm.doneTypingInterval);
      }
    },
    generateOptions(options, attribute, index) {
      let vm = this;
      let result = options;
      if (typeof vm.selectedBadgeOptions[index] !== 'undefined') {
        let notExist = [];
        vm.selectedBadgeOptions[index].forEach(function (item) {
          let exist = options.find(function (it) {
            return item.id === it.id;
          })
          if (!exist) {
            notExist.push(item);
          }
        })
        result = result.concat(notExist);
      }
      return result
    },
    closeFilter(index) {
      let vm = this;
      vm.filterOptions.splice(index, 1);
      vm.filterOptions = JSON.parse(JSON.stringify(vm.filterOptions));
    },
    getAttributeFilter(object, index) {
      let vm = this;
      if (object.type === 'date') {
        object.value = {
          'from': null,
          'to': null
        }
      } else if (object.type === 'price' || object.type === 'number_range') {
        object.value = {
          'from': "",
          'to': ""
        }
      } else if (object.type === 'badge' || object.type === 'badges') {
        object.value = []
      }
      vm.filterOptions[index] = object;
      vm.filterOptions = JSON.parse(JSON.stringify(vm.filterOptions));
    },

    inputValue(value, index) {
      let vm = this;
      clearTimeout(vm.typingTimer);
      vm.typingTimer = setTimeout(function () {
        vm.filterOptions[index].value = value.target.value;
        vm.filterOptions = JSON.parse(JSON.stringify(vm.filterOptions));
      }, vm.doneTypingInterval);
    },

    inputNumberRange(value, index, where = 'from') {
      let vm = this;
      clearTimeout(vm.typingTimer);
      vm.typingTimer = setTimeout(function () {
        if (where === 'from') {
          vm.filterOptions[index].value.from = value.target.value;
        } else {
          vm.filterOptions[index].value.to = value.target.value;
        }
        vm.filterOptions = JSON.parse(JSON.stringify(vm.filterOptions));
      }, vm.doneTypingInterval);
    },

    inputValuePrice(value, index, where = 'from') {
      let vm = this;
      clearTimeout(vm.typingTimer);
      vm.typingTimer = setTimeout(function () {
        if (where === 'from') {
          vm.filterOptions[index].value.from = value;
        } else {
          vm.filterOptions[index].value.to = value;
        }
        vm.filterOptions = JSON.parse(JSON.stringify(vm.filterOptions));
      }, vm.doneTypingInterval);
    },

    getValueOption(options, value) {
      return options.find(function (option) {
        return option.id === value
      });
    },
    getValueOptionEmit(object, index) {
      let vm = this;
      vm.filterOptions[index].value = object.id;
      vm.filterOptions = JSON.parse(JSON.stringify(vm.filterOptions));
    },

    getValueBadgeOption(options, value) {
      return options.filter(function (option) {
        return value.includes(option.id)
      });
    },

    getValueOptionBadgeEmit(object, index) {
      let vm = this;
      vm.filterOptions[index].value = object.map(function (item) {
        return item.id;
      });
      vm.selectedBadgeOptions[index] = object;
      vm.filterOptions = JSON.parse(JSON.stringify(vm.filterOptions));
    },
  }
}
</script>

<style scoped lang="scss">
hr {
  margin-bottom: 8px;
  margin-top: 8px;
}

.mx-datepicker {
  width: 100% !important;
}
</style>
