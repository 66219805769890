import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from "vue-i18n"
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Particles from "particles.vue"
import {VueReCaptcha} from 'vue-recaptcha-v3'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faUserSecret} from '@fortawesome/free-solid-svg-icons'
import 'bootstrap'
import VueMask from 'v-mask'
import {BootstrapVue} from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import InputPrice from "@/components/dashboard/layouts/InputPrice"
import UploadImageLayout from "@/components/dashboard/layouts/UploadImageLayout"
import DatatableLayout from "@/components/dashboard/layouts/DatatableLayout"
import PaginationLayout from "@/components/dashboard/layouts/PaginationLayout"
import FilterBoxLayout from "@/components/dashboard/layouts/FilterBoxLayout"
import BreadcrumbLayout from "@/components/dashboard/layouts/BreadcrumbLayout"
import VueSlider from 'vue-slider-component'
import VeeValidate from 'vee-validate'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueDeviceDetector from "vue-device-detector"
import VueApexCharts from 'vue-apexcharts'

library.add(faUserSecret)
import App from './App.vue'
import messages from "./i18n"
import router from './routes'
import stores from './stores'
import mixin from './mixins'
import './filter'
import dictionary from '@/vee-validate'

require('@/assets/app.scss')
require('@/assets/designer.scss')
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import '@/assets/assets/fonts/feather-font/css/iconfont.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'vue-slider-component/theme/default.css'

Vue.use(VueDeviceDetector);
Vue.use(Particles)
Vue.use(Vuex)
Vue.use(VueI18n)
Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.mixin(mixin)
Vue.use(VueApexCharts)

Vue.component('apexChart', VueApexCharts)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('MultipleSelect', Multiselect)
Vue.component('DatePicker', DatePicker)
Vue.component('InputPrice', InputPrice)
Vue.component('UploadImageLayout', UploadImageLayout)
Vue.component('DatatableLayout', DatatableLayout)
Vue.component('PaginationLayout', PaginationLayout)
Vue.component('FilterBoxLayout', FilterBoxLayout)
Vue.component('BreadcrumbLayout', BreadcrumbLayout)
Vue.component('VueSlider', VueSlider)


let appLanguage = stores.getters.getAppLanguage
const allowLanguages = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
if (!allowLanguages.includes(appLanguage)) {
    appLanguage = 'vi';
}

const i18n = new VueI18n({
    locale: appLanguage,
    fallbackLocale: appLanguage,
    messages: messages
})

Vue.use(VueReCaptcha, {siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY})

Vue.use(VeeValidate, {
    classes: true,
    classNames: {
        invalid: 'is-invalid'
    },
    locale: appLanguage,
    events: 'change|input|blur',
    dictionary
})
Vue.use(BootstrapVue)
Vue.use(VueMask, {
    placeholders: {
        N: /^[^0-9_!@#$%^&*()\-=+{}[\]\\/\\`'~?,:"<>|]+$/
    }
});

Vue.use(VueGoogleMaps, {
    load: {
        region: 'KR',
        key: process.env.VUE_APP_MAP_API_KEY,
        language: appLanguage,
        libraries: 'places',
        v: 3.52
    },
    installComponents: true
})

Vue.config.productionTip = false

new Vue({
    i18n,
    router: router,
    store: stores,
    render: h => h(App),
}).$mount('#app')

if (module.hot) {
    module.hot.accept(['./i18n/en', './i18n/kr', './i18n/vi'], function () {
        i18n.setLocaleMessage('en', require('./i18n/en').default)
        i18n.setLocaleMessage('kr', require('./i18n/kr').default)
        i18n.setLocaleMessage('vi', require('./i18n/vi').default)
    })
}

if (process.env.NODE_ENV === 'production') {
    Vue.config.devtools = false
    Vue.config.debug = false
    Vue.config.silent = true
    Vue.config.productionTip = true
}
