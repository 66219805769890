export default {
    title: 'Tự động',
    add_new: 'Thêm mới',
    searching: 'Tìm kiếm...',
    campaign_manage: 'Chiến dịch',
    option_pending: 'Chưa bắt đầu',
    option_running: 'Đang chạy',
    option_stop: 'Tạm dừng',
    option_delete: 'Đã xóa',
    attribute: {
        id: 'ID',
        status: 'Trạng thái',
        advertiser: 'Người quảng cáo',
        brand: 'Thương hiệu',
        name: 'Tên chiến dịch',
        budget: 'Ngân sách',
        count_product: 'Số sản phẩm',
        created_by: 'Người đăng',
        started_at: 'Thời gian bắt đầu',
        ended_at: 'Thời gian kết thúc',
        cpc: 'CPC',
        showing: 'Số hiển thị',
        cost: 'Chi phí',
        created_at: 'Ngày tạo',
    },
    create: {
        title: 'Tạo chiến dịch',
        confirm: 'Xác nhận tạo chiến dịch',
        confirm_description: 'Bạn chắc chắn về quyết định này không?',
        confirm_save: 'Xác nhận',
        confirm_cancel: 'Hủy bỏ',
        confirm_success_title: 'Thành Công',
        confirm_success_description: 'Tạo Chiến dịch thành công',
        confirm_fail_title: 'Thật bại',
        confirm_fail_description: 'Tạo Chiến dịch thất bại',
    },
    update: {
        title: 'Cập nhật chiến dịch',
        confirm: 'Xác nhận cập nhật chiến dịch',
        confirm_description: 'Bạn chắc chắn về quyết định này không?',
        confirm_save: 'Xác nhận',
        confirm_cancel: 'Hủy bỏ',
        confirm_success_title: 'Thành Công',
        confirm_success_description: 'Cập nhật Chiến dịch thành công',
        confirm_fail_title: 'Thật bại',
        confirm_fail_description: 'Cập nhật Chiến dịch thất bại',
    },
    field: {
        placeholder_budget: 'Nhập ngân sách...',
        placeholder_tags: 'Nhập...',
        placeholder_input: 'Nhập...',
    },
    gender: {
        all: 'Tất cả',
        male: 'Nam giới',
        female: 'Nữ giới'
    },
    country: {
        american: 'Hoa Kì',
        korean: 'Hàn Quốc',
        vietnam: 'Việt Nam'
    },
    ages: {
        '0_10': 'Dưới 10 tuổi',
        '10_20': '10 đến 20 tuổi',
        '20_30': '20 đến 30 tuổi',
        '30_40': '30 đến 40 tuổi',
        '40_50': '40 đến 50 tuổi',
        other: 'Tuổi khác',
    },
    product_types: {
        table_title: 'Quản lí Product được chọn',
        table_category: 'Loại sản phẩm',
        table_product_name: 'Tên sản phẩm',
        table_btn_search: 'Tìm kiếm',
        table_title_count: 'Sản phẩm được chọn',
        table_count: 'Tổng {count} sản phẩm',
        table_submit: 'Xác nhận',
        table_clear_selected: 'Bỏ các sản phẩm đã chọn',
        table_brand: 'Thương hiệu',
        table_price: 'Giá sản phẩm',
        table_keyword: 'Từ khóa',
        table_link: 'Loại liên kết',
        number_product: 'Số sản phẩm',
        number_pick: 'Số tập hợp',
        type_original: 'Liên kết chính hãng',
        type_external: 'Liên kết bên ngoài',
        type_shopee: 'Liên kết shopee',
        type_tiki: 'Liên kết tiki',
    },
    info: {
        title: 'Thông tin chiến dịch',
        name: 'Tên chiến dịch',
        status: 'Trạng thái hoạt động',
        advertiser: 'Nhà quảng cáo',
        brand: 'Thương hiệu',
        banner: 'Biểu ngữ',
        budget: 'Ngân sách',
        time: 'Thời gian chương trình',
        keyword: 'Từ khóa',
        to: 'Đến',
        started_at: 'Thời gian bắt đầu',
        ended_at: 'Thời gian kết thúc',
    },
    product: {
        title: 'Cài đặt sản phẩm',
        brand: 'Thương hiệu',
        category: 'Danh mục',
        keyword: 'Từ khóa',
        price: 'Giá sản phẩm',
        from: 'Từ',
        to: 'Đến',
        search: 'Tìm kiếm',
        selected_title: 'Thông tin sản phẩm được chọn',
        product_from_price: 'Giá phía trước',
        product_to_price: 'Giá phía sau',
        product_checkbox: 'chọn sản phẩm',
        button_manage: 'Quản lí sản phẩm',
        pick_matching: 'Pick phù hợp với sản phẩm được chọn',
        title_error_product_types: 'Cảnh báo',
        description_error_product_types: 'Hãy lựa chọn loại liên kết sản phẩm',
    },
    target: {
        title: 'Cài đặt mục tiêu',
        gender: 'Giới tính',
        country: 'Quốc Gia',
        age: 'Độ tuổi',
        age_from: 'Độ tuổi khởi đầu',
        age_to: 'Độ tuổi kết thúc',
        interests: 'Sở thích người dùng',
        total_view_guess: 'Tổng số lượt xem',
        check_target_setted: 'Kiểm tra target đã cài đặt',
        expected_views: 'Số lượt xem dự kiến',
        average_views: 'Số lượt xem bình quân mỗi ngày'
    },
    recommend: {
        title: 'Cài đặt gợi ý product video',
        subscriber: 'Số lượt theo dõi của kênh',
        view: 'Số lượt xem video',
        category: 'Danh mục Youtube'
    },
    save: 'Xác nhận',
    cancel: 'Hủy bỏ',
}
