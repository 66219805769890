<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  props: {
    storeName: {
      type: String,
      required: true
    },
    latitude: {
      type: [String, Number],
      required: true
    },
    longitude: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    ...mapGetters(['locationByKeyword', 'currentIpInfo'])
  },
  data() {
    return {
      latitudeHard: this.latitude,
      longitudeHard: this.longitude,
      storeNameHard: this.storeName,
      keyword: '',
      now: moment().unix(),
      isLoading: false,
      loadedIframe: false
    }
  },
  created() {
    const vm = this;
    window.addEventListener('message', function (event) {
      if (event.data.event && event.data.event === "changeLocationGGMap") {
        vm.changePosition(event.data)
      }
    });
  },
  methods: {
    changePosition(payload = {}) {
      this.$emit('change-position', payload)
    },

    keyDownSearch(event) {
      if (event.keyCode === 13) {
        this.searchLocation()
      }
    },

    onLoadedIframe() {
      this.loadedIframe = true;
    },

    async searchLocation() {
      this.isLoading = true
      await this.$store.dispatch('setResetLocationByKeyword')
      await this.$store.dispatch('getLocationByKeyword', {keyword: this.keyword})
      this.isLoading = false
    },

    async changeCenter(item) {
      if (this.loadedIframe) {
        const iframe = document.querySelector("iframe");
        iframe.contentWindow.postMessage({
          payload: item,
          key: 'changePositionBySearch'
        }, "*")
        this.$emit('change-position', {
          lat: item.latitude,
          lng: item.longitude
        })
        await this.$store.dispatch('setResetLocationByKeyword')
      }
    },
  }
}
</script>
s
<template>
  <div class="google-map-section map-component">
    <b-col cols="12">
      <b-row>
        <div class="search-map map-component-search">
          <div class="d-inline-flex w-100 align-items-center">
            <b-input class="form-control mr-2" :placeholder="$t('common.google_search_title')"
                     v-model="keyword" v-on:keydown="keyDownSearch($event)"></b-input>
            <b-button class="button-violet" @click="searchLocation">
              <template v-if="isLoading">
                <b-spinner small variant="light"></b-spinner>
              </template>
              <template v-else>
                {{ $t('common.google_button_search') }}
              </template>
            </b-button>
          </div>
          <div class="search-map-result" :class="{open: locationByKeyword.length > 0}">
            <div :key="'search-map-item-' + index" v-for="(item, index) in locationByKeyword"
                 v-on:click="changeCenter(item)"
                 class="search-map-result-item">
              <h6>{{ item.address }}</h6>
            </div>
          </div>
        </div>
      </b-row>
    </b-col>
    <b-col cols="12">
      <b-row>
        <iframe id="iframe-mapcontent" class="iframe-mapcontent"
                :src="`https://map.vodaplay.vn/map_server.html?latitude=${latitudeHard}&longitude=${longitudeHard}&text=${storeNameHard}&t=${now}`"
                @load="onLoadedIframe"
        ></iframe>
      </b-row>
    </b-col>
  </div>
</template>

<style scoped lang="scss">
.iframe-mapcontent {
  border: none;
  width: 100%;
  height: 600px;
}
</style>