export default {
    title: 'Trò chuyện',
    search: 'Tìm kiếm...',
    send_image: 'Gửi',
    title_modal_image: 'Gửi tệp',
    text_drag_file: 'Kéo tệp vào đây để gửi chúng',
    caption_placeholder: 'Thêm một chú thích...',
    no_result_conversation: 'Không có người liên hệ',
    chat_toast_images: 'Đã gửi cho bạn một hình ảnh',
    chat_toast_title: 'Bạn có tin nhắn mới',
    filter_products: 'Lựa chọn sản phẩm',
    filter_no_result: 'Không tìm thấy sản phẩm',
    filter_no_options: 'Nhập để tìm kiếm sản phẩm',
    no_price_text: 'Miễn phí',
    emoji:{
        search: 'Search',
        notfound: 'No Emoji Found',
        categories: {
            search: 'Search Results',
            recent: 'Frequently Used',
            people: 'Smileys & People',
            nature: 'Animals & Nature',
            foods: 'Food & Drink',
            activity: 'Activity',
            places: 'Travel & Places',
            objects: 'Objects',
            symbols: 'Symbols',
            flags: 'Flags',
            custom: 'Custom',
        }
    },
    none_select: 'Không chọn',
    upload_image: 'Tải ảnh lên',
    create_order: 'Tạo đơn hàng',
    client_order: 'Đơn hàng',
    your_order: 'Đơn hàng của bạn',
    confirm_order: 'Xác nhận',
    confirm_order_text: 'Bạn muốn xác nhận đơn hàng hiện tại?',
    note: 'Ghi chú',
    submit_order: 'Xác nhận',
    cancel_order: 'Hủy bỏ',
    create_order_error: 'Không thể tạo đơn hàng. Vui lòng thử lại!',
    confirmed: 'Đã xác nhận',
    unconfimred: 'Chưa xác nhận',
}