import axios from "axios";

const qs = require('qs');
import JwtService from "@/lib/jwt-service";

axios.interceptors.response.use(response => {
    if (response.data && response.data.status_code === 404) {
        //return window.location.href = '/404';
    }
    return response;
}, error => {
    return Promise.resolve(error.response);
})

export const BaseApi = {
    headers(headers = {}) {
        let token = JwtService.getToken()
        if (typeof token !== 'undefined' && token !== 'undefined') {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        }

        if (!headers['Content-Type']) {
            axios.defaults.headers.common['Content-Type'] = 'application/json';
        }
    },

    async request(type, resource, params, slug = '', headers = {}, config = {}) {
        this.headers(headers);

        if (Object.keys(headers).length > 0) {
            if (!config.headers) {
                config.headers = {}
            }
            for (let keyHeader in headers) {
                config.headers[keyHeader] = headers[keyHeader];
            }
        }

        let response
        params = this.parseParams(params);
        if (type === 'querySerialize') {
            response = this.querySerialize(resource, params, config);
        }
        if (type === 'querySerializeBlobType') {
            response = this.querySerializeBlobType(resource, params, config);
        }
        if (type === 'query') {
            response = this.query(resource, params, config);
        }
        if (type === 'get') {
            response = this.get(resource, slug, config);
        }
        if (type === 'post') {
            response = this.post(resource, params, config);
        }
        if (type === 'postFormData') {
            response = this.post(resource, params, {...config, 'Content-Type': 'multipart/form-data'});
        }
        if (type === 'update') {
            response = this.update(resource, slug, params, config);
        }
        if (type === 'put') {
            response = this.put(resource, params, config);
        }
        if (type === 'putFormData') {
            response = this.put(resource, params, {...config, 'Content-Type': 'multipart/form-data'});
        }
        if (type === 'delete') {
            response = this.delete(resource, params, config);
        }
        if (type === 'refreshToken') {
            response = this.refreshToken(resource, params, config);
        }
        const parseResponse = await response
        const isRefresh = JwtService.getRefreshToken()
        if (!isRefresh || (parseResponse && parseResponse.data.status === 200) || (resource === process.env.VUE_APP_IP_LINK && parseResponse && parseResponse.status === 200)) {
            return response
        } else {
            if (parseResponse.data.status === 401) {
                const resPromise = await this.request('post', process.env.VUE_APP_API_URL + 'auth/refresh-token')
                if (resPromise && resPromise.data.status === 200) {
                    JwtService.saveToken(resPromise.data.data.access_token)
                    const retryResponse = await this.request(type, resource, params, slug, headers, config)
                    return Promise.resolve(retryResponse)
                } else {
                    JwtService.destroyToken()
                    window.location.href = '/login'
                }
            } else {
                return response;
            }
        }
    },

    refreshToken(resource, params, config = {}) {
        axios.defaults.headers.common['AuthorizationRefreshToken'] = JwtService.getRefreshToken();
        return axios.post(`${resource}`, params, config);
    },

    querySerialize(resource, parameters, config = {}) {
        let configRequest = this.parseConfig(config, {
            params: parameters,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
        return axios.get(resource, configRequest).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },

    querySerializeBlobType(resource, parameters, config = {}) {
        let configRequest = this.parseConfig(config, {
            responseType: 'blob',
            params: parameters,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
        return axios.get(resource, configRequest)
            .catch(error => {
                throw new Error(`[RWV] ApiService ${error}`);
            });
    },

    query(resource, params, config = {}) {
        let configRequest = this.parseConfig(config, {
            params: params,
        })
        return axios.get(resource, configRequest).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },

    get(resource, slug = "", config = {}) {
        return axios.get(`${resource}/${slug}`, config).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },

    post(resource, params, config = {}) {
        return axios.post(`${resource}`, params, config);
    },

    update(resource, slug, params, config = {}) {
        return axios.put(`${resource}/${slug}`, params, config);
    },

    put(resource, params, config = {}) {
        return axios.put(`${resource}`, params, config);
    },

    delete(resource, params, config = {}) {
        let configRequest = this.parseConfig(config, {
            params: params,
        })
        return axios.delete(resource, configRequest).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },

    parseConfig(config, baseConfig) {
        if (Object.keys(config).length > 0) {
            return {...config, ...baseConfig}
        } else {
            return baseConfig;
        }
    },

    parseParams(params) {
        if (!params) {
            return params;
        }
        let result = {};
        if (params instanceof FormData) {
            return params;
        }
        for (let key in params) {
            if (!Object.prototype.hasOwnProperty.call(params, key)) continue;
            if (typeof params[key] === "undefined") continue;
            if (params[key] === '' || params[key] === null) continue;
            result[key] = params[key];
        }
        return result;
    },

    serialize(obj, prefix) {
        let str = [],
            p;
        for (p in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(p)) {
                let k = prefix ? prefix + "[" + p + "]" : p,
                    v = obj[p];
                str.push((v !== null && typeof v === "object") ?
                    this.serialize(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
        return str.join("&");
    },
}
