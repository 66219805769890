<template>
    <div class="campaign-page w-100">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <b-col :cols="isMobile ? 12 : 6">
                        <template v-if="isMobile">
                            <h3>{{ $t('campaign.title') }}</h3>
                        </template>
                        <template v-else>
                            <h1>{{ $t('campaign.title') }}</h1>
                        </template>
                    </b-col>

                    <b-col :cols="isMobile ? 12 : 6" :class="{'mt-4': isMobile}">
                        <router-link tag="a" :to="{ name: 'CampaignCreate'}" class="btn btn-primary float-right">
                            {{ $t('campaign.add_new') }}
                        </router-link>
                    </b-col>
                </div>
                <div class="row mb-2">
                    <breadcrumb-layout></breadcrumb-layout>
                </div>
            </div>
        </section>
        <div class="card-datatable d-flex p-3" :class="{'flex-wrap': isMobile}">
            <filter-box-layout :options="campaignOptions" :attributes="brandAIAttributes" :is-show="isShowFilter"
                               @filtered="filtered"
                               @hide="hideFilter"></filter-box-layout>
            <div class="content content-datatable" :class="{'collapse-right': !isMobile && isShowFilter}">
                <div class="clearfix"></div>

                <div class="card">
                    <div class="card-header card-header-datatable">
                        <div class="row">
                            <div class="col-12 d-flex w-100">
                                <div class="filter-box w-50">
                                    <div class="form-group max-width-40">
                                        <button class="btn-filter btn btn-primary m-r-15"
                                                @click="isShowFilter = !isShowFilter">
                                            <font-awesome-icon icon="fa-solid fa-filter"/>
                                        </button>
                                    </div>
                                </div>
                                <div class="search-box text-right w-50 d-flex justify-content-end">
                                    <div class="form-group d-flex max-width-300">
                                        <input type="text" class="form-control mr-2"
                                               :placeholder="$t('campaign.searching')"
                                               v-model="payload.search" @keydown="searching()">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex w-100 justify-content-end">
                                <div class="card-adding-button">
                                    <button class="btn btn-primary button-adding">
                                        {{ $t('campaign.campaign_manage') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row form-group">
                            <div class="col-md-12">
                                <b-overlay :show="isLoading" rounded="lg">
                                    <div class="table-hover table-responsive table-custom">
                                        <datatable-layout
                                                type="campaign-brand-ai"
                                                :key="`campaign-table-${keyRerender}`"
                                                :items="campaigns"
                                                :attributes="brandAIAttributes"
                                                :options="campaignOptions"
                                                :is-need-checkbox="true"
                                                :is-paginate="true"
                                                :is-update="true"
                                                :page="campaignMeta.current_page || 1"
                                                :checked-items="swapKeysAndValues(campaignCheckBox)"
                                                @checked="checkedCampaign"
                                        >

                                        </datatable-layout>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                        <pagination-layout :meta="campaignMeta" :flag-paginate-special="10"
                                           @paginate="paginate"></pagination-layout>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    computed: {
        isMobile() {
            return this.$device.mobile
        },
        ...mapGetters(["campaigns", "campaignMeta"]),
        campaignOptions() {
            return {
                status: [
                    {
                        id: 0,
                        name: this.$i18n.t('campaign.option_pending'),
                        class: 'badge badge-warning'
                    },
                    {
                        id: 1,
                        name: this.$i18n.t('campaign.option_running'),
                        class: 'badge badge-success'
                    },
                    {
                        id: 2,
                        name: this.$i18n.t('campaign.option_stop'),
                        class: 'badge badge-primary'
                    },
                    {
                        id: 3,
                        name: this.$i18n.t('campaign.option_delete'),
                        class: 'badge badge-danger'
                    }
                ],
            }
        },
        brandAIAttributes() {
            return [
                {
                    name: this.$i18n.t('campaign.attribute.id'),
                    attribute: 'id',
                    type: 'string',
                    className: "",
                    isSort: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.status'),
                    attribute: 'status',
                    type: 'option',
                    className: "",
                    isSort: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.advertiser'),
                    attribute: 'advertiser_name',
                    type: 'string',
                    className: "",
                    isSort: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.brand'),
                    attribute: 'brand_name',
                    type: 'string',
                    className: "",
                    isSort: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.name'),
                    attribute: 'name',
                    type: 'string',
                    className: "",
                    isSort: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.budget'),
                    attribute: 'budget',
                    type: 'price',
                    className: "",
                    isSort: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.count_product'),
                    attribute: 'total_product',
                    type: 'number',
                    className: "",
                    isSort: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.created_by'),
                    attribute: 'created_by',
                    type: 'string',
                    className: "",
                    isSort: true,
                    isFetch: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.started_at'),
                    attribute: 'started_at',
                    type: 'date',
                    className: "",
                    isSort: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.ended_at'),
                    attribute: 'ended_at',
                    type: 'date',
                    className: "",
                    isSort: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.cpc'),
                    attribute: 'cpc',
                    type: 'number',
                    className: "",
                    isSort: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.showing'),
                    attribute: 'showing',
                    type: 'number',
                    className: "",
                    isSort: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.cost'),
                    attribute: 'cost',
                    type: 'price',
                    className: "",
                    isSort: true
                },
                {
                    name: this.$i18n.t('campaign.attribute.created_at'),
                    attribute: 'created_at',
                    type: 'date',
                    className: "",
                    isSort: true
                }
            ]
        }
    },
    mounted() {
        this.getListCampaign()
    },
    data() {
        return {
            isLoading: false,
            isShowFilter: false,
            filter: {},
            payload: {
                search: "",
                page: 1
            },
            campaignCheckBox: [],
            keyRerender: 0,
            typingTimer: null
        }
    },
    methods: {

        searching() {
            const vm = this
            clearTimeout(vm.typingTimer);
            vm.typingTimer = setTimeout(function () {
                vm.getListCampaign()
            }, 500);
        },

        reRender() {
            this.keyRerender++
        },

        async getListCampaign() {
            this.isLoading = true
            const payload = {
                ...this.payload,
                ...this.filter
            }
            const res = await this.$store.dispatch('getCampaigns', payload)
            this.isLoading = false
            if (!res.data.isStorage) {
                this.reRender()
            }
        },

        async paginate(page) {
            this.payload.page = page
            await this.getListCampaign()
        },

        checkedCampaign(campaigns) {
            this.campaignCheckBox = campaigns
        },

        searchOptionFilter() {

        },

        hideFilter() {
            let vm = this;
            vm.isShowFilter = false;
        },

        filtered(filters) {
            let vm = this;
            vm.filter = {}
            filters.forEach(function (filter) {
                let value = filter.value;
                let attribute = filter.attribute;
                if (value !== null) {
                    if (typeof value === 'object') {
                        if (Array.isArray(value) && value.length > 0) {
                            vm.filter[attribute] = value;
                        } else {
                            if (value.from !== null) {
                                vm.filter['from_' + attribute] = value.from;
                            }
                            if (filter.value.to !== null) {
                                vm.filter['to_' + attribute] = value.to;
                            }
                        }
                    } else if (typeof value !== 'object') {
                        vm.filter[attribute] = value;
                    }
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">

</style>
