export default {
    title: 'Chỉnh sửa hồ sơ',
    title_store: 'Tên cửa hàng',
    title_avatar: 'Ảnh hồ sơ',
    title_avatar_upload: 'Ảnh đại diện',
    title_cover_image_upload: 'Ảnh bìa',
    title_info: 'Thông tin tài khoản',
    title_name: 'Tên hiển thị',
    title_email: 'Thư điện tử',
    title_phone: 'Số điện thoại',
    title_gender: 'Giới tính',
    title_birthday: 'Ngày sinh',
    title_channel_id: 'Thông tin kênh',
    title_description: 'Miêu tả',
    title_password: 'Mật khẩu',
    title_current_password: 'Mật khẩu hiện tại',
    title_new_password: 'Mật khẩu mới',
    title_re_new_password: 'Xác nhận mật khẩu',
    gender_male: 'Nam',
    gender_female: 'Nữ',
    gender_other: 'Khác',
    error_wrong_current_password: 'Mật khẩu không chính xác',
    error_confirm_password: 'Vui lòng kiểm tra lại mật khẩu',
    title_update_avatar: 'Cập nhật Ảnh hồ sơ',
    update_avatar: 'Cập nhật Ảnh hồ sơ thành công',
    update_avatar_fail: 'Cập nhật Ảnh hồ sơ thất bại',
    title_update_info: 'Cập nhật Thông tin tài khoản',
    update_info: 'Cập nhật Thông tin tài khoản thành công',
    update_info_fail: 'Cập nhật Thông tin tài khoản thất bại',
    title_update_password: 'Cập nhật Mật khẩu',
    update_password: 'Cập nhật Mật khẩu thành công',
    update_password_fail: 'Cập nhật Mật khẩu thất bại',
}
