<template>
  <div class="campaign-create-page w-100">

    <title-page :title="$t('booking.pick.create.title')" :icon="'ComputerDisk'"
                :is-has-create="false"></title-page>

    <b-form data-vv-scope="booking_product_validate">
      <b-overlay :show="isSubmit" rounded="lg">
        <booking-pick-fields v-if="!isLoading" :current-data="baseData"></booking-pick-fields>
      </b-overlay>
    </b-form>
    <div class="p-3">
      <b-card class="card-layer">
        <b-row>
          <b-col cols="12" class="text-right">
            <b-button class="button-violet" @click="submitCreateProduct" :disabled="isSubmit"
                      :style="{cursor: isSubmit ? 'no-drop' : 'pointer'}" variant="primary mr-4">
              <template v-if="isSubmit">
                <b-spinner small></b-spinner>
              </template>
              <template v-else>
                {{ $t('common.save') }}
              </template>
            </b-button>
            <b-button class="button-violet-outline" @click="cancelCreate()">{{ $t('common.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import BookingPickFields from "@/components/dashboard/components/pages/booking/BookingPickFields.vue"
import {mapGetters} from "vuex";
import TitlePage from "@/components/dashboard/layouts/TitlePage.vue";

export default {
  components: {
    TitlePage,
    BookingPickFields
  },
  computed: {
    ...mapGetters(["currentIpInfo"]),
    isMobile() {
      return this.$device.mobile
    },
  },
  async mounted() {
    await this.$store.dispatch('setResetVideoInformation')
    await this.$store.dispatch('setResetChannelInformation')
    await this.loading()
    await this.$validator.reset()
  },
  data() {
    return {
      baseData: {},
      isLoading: true,
      isSubmit: false
    }
  },
  methods: {
    async loading() {
      this.isLoading = true
      await this.$store.dispatch('getCurrentIpInfo')
      this.baseData = {
        video_id: '',
        video_title: '',
        video_url: '',
        keyword: [],
        status: '',
        type_link: '0',
        created_by: '',
        created_at: '',
        product_ids: [],
        country_code: 'VN'
      }
      this.baseData.country_code = this.currentIpInfo.countryCode
      this.isLoading = false
    },

    async cancelCreate() {
      await this.$router.push({name: 'BookingPicks'})
    },

    async submitCreateProduct() {
      const valid = await this.$validator.validate('booking_pick_validate.*')
      if (!valid) {
        return false
      }
      const vm = this
      const description = this.$t('booking.pick.create.confirm_description')
      this.$bvModal.msgBoxConfirm(description, {
        title: this.$t('booking.pick.create.confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okTitle: this.$t('booking.pick.create.confirm_save'),
        cancelTitle: this.$t('booking.pick.create.confirm_cancel'),
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0 message-button-confirm'
      })
      .then(value => {
        if (!value) {
          return false
        }
        vm.createPick()
      })
    },

    async createPick() {
      const vm = this
      this.isSubmit = true
      Object.keys(this.baseData).forEach((key) => {
        if (['status'].includes(key)) {
          vm.baseData[key] = vm.baseData[key]['id'] || 0
        }
      })

      const youtubePattern = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gim
      const isYoutubeUrl = youtubePattern.test(this.baseData.video_id)
      let videoId = ''
      if (isYoutubeUrl) {
        let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
        videoId = regex.exec(this.baseData.video_id)[3];
      } else {
        videoId = this.baseData.video_id
      }
      this.baseData.video_id = videoId

      const res = await this.$store.dispatch('createPick', this.baseData)
      if (res.data.status === 200) {
        this.$root.$emit('toast-event', {
          description: this.$t('booking.pick.create.confirm_success_description'),
          title: this.$t('booking.pick.create.confirm_success_title'),
          variant: 'success',
        })
        await this.$router.push({name: 'BookingPicks'})
      } else {
        this.$root.$emit('toast-event', {
          description: this.$t('booking.pick.create.confirm_fail_description'),
          title: this.$t('booking.pick.create.confirm_fail_title'),
          variant: 'danger',
        })
      }
      this.isSubmit = false
    }
  }
}
</script>

<style scoped>

</style>