export default {
    upload_image: {
        button: 'Upload Image',
        cancel: 'Cancel',
        success: 'Upload Successfully',
        banner: 'Banner',
        image: 'Image {index}',
        upload: 'Update',
        delete_image: 'Delete'
    },
    google_search_title: 'Search and add a pin',
    google_button_search: 'Search',
    save: 'Save',
    cancel: 'Cancel',
    upload_min_size: 'The minimum size of the photo should be 100x100',
    upload_max_size: 'Maximum image size is 2000x2000',
    upload_error: 'Image upload failed',
}
