import {ProductServiceAPI} from "@/api/product"

const initialState = () => {
  return {
    products: {},
    productMeta: {},
    productByTypes: {},
    productByTypeMeta: {},
    productTypes: {
      products: []
    },
    productBrands: [],
    productCategories: [],
    searchProducts: [],
  }
}

export default {
  state: initialState,
  mutations: {
    setProducts(state, data) {
      state.products[data.meta.current_page] = data.data
    },
    setProductMeta(state, data) {
      state.productMeta = data
    },
    setProductByTypes(state, data) {
      state.productByTypes[data.meta.current_page] = data.data
    },
    setProductByTypeMeta(state, data) {
      state.productByTypeMeta = data
    },
    setProductTypes(state, data) {
      state.productTypes = data
    },
    setProductBrands(state, data) {
      state.productBrands = data
    },
    setProductCategories(state, data) {
      state.productCategories = data
    },
    setResetProductByTypes(state) {
      state.productByTypes = {}
      state.productByTypeMeta = {}
    },
    setResetCampaignProduct(state) {
      state.productTypes = {
        products: []
      };
      state.productByTypes = [];
      state.productByTypeMeta = {};
    },
    setResetProduct(state) {
      state.products = {}
      state.productMeta = {}
    },
    setSearchProducts(state, data) {
      state.searchProducts = data
    },
    resetProducts(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  actions: {
    async getProducts({commit, getters}, params) {
      try {
        if (typeof getters.products[params.page] !== "undefined" && (typeof params.is_reload === 'undefined' || !params.is_reload)
        ) {
          return {
            data: {
              status: 200,
              data: getters.products[params.page],
              meta: getters.productByTypeMeta,
              isStorage: true
            }
          };
        } else {
          let result = await ProductServiceAPI.getProducts(params);
          if (result && result.data.status === 200) {
            commit('setProducts', {data: result.data.data, meta: result.data.meta});
            commit('setProductMeta', result.data.meta)
          }
          return result;
        }
      } catch (e) {
        return e;
      }
    },
    
    async getProductByTypes({commit, getters}, params) {
      try {
        if (typeof getters.productByTypes[params.page] !== "undefined" && (typeof params.is_reload === 'undefined' || !params.is_reload)
        ) {
          return {
            data: {
              status: 200,
              data: getters.productByTypes[params.page],
              meta: getters.productByTypeMeta,
              isStorage: true
            }
          };
        } else {
          let result = await ProductServiceAPI.getProductByTypes(params);
          if (result && result.data.status === 200) {
            commit('setProductByTypes', {data: result.data.data, meta: result.data.meta})
            commit('setProductByTypeMeta', result.data.meta)
          }
          return result;
        }
      } catch (e) {
        return e;
      }
    },
    
    async resetProductByTypes({commit}) {
      commit('setResetProductByTypes', true)
    },
    
    async getProductTypes({commit}, params) {
      try {
        let result = await ProductServiceAPI.getProductTypes(params);
        if (result && result.status === 200) {
          commit('setProductTypes', result.data.data);
        }
        return result;
      } catch (e) {
        return e;
      }
    },
    
    async getProductBrands({commit}, params) {
      try {
        let result = await ProductServiceAPI.getBrands(params);
        if (result && result.status === 200) {
          commit('setProductBrands', result.data.data);
        }
        return result;
      } catch (e) {
        return e;
      }
    },
    
    async getProductCategories({commit}, params) {
      try {
        let result = await ProductServiceAPI.getCategories(params);
        if (result && result.status === 200) {
          commit('setProductCategories', result.data.data);
        }
        return result;
      } catch (e) {
        return e;
      }
    },
    
    resetCampaignProduct({commit}) {
      commit('setResetCampaignProduct', true);
    },
    
    async resetProduct({commit}) {
      commit('setResetProduct', true)
    },
    
    async searchProducts({commit}, params) {
      let result = await ProductServiceAPI.getProducts(params);
      if (result && result.data.status === 200) {
        commit('setSearchProducts', result.data.data)
      }
      return result;
    },
    
    async updateProductImage(params) {
      try {
        return await ProductServiceAPI.updateProductImage(params);
      } catch (e) {
        return e;
      }
    },
    
    async deleteProductImage(params) {
      try {
        return await ProductServiceAPI.deleteProductImage(params);
      } catch (e) {
        return e;
      }
    },
    
    resetProducts({commit}) {
      commit('resetProducts')
    }
  },
  getters: {
    products(state) {
      return state.products
    },
    productMeta(state) {
      return state.productMeta
    },
    productByTypes(state) {
      return state.productByTypes;
    },
    productByTypeMeta(state) {
      return state.productByTypeMeta
    },
    productTypes(state) {
      return state.productTypes
    },
    productBrands(state) {
      return state.productBrands
    },
    productCategories(state) {
      return state.productCategories
    },
    searchProducts(state) {
      return state.searchProducts
    }
  }
}
