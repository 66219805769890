<template>
  <div class="hl-timepicker position-relative" :class="name" :style="{maxWidth: maxWidth}">
    <input
        :key="uniqueId"
        v-bind:value="displayValue(value)"
        v-mask="'##:##'"
        type="text"
        v-bind="$attrs"
        v-bind:name="name"
        class="form-control hl-input-timepicker"
        :placeholder="placeHolder"
        v-on:click="showDropdown"
        @input="$emit('input', $event.target.value)"
        @blur="formatTime($event)"
    >
    <ul class="hl-dropdown hl-dropdown-timepicker mb-0" :class="isShowDropdown ? 'show' : ''">
      <template v-for="(time, index) in rangeTimes">
        <li v-on:click="clickItemDropdown(time)" :key="name + '-' + index">
          {{ time }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>

import {v4 as uuidv4} from 'uuid';

export default {
  props: {
    value: {
      type: String,
      default: '',
      require: true
    },
    icon: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: '',
      require: true
    },
    maxWidth: {
      type: String,
      default: '300px'
    },
    format: {
      type: String,
      default: 'HH:mm'
    },
    placeHolder: {
      type: String,
      default: ''
    },
    minHour: {
      type: Number,
      default: 1,
      max: 24,
      min: 1
    },
    maxHour: {
      type: Number,
      default: 24,
      max: 24,
      min: 1
    },
    incrementMinutes: {
      type: Number,
      default: 1,
      max: 60,
      min: 1
    },
  },
  mounted() {
    const vm = this
    this.$nextTick(() => {
      window.addEventListener('click', function (e) {
        const checkedElement = document.getElementsByClassName(vm.name)[0]
        if ((!checkedElement || checkedElement && !checkedElement.contains(e.target))) {
          vm.isShowDropdown = false
        }
      });
    })
  },
  computed: {
    rangeTimes() {
      const sizeOfMinutes = Math.round(60 / this.incrementMinutes)
      const times = [...Array(this.maxHour - this.minHour).keys()].map(i => i + this.minHour)
      const minutes = [...Array(sizeOfMinutes).keys()].map(i => i * this.incrementMinutes)
      const result = []
      times.forEach((time) => {
        if (time < 10) {
          time = '0' + time
        }
        minutes.forEach((minute) => {
          if (minute < 10) {
            minute = '0' + minute
          }
          result.push(`${time}:${minute}`)
        })
      })
      let maxHour = this.maxHour
      if (this.maxHour < 10) {
        maxHour = '0' + this.maxHour
      }
      if (this.maxHour === 24) {
        maxHour = '00'
      }
      result.push(`${maxHour}:00`)
      return result
    },
    uniqueId() {
      return uuidv4();
    }
  },
  watch: {},
  data() {
    return {
      time: "",
      isShowDropdown: false
    };
  },
  methods: {

    showDropdown() {
      this.isShowDropdown = true
    },

    clickItemDropdown(value) {
      this.isShowDropdown = false
      this.$emit('input', value);
    },

    formatTime(event) {
      const value = event.target.value

      const splintered = value.split(':')

      if (splintered[0] && parseInt(splintered[0]) > 23) {
        splintered[0] = '00'
      }
      if (splintered[0] && parseInt(splintered[0]) < 0) {
        splintered[0] = '00'
      }
      if (splintered[1] && parseInt(splintered[1]) > 59) {
        splintered[1] = '00'
      }
      if (splintered[1] && parseInt(splintered[1]) < 0) {
        splintered[1] = '00'
      }
      if (typeof splintered[0] === 'undefined') {
        splintered[0] = '00';
      }
      if (typeof splintered[1] === 'undefined') {
        splintered[1] = '00';
      }
      this.$emit('input', splintered.join(':'))
      this.$emit('blur', splintered.join(':'))
    },

    displayValue(value) {
      return value;
    }
  }
}
</script>

<style scoped lang="scss">
.hl-timepicker {

  &.is-invalid {

    input {
      border-color: #dc3545;
    }
  }
}

.hl-dropdown-timepicker {
  padding: 0;
  width: 100%;
  max-height: 250px;
  background: #FFFFFF;
  border-width: 0 1px 1px 1px;
  border-color: #e8ebf1;
  border-style: solid;
  overflow-y: auto;
  border-radius: 0.25rem;
  position: absolute;
  top: 38px;
  z-index: 100;
  height: 0;
  opacity: 0;
  transition: height 0.5s ease-in-out;

  &.show {
    height: auto;
    opacity: 1;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
  }

  li {
    text-align: center;
    padding: 10px 16px;
    cursor: pointer;

    &:hover {
      background: #0b95ef;
      color: #FFFFFF;
    }
  }
}
</style>