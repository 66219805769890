export default {
    product: {
        title: 'Products',
        add_new: 'Add New',
        searching: 'Searching...',
        option_pending: 'Inactive',
        option_running: 'Active',
        is_booking_off: 'Normal Product',
        is_booking_on: 'Booking Product',
        attribute: {
            id: 'ID',
            image: 'Image',
            is_booking: 'Product Type',
            store_id: 'Store',
            status: 'Status',
            main_category_id: 'Main Category',
            sub_category_id: 'Category',
            name: 'Name',
            zalo: 'Zalo',
            price: 'Price',
            discount_price: 'Discount Price',
            created_at: 'Created At'
        },
        create: {
            title: 'Create Product',
            confirm: 'Confirm Create Product',
            confirm_description: 'Are you sure about this decision?',
            confirm_save: 'Submit',
            confirm_cancel: 'Cancel',
            confirm_success_title: 'Success',
            confirm_success_description: 'Create Product Success!',
            confirm_fail_title: 'Fail',
            confirm_fail_description: 'Create Product Fail!',
        },
        fields: {
            title: 'Product Information',
            name: 'Name',
            is_booking: 'Product Type',
            image: 'Image',
            store_name : 'Store',
            status: 'Status',
            category: 'Category',
            parent_category: 'Parent Category',
            sub_category: 'Sub Categody',
            price: 'Price',
            sale_price: 'Discount Price',
            url: 'Url',
            keyword: 'Keyword',
            discount_rate: 'Discount: {rate}%',
            title_discount: 'Discount Information',
            title_created: 'Creater Information',
            add_discount: 'Add Discount',
            combo_service: 'Combo Service',
            discount_by_time: 'Hourly Discount',
            discount_price: 'Discount Price',
            time_slot: 'Time Slot',
            start_time: 'Start time',
            end_time: 'End time',
            applicable_date: 'Applicable Date',
            combo_name: 'Combo Name',
            setup_invalid: 'The time coincides with the time you set before',
            created_by: 'Creater',
            created_at: 'Created At'
        },
        update: {
            title: 'Update Product',
            confirm: 'Confirm Update Product',
            confirm_description: 'Are you sure about this decision?',
            confirm_save: 'Submit',
            confirm_cancel: 'Cancel',
            confirm_success_title: 'Success',
            confirm_success_description: 'Update Product Success!',
            confirm_fail_title: 'Fail',
            confirm_fail_description: 'Update Product Fail!',
        },
        preview: 'Preview',
        preview_other_product: 'Other Product',
        preview_open_time: 'Open Time',
        preview_close_time: 'Closed',
        preview_connect: 'Connect',
        preview_see_option: 'See Options',
        preview_chat_with_shop: 'Chat with Shop'
    },
    store: {
        title: 'Stores',
        add_new: 'Add New',
        searching: 'Searching...',
        option_pending: 'Inactive',
        option_running: 'Active',
        attribute: {
            id: 'ID',
            advertiser: 'Advertiser',
            advertiser_type: 'Advertiser Type',
            name: 'Name',
            sector: 'Sector',
            manager: 'Manager',
            manager_contact: 'Manager Contact',
            manager_email: 'Manager Email',
            customer_advice_contact: 'Customer Advice Contact',
            city_name: 'Province',
            district_name: 'District',
            ward_name: 'Ward',
            created_by: 'Created',
            approval_by: 'Approved',
            created_at: 'Created At',
        },
        type:{
            advertiser: 'Advertiser',
            agency: 'Agency'
        },
        create: {
            title: 'Create Store',
            confirm: 'Confirm Create Store',
            confirm_description: 'Are you sure about this decision?',
            confirm_save: 'Submit',
            confirm_cancel: 'Cancel',
            confirm_success_title: 'Success',
            confirm_success_description: 'Create Store Success!',
            confirm_fail_title: 'Fail',
            confirm_fail_description: 'Create Store Fail!',
        },
        fields: {
            title: 'Store Information',
            image: 'Store Image',
            name: 'Store Name',
            advertiser_type: 'Advertiser Type',
            sector: 'Sectors',
            status: 'Status',
            manager: 'Manager',
            manager_contact: 'Manager Contact',
            manager_email: 'Manager Email',
            customer_advice_contact: 'Contact (for customer advice)',
            cities: 'Cities',
            districts: 'Districts',
            wards: 'Wards',
            address: 'Location summary',
            maps: 'Maps',
            day_open: 'Open Days',
            zalo_contact: 'Zalo Contact',
            time_open: 'Time Open',
            open_time: 'Open',
            close_time: 'Close',
            button_add_time: 'More opening hours'
        },
        update: {
            title: 'Update Store',
            confirm: 'Update Store Confirm',
            confirm_description: 'Are you sure about this decision?',
            confirm_save: 'Submit',
            confirm_cancel: 'Cancel',
            confirm_success_title: 'Success',
            confirm_success_description: 'Update Store Success!',
            confirm_fail_title: 'Fail',
            confirm_fail_description: 'Update Store Fail!',
        },
    },
    pick: {
        title: 'Picks',
        add_new: 'Add New',
        searching: 'Searching...',
        option_pending: 'Inactive',
        option_running: 'Active',
        attribute: {
            id: 'ID',
            video_id: 'Video',
            video_title: 'Video Title',
            video_url: 'Video URL',
            status: 'Status',
            product_name: 'Product Name',
            price: 'Price',
            price_sale: 'Sale Price',
            created_by: 'Created By',
            created_at: 'Created At'
        },
        create: {
            title: 'Create Pick',
            confirm: 'Create Pick Confirm',
            confirm_description: 'Are you sure about this decision?',
            confirm_save: 'Submit',
            confirm_cancel: 'Cancel',
            confirm_success_title: 'Success',
            confirm_success_description: 'Create Pick Success!',
            confirm_fail_title: 'Fail',
            confirm_fail_description: 'Create Pick Fail!',
        },
        fields: {
            video: {
                title: 'Video Information',
                input_video_id: 'Video Id',
                button_video_id: 'Get Info',
                thumbnail_title: 'Video Info',
                video_url: 'Video URL',
                number_of: 'Count',
                information_video: 'Get Information',
                channel_title: 'Channel Information',
            },
            pick: {
                title: 'Pick Information',
                keyword: 'Keyword',
                status: 'Status',
                created_by: 'Created By',
                created_at: 'Created At',
                pick_type: 'Pick Type',
                pick_type_short: 'Video Short',
                pick_type_video: 'Video Normal',
            },
            products: {
                title: 'Matching Products',
                information: 'Product',
                price: 'Price',
                add_product: 'Add Product',
                table_store: 'Store',
                table_brand: 'Brand',
                table_category: 'Category',
                table_name: 'Name',
                table_price: 'Price',
                table_sale_price: 'Sale Price',
                table_keyword: 'Keyword',
                table_submit: 'Submit',
                number_product: 'Count Product',
                number_pick: 'Pick',
                table_count: 'Total {count} Product',
                table_title_count: 'Choose Product',
                table_btn_search: 'Search',
                table_title: 'Matching Product',
            }
        },
        update: {
            title: 'Update Pick',
            confirm: 'Update Pick Confirm',
            confirm_description: 'Are you sure about this decision?',
            confirm_save: 'Submit',
            confirm_cancel: 'Cancel',
            confirm_success_title: 'Success',
            confirm_success_description: 'Update Pick Success!',
            confirm_fail_title: 'Fail',
            confirm_fail_description: 'Update Pick Fail!',
        },
    }
}
