export default {
    title: 'Booking Products',
    add_new: 'Add New',
    searching: 'Searching...',
    option_pending: 'Inactive',
    option_running: 'Active',
    attribute:{
        id: 'ID',
        image: 'Image',
        advertiser: 'Advertiser',
        campaign: 'Campaign',
        name: 'Name',
        status: 'Status',
        brand: 'Brand',
        category: 'Category',
        price: 'Price',
        sale_price: 'Sale Price',
        is_mall_link: 'Link Type',
        keyword: 'Keyword',
        created_by: 'Created By',
        approval_by: 'Approval By',
        created_at: 'Created At',
    }
}
