<template>
  <section class="profile-page w-100">
    <title-page :title="$t('profile.title')" :icon="'WomanLightSkinTone'"></title-page>

    <section class="w-100 mb-4">
      <div class="container-fluid">
        <b-row>
          <b-col>
            <b-card class="card-layer">
              <h4 class="title-card-designer margin-bottom-24">{{ $t('profile.title_info') }}</h4>
              <b-row class="d-flex">
                <b-col cols="3" class="text-center">
                  <div class="hl-preview-image">
                    <img :src="preview !== '' ? preview : requestData.avartar" alt="Vodaplay">
                    <div class="change-avatar">
                      <a href="javascript:;" v-on:click="showPreviewImage">{{ $t('common.upload_image.upload') }}</a>
                    </div>
                  </div>
                  <input type="file"
                         accept=".png, .jpg, .jpeg, .gif"
                         style="display:none;"
                         ref="profile-upload-avatar"
                         name="profile-upload-avatar"
                         :id="'upload-image-profile-upload-avatar'"
                         @change="handleFileUpload()"
                  />
                  <div v-if="errorUpload !== ''" class="error-show text-danger">{{ errorUpload }}</div>
                  <div v-if="isSuccess" class="error-show text-success">{{ $t('common.upload_image.success') }}</div>

                  <div class="info mt-2 text-center">
                    <p class="name" style="margin-bottom: 4px">{{ requestData.name }}</p>
                    <p class="email mb-1">{{ requestData.email }}</p>
                  </div>
                </b-col>
                <b-col cols="9" class="pl-5">
                  <b-alert class="w-100" :show="submitError !== ''" variant="danger">{{ submitError }}</b-alert>
                  <table class="table table-no-border">
                    <tr>
                      <th style="width: 200px">
                        <label class="label-selector">{{ $t('profile.title_name') }} <span class="text-danger">*</span></label>
                      </th>
                      <td>
                        <input v-model="requestData.name"
                               v-validate="'required'"
                               data-vv-scope="profile_validate"
                               :data-vv-as="$t('profile.title_name')"
                               name="title_name"
                               type="text"
                               class="form-control"
                               :class="errors.first('profile_validate.title_name') ? 'is-invalid' : ''"
                               :placeholder="$t('profile.title_name') + '...'">
                        <b-form-invalid-feedback>{{
                            errors.first('profile_validate.title_name')
                          }}
                        </b-form-invalid-feedback>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 200px">
                        <label class="label-selector">{{ $t('profile.title_email') }} <span class="text-danger">*</span></label>
                      </th>
                      <td>
                        <span class="form-control readonly">{{ requestData.email }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 200px">
                        <label class="label-selector">{{ $t('profile.title_phone') }} </label>
                      </th>
                      <td>
                        <input v-model="requestData.phone"
                               v-validate="'phone_number_mask'"
                               v-mask="'#### ### ###'"
                               data-vv-scope="profile_validate"
                               :data-vv-as="$t('profile.title_phone')"
                               name="title_phone"
                               type="text"
                               class="form-control"
                               :class="errors.first('profile_validate.title_phone') ? 'is-invalid' : ''"
                               :placeholder="$t('profile.title_phone') + '...'">
                        <b-form-invalid-feedback>{{
                            errors.first('profile_validate.title_phone')
                          }}
                        </b-form-invalid-feedback>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 200px">
                        <label class="label-selector">{{ $t('profile.title_gender') }} </label>
                      </th>
                      <td>
                        <multiple-select
                            name="title_gender"
                            v-model="requestData.gender"
                            :options="options.gender"
                            :allow-empty="false"
                            :placeholder="$t('profile.title_gender')"
                            open-direction="bottom"
                            label="name"
                            track-by="id"
                            :show-labels="false"
                        >
                          <span slot="noResult">{{ $t('filter.no_result') }}</span>
                        </multiple-select>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 200px">
                        <label class="label-selector">{{ $t('profile.title_birthday') }} </label>
                      </th>
                      <td>
                        <date-picker
                            data-vv-scope="profile_validate"
                            name="title_birthday"
                            class="form-control"
                            v-model="requestData.birthday"
                            valueType="format">
                        </date-picker>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 200px">
                        <label class="label-selector">{{ $t('profile.title_description') }} </label>
                      </th>
                      <td>
                        <b-textarea v-model="requestData.description"
                                    :placeholder="$t('profile.title_description')"></b-textarea>
                      </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-end">
                <b-button class="button-violet-outline" style="margin-right: 24px;" @click="resetUploadProfile">
                  {{ $t('campaign.cancel') }}
                </b-button>
                <b-button class="button-violet" @click="submitUpdateProfile" :disabled="isSubmit"
                          :style="{cursor: isSubmit ? 'no-drop' : 'pointer'}">
                  <template v-if="isSubmit">
                    <b-spinner small></b-spinner>
                  </template>
                  <template v-else>
                    {{ $t('campaign.save') }}
                  </template>
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </section>

    <section class="w-100 mb-4">
      <div class="container-fluid">
        <div class="card card-layer">
          <div class="card-body">
            <h4 class="title-card-designer margin-bottom-24">{{ $t('profile.title_password') }}</h4>

            <div class="row">
              <b-alert class="w-100"
                       dismissible
                       :show="submitPasswordError !== ''"
                       variant="danger"
              >
                {{ error[submitPasswordError] }}
              </b-alert>
              <table class="table table-no-border ">
                <tr>
                  <th style="width: 200px">
                    <label class="label-selector">{{ $t('profile.title_current_password') }} <span
                        class="text-danger">*</span></label>
                  </th>
                  <td>
                    <b-form-input :type="'password'"
                                  v-model="passwordChange.currentPassword"
                                  :placeholder="$t('profile.title_current_password') "
                    >

                    </b-form-input>
                  </td>
                </tr>
                <tr>
                  <th style="width: 200px">
                    <label class="label-selector">{{ $t('profile.title_new_password') }} <span
                        class="text-danger">*</span></label>
                  </th>
                  <td>
                    <b-form-input :type="'password'"
                                  v-model="passwordChange.newPassword"
                                  :placeholder="$t('profile.title_new_password')"
                    ></b-form-input>
                  </td>
                </tr>
                <tr>
                  <th style="width: 200px">
                    <label class="label-selector">{{ $t('profile.title_re_new_password') }} <span
                        class="text-danger">*</span></label>
                  </th>
                  <td>
                    <b-form-input :type="'password'"
                                  v-model="passwordChange.confirmPassword"
                                  :placeholder="$t('profile.title_re_new_password')"
                    >

                    </b-form-input>
                  </td>
                </tr>
              </table>
            </div>
            <div class="d-flex justify-content-end mt-4">
              <b-button class="button-violet-outline" @click="resetUploadPassword" style="margin-right: 24px;">
                {{ $t('campaign.cancel') }}
              </b-button>
              <b-button class="button-violet" @click="submitUpdatePassword" :disabled="isSubmit"
                        :style="{cursor: isSubmit ? 'no-drop' : 'pointer'}">
                <template v-if="isSubmit">
                  <b-spinner small></b-spinner>
                </template>
                <template v-else>
                  {{ $t('campaign.save') }}
                </template>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>

  </section>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import TitlePage from "@/components/dashboard/layouts/TitlePage.vue";

export default {
  components: {TitlePage},
  computed: {
    isMobile() {
      return this.$device.mobile
    },
    ...mapGetters(["currentUser", "channelInformation"]),
    options() {
      return {
        gender: [
          {
            id: 0,
            name: this.$i18n.t('profile.gender_male')
          },
          {
            id: 1,
            name: this.$i18n.t('profile.gender_female')
          },
          {
            id: 2,
            name: this.$i18n.t('profile.gender_other')
          }
        ]
      };
    },
    error() {
      return {
        201: this.$i18n.t('profile.error_wrong_current_password'),
        202: this.$i18n.t('profile.error_confirm_password')
      }
    }
  },
  data() {
    return {
      requestData: {},
      passwordChange: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      cacheData: {},
      passwordChangeCache: {},
      isSubmit: false,
      submitError: '',
      isSubmitPassword: false,
      submitPasswordError: '',
      isSubmitAvatar: false,
      isSubmitCover: false,
      searchChannelLoading: false,
      isLoading: true,
      preview: '',
      isSuccess: '',
      errorUpload: '',
      isLoadingUpload: false
    }
  },
  async mounted() {
    await this.setFormData()
  },
  methods: {
    async resetUploadProfile() {
      await this.resetUploadOnUpload()
      this.requestData = JSON.parse(JSON.stringify(this.cacheData))
    },

    async resetUploadPassword() {
      this.passwordChange = JSON.parse(JSON.stringify(this.passwordChangeCache))
    },

    showPreviewImage() {
      const idElement = 'upload-image-profile-upload-avatar';
      document.getElementById(idElement).click()
    },

    handleFileUpload() {
      let vm = this;
      vm.errorUpload = "";
      vm.isSuccess = false;
      let files = vm.$refs['profile-upload-avatar'].files;
      if (files.length > 0) {
        let image = new Image();
        let imageSrc = window.URL.createObjectURL(files[0]);
        image.src = imageSrc;
        vm.preview = imageSrc;
        image.onload = function () {
          let width = image.naturalWidth,
              height = image.naturalHeight;

          if (width < 100 || height < 100) {
            vm.errorUpload = vm.$t('common.upload_min_size');
            vm.resetUploadOnUpload()
            return false;
          } else if (width > 2000 || height > 2000) {
            vm.errorUpload = vm.$t('common.upload_max_size');
            vm.resetUploadOnUpload()
            return false;
          }
          vm.file = files[0];
          vm.uploadFile()
        };
      }
    },

    async uploadFile() {
      let vm = this;
      if (!vm.isLoading) {
        vm.isLoading = true;
        let formData = new FormData()
        formData.append('file', this.file)
        const res = await this.$store.dispatch('uploadImage', formData)
        if (res.data.status === 200) {
          vm.requestData.avartar = res.data.data
          vm.preview = res.data.data
          vm.isSuccess = true
        } else {
          vm.errorUpload = vm.$t('common.upload_error');
          await vm.resetUploadOnUpload()
        }
        vm.isLoading = false
      }
    },

    async resetUploadOnUpload() {
      if (typeof this.$refs[this.refName] !== 'undefined') {
        this.$refs[this.refName].value = "";
      }
      await this.$store.dispatch('setResetUploadImage')
    },

    async setFormData() {
      this.$nextTick(() => {
        this.requestData = JSON.parse(JSON.stringify(this.currentUser))
        if (this.requestData.gender !== '' && this.options.gender.length > 0) {
          this.requestData.gender = this.options.gender.find((gender) => {
            return gender.id === this.requestData.gender
          })
        }
        if (this.requestData.birthday !== '') {
          this.requestData.birthday = moment.unix(this.requestData.birthday).format()
        }
        if (this.requestData.channel_id !== '') {
          this.getChannelInformation()
        }
        this.cacheData = JSON.parse(JSON.stringify(this.requestData))
        this.passwordChangeCache = JSON.parse(JSON.stringify(this.passwordChange))
        this.isLoading = false
      })
    },

    async getChannelInformation() {
      this.searchChannelLoading = true
      await this.$store.dispatch('getChannelInformation', {channel_id: this.requestData.channel_id})
      this.searchChannelLoading = false
    },

    async uploadAvatar(file) {
      this.isSubmitAvatar = true
      await this.$store.dispatch('updateAvatar', {avatar: file})
      await this.$store.dispatch('getUserInfo')
      await this.setFormData()
      this.isSubmitAvatar = false
    },

    async uploadCover(file) {
      this.isSubmitCover = true
      const response = await this.$store.dispatch('updateCover', {cover: file})
      if (!response.data.success) {
        this.$root.$emit('toast-event', {
          description: this.$t('profile.update_avatar_fail'),
          title: this.$t('profile.title_update_avatar'),
          variant: 'danger',
        })
      } else {
        this.$root.$emit('toast-event', {
          description: this.$t('profile.update_avatar'),
          title: this.$t('profile.title_update_avatar'),
          variant: 'success',
        })
        await this.$store.dispatch('getUserInfo')
        await this.setFormData()
      }
      this.isSubmitCover = false
    },

    async submitUpdateProfile() {
      const vm = this
      Object.keys(this.requestData).forEach((key) => {
        if (['gender'].includes(key)) {
          vm.requestData[key] = vm.requestData[key]['id'] || 0
        }
      })
      if (this.requestData.birthday !== '') {
        this.requestData.birthday = moment(this.requestData.birthday).unix()
      }
      this.isSubmit = true
      const response = await this.$store.dispatch('updateInfo', this.requestData)
      if (!response.data.success) {
        this.submitError = '';
        this.$root.$emit('toast-event', {
          description: this.$t('profile.update_info_fail'),
          title: this.$t('profile.title_update_info'),
          variant: 'danger',
        })
      } else {
        this.$root.$emit('toast-event', {
          description: this.$t('profile.update_info'),
          title: this.$t('profile.title_update_info'),
          variant: 'success',
        })
        await this.$store.dispatch('getUserInfo')
        await this.setFormData()
      }
      this.isSubmit = false
    },

    async submitUpdatePassword() {
      const vm = this
      this.isSubmitPassword = true
      const response = await this.$store.dispatch('updatePassword', this.passwordChange)
      if (!response.data.success) {
        this.submitPasswordError = response.data.code;
        this.$root.$emit('toast-event', {
          description: this.$t('profile.update_password_fail'),
          title: this.$t('profile.title_update_password'),
          variant: 'danger',
        })
        setTimeout(() => {
          vm.submitPasswordError = ''
        }, 5000)
      } else {
        this.$root.$emit('toast-event', {
          description: this.$t('profile.update_password'),
          title: this.$t('profile.title_update_password'),
          variant: 'success',
        })
        await this.$store.dispatch('getUserInfo')
        await this.setFormData()
      }
      this.isSubmitPassword = false
    }
  }
}
</script>

<style lang="scss">
.profile-page {

  .hl-preview-image {
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    width: 180px;
    height: 180px;
    min-width: 180px;
    min-height: 180px;
    margin: 0 auto;

    img {
      width: 180px;
      height: 180px;
      border-radius: 50% !important;
    }
  }

  .change-avatar {
    position: absolute;
    background: rgba(87, 80, 80, 0.4);
    text-align: center;
    bottom: 0;
    height: 40px;
    width: 100%;
    padding-top: 6px;

    a {
      color: #FFFFFF;
      font-size: 16px;
    }
  }

  .info {
    width: 100%;
    margin-top: 24px !important;

    .name {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      text-align: center;
      color: #090909;
    }

    .email {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
      color: #323232;
    }
  }
}
</style>
