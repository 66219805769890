<template>
  <div :class="classMain()">
    <template v-if="this.currentRouteName !== 'login' && this.currentRouteName !== 'loading' && this.currentRouteName !== 'MapContent' && this.currentRouteName !== 'NotFoundPage'">
      <side-bar-layout></side-bar-layout>
    </template>
    <router-view name="dashboard"></router-view>
    <router-view name="map"></router-view>
    <router-view name="login"></router-view>
    <router-view name="loading"></router-view>
    <router-view name="error"></router-view>
  </div>
</template>

<script>


import SideBarLayout from '@/components/dashboard/layouts/SideBarLayout'

export default {
  components: {
    SideBarLayout
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  created(){

  },
  methods: {
    classMain() {
      if (this.currentRouteName === 'login' || this.currentRouteName === 'loading' || this.currentRouteName === 'NotFoundPage') {
        return 'full-screen'
      } else {
        return 'main-wrapper'
      }
    }
  }
}
</script>
